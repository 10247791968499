import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Enum_Inward_Shipment_Status_Enum,
  GetAllInwardShipmentForRawMaterialQuery,
  GetAllInwardShipmentForRawMaterialQueryVariables,
} from '../../../../graphql/graphql-types';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Alert, Button, Modal, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ColumnSearchComponent from '../../../../components/ColumnSearchComponent';
import { RawMaterialInwardShipmentReportType } from '../../../../utils/types';
import { dateFormatFunc } from '../../../../utils/globals';
import { useApp } from '../../../../context/AppContext';
import RawMaterialPaymentForm from './RawMaterialPaymentForm';
import styles from '../InwardShipmentReportTable.module.scss';
import InwardShipmentSummaryScreen from './InwardShipmentSummaryScreen';
import EditCompletedGRNScreen from './EditCompletedGRNScreen';
import ProcessGRNScreen from '../../../inwardAdmin/ProcessGRNScreen';
import ModifyGRNScreen from '../../../inwardAdmin/ModifyGRNScreen';
import FinancialYearComponent from '../../../../components/FinancialYearComponent';
import InwardShipmentPrintScreen from '../InwardShipmentPrintScreen';
import { logger } from '../../../../utils/helpers';
import colors from '../../../../scss/variables.module.scss';
import FormItem from '../../../../components/FormItem';
import { useForm } from 'react-hook-form';
import RadioGroup from '../../../../components/RadioGroup';
import { useReactToPrint } from 'react-to-print';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* loading get all inward raw material shipment data query */
const getAllInwardRawMaterialQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForRawMaterialQuery.graphql',
);

/* type for inward shipment raw material filter by status */
type InwardShipmentRawMaterialType = {
  /* prop type for 'filterByStatus' radio buttons */
  filterByStatus: 'all' | 'completed' | 'inProcess';
};

/* react functional component */
const InwardShipmentRawMaterialScreen = (): JSX.Element => {
  /* extracting user from context */
  const { user } = useApp();

  /* this state used to store start and end date, which is selected using date range picker or financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* state to set the payment data & modal visibility when user click payment button */
  const [paymentDataAndIsModalVisible, setPaymentDataAndIsModalVisible] =
    useState<RawMaterialInwardShipmentReportType | null>(null);

  /** const to store reference which is used to print content */
  const printRef = useRef(null);

  /** const used to call 'react-to-print' print method*/
  const handlePrint = useReactToPrint({
    /* styling for page during print */
    content: () => printRef.current,
  });

  /** state use to store InwardShipmentId, which modal to open and title of modal so that we can decide which button is click based on that will decide content of modal */
  const [inwardShipmentIdAndModalVisibility, setInwardShipmentIdAndModalVisibility] = useState<{
    modalToOpen: 'edit' | 'summary' | 'process' | 'completedGrnEdit' | 'print';
    shipmentId: number;
    modalTitle: string;
  } | null>(null);

  const { watch, control } = useForm<InwardShipmentRawMaterialType>({
    defaultValues: {
      filterByStatus: 'all',
    },
    mode: 'onChange',
  });

  /* get all raw material shipments data query */
  const {
    data: getAllInwardRawMaterialShipmentData,
    loading: getAllInwardRawMaterialShipmentDataLoading,
    error: getAllInwardRawMaterialShipmentDataError,
    refetch,
    fetchMore,
  } = useQuery<
    GetAllInwardShipmentForRawMaterialQuery,
    GetAllInwardShipmentForRawMaterialQueryVariables
  >(getAllInwardRawMaterialQuery, {
    fetchPolicy: 'network-only',
    /** Intially fetching data for only 200 entries  */
    variables: {
      limit: 200,
    },
  });

  /** as soon as initial fetching of data is done then fetching remaining data */
  useEffect(() => {
    if (
      getAllInwardRawMaterialShipmentData &&
      getAllInwardRawMaterialShipmentData.inwardShipments.length === 200
    ) {
      fetchMore({
        variables: {
          offset: 200,
        },
        /** appending newly fetched data with the previously fetched data  */
        updateQuery(previousQueryResult, { fetchMoreResult }) {
          /** If there is no new data to fetch then returning previously fetched data */
          if (!fetchMoreResult) {
            return previousQueryResult;
          }

          return {
            ...previousQueryResult,
            inwardShipments: [
              ...previousQueryResult.inwardShipments,
              ...fetchMoreResult.inwardShipments,
            ],
          };
        },
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [fetchMore, getAllInwardRawMaterialShipmentData]);

  const filterByStatusVal = watch('filterByStatus');

  /** const to check 'All' radio box is selected or not */
  const allFilterByStatus = filterByStatusVal === 'all';

  /** const to check 'Completed' radio box is selected or not  */
  const completedFilterByStatus = filterByStatusVal === 'completed';

  /* function to filter inward shipment data based on the selected status*/
  const filteredInwardShipmentDataAndOptionArray = useCallback(
    (
      filterByStatus: string,
    ): {
      shipmentData: GetAllInwardShipmentForRawMaterialQuery['inwardShipments'];
      filteredOptions: {
        text: string;
        value: string;
      }[];
    } => {
      /** const to store filter option array which is used to apply filter on `Status` field of the table  */
      const filterOptionArray = [
        {
          text: 'Open',
          value: 'open',
        },
        {
          text: 'Unloaded',
          value: 'unloaded',
        },
        {
          text: 'Admin Open',
          value: 'adminOpen',
        },
        { text: 'Admin Review', value: 'adminReview' },
        { text: 'Completed', value: 'completed' },
      ];

      const isAllInwardShipmentData =
        getAllInwardRawMaterialShipmentData &&
        getAllInwardRawMaterialShipmentData.inwardShipments &&
        Array.isArray(getAllInwardRawMaterialShipmentData.inwardShipments) &&
        getAllInwardRawMaterialShipmentData.inwardShipments.length > 0;

      /* condition to check "inProcess" filter selected or not */
      if (filterByStatus === 'inProcess') {
        /** store inward shipment data which for four statuses excluding 'completed'.
         * This data is shown when user selected 'inProcess' filter status
         */
        filterOptionArray.pop();
        return {
          shipmentData: isAllInwardShipmentData
            ? getAllInwardRawMaterialShipmentData.inwardShipments.filter(
                (item) => item.status !== Enum_Inward_Shipment_Status_Enum.Completed,
              )
            : [],
          filteredOptions: filterOptionArray,
        };
      }

      /* condition to check "completed" filter selected or not */
      if (filterByStatusVal === 'completed') {
        /** condition to check "completed" filter selected or not.
         * store inward shipment data which includes shipment status as 'completed' and 'filteredOptions' as undefined
         */
        return {
          shipmentData: isAllInwardShipmentData
            ? getAllInwardRawMaterialShipmentData.inwardShipments.filter(
                (item) => item.status === Enum_Inward_Shipment_Status_Enum.Completed,
              )
            : [],
          filteredOptions: [],
        };
      }

      return {
        shipmentData: isAllInwardShipmentData
          ? getAllInwardRawMaterialShipmentData.inwardShipments
          : [],
        filteredOptions: filterOptionArray,
      };
    },
    [filterByStatusVal, getAllInwardRawMaterialShipmentData],
  );

  /** const to store filtered inward shipment data  and filter options for 'Status' column field
   * according to selected filter by calling `filteredInwardShipmentDataAndOptionArray` function */
  const filteredInwardShipmentData = filteredInwardShipmentDataAndOptionArray(filterByStatusVal);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllInwardRawMaterialShipmentDataError) {
    return <div className="errorText">{getAllInwardRawMaterialShipmentDataError.message}</div>;
  }

  // function use to get modal content based on which button is clicked
  const getModalContent = (): JSX.Element | null => {
    if (inwardShipmentIdAndModalVisibility) {
      if (inwardShipmentIdAndModalVisibility.modalToOpen === 'completedGrnEdit') {
        return (
          <EditCompletedGRNScreen
            editInwardShipmentId={inwardShipmentIdAndModalVisibility.shipmentId}
            closeModal={() => {
              setInwardShipmentIdAndModalVisibility(null);
            }}
          />
        );
      } else if (inwardShipmentIdAndModalVisibility.modalToOpen === 'process') {
        return (
          <ProcessGRNScreen
            inwardShipmentId={inwardShipmentIdAndModalVisibility.shipmentId}
            closeModal={() => {
              setInwardShipmentIdAndModalVisibility(null);
            }}
            refetch={() => {
              refetch().catch((err: ApolloError) => logger(err));
            }}
          />
        );
      } else if (inwardShipmentIdAndModalVisibility.modalToOpen === 'summary') {
        return (
          <InwardShipmentSummaryScreen summaryId={inwardShipmentIdAndModalVisibility.shipmentId} />
        );
      } else if (inwardShipmentIdAndModalVisibility.modalToOpen === 'edit') {
        return (
          <ModifyGRNScreen
            inwardShipmentId={inwardShipmentIdAndModalVisibility.shipmentId}
            closeModal={() => {
              setInwardShipmentIdAndModalVisibility(null);
            }}
          />
        );
      } else if (inwardShipmentIdAndModalVisibility.modalToOpen === 'print') {
        return (
          <InwardShipmentPrintScreen
            printId={inwardShipmentIdAndModalVisibility.shipmentId}
            isCalledFromSummaryModal={true}
            printReference={printRef}
          />
        );
      } else {
        return null;
      }
    }
    return null;
  };

  return (
    <div className={styles.reportTableContainer}>
      <Modal
        visible={
          inwardShipmentIdAndModalVisibility !== null || paymentDataAndIsModalVisible !== null
        }
        title={
          inwardShipmentIdAndModalVisibility
            ? inwardShipmentIdAndModalVisibility.modalTitle
            : 'Add Payment Details'
        }
        footer={
          inwardShipmentIdAndModalVisibility &&
          inwardShipmentIdAndModalVisibility.modalToOpen === 'print'
            ? [
                <Button
                  type="primary"
                  className="hideInPrint"
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Print
                </Button>,
              ]
            : false
        }
        destroyOnClose
        centered
        width={inwardShipmentIdAndModalVisibility ? 1200 : 900}
        onCancel={() => {
          setPaymentDataAndIsModalVisible(null);
          setInwardShipmentIdAndModalVisibility(null);
        }}
      >
        {paymentDataAndIsModalVisible ? (
          <RawMaterialPaymentForm inwardShipmentData={paymentDataAndIsModalVisible} />
        ) : (
          getModalContent()
        )}
      </Modal>

      <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      <FormItem label="Filter by Status :">
        <RadioGroup
          name="filterByStatus"
          options={[
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Completed GRN',
              value: 'completed',
            },
            {
              label: 'In Process GRN',
              value: 'inProcess',
            },
          ]}
          rhfControllerProps={{ control }}
        />
      </FormItem>
      {allFilterByStatus || completedFilterByStatus ? (
        <Alert
          type="info"
          message="Rows highlighted in light green indicate that full payment has been completed."
          style={{ marginTop: 20, width: 550 }}
          showIcon
        />
      ) : null}

      <div className="reportTable">
        <Table<RawMaterialInwardShipmentReportType>
          dataSource={
            filteredInwardShipmentData &&
            filteredInwardShipmentData.shipmentData &&
            selectedDate.startDate &&
            selectedDate.endDate
              ? filteredInwardShipmentData.shipmentData.filter((shipment) => {
                  return dayjs(shipment.createdAt).isBetween(
                    selectedDate.startDate,
                    selectedDate.endDate,
                    'date',
                    '[]',
                  );
                })
              : filteredInwardShipmentData.shipmentData
          }
          className="tableStyle"
          rowKey="id"
          bordered
          size="small"
          loading={getAllInwardRawMaterialShipmentDataLoading}
          pagination={{ showSizeChanger: true }}
          rowClassName={(record) => (record.isPaymentDone ? 'highLightColor' : '')}
          style={{ marginTop: 10 }}
        >
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="GRN/Date"
            dataIndex="grn"
            align="center"
            width={120}
            onFilter={(value, record) => {
              return record.grn
                ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(grnNumber, record) => {
              return (
                <>
                  <span>
                    <Button
                      type="link"
                      onClick={() => {
                        setInwardShipmentIdAndModalVisibility({
                          modalToOpen: 'summary',
                          shipmentId: record.id,
                          modalTitle: 'Inward Shipment Summary',
                        });
                      }}
                    >
                      {grnNumber}
                    </Button>
                  </span>
                  <br />
                  {record.createdAt ? dateFormatFunc(record.createdAt) : '-'}
                </>
              );
            }}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Status"
            dataIndex="status"
            key="status"
            align="center"
            width={100}
            filters={filteredInwardShipmentData.filteredOptions}
            onFilter={(value, record) => {
              return record.status ? record.status.indexOf(value.toString()) === 0 : false;
            }}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Vehicle No."
            dataIndex="vehicleNumber"
            width={120}
            align="center"
            render={(text, record) => record.vehicleNumber || '-'}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Farmer/Trader (Purchase)"
            key="seller"
            dataIndex="seller"
            align="center"
            width={200}
            onFilter={(value, { items }) => {
              if (items && Array.isArray(items) && items.length > 0) {
                /* variable for storing exist value */
                let exist = false;
                items.forEach((item) => {
                  if (
                    item.seller &&
                    item.seller.name.toLowerCase().includes(value.toString().toLowerCase())
                  ) {
                    exist = true;
                  }
                });
                return exist;
              }

              return false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent
                filterBy="farmer/trader purchase"
                filterDropDownProp={filterDropDownProp}
              />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => {
              if (Array.isArray(record.items) && record.items.length > 0) {
                const { items } = record;
                return items.map((seller) => {
                  return (
                    <div
                      key={seller.id}
                      style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
                    >
                      {(seller.seller && seller.seller.name) || '-'}
                    </div>
                  );
                });
              }
              return '-';
            }}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Paddy Name (Grade) - No. Of Bags"
            key="paddyName"
            dataIndex="paddyName"
            align="center"
            width={200}
            render={(text, record) => {
              if (Array.isArray(record.items) && record.items.length > 0) {
                const { items } = record;
                return items.map((item) => {
                  return (
                    <div key={item.id} style={{ whiteSpace: 'nowrap' }}>
                      {item.rawMaterial && item.rawMaterial.name}
                      {item.paddyGrade !== 'rice' &&
                        item.paddyGrade &&
                        `(${item.paddyGrade.replace(/[^\d]/g, '')})`}
                      {item && item.bagsCount ? ` - ${item.bagsCount} Bags` : null}
                    </div>
                  );
                });
              }
              return '-';
            }}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Unloading Location"
            key="unloadingLocation"
            dataIndex="unloadingLocation"
            align="center"
            width={100}
            render={(text, record) => {
              if (Array.isArray(record.items) && record.items.length > 0) {
                const { items } = record;
                return items.map((item) => {
                  if (item.destination === 'mill') {
                    return (
                      <div key={item.id}>
                        <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Mill: </span>
                        {item.mill ? item.mill.name : '-'}
                      </div>
                    );
                  }
                  return (
                    <div key={item.id} style={{ whiteSpace: 'nowrap' }}>
                      <span style={{ fontWeight: 'bold' }}>Godown: </span>
                      {item.godown ? item.godown.name : '-'}
                    </div>
                  );
                });
              }
              return '-';
            }}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Net Truck Weight (quintals)"
            key="netWeight"
            dataIndex="netWeight"
            align="center"
            render={(text, record) => {
              /* destructing shipment data */
              const { fullVehicleWtKg, emptyVehicleWtKg } = record;
              /* const used to store full vehicle weight in kg */
              const fullVehicleWeightInKg = fullVehicleWtKg ? fullVehicleWtKg : 0;

              /* const used to store empty vehicle weight in kg */
              const emptyVehicleWeightInKg = emptyVehicleWtKg ? emptyVehicleWtKg : 0;

              /* this variable used to store calculated 'net material weight' */
              const calculatedNetMaterialWeight = fullVehicleWeightInKg - emptyVehicleWeightInKg;

              return (
                <span>
                  {calculatedNetMaterialWeight === 0 ? '-' : calculatedNetMaterialWeight / 100}
                </span>
              );
            }}
            width={100}
          />
          <Table.Column<RawMaterialInwardShipmentReportType>
            title="Actions"
            key="action"
            align="center"
            width={200}
            render={(text, record) => {
              const printButton = (
                <Button
                  style={{ fontSize: 12 }}
                  type="default"
                  onClick={() => {
                    setInwardShipmentIdAndModalVisibility({
                      modalToOpen: 'print',
                      shipmentId: record.id,
                      modalTitle: '',
                    });
                  }}
                >
                  Print
                </Button>
              );
              if (user && user.role) {
                if (user.role === 'admin')
                  return (
                    <>
                      {record.status === 'completed' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 5,
                            alignItems: 'center',
                          }}
                        >
                          {printButton}
                          <Button
                            style={{ fontSize: 12 }}
                            onClick={() => {
                              setPaymentDataAndIsModalVisible(record);
                            }}
                          >
                            Payment
                          </Button>

                          <Button
                            type="default"
                            style={{
                              fontSize: 12,
                              backgroundColor: colors.colorGreen,
                              color: 'white',
                            }}
                            onClick={() => {
                              setInwardShipmentIdAndModalVisibility({
                                modalToOpen: 'completedGrnEdit',
                                shipmentId: record.id,
                                modalTitle: 'Edit Completed GRN',
                              });
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      ) : null}

                      {record.status !== 'completed' ? (
                        <>
                          {printButton}
                          <Button
                            type="default"
                            style={{ marginLeft: 10, fontSize: 12, marginBottom: 5 }}
                            onClick={() => {
                              setInwardShipmentIdAndModalVisibility({
                                modalToOpen: 'edit',
                                shipmentId: record.id,
                                modalTitle: 'Edit/Modify GRN',
                              });
                            }}
                          >
                            Edit
                          </Button>
                        </>
                      ) : null}

                      {record.status === 'adminOpen' ? (
                        <Button
                          type="default"
                          style={{ marginLeft: 10, fontSize: 12 }}
                          onClick={() => {
                            setInwardShipmentIdAndModalVisibility({
                              modalToOpen: 'process',
                              shipmentId: record.id,
                              modalTitle: 'Process GRN',
                            });
                          }}
                        >
                          Process
                        </Button>
                      ) : null}
                    </>
                  );
                if (user.role === 'staff' && record.status === 'completed') {
                  return (
                    <>
                      {printButton}
                      <Button
                        type="default"
                        style={{ marginLeft: 10, fontSize: 12 }}
                        onClick={() => {
                          setPaymentDataAndIsModalVisible(record);
                        }}
                      >
                        Payment
                      </Button>
                    </>
                  );
                }
                if (user.role === 'manager') {
                  return (
                    <>
                      {record.status === 'adminOpen' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}
                        >
                          {printButton}
                          <Button
                            type="default"
                            style={{ fontSize: 12 }}
                            onClick={() => {
                              setInwardShipmentIdAndModalVisibility({
                                modalToOpen: 'process',
                                shipmentId: record.id,
                                modalTitle: 'Process Inward Shipment',
                              });
                            }}
                          >
                            Process
                          </Button>
                        </div>
                      ) : null}
                      {record.status === 'completed' ? (
                        <Button
                          type="default"
                          style={{ marginLeft: 10, fontSize: 12 }}
                          onClick={() => {
                            setPaymentDataAndIsModalVisible(record);
                          }}
                        >
                          Payment
                        </Button>
                      ) : null}
                    </>
                  );
                }
              }
              return '-';
            }}
          />
        </Table>
      </div>
    </div>
  );
};

export default InwardShipmentRawMaterialScreen;
