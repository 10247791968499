import { Enum_Empty_Bag_Wt_Unit_Enum } from '../../graphql/graphql-types';
import { convertKgsToQuintals } from '../helpers';
import { CompletedShipmentItemType, InwardShipmentPrintTableType, ItemsShipment } from '../types';

/* function to calculate net material weight calculation for each shipment item present in inward shipment */
const netMaterialWeightCalculationFunction = (
  item: ItemsShipment | InwardShipmentPrintTableType | CompletedShipmentItemType,
  totalMaterialWtInKg: number,
  totalBags: number,
  emptyBagWtKgValue?: number | null,
  // empty bag weight type
  bagWeightTypeValue?: string | null,
) => {
  /* variable to storing  bag count value */
  const bagsCount = item.bagsCount ? item.bagsCount : 0;

  /*  if bagWeightTypeValue is present then its stored in variable other default type stored.  */
  const bagTypeValue = bagWeightTypeValue
    ? bagWeightTypeValue
    : Enum_Empty_Bag_Wt_Unit_Enum.KgPerBag;

  // here we are calculating total material wt(combination of empty bag wt and actual material) for given shipment item (single item)
  /* calculation of total weight of bags in kg */
  const totalWtOfBagsInKg = (bagsCount * totalMaterialWtInKg) / totalBags;

  // here we are calculating total material wt(combination of empty bag wt and actual material) for given shipment item (single item) in quintal
  /* calculation of total material weight in quintal */
  const totalMaterialWtInQt = convertKgsToQuintals(totalWtOfBagsInKg);

  /* calculation of weight of per bag */
  const wtOfPerBag = totalWtOfBagsInKg / bagsCount;

  /* stored weight of empty bag value */
  let wtOfEmptyBag = 0;

  /* if bag type value is 'kgPerQuintal' */
  if (bagTypeValue === 'kgPerQuintal') {
    if (emptyBagWtKgValue) {
      /* calculation of total weight of empty bag */
      const totalWtOfEmptyBag = emptyBagWtKgValue * totalMaterialWtInQt;

      /* calculation of weight of empty bag */
      wtOfEmptyBag = totalWtOfEmptyBag / bagsCount;
    }

    /* calculation of weight of material per bag */
    const wtOfMaterialPerBag = wtOfPerBag - wtOfEmptyBag;

    return parseFloat(wtOfMaterialPerBag.toFixed(2));
  }

  /* if bag type value is 'kgPerBag */
  if (bagTypeValue === 'kgPerBag') {
    /* calculation of weight of material per bag */
    const wtOfMaterialPerBag = wtOfPerBag - (emptyBagWtKgValue ? emptyBagWtKgValue : 0);

    return parseFloat(wtOfMaterialPerBag.toFixed(2));
  }
};

export default netMaterialWeightCalculationFunction;
