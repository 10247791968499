export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: Date | string;
  ecp13date: any;
  ecp13timestamptz: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: Date | string;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CreateUserOutputType = {
  __typename?: 'CreateUserOutputType';
  address?: Maybe<Scalars['json']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mobile: Scalars['String'];
  name: Scalars['String'];
  role: UserRole;
  updatePassword: Scalars['Boolean'];
};

export type DeleteS3FileOutputType = {
  __typename?: 'DeleteS3FileOutputType';
  status: Scalars['Boolean'];
};

export type FileUploadS3UrlOutputType = {
  __typename?: 'FileUploadS3UrlOutputType';
  algorithm: Scalars['String'];
  contentDisposition: Scalars['String'];
  contentType: Scalars['String'];
  credential: Scalars['String'];
  date: Scalars['String'];
  key: Scalars['String'];
  policy: Scalars['String'];
  signature: Scalars['String'];
  url: Scalars['String'];
};

export type GetFilesUrlOutputType = {
  __typename?: 'GetFilesUrlOutputType';
  key: Scalars['String'];
  url: Scalars['String'];
};

/** columns and relationships of "GodownCurrentState" */
export type GodownCurrentState = {
  __typename?: 'GodownCurrentState';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  godown?: Maybe<Godowns>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  paddyGrade?: Maybe<Scalars['String']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "GodownCurrentState" */
export type GodownCurrentState_Aggregate = {
  __typename?: 'GodownCurrentState_aggregate';
  aggregate?: Maybe<GodownCurrentState_Aggregate_Fields>;
  nodes: Array<GodownCurrentState>;
};

export type GodownCurrentState_Aggregate_Bool_Exp = {
  count?: InputMaybe<GodownCurrentState_Aggregate_Bool_Exp_Count>;
};

export type GodownCurrentState_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<GodownCurrentState_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "GodownCurrentState" */
export type GodownCurrentState_Aggregate_Fields = {
  __typename?: 'GodownCurrentState_aggregate_fields';
  avg?: Maybe<GodownCurrentState_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GodownCurrentState_Max_Fields>;
  min?: Maybe<GodownCurrentState_Min_Fields>;
  stddev?: Maybe<GodownCurrentState_Stddev_Fields>;
  stddev_pop?: Maybe<GodownCurrentState_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GodownCurrentState_Stddev_Samp_Fields>;
  sum?: Maybe<GodownCurrentState_Sum_Fields>;
  var_pop?: Maybe<GodownCurrentState_Var_Pop_Fields>;
  var_samp?: Maybe<GodownCurrentState_Var_Samp_Fields>;
  variance?: Maybe<GodownCurrentState_Variance_Fields>;
};


/** aggregate fields of "GodownCurrentState" */
export type GodownCurrentState_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "GodownCurrentState" */
export type GodownCurrentState_Aggregate_Order_By = {
  avg?: InputMaybe<GodownCurrentState_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<GodownCurrentState_Max_Order_By>;
  min?: InputMaybe<GodownCurrentState_Min_Order_By>;
  stddev?: InputMaybe<GodownCurrentState_Stddev_Order_By>;
  stddev_pop?: InputMaybe<GodownCurrentState_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<GodownCurrentState_Stddev_Samp_Order_By>;
  sum?: InputMaybe<GodownCurrentState_Sum_Order_By>;
  var_pop?: InputMaybe<GodownCurrentState_Var_Pop_Order_By>;
  var_samp?: InputMaybe<GodownCurrentState_Var_Samp_Order_By>;
  variance?: InputMaybe<GodownCurrentState_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "GodownCurrentState" */
export type GodownCurrentState_Arr_Rel_Insert_Input = {
  data: Array<GodownCurrentState_Insert_Input>;
};

/** aggregate avg on columns */
export type GodownCurrentState_Avg_Fields = {
  __typename?: 'GodownCurrentState_avg_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Avg_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "GodownCurrentState". All fields are combined with a logical 'AND'. */
export type GodownCurrentState_Bool_Exp = {
  _and?: InputMaybe<Array<GodownCurrentState_Bool_Exp>>;
  _not?: InputMaybe<GodownCurrentState_Bool_Exp>;
  _or?: InputMaybe<Array<GodownCurrentState_Bool_Exp>>;
  currentBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  godown?: InputMaybe<Godowns_Bool_Exp>;
  godownId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  inBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  outBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  paddyGrade?: InputMaybe<String_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "GodownCurrentState" */
export type GodownCurrentState_Insert_Input = {
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  godown?: InputMaybe<Godowns_Obj_Rel_Insert_Input>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type GodownCurrentState_Max_Fields = {
  __typename?: 'GodownCurrentState_max_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Max_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type GodownCurrentState_Min_Fields = {
  __typename?: 'GodownCurrentState_min_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Min_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "GodownCurrentState". */
export type GodownCurrentState_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godown?: InputMaybe<Godowns_Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** select columns of table "GodownCurrentState" */
export enum GodownCurrentState_Select_Column {
  /** column name */
  CurrentBagsCount = 'currentBagsCount',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  InBagsCount = 'inBagsCount',
  /** column name */
  OutBagsCount = 'outBagsCount',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  RawMaterialId = 'rawMaterialId'
}

/** aggregate stddev on columns */
export type GodownCurrentState_Stddev_Fields = {
  __typename?: 'GodownCurrentState_stddev_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Stddev_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GodownCurrentState_Stddev_Pop_Fields = {
  __typename?: 'GodownCurrentState_stddev_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Stddev_Pop_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GodownCurrentState_Stddev_Samp_Fields = {
  __typename?: 'GodownCurrentState_stddev_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Stddev_Samp_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "GodownCurrentState" */
export type GodownCurrentState_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GodownCurrentState_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GodownCurrentState_Stream_Cursor_Value_Input = {
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type GodownCurrentState_Sum_Fields = {
  __typename?: 'GodownCurrentState_sum_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  godownId?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Sum_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type GodownCurrentState_Var_Pop_Fields = {
  __typename?: 'GodownCurrentState_var_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Var_Pop_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GodownCurrentState_Var_Samp_Fields = {
  __typename?: 'GodownCurrentState_var_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Var_Samp_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type GodownCurrentState_Variance_Fields = {
  __typename?: 'GodownCurrentState_variance_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "GodownCurrentState" */
export type GodownCurrentState_Variance_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  email?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  token: Scalars['String'];
  updatePassword: Scalars['Boolean'];
};

/** columns and relationships of "MillCurrentState" */
export type MillCurrentState = {
  __typename?: 'MillCurrentState';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  mill?: Maybe<Mills>;
  millId?: Maybe<Scalars['Int']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  paddyGrade?: Maybe<Scalars['String']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "MillCurrentState" */
export type MillCurrentState_Aggregate = {
  __typename?: 'MillCurrentState_aggregate';
  aggregate?: Maybe<MillCurrentState_Aggregate_Fields>;
  nodes: Array<MillCurrentState>;
};

/** aggregate fields of "MillCurrentState" */
export type MillCurrentState_Aggregate_Fields = {
  __typename?: 'MillCurrentState_aggregate_fields';
  avg?: Maybe<MillCurrentState_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MillCurrentState_Max_Fields>;
  min?: Maybe<MillCurrentState_Min_Fields>;
  stddev?: Maybe<MillCurrentState_Stddev_Fields>;
  stddev_pop?: Maybe<MillCurrentState_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MillCurrentState_Stddev_Samp_Fields>;
  sum?: Maybe<MillCurrentState_Sum_Fields>;
  var_pop?: Maybe<MillCurrentState_Var_Pop_Fields>;
  var_samp?: Maybe<MillCurrentState_Var_Samp_Fields>;
  variance?: Maybe<MillCurrentState_Variance_Fields>;
};


/** aggregate fields of "MillCurrentState" */
export type MillCurrentState_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MillCurrentState_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MillCurrentState_Avg_Fields = {
  __typename?: 'MillCurrentState_avg_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MillCurrentState". All fields are combined with a logical 'AND'. */
export type MillCurrentState_Bool_Exp = {
  _and?: InputMaybe<Array<MillCurrentState_Bool_Exp>>;
  _not?: InputMaybe<MillCurrentState_Bool_Exp>;
  _or?: InputMaybe<Array<MillCurrentState_Bool_Exp>>;
  currentBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  inBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  mill?: InputMaybe<Mills_Bool_Exp>;
  millId?: InputMaybe<Int_Comparison_Exp>;
  outBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  paddyGrade?: InputMaybe<String_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type MillCurrentState_Max_Fields = {
  __typename?: 'MillCurrentState_max_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  millId?: Maybe<Scalars['Int']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type MillCurrentState_Min_Fields = {
  __typename?: 'MillCurrentState_min_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  millId?: Maybe<Scalars['Int']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "MillCurrentState". */
export type MillCurrentState_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  mill?: InputMaybe<Mills_Order_By>;
  millId?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** select columns of table "MillCurrentState" */
export enum MillCurrentState_Select_Column {
  /** column name */
  CurrentBagsCount = 'currentBagsCount',
  /** column name */
  Id = 'id',
  /** column name */
  InBagsCount = 'inBagsCount',
  /** column name */
  MillId = 'millId',
  /** column name */
  OutBagsCount = 'outBagsCount',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  RawMaterialId = 'rawMaterialId'
}

/** aggregate stddev on columns */
export type MillCurrentState_Stddev_Fields = {
  __typename?: 'MillCurrentState_stddev_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MillCurrentState_Stddev_Pop_Fields = {
  __typename?: 'MillCurrentState_stddev_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MillCurrentState_Stddev_Samp_Fields = {
  __typename?: 'MillCurrentState_stddev_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "MillCurrentState" */
export type MillCurrentState_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MillCurrentState_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MillCurrentState_Stream_Cursor_Value_Input = {
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  millId?: InputMaybe<Scalars['Int']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type MillCurrentState_Sum_Fields = {
  __typename?: 'MillCurrentState_sum_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  millId?: Maybe<Scalars['Int']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type MillCurrentState_Var_Pop_Fields = {
  __typename?: 'MillCurrentState_var_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MillCurrentState_Var_Samp_Fields = {
  __typename?: 'MillCurrentState_var_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MillCurrentState_Variance_Fields = {
  __typename?: 'MillCurrentState_variance_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "MillSummary" */
export type MillSummary = {
  __typename?: 'MillSummary';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "MillSummary" */
export type MillSummary_Aggregate = {
  __typename?: 'MillSummary_aggregate';
  aggregate?: Maybe<MillSummary_Aggregate_Fields>;
  nodes: Array<MillSummary>;
};

/** aggregate fields of "MillSummary" */
export type MillSummary_Aggregate_Fields = {
  __typename?: 'MillSummary_aggregate_fields';
  avg?: Maybe<MillSummary_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MillSummary_Max_Fields>;
  min?: Maybe<MillSummary_Min_Fields>;
  stddev?: Maybe<MillSummary_Stddev_Fields>;
  stddev_pop?: Maybe<MillSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MillSummary_Stddev_Samp_Fields>;
  sum?: Maybe<MillSummary_Sum_Fields>;
  var_pop?: Maybe<MillSummary_Var_Pop_Fields>;
  var_samp?: Maybe<MillSummary_Var_Samp_Fields>;
  variance?: Maybe<MillSummary_Variance_Fields>;
};


/** aggregate fields of "MillSummary" */
export type MillSummary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MillSummary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MillSummary_Avg_Fields = {
  __typename?: 'MillSummary_avg_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MillSummary". All fields are combined with a logical 'AND'. */
export type MillSummary_Bool_Exp = {
  _and?: InputMaybe<Array<MillSummary_Bool_Exp>>;
  _not?: InputMaybe<MillSummary_Bool_Exp>;
  _or?: InputMaybe<Array<MillSummary_Bool_Exp>>;
  currentBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  outBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type MillSummary_Max_Fields = {
  __typename?: 'MillSummary_max_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type MillSummary_Min_Fields = {
  __typename?: 'MillSummary_min_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "MillSummary". */
export type MillSummary_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
};

/** select columns of table "MillSummary" */
export enum MillSummary_Select_Column {
  /** column name */
  CurrentBagsCount = 'currentBagsCount',
  /** column name */
  Id = 'id',
  /** column name */
  InBagsCount = 'inBagsCount',
  /** column name */
  OutBagsCount = 'outBagsCount'
}

/** aggregate stddev on columns */
export type MillSummary_Stddev_Fields = {
  __typename?: 'MillSummary_stddev_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MillSummary_Stddev_Pop_Fields = {
  __typename?: 'MillSummary_stddev_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MillSummary_Stddev_Samp_Fields = {
  __typename?: 'MillSummary_stddev_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "MillSummary" */
export type MillSummary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MillSummary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MillSummary_Stream_Cursor_Value_Input = {
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type MillSummary_Sum_Fields = {
  __typename?: 'MillSummary_sum_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type MillSummary_Var_Pop_Fields = {
  __typename?: 'MillSummary_var_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MillSummary_Var_Samp_Fields = {
  __typename?: 'MillSummary_var_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MillSummary_Variance_Fields = {
  __typename?: 'MillSummary_variance_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

export type ResetUserPasswordOutputType = {
  __typename?: 'ResetUserPasswordOutputType';
  id: Scalars['Int'];
  updatedPassword: Scalars['Boolean'];
};

/** columns and relationships of "RmPgBagsSummary" */
export type RmPgBagsSummary = {
  __typename?: 'RmPgBagsSummary';
  averageWtPerBagKg?: Maybe<Scalars['float8']>;
  bagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  materialWeightKg?: Maybe<Scalars['float8']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "RmPgBagsSummary" */
export type RmPgBagsSummary_Aggregate = {
  __typename?: 'RmPgBagsSummary_aggregate';
  aggregate?: Maybe<RmPgBagsSummary_Aggregate_Fields>;
  nodes: Array<RmPgBagsSummary>;
};

/** aggregate fields of "RmPgBagsSummary" */
export type RmPgBagsSummary_Aggregate_Fields = {
  __typename?: 'RmPgBagsSummary_aggregate_fields';
  avg?: Maybe<RmPgBagsSummary_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RmPgBagsSummary_Max_Fields>;
  min?: Maybe<RmPgBagsSummary_Min_Fields>;
  stddev?: Maybe<RmPgBagsSummary_Stddev_Fields>;
  stddev_pop?: Maybe<RmPgBagsSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RmPgBagsSummary_Stddev_Samp_Fields>;
  sum?: Maybe<RmPgBagsSummary_Sum_Fields>;
  var_pop?: Maybe<RmPgBagsSummary_Var_Pop_Fields>;
  var_samp?: Maybe<RmPgBagsSummary_Var_Samp_Fields>;
  variance?: Maybe<RmPgBagsSummary_Variance_Fields>;
};


/** aggregate fields of "RmPgBagsSummary" */
export type RmPgBagsSummary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RmPgBagsSummary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RmPgBagsSummary_Avg_Fields = {
  __typename?: 'RmPgBagsSummary_avg_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "RmPgBagsSummary". All fields are combined with a logical 'AND'. */
export type RmPgBagsSummary_Bool_Exp = {
  _and?: InputMaybe<Array<RmPgBagsSummary_Bool_Exp>>;
  _not?: InputMaybe<RmPgBagsSummary_Bool_Exp>;
  _or?: InputMaybe<Array<RmPgBagsSummary_Bool_Exp>>;
  averageWtPerBagKg?: InputMaybe<Float8_Comparison_Exp>;
  bagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  materialWeightKg?: InputMaybe<Float8_Comparison_Exp>;
  paddyGrade?: InputMaybe<String_Comparison_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type RmPgBagsSummary_Max_Fields = {
  __typename?: 'RmPgBagsSummary_max_fields';
  averageWtPerBagKg?: Maybe<Scalars['float8']>;
  bagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  materialWeightKg?: Maybe<Scalars['float8']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type RmPgBagsSummary_Min_Fields = {
  __typename?: 'RmPgBagsSummary_min_fields';
  averageWtPerBagKg?: Maybe<Scalars['float8']>;
  bagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  materialWeightKg?: Maybe<Scalars['float8']>;
  paddyGrade?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "RmPgBagsSummary". */
export type RmPgBagsSummary_Order_By = {
  averageWtPerBagKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  materialWeightKg?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** select columns of table "RmPgBagsSummary" */
export enum RmPgBagsSummary_Select_Column {
  /** column name */
  AverageWtPerBagKg = 'averageWtPerBagKg',
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  Id = 'id',
  /** column name */
  MaterialWeightKg = 'materialWeightKg',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  RawMaterialId = 'rawMaterialId'
}

/** aggregate stddev on columns */
export type RmPgBagsSummary_Stddev_Fields = {
  __typename?: 'RmPgBagsSummary_stddev_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RmPgBagsSummary_Stddev_Pop_Fields = {
  __typename?: 'RmPgBagsSummary_stddev_pop_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RmPgBagsSummary_Stddev_Samp_Fields = {
  __typename?: 'RmPgBagsSummary_stddev_samp_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "RmPgBagsSummary" */
export type RmPgBagsSummary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RmPgBagsSummary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RmPgBagsSummary_Stream_Cursor_Value_Input = {
  averageWtPerBagKg?: InputMaybe<Scalars['float8']>;
  bagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  materialWeightKg?: InputMaybe<Scalars['float8']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type RmPgBagsSummary_Sum_Fields = {
  __typename?: 'RmPgBagsSummary_sum_fields';
  averageWtPerBagKg?: Maybe<Scalars['float8']>;
  bagsCount?: Maybe<Scalars['bigint']>;
  materialWeightKg?: Maybe<Scalars['float8']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type RmPgBagsSummary_Var_Pop_Fields = {
  __typename?: 'RmPgBagsSummary_var_pop_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RmPgBagsSummary_Var_Samp_Fields = {
  __typename?: 'RmPgBagsSummary_var_samp_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RmPgBagsSummary_Variance_Fields = {
  __typename?: 'RmPgBagsSummary_variance_fields';
  averageWtPerBagKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  materialWeightKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

export type StatusOutput = {
  __typename?: 'StatusOutput';
  status: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
  Staff = 'staff'
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "brokers" */
export type Brokers = {
  __typename?: 'brokers';
  address?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  gst?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  mobile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "brokers" */
export type BrokersAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "brokers" */
export type Brokers_Aggregate = {
  __typename?: 'brokers_aggregate';
  aggregate?: Maybe<Brokers_Aggregate_Fields>;
  nodes: Array<Brokers>;
};

/** aggregate fields of "brokers" */
export type Brokers_Aggregate_Fields = {
  __typename?: 'brokers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Brokers_Max_Fields>;
  min?: Maybe<Brokers_Min_Fields>;
};


/** aggregate fields of "brokers" */
export type Brokers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Brokers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Brokers_Append_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "brokers". All fields are combined with a logical 'AND'. */
export type Brokers_Bool_Exp = {
  _and?: InputMaybe<Array<Brokers_Bool_Exp>>;
  _not?: InputMaybe<Brokers_Bool_Exp>;
  _or?: InputMaybe<Array<Brokers_Bool_Exp>>;
  address?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  gst?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "brokers" */
export enum Brokers_Constraint {
  /** unique or primary key constraint on columns "gst" */
  BrokersGstKey = 'brokers_gst_key',
  /** unique or primary key constraint on columns "mobile" */
  BrokersMobileKey = 'brokers_mobile_key',
  /** unique or primary key constraint on columns "id" */
  BrokersPkey = 'brokers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Brokers_Delete_At_Path_Input = {
  address?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Brokers_Delete_Elem_Input = {
  address?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Brokers_Delete_Key_Input = {
  address?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "brokers" */
export type Brokers_Insert_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  gst?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Brokers_Max_Fields = {
  __typename?: 'brokers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  gst?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Brokers_Min_Fields = {
  __typename?: 'brokers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  gst?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "brokers" */
export type Brokers_Mutation_Response = {
  __typename?: 'brokers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Brokers>;
};

/** input type for inserting object relation for remote table "brokers" */
export type Brokers_Obj_Rel_Insert_Input = {
  data: Brokers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Brokers_On_Conflict>;
};

/** on_conflict condition type for table "brokers" */
export type Brokers_On_Conflict = {
  constraint: Brokers_Constraint;
  update_columns?: Array<Brokers_Update_Column>;
  where?: InputMaybe<Brokers_Bool_Exp>;
};

/** Ordering options when selecting data from "brokers". */
export type Brokers_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: brokers */
export type Brokers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Brokers_Prepend_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "brokers" */
export enum Brokers_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Gst = 'gst',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "brokers" */
export type Brokers_Set_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  gst?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "brokers" */
export type Brokers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Brokers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Brokers_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  gst?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "brokers" */
export enum Brokers_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Gst = 'gst',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Brokers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Brokers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Brokers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Brokers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Brokers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Brokers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Brokers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Brokers_Bool_Exp;
};

/** columns and relationships of "completed_process_yield" */
export type Completed_Process_Yield = {
  __typename?: 'completed_process_yield';
  id?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "completed_process_yield" */
export type Completed_Process_Yield_Aggregate = {
  __typename?: 'completed_process_yield_aggregate';
  aggregate?: Maybe<Completed_Process_Yield_Aggregate_Fields>;
  nodes: Array<Completed_Process_Yield>;
};

/** aggregate fields of "completed_process_yield" */
export type Completed_Process_Yield_Aggregate_Fields = {
  __typename?: 'completed_process_yield_aggregate_fields';
  avg?: Maybe<Completed_Process_Yield_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Completed_Process_Yield_Max_Fields>;
  min?: Maybe<Completed_Process_Yield_Min_Fields>;
  stddev?: Maybe<Completed_Process_Yield_Stddev_Fields>;
  stddev_pop?: Maybe<Completed_Process_Yield_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Completed_Process_Yield_Stddev_Samp_Fields>;
  sum?: Maybe<Completed_Process_Yield_Sum_Fields>;
  var_pop?: Maybe<Completed_Process_Yield_Var_Pop_Fields>;
  var_samp?: Maybe<Completed_Process_Yield_Var_Samp_Fields>;
  variance?: Maybe<Completed_Process_Yield_Variance_Fields>;
};


/** aggregate fields of "completed_process_yield" */
export type Completed_Process_Yield_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Completed_Process_Yield_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Completed_Process_Yield_Avg_Fields = {
  __typename?: 'completed_process_yield_avg_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "completed_process_yield". All fields are combined with a logical 'AND'. */
export type Completed_Process_Yield_Bool_Exp = {
  _and?: InputMaybe<Array<Completed_Process_Yield_Bool_Exp>>;
  _not?: InputMaybe<Completed_Process_Yield_Bool_Exp>;
  _or?: InputMaybe<Array<Completed_Process_Yield_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  yield?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Completed_Process_Yield_Max_Fields = {
  __typename?: 'completed_process_yield_max_fields';
  id?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Completed_Process_Yield_Min_Fields = {
  __typename?: 'completed_process_yield_min_fields';
  id?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "completed_process_yield". */
export type Completed_Process_Yield_Order_By = {
  id?: InputMaybe<Order_By>;
  yield?: InputMaybe<Order_By>;
};

/** select columns of table "completed_process_yield" */
export enum Completed_Process_Yield_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Yield = 'yield'
}

/** aggregate stddev on columns */
export type Completed_Process_Yield_Stddev_Fields = {
  __typename?: 'completed_process_yield_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Completed_Process_Yield_Stddev_Pop_Fields = {
  __typename?: 'completed_process_yield_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Completed_Process_Yield_Stddev_Samp_Fields = {
  __typename?: 'completed_process_yield_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "completed_process_yield" */
export type Completed_Process_Yield_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Completed_Process_Yield_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Completed_Process_Yield_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  yield?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Completed_Process_Yield_Sum_Fields = {
  __typename?: 'completed_process_yield_sum_fields';
  id?: Maybe<Scalars['Int']>;
  yield?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Completed_Process_Yield_Var_Pop_Fields = {
  __typename?: 'completed_process_yield_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Completed_Process_Yield_Var_Samp_Fields = {
  __typename?: 'completed_process_yield_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Completed_Process_Yield_Variance_Fields = {
  __typename?: 'completed_process_yield_variance_fields';
  id?: Maybe<Scalars['Float']>;
  yield?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "counters" */
export type Counters = {
  __typename?: 'counters';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lastValue: Scalars['Int'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "counters" */
export type Counters_Aggregate = {
  __typename?: 'counters_aggregate';
  aggregate?: Maybe<Counters_Aggregate_Fields>;
  nodes: Array<Counters>;
};

/** aggregate fields of "counters" */
export type Counters_Aggregate_Fields = {
  __typename?: 'counters_aggregate_fields';
  avg?: Maybe<Counters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Counters_Max_Fields>;
  min?: Maybe<Counters_Min_Fields>;
  stddev?: Maybe<Counters_Stddev_Fields>;
  stddev_pop?: Maybe<Counters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Counters_Stddev_Samp_Fields>;
  sum?: Maybe<Counters_Sum_Fields>;
  var_pop?: Maybe<Counters_Var_Pop_Fields>;
  var_samp?: Maybe<Counters_Var_Samp_Fields>;
  variance?: Maybe<Counters_Variance_Fields>;
};


/** aggregate fields of "counters" */
export type Counters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Counters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Counters_Avg_Fields = {
  __typename?: 'counters_avg_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "counters". All fields are combined with a logical 'AND'. */
export type Counters_Bool_Exp = {
  _and?: InputMaybe<Array<Counters_Bool_Exp>>;
  _not?: InputMaybe<Counters_Bool_Exp>;
  _or?: InputMaybe<Array<Counters_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lastValue?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "counters" */
export enum Counters_Constraint {
  /** unique or primary key constraint on columns "id" */
  CountersPkey = 'counters_pkey'
}

/** input type for incrementing numeric columns in table "counters" */
export type Counters_Inc_Input = {
  lastValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "counters" */
export type Counters_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lastValue?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Counters_Max_Fields = {
  __typename?: 'counters_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Counters_Min_Fields = {
  __typename?: 'counters_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastValue?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "counters" */
export type Counters_Mutation_Response = {
  __typename?: 'counters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Counters>;
};

/** on_conflict condition type for table "counters" */
export type Counters_On_Conflict = {
  constraint: Counters_Constraint;
  update_columns?: Array<Counters_Update_Column>;
  where?: InputMaybe<Counters_Bool_Exp>;
};

/** Ordering options when selecting data from "counters". */
export type Counters_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastValue?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: counters */
export type Counters_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "counters" */
export enum Counters_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LastValue = 'lastValue',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "counters" */
export type Counters_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lastValue?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Counters_Stddev_Fields = {
  __typename?: 'counters_stddev_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Counters_Stddev_Pop_Fields = {
  __typename?: 'counters_stddev_pop_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Counters_Stddev_Samp_Fields = {
  __typename?: 'counters_stddev_samp_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "counters" */
export type Counters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Counters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Counters_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lastValue?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Counters_Sum_Fields = {
  __typename?: 'counters_sum_fields';
  lastValue?: Maybe<Scalars['Int']>;
};

/** update columns of table "counters" */
export enum Counters_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LastValue = 'lastValue',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Counters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Counters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Counters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Counters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Counters_Var_Pop_Fields = {
  __typename?: 'counters_var_pop_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Counters_Var_Samp_Fields = {
  __typename?: 'counters_var_samp_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Counters_Variance_Fields = {
  __typename?: 'counters_variance_fields';
  lastValue?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Ecp13Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type Ecp13String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "access_logs" */
export type Ecp13access_Logs = {
  __typename?: 'ecp13access_logs';
  access_medium?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  created_at: Scalars['ecp13timestamptz'];
  email?: Maybe<Scalars['String']>;
  fingerprintjs2?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mobile?: Maybe<Scalars['String']>;
  mobile_device_unique_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['ecp13date']>;
  response_key?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

/** aggregated selection of "access_logs" */
export type Ecp13access_Logs_Aggregate = {
  __typename?: 'ecp13access_logs_aggregate';
  aggregate?: Maybe<Ecp13access_Logs_Aggregate_Fields>;
  nodes: Array<Ecp13access_Logs>;
};

/** aggregate fields of "access_logs" */
export type Ecp13access_Logs_Aggregate_Fields = {
  __typename?: 'ecp13access_logs_aggregate_fields';
  avg?: Maybe<Ecp13access_Logs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ecp13access_Logs_Max_Fields>;
  min?: Maybe<Ecp13access_Logs_Min_Fields>;
  stddev?: Maybe<Ecp13access_Logs_Stddev_Fields>;
  stddev_pop?: Maybe<Ecp13access_Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ecp13access_Logs_Stddev_Samp_Fields>;
  sum?: Maybe<Ecp13access_Logs_Sum_Fields>;
  var_pop?: Maybe<Ecp13access_Logs_Var_Pop_Fields>;
  var_samp?: Maybe<Ecp13access_Logs_Var_Samp_Fields>;
  variance?: Maybe<Ecp13access_Logs_Variance_Fields>;
};


/** aggregate fields of "access_logs" */
export type Ecp13access_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ecp13access_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ecp13access_Logs_Avg_Fields = {
  __typename?: 'ecp13access_logs_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "access_logs". All fields are combined with a logical 'AND'. */
export type Ecp13access_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Ecp13access_Logs_Bool_Exp>>;
  _not?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Ecp13access_Logs_Bool_Exp>>;
  access_medium?: InputMaybe<Ecp13String_Comparison_Exp>;
  cid?: InputMaybe<Ecp13String_Comparison_Exp>;
  code?: InputMaybe<Ecp13String_Comparison_Exp>;
  created_at?: InputMaybe<Ecp13timestamptz_Comparison_Exp>;
  email?: InputMaybe<Ecp13String_Comparison_Exp>;
  fingerprintjs2?: InputMaybe<Ecp13String_Comparison_Exp>;
  id?: InputMaybe<Ecp13Int_Comparison_Exp>;
  mobile?: InputMaybe<Ecp13String_Comparison_Exp>;
  mobile_device_unique_id?: InputMaybe<Ecp13String_Comparison_Exp>;
  name?: InputMaybe<Ecp13String_Comparison_Exp>;
  purchase_date?: InputMaybe<Ecp13date_Comparison_Exp>;
  response_key?: InputMaybe<Ecp13String_Comparison_Exp>;
  result?: InputMaybe<Ecp13String_Comparison_Exp>;
};

/** unique or primary key constraints on table "access_logs" */
export enum Ecp13access_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccessLogsPkey = 'access_logs_pkey'
}

/** input type for incrementing numeric columns in table "access_logs" */
export type Ecp13access_Logs_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "access_logs" */
export type Ecp13access_Logs_Insert_Input = {
  access_medium?: InputMaybe<Scalars['String']>;
  cid?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['ecp13timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  fingerprintjs2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile_device_unique_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  purchase_date?: InputMaybe<Scalars['ecp13date']>;
  response_key?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ecp13access_Logs_Max_Fields = {
  __typename?: 'ecp13access_logs_max_fields';
  access_medium?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ecp13timestamptz']>;
  email?: Maybe<Scalars['String']>;
  fingerprintjs2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile?: Maybe<Scalars['String']>;
  mobile_device_unique_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['ecp13date']>;
  response_key?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ecp13access_Logs_Min_Fields = {
  __typename?: 'ecp13access_logs_min_fields';
  access_medium?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ecp13timestamptz']>;
  email?: Maybe<Scalars['String']>;
  fingerprintjs2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile?: Maybe<Scalars['String']>;
  mobile_device_unique_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['ecp13date']>;
  response_key?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "access_logs" */
export type Ecp13access_Logs_Mutation_Response = {
  __typename?: 'ecp13access_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ecp13access_Logs>;
};

/** on_conflict condition type for table "access_logs" */
export type Ecp13access_Logs_On_Conflict = {
  constraint: Ecp13access_Logs_Constraint;
  update_columns?: Array<Ecp13access_Logs_Update_Column>;
  where?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "access_logs". */
export type Ecp13access_Logs_Order_By = {
  access_medium?: InputMaybe<Ecp13order_By>;
  cid?: InputMaybe<Ecp13order_By>;
  code?: InputMaybe<Ecp13order_By>;
  created_at?: InputMaybe<Ecp13order_By>;
  email?: InputMaybe<Ecp13order_By>;
  fingerprintjs2?: InputMaybe<Ecp13order_By>;
  id?: InputMaybe<Ecp13order_By>;
  mobile?: InputMaybe<Ecp13order_By>;
  mobile_device_unique_id?: InputMaybe<Ecp13order_By>;
  name?: InputMaybe<Ecp13order_By>;
  purchase_date?: InputMaybe<Ecp13order_By>;
  response_key?: InputMaybe<Ecp13order_By>;
  result?: InputMaybe<Ecp13order_By>;
};

/** primary key columns input for table: access_logs */
export type Ecp13access_Logs_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "access_logs" */
export enum Ecp13access_Logs_Select_Column {
  /** column name */
  AccessMedium = 'access_medium',
  /** column name */
  Cid = 'cid',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Fingerprintjs2 = 'fingerprintjs2',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  MobileDeviceUniqueId = 'mobile_device_unique_id',
  /** column name */
  Name = 'name',
  /** column name */
  PurchaseDate = 'purchase_date',
  /** column name */
  ResponseKey = 'response_key',
  /** column name */
  Result = 'result'
}

/** input type for updating data in table "access_logs" */
export type Ecp13access_Logs_Set_Input = {
  access_medium?: InputMaybe<Scalars['String']>;
  cid?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['ecp13timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  fingerprintjs2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile_device_unique_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  purchase_date?: InputMaybe<Scalars['ecp13date']>;
  response_key?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ecp13access_Logs_Stddev_Fields = {
  __typename?: 'ecp13access_logs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ecp13access_Logs_Stddev_Pop_Fields = {
  __typename?: 'ecp13access_logs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ecp13access_Logs_Stddev_Samp_Fields = {
  __typename?: 'ecp13access_logs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "access_logs" */
export type Ecp13access_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ecp13access_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Ecp13cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ecp13access_Logs_Stream_Cursor_Value_Input = {
  access_medium?: InputMaybe<Scalars['String']>;
  cid?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['ecp13timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  fingerprintjs2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  mobile_device_unique_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  purchase_date?: InputMaybe<Scalars['ecp13date']>;
  response_key?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ecp13access_Logs_Sum_Fields = {
  __typename?: 'ecp13access_logs_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "access_logs" */
export enum Ecp13access_Logs_Update_Column {
  /** column name */
  AccessMedium = 'access_medium',
  /** column name */
  Cid = 'cid',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Fingerprintjs2 = 'fingerprintjs2',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  MobileDeviceUniqueId = 'mobile_device_unique_id',
  /** column name */
  Name = 'name',
  /** column name */
  PurchaseDate = 'purchase_date',
  /** column name */
  ResponseKey = 'response_key',
  /** column name */
  Result = 'result'
}

export type Ecp13access_Logs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ecp13access_Logs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ecp13access_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ecp13access_Logs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ecp13access_Logs_Var_Pop_Fields = {
  __typename?: 'ecp13access_logs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ecp13access_Logs_Var_Samp_Fields = {
  __typename?: 'ecp13access_logs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ecp13access_Logs_Variance_Fields = {
  __typename?: 'ecp13access_logs_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Ecp13cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Ecp13date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ecp13date']>;
  _gt?: InputMaybe<Scalars['ecp13date']>;
  _gte?: InputMaybe<Scalars['ecp13date']>;
  _in?: InputMaybe<Array<Scalars['ecp13date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['ecp13date']>;
  _lte?: InputMaybe<Scalars['ecp13date']>;
  _neq?: InputMaybe<Scalars['ecp13date']>;
  _nin?: InputMaybe<Array<Scalars['ecp13date']>>;
};

/** columns and relationships of "labels" */
export type Ecp13labels = {
  __typename?: 'ecp13labels';
  code: Scalars['String'];
  created_at: Scalars['ecp13timestamptz'];
  id: Scalars['Int'];
};

/** aggregated selection of "labels" */
export type Ecp13labels_Aggregate = {
  __typename?: 'ecp13labels_aggregate';
  aggregate?: Maybe<Ecp13labels_Aggregate_Fields>;
  nodes: Array<Ecp13labels>;
};

/** aggregate fields of "labels" */
export type Ecp13labels_Aggregate_Fields = {
  __typename?: 'ecp13labels_aggregate_fields';
  avg?: Maybe<Ecp13labels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ecp13labels_Max_Fields>;
  min?: Maybe<Ecp13labels_Min_Fields>;
  stddev?: Maybe<Ecp13labels_Stddev_Fields>;
  stddev_pop?: Maybe<Ecp13labels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ecp13labels_Stddev_Samp_Fields>;
  sum?: Maybe<Ecp13labels_Sum_Fields>;
  var_pop?: Maybe<Ecp13labels_Var_Pop_Fields>;
  var_samp?: Maybe<Ecp13labels_Var_Samp_Fields>;
  variance?: Maybe<Ecp13labels_Variance_Fields>;
};


/** aggregate fields of "labels" */
export type Ecp13labels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ecp13labels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ecp13labels_Avg_Fields = {
  __typename?: 'ecp13labels_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "labels". All fields are combined with a logical 'AND'. */
export type Ecp13labels_Bool_Exp = {
  _and?: InputMaybe<Array<Ecp13labels_Bool_Exp>>;
  _not?: InputMaybe<Ecp13labels_Bool_Exp>;
  _or?: InputMaybe<Array<Ecp13labels_Bool_Exp>>;
  code?: InputMaybe<Ecp13String_Comparison_Exp>;
  created_at?: InputMaybe<Ecp13timestamptz_Comparison_Exp>;
  id?: InputMaybe<Ecp13Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "labels" */
export enum Ecp13labels_Constraint {
  /** unique or primary key constraint on columns "code" */
  EcCodeUnique = 'ec_code_unique',
  /** unique or primary key constraint on columns "id" */
  LabelsPkey = 'labels_pkey'
}

/** input type for incrementing numeric columns in table "labels" */
export type Ecp13labels_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "labels" */
export type Ecp13labels_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['ecp13timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ecp13labels_Max_Fields = {
  __typename?: 'ecp13labels_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ecp13timestamptz']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Ecp13labels_Min_Fields = {
  __typename?: 'ecp13labels_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['ecp13timestamptz']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "labels" */
export type Ecp13labels_Mutation_Response = {
  __typename?: 'ecp13labels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ecp13labels>;
};

/** on_conflict condition type for table "labels" */
export type Ecp13labels_On_Conflict = {
  constraint: Ecp13labels_Constraint;
  update_columns?: Array<Ecp13labels_Update_Column>;
  where?: InputMaybe<Ecp13labels_Bool_Exp>;
};

/** Ordering options when selecting data from "labels". */
export type Ecp13labels_Order_By = {
  code?: InputMaybe<Ecp13order_By>;
  created_at?: InputMaybe<Ecp13order_By>;
  id?: InputMaybe<Ecp13order_By>;
};

/** primary key columns input for table: labels */
export type Ecp13labels_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "labels" */
export enum Ecp13labels_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "labels" */
export type Ecp13labels_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['ecp13timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ecp13labels_Stddev_Fields = {
  __typename?: 'ecp13labels_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ecp13labels_Stddev_Pop_Fields = {
  __typename?: 'ecp13labels_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ecp13labels_Stddev_Samp_Fields = {
  __typename?: 'ecp13labels_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "labels" */
export type Ecp13labels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ecp13labels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Ecp13cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ecp13labels_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['ecp13timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ecp13labels_Sum_Fields = {
  __typename?: 'ecp13labels_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "labels" */
export enum Ecp13labels_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id'
}

export type Ecp13labels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ecp13labels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ecp13labels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ecp13labels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ecp13labels_Var_Pop_Fields = {
  __typename?: 'ecp13labels_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ecp13labels_Var_Samp_Fields = {
  __typename?: 'ecp13labels_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ecp13labels_Variance_Fields = {
  __typename?: 'ecp13labels_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Ecp13mutation_Root = {
  __typename?: 'ecp13mutation_root';
  /** delete data from the table: "access_logs" */
  delete_access_logs?: Maybe<Ecp13access_Logs_Mutation_Response>;
  /** delete single row from the table: "access_logs" */
  delete_access_logs_by_pk?: Maybe<Ecp13access_Logs>;
  /** delete data from the table: "labels" */
  delete_labels?: Maybe<Ecp13labels_Mutation_Response>;
  /** delete single row from the table: "labels" */
  delete_labels_by_pk?: Maybe<Ecp13labels>;
  /** insert data into the table: "access_logs" */
  insert_access_logs?: Maybe<Ecp13access_Logs_Mutation_Response>;
  /** insert a single row into the table: "access_logs" */
  insert_access_logs_one?: Maybe<Ecp13access_Logs>;
  /** insert data into the table: "labels" */
  insert_labels?: Maybe<Ecp13labels_Mutation_Response>;
  /** insert a single row into the table: "labels" */
  insert_labels_one?: Maybe<Ecp13labels>;
  /** update data of the table: "access_logs" */
  update_access_logs?: Maybe<Ecp13access_Logs_Mutation_Response>;
  /** update single row of the table: "access_logs" */
  update_access_logs_by_pk?: Maybe<Ecp13access_Logs>;
  /** update multiples rows of table: "access_logs" */
  update_access_logs_many?: Maybe<Array<Maybe<Ecp13access_Logs_Mutation_Response>>>;
  /** update data of the table: "labels" */
  update_labels?: Maybe<Ecp13labels_Mutation_Response>;
  /** update single row of the table: "labels" */
  update_labels_by_pk?: Maybe<Ecp13labels>;
  /** update multiples rows of table: "labels" */
  update_labels_many?: Maybe<Array<Maybe<Ecp13labels_Mutation_Response>>>;
};


export type Ecp13mutation_RootDelete_Access_LogsArgs = {
  where: Ecp13access_Logs_Bool_Exp;
};


export type Ecp13mutation_RootDelete_Access_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Ecp13mutation_RootDelete_LabelsArgs = {
  where: Ecp13labels_Bool_Exp;
};


export type Ecp13mutation_RootDelete_Labels_By_PkArgs = {
  id: Scalars['Int'];
};


export type Ecp13mutation_RootInsert_Access_LogsArgs = {
  objects: Array<Ecp13access_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Ecp13access_Logs_On_Conflict>;
};


export type Ecp13mutation_RootInsert_Access_Logs_OneArgs = {
  object: Ecp13access_Logs_Insert_Input;
  on_conflict?: InputMaybe<Ecp13access_Logs_On_Conflict>;
};


export type Ecp13mutation_RootInsert_LabelsArgs = {
  objects: Array<Ecp13labels_Insert_Input>;
  on_conflict?: InputMaybe<Ecp13labels_On_Conflict>;
};


export type Ecp13mutation_RootInsert_Labels_OneArgs = {
  object: Ecp13labels_Insert_Input;
  on_conflict?: InputMaybe<Ecp13labels_On_Conflict>;
};


export type Ecp13mutation_RootUpdate_Access_LogsArgs = {
  _inc?: InputMaybe<Ecp13access_Logs_Inc_Input>;
  _set?: InputMaybe<Ecp13access_Logs_Set_Input>;
  where: Ecp13access_Logs_Bool_Exp;
};


export type Ecp13mutation_RootUpdate_Access_Logs_By_PkArgs = {
  _inc?: InputMaybe<Ecp13access_Logs_Inc_Input>;
  _set?: InputMaybe<Ecp13access_Logs_Set_Input>;
  pk_columns: Ecp13access_Logs_Pk_Columns_Input;
};


export type Ecp13mutation_RootUpdate_Access_Logs_ManyArgs = {
  updates: Array<Ecp13access_Logs_Updates>;
};


export type Ecp13mutation_RootUpdate_LabelsArgs = {
  _inc?: InputMaybe<Ecp13labels_Inc_Input>;
  _set?: InputMaybe<Ecp13labels_Set_Input>;
  where: Ecp13labels_Bool_Exp;
};


export type Ecp13mutation_RootUpdate_Labels_By_PkArgs = {
  _inc?: InputMaybe<Ecp13labels_Inc_Input>;
  _set?: InputMaybe<Ecp13labels_Set_Input>;
  pk_columns: Ecp13labels_Pk_Columns_Input;
};


export type Ecp13mutation_RootUpdate_Labels_ManyArgs = {
  updates: Array<Ecp13labels_Updates>;
};

/** column ordering options */
export enum Ecp13order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Ecp13query_Root = {
  __typename?: 'ecp13query_root';
  /** fetch data from the table: "access_logs" */
  access_logs: Array<Ecp13access_Logs>;
  /** fetch aggregated fields from the table: "access_logs" */
  access_logs_aggregate: Ecp13access_Logs_Aggregate;
  /** fetch data from the table: "access_logs" using primary key columns */
  access_logs_by_pk?: Maybe<Ecp13access_Logs>;
  /** fetch data from the table: "labels" */
  labels: Array<Ecp13labels>;
  /** fetch aggregated fields from the table: "labels" */
  labels_aggregate: Ecp13labels_Aggregate;
  /** fetch data from the table: "labels" using primary key columns */
  labels_by_pk?: Maybe<Ecp13labels>;
};


export type Ecp13query_RootAccess_LogsArgs = {
  distinct_on?: InputMaybe<Array<Ecp13access_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13access_Logs_Order_By>>;
  where?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
};


export type Ecp13query_RootAccess_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ecp13access_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13access_Logs_Order_By>>;
  where?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
};


export type Ecp13query_RootAccess_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Ecp13query_RootLabelsArgs = {
  distinct_on?: InputMaybe<Array<Ecp13labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13labels_Order_By>>;
  where?: InputMaybe<Ecp13labels_Bool_Exp>;
};


export type Ecp13query_RootLabels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ecp13labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13labels_Order_By>>;
  where?: InputMaybe<Ecp13labels_Bool_Exp>;
};


export type Ecp13query_RootLabels_By_PkArgs = {
  id: Scalars['Int'];
};

export type Ecp13subscription_Root = {
  __typename?: 'ecp13subscription_root';
  /** fetch data from the table: "access_logs" */
  access_logs: Array<Ecp13access_Logs>;
  /** fetch aggregated fields from the table: "access_logs" */
  access_logs_aggregate: Ecp13access_Logs_Aggregate;
  /** fetch data from the table: "access_logs" using primary key columns */
  access_logs_by_pk?: Maybe<Ecp13access_Logs>;
  /** fetch data from the table in a streaming manner: "access_logs" */
  access_logs_stream: Array<Ecp13access_Logs>;
  /** fetch data from the table: "labels" */
  labels: Array<Ecp13labels>;
  /** fetch aggregated fields from the table: "labels" */
  labels_aggregate: Ecp13labels_Aggregate;
  /** fetch data from the table: "labels" using primary key columns */
  labels_by_pk?: Maybe<Ecp13labels>;
  /** fetch data from the table in a streaming manner: "labels" */
  labels_stream: Array<Ecp13labels>;
};


export type Ecp13subscription_RootAccess_LogsArgs = {
  distinct_on?: InputMaybe<Array<Ecp13access_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13access_Logs_Order_By>>;
  where?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
};


export type Ecp13subscription_RootAccess_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ecp13access_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13access_Logs_Order_By>>;
  where?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
};


export type Ecp13subscription_RootAccess_Logs_By_PkArgs = {
  id: Scalars['Int'];
};


export type Ecp13subscription_RootAccess_Logs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ecp13access_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Ecp13access_Logs_Bool_Exp>;
};


export type Ecp13subscription_RootLabelsArgs = {
  distinct_on?: InputMaybe<Array<Ecp13labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13labels_Order_By>>;
  where?: InputMaybe<Ecp13labels_Bool_Exp>;
};


export type Ecp13subscription_RootLabels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ecp13labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ecp13labels_Order_By>>;
  where?: InputMaybe<Ecp13labels_Bool_Exp>;
};


export type Ecp13subscription_RootLabels_By_PkArgs = {
  id: Scalars['Int'];
};


export type Ecp13subscription_RootLabels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ecp13labels_Stream_Cursor_Input>>;
  where?: InputMaybe<Ecp13labels_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Ecp13timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ecp13timestamptz']>;
  _gt?: InputMaybe<Scalars['ecp13timestamptz']>;
  _gte?: InputMaybe<Scalars['ecp13timestamptz']>;
  _in?: InputMaybe<Array<Scalars['ecp13timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['ecp13timestamptz']>;
  _lte?: InputMaybe<Scalars['ecp13timestamptz']>;
  _neq?: InputMaybe<Scalars['ecp13timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['ecp13timestamptz']>>;
};

/** columns and relationships of "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit = {
  __typename?: 'enum_empty_bag_wt_unit';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Aggregate = {
  __typename?: 'enum_empty_bag_wt_unit_aggregate';
  aggregate?: Maybe<Enum_Empty_Bag_Wt_Unit_Aggregate_Fields>;
  nodes: Array<Enum_Empty_Bag_Wt_Unit>;
};

/** aggregate fields of "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Aggregate_Fields = {
  __typename?: 'enum_empty_bag_wt_unit_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Empty_Bag_Wt_Unit_Max_Fields>;
  min?: Maybe<Enum_Empty_Bag_Wt_Unit_Min_Fields>;
};


/** aggregate fields of "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_empty_bag_wt_unit". All fields are combined with a logical 'AND'. */
export type Enum_Empty_Bag_Wt_Unit_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Bool_Exp>>;
  _not?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_empty_bag_wt_unit" */
export enum Enum_Empty_Bag_Wt_Unit_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumEmptyBagWtUnitPkey = 'enum_empty_bag_wt_unit_pkey'
}

export enum Enum_Empty_Bag_Wt_Unit_Enum {
  KgPerBag = 'kgPerBag',
  KgPerQuintal = 'kgPerQuintal'
}

/** Boolean expression to compare columns of type "enum_empty_bag_wt_unit_enum". All fields are combined with logical 'AND'. */
export type Enum_Empty_Bag_Wt_Unit_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Enum>;
  _in?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Enum>;
  _nin?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Enum>>;
};

/** input type for inserting data into table "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Empty_Bag_Wt_Unit_Max_Fields = {
  __typename?: 'enum_empty_bag_wt_unit_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Empty_Bag_Wt_Unit_Min_Fields = {
  __typename?: 'enum_empty_bag_wt_unit_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Mutation_Response = {
  __typename?: 'enum_empty_bag_wt_unit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Empty_Bag_Wt_Unit>;
};

/** on_conflict condition type for table "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_On_Conflict = {
  constraint: Enum_Empty_Bag_Wt_Unit_Constraint;
  update_columns?: Array<Enum_Empty_Bag_Wt_Unit_Update_Column>;
  where?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_empty_bag_wt_unit". */
export type Enum_Empty_Bag_Wt_Unit_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_empty_bag_wt_unit */
export type Enum_Empty_Bag_Wt_Unit_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_empty_bag_wt_unit" */
export enum Enum_Empty_Bag_Wt_Unit_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_empty_bag_wt_unit" */
export type Enum_Empty_Bag_Wt_Unit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Empty_Bag_Wt_Unit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Empty_Bag_Wt_Unit_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_empty_bag_wt_unit" */
export enum Enum_Empty_Bag_Wt_Unit_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Empty_Bag_Wt_Unit_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Empty_Bag_Wt_Unit_Bool_Exp;
};

/** columns and relationships of "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination = {
  __typename?: 'enum_internalTransfer_source_destination';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Aggregate = {
  __typename?: 'enum_internalTransfer_source_destination_aggregate';
  aggregate?: Maybe<Enum_InternalTransfer_Source_Destination_Aggregate_Fields>;
  nodes: Array<Enum_InternalTransfer_Source_Destination>;
};

/** aggregate fields of "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Aggregate_Fields = {
  __typename?: 'enum_internalTransfer_source_destination_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_InternalTransfer_Source_Destination_Max_Fields>;
  min?: Maybe<Enum_InternalTransfer_Source_Destination_Min_Fields>;
};


/** aggregate fields of "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_internalTransfer_source_destination". All fields are combined with a logical 'AND'. */
export type Enum_InternalTransfer_Source_Destination_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Bool_Exp>>;
  _not?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_internalTransfer_source_destination" */
export enum Enum_InternalTransfer_Source_Destination_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumInternalTransferShipmentItemSourcePkey = 'enum_internalTransferShipmentItem_source_pkey'
}

export enum Enum_InternalTransfer_Source_Destination_Enum {
  /** Godown */
  Godown = 'godown',
  /** Mill */
  Mill = 'mill'
}

/** Boolean expression to compare columns of type "enum_internalTransfer_source_destination_enum". All fields are combined with logical 'AND'. */
export type Enum_InternalTransfer_Source_Destination_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  _in?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  _nin?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Enum>>;
};

/** input type for inserting data into table "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_InternalTransfer_Source_Destination_Max_Fields = {
  __typename?: 'enum_internalTransfer_source_destination_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_InternalTransfer_Source_Destination_Min_Fields = {
  __typename?: 'enum_internalTransfer_source_destination_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Mutation_Response = {
  __typename?: 'enum_internalTransfer_source_destination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_InternalTransfer_Source_Destination>;
};

/** on_conflict condition type for table "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_On_Conflict = {
  constraint: Enum_InternalTransfer_Source_Destination_Constraint;
  update_columns?: Array<Enum_InternalTransfer_Source_Destination_Update_Column>;
  where?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_internalTransfer_source_destination". */
export type Enum_InternalTransfer_Source_Destination_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_internalTransfer_source_destination */
export type Enum_InternalTransfer_Source_Destination_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_internalTransfer_source_destination" */
export enum Enum_InternalTransfer_Source_Destination_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_internalTransfer_source_destination" */
export type Enum_InternalTransfer_Source_Destination_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_InternalTransfer_Source_Destination_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_InternalTransfer_Source_Destination_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_internalTransfer_source_destination" */
export enum Enum_InternalTransfer_Source_Destination_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_InternalTransfer_Source_Destination_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_InternalTransfer_Source_Destination_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_InternalTransfer_Source_Destination_Bool_Exp;
};

/** columns and relationships of "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source = {
  __typename?: 'enum_inwardShipmentItem_source';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Aggregate = {
  __typename?: 'enum_inwardShipmentItem_source_aggregate';
  aggregate?: Maybe<Enum_InwardShipmentItem_Source_Aggregate_Fields>;
  nodes: Array<Enum_InwardShipmentItem_Source>;
};

/** aggregate fields of "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Aggregate_Fields = {
  __typename?: 'enum_inwardShipmentItem_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_InwardShipmentItem_Source_Max_Fields>;
  min?: Maybe<Enum_InwardShipmentItem_Source_Min_Fields>;
};


/** aggregate fields of "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_inwardShipmentItem_source". All fields are combined with a logical 'AND'. */
export type Enum_InwardShipmentItem_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Bool_Exp>>;
  _not?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_inwardShipmentItem_source" */
export enum Enum_InwardShipmentItem_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumInwardShipmentItemSourcePkey = 'enum_inwardShipmentItem_source_pkey'
}

export enum Enum_InwardShipmentItem_Source_Enum {
  /** Vendor */
  Vendor = 'vendor'
}

/** Boolean expression to compare columns of type "enum_inwardShipmentItem_source_enum". All fields are combined with logical 'AND'. */
export type Enum_InwardShipmentItem_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_InwardShipmentItem_Source_Enum>;
  _in?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_InwardShipmentItem_Source_Enum>;
  _nin?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Enum>>;
};

/** input type for inserting data into table "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_InwardShipmentItem_Source_Max_Fields = {
  __typename?: 'enum_inwardShipmentItem_source_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_InwardShipmentItem_Source_Min_Fields = {
  __typename?: 'enum_inwardShipmentItem_source_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Mutation_Response = {
  __typename?: 'enum_inwardShipmentItem_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_InwardShipmentItem_Source>;
};

/** on_conflict condition type for table "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_On_Conflict = {
  constraint: Enum_InwardShipmentItem_Source_Constraint;
  update_columns?: Array<Enum_InwardShipmentItem_Source_Update_Column>;
  where?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_inwardShipmentItem_source". */
export type Enum_InwardShipmentItem_Source_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_inwardShipmentItem_source */
export type Enum_InwardShipmentItem_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_inwardShipmentItem_source" */
export enum Enum_InwardShipmentItem_Source_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_inwardShipmentItem_source" */
export type Enum_InwardShipmentItem_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_InwardShipmentItem_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_InwardShipmentItem_Source_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_inwardShipmentItem_source" */
export enum Enum_InwardShipmentItem_Source_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_InwardShipmentItem_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_InwardShipmentItem_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_InwardShipmentItem_Source_Bool_Exp;
};

/** columns and relationships of "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status = {
  __typename?: 'enum_inward_shipment_status';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Aggregate = {
  __typename?: 'enum_inward_shipment_status_aggregate';
  aggregate?: Maybe<Enum_Inward_Shipment_Status_Aggregate_Fields>;
  nodes: Array<Enum_Inward_Shipment_Status>;
};

/** aggregate fields of "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Aggregate_Fields = {
  __typename?: 'enum_inward_shipment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Inward_Shipment_Status_Max_Fields>;
  min?: Maybe<Enum_Inward_Shipment_Status_Min_Fields>;
};


/** aggregate fields of "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Inward_Shipment_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_inward_shipment_status". All fields are combined with a logical 'AND'. */
export type Enum_Inward_Shipment_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Inward_Shipment_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Inward_Shipment_Status_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_inward_shipment_status" */
export enum Enum_Inward_Shipment_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumInwardShipmentStatusPkey = 'enum_inward_shipment_status_pkey'
}

export enum Enum_Inward_Shipment_Status_Enum {
  AdminOpen = 'adminOpen',
  AdminReview = 'adminReview',
  Completed = 'completed',
  Open = 'open',
  Unloaded = 'unloaded'
}

/** Boolean expression to compare columns of type "enum_inward_shipment_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Inward_Shipment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Inward_Shipment_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Inward_Shipment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Inward_Shipment_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Inward_Shipment_Status_Enum>>;
};

/** input type for inserting data into table "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Inward_Shipment_Status_Max_Fields = {
  __typename?: 'enum_inward_shipment_status_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Inward_Shipment_Status_Min_Fields = {
  __typename?: 'enum_inward_shipment_status_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Mutation_Response = {
  __typename?: 'enum_inward_shipment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Inward_Shipment_Status>;
};

/** on_conflict condition type for table "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_On_Conflict = {
  constraint: Enum_Inward_Shipment_Status_Constraint;
  update_columns?: Array<Enum_Inward_Shipment_Status_Update_Column>;
  where?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_inward_shipment_status". */
export type Enum_Inward_Shipment_Status_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_inward_shipment_status */
export type Enum_Inward_Shipment_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_inward_shipment_status" */
export enum Enum_Inward_Shipment_Status_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_inward_shipment_status" */
export type Enum_Inward_Shipment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Inward_Shipment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Inward_Shipment_Status_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_inward_shipment_status" */
export enum Enum_Inward_Shipment_Status_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Inward_Shipment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Inward_Shipment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Inward_Shipment_Status_Bool_Exp;
};

/** enum table for item's category */
export type Enum_Item_Category = {
  __typename?: 'enum_item_category';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_item_category" */
export type Enum_Item_Category_Aggregate = {
  __typename?: 'enum_item_category_aggregate';
  aggregate?: Maybe<Enum_Item_Category_Aggregate_Fields>;
  nodes: Array<Enum_Item_Category>;
};

/** aggregate fields of "enum_item_category" */
export type Enum_Item_Category_Aggregate_Fields = {
  __typename?: 'enum_item_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Item_Category_Max_Fields>;
  min?: Maybe<Enum_Item_Category_Min_Fields>;
};


/** aggregate fields of "enum_item_category" */
export type Enum_Item_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Item_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_item_category". All fields are combined with a logical 'AND'. */
export type Enum_Item_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Item_Category_Bool_Exp>>;
  _not?: InputMaybe<Enum_Item_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Item_Category_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_item_category" */
export enum Enum_Item_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumItemCategoryPkey = 'enum_item_category_pkey'
}

export enum Enum_Item_Category_Enum {
  /** Others */
  Others = 'others',
  /** Paddy Bag */
  PaddyBag = 'paddy_bag',
  /** Product */
  Product = 'product',
  /** Product Bag */
  ProductBag = 'product_bag',
  /** Raw Material */
  RawMaterial = 'raw_material'
}

/** Boolean expression to compare columns of type "enum_item_category_enum". All fields are combined with logical 'AND'. */
export type Enum_Item_Category_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Item_Category_Enum>;
  _in?: InputMaybe<Array<Enum_Item_Category_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Item_Category_Enum>;
  _nin?: InputMaybe<Array<Enum_Item_Category_Enum>>;
};

/** input type for inserting data into table "enum_item_category" */
export type Enum_Item_Category_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Item_Category_Max_Fields = {
  __typename?: 'enum_item_category_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Item_Category_Min_Fields = {
  __typename?: 'enum_item_category_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_item_category" */
export type Enum_Item_Category_Mutation_Response = {
  __typename?: 'enum_item_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Item_Category>;
};

/** input type for inserting object relation for remote table "enum_item_category" */
export type Enum_Item_Category_Obj_Rel_Insert_Input = {
  data: Enum_Item_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Item_Category_On_Conflict>;
};

/** on_conflict condition type for table "enum_item_category" */
export type Enum_Item_Category_On_Conflict = {
  constraint: Enum_Item_Category_Constraint;
  update_columns?: Array<Enum_Item_Category_Update_Column>;
  where?: InputMaybe<Enum_Item_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_item_category". */
export type Enum_Item_Category_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_item_category */
export type Enum_Item_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_item_category" */
export enum Enum_Item_Category_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_item_category" */
export type Enum_Item_Category_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_item_category" */
export type Enum_Item_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Item_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Item_Category_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_item_category" */
export enum Enum_Item_Category_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Item_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Item_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Item_Category_Bool_Exp;
};

/** columns and relationships of "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type = {
  __typename?: 'enum_labor_charge_type';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Aggregate = {
  __typename?: 'enum_labor_charge_type_aggregate';
  aggregate?: Maybe<Enum_Labor_Charge_Type_Aggregate_Fields>;
  nodes: Array<Enum_Labor_Charge_Type>;
};

/** aggregate fields of "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Aggregate_Fields = {
  __typename?: 'enum_labor_charge_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Labor_Charge_Type_Max_Fields>;
  min?: Maybe<Enum_Labor_Charge_Type_Min_Fields>;
};


/** aggregate fields of "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Labor_Charge_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_labor_charge_type". All fields are combined with a logical 'AND'. */
export type Enum_Labor_Charge_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Labor_Charge_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Labor_Charge_Type_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_labor_charge_type" */
export enum Enum_Labor_Charge_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLaborChargeTypePkey = 'enum_labor_charge_type_pkey'
}

export enum Enum_Labor_Charge_Type_Enum {
  Add = 'add',
  Deduct = 'deduct'
}

/** Boolean expression to compare columns of type "enum_labor_charge_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Labor_Charge_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Labor_Charge_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Labor_Charge_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Labor_Charge_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Labor_Charge_Type_Enum>>;
};

/** input type for inserting data into table "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Labor_Charge_Type_Max_Fields = {
  __typename?: 'enum_labor_charge_type_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Labor_Charge_Type_Min_Fields = {
  __typename?: 'enum_labor_charge_type_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Mutation_Response = {
  __typename?: 'enum_labor_charge_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Labor_Charge_Type>;
};

/** on_conflict condition type for table "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_On_Conflict = {
  constraint: Enum_Labor_Charge_Type_Constraint;
  update_columns?: Array<Enum_Labor_Charge_Type_Update_Column>;
  where?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_labor_charge_type". */
export type Enum_Labor_Charge_Type_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_labor_charge_type */
export type Enum_Labor_Charge_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_labor_charge_type" */
export enum Enum_Labor_Charge_Type_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_labor_charge_type" */
export type Enum_Labor_Charge_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Labor_Charge_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Labor_Charge_Type_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_labor_charge_type" */
export enum Enum_Labor_Charge_Type_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Labor_Charge_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Labor_Charge_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Labor_Charge_Type_Bool_Exp;
};

/** columns and relationships of "enum_milling_process_status" */
export type Enum_Milling_Process_Status = {
  __typename?: 'enum_milling_process_status';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Aggregate = {
  __typename?: 'enum_milling_process_status_aggregate';
  aggregate?: Maybe<Enum_Milling_Process_Status_Aggregate_Fields>;
  nodes: Array<Enum_Milling_Process_Status>;
};

/** aggregate fields of "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Aggregate_Fields = {
  __typename?: 'enum_milling_process_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Milling_Process_Status_Max_Fields>;
  min?: Maybe<Enum_Milling_Process_Status_Min_Fields>;
};


/** aggregate fields of "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Milling_Process_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_milling_process_status". All fields are combined with a logical 'AND'. */
export type Enum_Milling_Process_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Milling_Process_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Milling_Process_Status_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_milling_process_status" */
export enum Enum_Milling_Process_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMillingProcessStatusPkey = 'enum_milling_process_status_pkey'
}

export enum Enum_Milling_Process_Status_Enum {
  Completed = 'completed',
  Created = 'created',
  Started = 'started'
}

/** Boolean expression to compare columns of type "enum_milling_process_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Milling_Process_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Milling_Process_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Milling_Process_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Milling_Process_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Milling_Process_Status_Enum>>;
};

/** input type for inserting data into table "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Milling_Process_Status_Max_Fields = {
  __typename?: 'enum_milling_process_status_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Milling_Process_Status_Min_Fields = {
  __typename?: 'enum_milling_process_status_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Mutation_Response = {
  __typename?: 'enum_milling_process_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Milling_Process_Status>;
};

/** input type for inserting object relation for remote table "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Obj_Rel_Insert_Input = {
  data: Enum_Milling_Process_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Milling_Process_Status_On_Conflict>;
};

/** on_conflict condition type for table "enum_milling_process_status" */
export type Enum_Milling_Process_Status_On_Conflict = {
  constraint: Enum_Milling_Process_Status_Constraint;
  update_columns?: Array<Enum_Milling_Process_Status_Update_Column>;
  where?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_milling_process_status". */
export type Enum_Milling_Process_Status_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_milling_process_status */
export type Enum_Milling_Process_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_milling_process_status" */
export enum Enum_Milling_Process_Status_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_milling_process_status" */
export type Enum_Milling_Process_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Milling_Process_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Milling_Process_Status_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_milling_process_status" */
export enum Enum_Milling_Process_Status_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Milling_Process_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Milling_Process_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Milling_Process_Status_Bool_Exp;
};

/** columns and relationships of "enum_milling_process_type" */
export type Enum_Milling_Process_Type = {
  __typename?: 'enum_milling_process_type';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Aggregate = {
  __typename?: 'enum_milling_process_type_aggregate';
  aggregate?: Maybe<Enum_Milling_Process_Type_Aggregate_Fields>;
  nodes: Array<Enum_Milling_Process_Type>;
};

/** aggregate fields of "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Aggregate_Fields = {
  __typename?: 'enum_milling_process_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Milling_Process_Type_Max_Fields>;
  min?: Maybe<Enum_Milling_Process_Type_Min_Fields>;
};


/** aggregate fields of "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Milling_Process_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_milling_process_type". All fields are combined with a logical 'AND'. */
export type Enum_Milling_Process_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Milling_Process_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Milling_Process_Type_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_milling_process_type" */
export enum Enum_Milling_Process_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMillingProcessTypePkey = 'enum_milling_process_type_pkey'
}

export enum Enum_Milling_Process_Type_Enum {
  /** Combined */
  Combined = 'combined',
  /** Individual */
  Individual = 'individual'
}

/** Boolean expression to compare columns of type "enum_milling_process_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Milling_Process_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Milling_Process_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Milling_Process_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Milling_Process_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Milling_Process_Type_Enum>>;
};

/** input type for inserting data into table "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Milling_Process_Type_Max_Fields = {
  __typename?: 'enum_milling_process_type_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Milling_Process_Type_Min_Fields = {
  __typename?: 'enum_milling_process_type_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Mutation_Response = {
  __typename?: 'enum_milling_process_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Milling_Process_Type>;
};

/** input type for inserting object relation for remote table "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Obj_Rel_Insert_Input = {
  data: Enum_Milling_Process_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Milling_Process_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_milling_process_type" */
export type Enum_Milling_Process_Type_On_Conflict = {
  constraint: Enum_Milling_Process_Type_Constraint;
  update_columns?: Array<Enum_Milling_Process_Type_Update_Column>;
  where?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_milling_process_type". */
export type Enum_Milling_Process_Type_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_milling_process_type */
export type Enum_Milling_Process_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_milling_process_type" */
export enum Enum_Milling_Process_Type_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_milling_process_type" */
export type Enum_Milling_Process_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Milling_Process_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Milling_Process_Type_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_milling_process_type" */
export enum Enum_Milling_Process_Type_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Milling_Process_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Milling_Process_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Milling_Process_Type_Bool_Exp;
};

/** columns and relationships of "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source = {
  __typename?: 'enum_outwardShipmentOrder_source';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Aggregate = {
  __typename?: 'enum_outwardShipmentOrder_source_aggregate';
  aggregate?: Maybe<Enum_OutwardShipmentOrder_Source_Aggregate_Fields>;
  nodes: Array<Enum_OutwardShipmentOrder_Source>;
};

/** aggregate fields of "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Aggregate_Fields = {
  __typename?: 'enum_outwardShipmentOrder_source_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_OutwardShipmentOrder_Source_Max_Fields>;
  min?: Maybe<Enum_OutwardShipmentOrder_Source_Min_Fields>;
};


/** aggregate fields of "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_outwardShipmentOrder_source". All fields are combined with a logical 'AND'. */
export type Enum_OutwardShipmentOrder_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Bool_Exp>>;
  _not?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_outwardShipmentOrder_source" */
export enum Enum_OutwardShipmentOrder_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumOutwardShipmentOrderSourcePkey = 'enum_outwardShipmentOrder_source_pkey'
}

export enum Enum_OutwardShipmentOrder_Source_Enum {
  /** Godown */
  Godown = 'godown',
  /** Mill */
  Mill = 'mill'
}

/** Boolean expression to compare columns of type "enum_outwardShipmentOrder_source_enum". All fields are combined with logical 'AND'. */
export type Enum_OutwardShipmentOrder_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_OutwardShipmentOrder_Source_Enum>;
  _in?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_OutwardShipmentOrder_Source_Enum>;
  _nin?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Enum>>;
};

/** input type for inserting data into table "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_OutwardShipmentOrder_Source_Max_Fields = {
  __typename?: 'enum_outwardShipmentOrder_source_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_OutwardShipmentOrder_Source_Min_Fields = {
  __typename?: 'enum_outwardShipmentOrder_source_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Mutation_Response = {
  __typename?: 'enum_outwardShipmentOrder_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_OutwardShipmentOrder_Source>;
};

/** on_conflict condition type for table "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_On_Conflict = {
  constraint: Enum_OutwardShipmentOrder_Source_Constraint;
  update_columns?: Array<Enum_OutwardShipmentOrder_Source_Update_Column>;
  where?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_outwardShipmentOrder_source". */
export type Enum_OutwardShipmentOrder_Source_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_outwardShipmentOrder_source */
export type Enum_OutwardShipmentOrder_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_outwardShipmentOrder_source" */
export enum Enum_OutwardShipmentOrder_Source_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_outwardShipmentOrder_source" */
export type Enum_OutwardShipmentOrder_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_OutwardShipmentOrder_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_OutwardShipmentOrder_Source_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_outwardShipmentOrder_source" */
export enum Enum_OutwardShipmentOrder_Source_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_OutwardShipmentOrder_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_OutwardShipmentOrder_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_OutwardShipmentOrder_Source_Bool_Exp;
};

/** columns and relationships of "enum_paddy_grade" */
export type Enum_Paddy_Grade = {
  __typename?: 'enum_paddy_grade';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_paddy_grade" */
export type Enum_Paddy_Grade_Aggregate = {
  __typename?: 'enum_paddy_grade_aggregate';
  aggregate?: Maybe<Enum_Paddy_Grade_Aggregate_Fields>;
  nodes: Array<Enum_Paddy_Grade>;
};

/** aggregate fields of "enum_paddy_grade" */
export type Enum_Paddy_Grade_Aggregate_Fields = {
  __typename?: 'enum_paddy_grade_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Paddy_Grade_Max_Fields>;
  min?: Maybe<Enum_Paddy_Grade_Min_Fields>;
};


/** aggregate fields of "enum_paddy_grade" */
export type Enum_Paddy_Grade_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Paddy_Grade_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_paddy_grade". All fields are combined with a logical 'AND'. */
export type Enum_Paddy_Grade_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Paddy_Grade_Bool_Exp>>;
  _not?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Paddy_Grade_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_paddy_grade" */
export enum Enum_Paddy_Grade_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPaddyGradePkey = 'enum_paddy_grade_pkey'
}

/** input type for inserting data into table "enum_paddy_grade" */
export type Enum_Paddy_Grade_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Paddy_Grade_Max_Fields = {
  __typename?: 'enum_paddy_grade_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Paddy_Grade_Min_Fields = {
  __typename?: 'enum_paddy_grade_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_paddy_grade" */
export type Enum_Paddy_Grade_Mutation_Response = {
  __typename?: 'enum_paddy_grade_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Paddy_Grade>;
};

/** on_conflict condition type for table "enum_paddy_grade" */
export type Enum_Paddy_Grade_On_Conflict = {
  constraint: Enum_Paddy_Grade_Constraint;
  update_columns?: Array<Enum_Paddy_Grade_Update_Column>;
  where?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_paddy_grade". */
export type Enum_Paddy_Grade_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_paddy_grade */
export type Enum_Paddy_Grade_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_paddy_grade" */
export enum Enum_Paddy_Grade_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_paddy_grade" */
export type Enum_Paddy_Grade_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_paddy_grade" */
export type Enum_Paddy_Grade_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Paddy_Grade_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Paddy_Grade_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_paddy_grade" */
export enum Enum_Paddy_Grade_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Paddy_Grade_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Paddy_Grade_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Paddy_Grade_Bool_Exp;
};

/** columns and relationships of "enum_raw_material_type" */
export type Enum_Raw_Material_Type = {
  __typename?: 'enum_raw_material_type';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Aggregate = {
  __typename?: 'enum_raw_material_type_aggregate';
  aggregate?: Maybe<Enum_Raw_Material_Type_Aggregate_Fields>;
  nodes: Array<Enum_Raw_Material_Type>;
};

/** aggregate fields of "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Aggregate_Fields = {
  __typename?: 'enum_raw_material_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Raw_Material_Type_Max_Fields>;
  min?: Maybe<Enum_Raw_Material_Type_Min_Fields>;
};


/** aggregate fields of "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Raw_Material_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_raw_material_type". All fields are combined with a logical 'AND'. */
export type Enum_Raw_Material_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Raw_Material_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Raw_Material_Type_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_raw_material_type" */
export enum Enum_Raw_Material_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumRawMaterialTypePkey = 'enum_raw_material_type_pkey'
}

export enum Enum_Raw_Material_Type_Enum {
  Paddy = 'paddy',
  Rice = 'rice'
}

/** Boolean expression to compare columns of type "enum_raw_material_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Raw_Material_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Raw_Material_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Raw_Material_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Raw_Material_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Raw_Material_Type_Enum>>;
};

/** input type for inserting data into table "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Raw_Material_Type_Max_Fields = {
  __typename?: 'enum_raw_material_type_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Raw_Material_Type_Min_Fields = {
  __typename?: 'enum_raw_material_type_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Mutation_Response = {
  __typename?: 'enum_raw_material_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Raw_Material_Type>;
};

/** on_conflict condition type for table "enum_raw_material_type" */
export type Enum_Raw_Material_Type_On_Conflict = {
  constraint: Enum_Raw_Material_Type_Constraint;
  update_columns?: Array<Enum_Raw_Material_Type_Update_Column>;
  where?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_raw_material_type". */
export type Enum_Raw_Material_Type_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_raw_material_type */
export type Enum_Raw_Material_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_raw_material_type" */
export enum Enum_Raw_Material_Type_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_raw_material_type" */
export type Enum_Raw_Material_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Raw_Material_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Raw_Material_Type_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_raw_material_type" */
export enum Enum_Raw_Material_Type_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Raw_Material_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Raw_Material_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Raw_Material_Type_Bool_Exp;
};

/** columns and relationships of "enum_setting_value_type" */
export type Enum_Setting_Value_Type = {
  __typename?: 'enum_setting_value_type';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Aggregate = {
  __typename?: 'enum_setting_value_type_aggregate';
  aggregate?: Maybe<Enum_Setting_Value_Type_Aggregate_Fields>;
  nodes: Array<Enum_Setting_Value_Type>;
};

/** aggregate fields of "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Aggregate_Fields = {
  __typename?: 'enum_setting_value_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Setting_Value_Type_Max_Fields>;
  min?: Maybe<Enum_Setting_Value_Type_Min_Fields>;
};


/** aggregate fields of "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Setting_Value_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_setting_value_type". All fields are combined with a logical 'AND'. */
export type Enum_Setting_Value_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Setting_Value_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Setting_Value_Type_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_setting_value_type" */
export enum Enum_Setting_Value_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSettingValueTypePkey = 'enum_setting_value_type_pkey'
}

export enum Enum_Setting_Value_Type_Enum {
  ArrayString = 'arrayString',
  Date = 'date',
  Float = 'float',
  Int = 'int',
  Json = 'json',
  String = 'string',
  Timestamp = 'timestamp'
}

/** Boolean expression to compare columns of type "enum_setting_value_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Setting_Value_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Setting_Value_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Setting_Value_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Setting_Value_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Setting_Value_Type_Enum>>;
};

/** input type for inserting data into table "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Setting_Value_Type_Max_Fields = {
  __typename?: 'enum_setting_value_type_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Setting_Value_Type_Min_Fields = {
  __typename?: 'enum_setting_value_type_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Mutation_Response = {
  __typename?: 'enum_setting_value_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Setting_Value_Type>;
};

/** on_conflict condition type for table "enum_setting_value_type" */
export type Enum_Setting_Value_Type_On_Conflict = {
  constraint: Enum_Setting_Value_Type_Constraint;
  update_columns?: Array<Enum_Setting_Value_Type_Update_Column>;
  where?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_setting_value_type". */
export type Enum_Setting_Value_Type_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_setting_value_type */
export type Enum_Setting_Value_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_setting_value_type" */
export enum Enum_Setting_Value_Type_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_setting_value_type" */
export type Enum_Setting_Value_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Setting_Value_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Setting_Value_Type_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_setting_value_type" */
export enum Enum_Setting_Value_Type_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Setting_Value_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Setting_Value_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Setting_Value_Type_Bool_Exp;
};

/** columns and relationships of "enum_source_destination" */
export type Enum_Source_Destination = {
  __typename?: 'enum_source_destination';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_source_destination" */
export type Enum_Source_Destination_Aggregate = {
  __typename?: 'enum_source_destination_aggregate';
  aggregate?: Maybe<Enum_Source_Destination_Aggregate_Fields>;
  nodes: Array<Enum_Source_Destination>;
};

/** aggregate fields of "enum_source_destination" */
export type Enum_Source_Destination_Aggregate_Fields = {
  __typename?: 'enum_source_destination_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Source_Destination_Max_Fields>;
  min?: Maybe<Enum_Source_Destination_Min_Fields>;
};


/** aggregate fields of "enum_source_destination" */
export type Enum_Source_Destination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Source_Destination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_source_destination". All fields are combined with a logical 'AND'. */
export type Enum_Source_Destination_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Source_Destination_Bool_Exp>>;
  _not?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Source_Destination_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_source_destination" */
export enum Enum_Source_Destination_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSourceDestinationPkey = 'enum_source_destination_pkey'
}

export enum Enum_Source_Destination_Enum {
  Direct = 'direct',
  Godown = 'godown',
  Mill = 'mill',
  Office = 'office',
  Process = 'process',
  StoreRoom = 'store_room'
}

/** Boolean expression to compare columns of type "enum_source_destination_enum". All fields are combined with logical 'AND'. */
export type Enum_Source_Destination_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Source_Destination_Enum>;
  _in?: InputMaybe<Array<Enum_Source_Destination_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Source_Destination_Enum>;
  _nin?: InputMaybe<Array<Enum_Source_Destination_Enum>>;
};

/** input type for inserting data into table "enum_source_destination" */
export type Enum_Source_Destination_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Source_Destination_Max_Fields = {
  __typename?: 'enum_source_destination_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Source_Destination_Min_Fields = {
  __typename?: 'enum_source_destination_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_source_destination" */
export type Enum_Source_Destination_Mutation_Response = {
  __typename?: 'enum_source_destination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Source_Destination>;
};

/** on_conflict condition type for table "enum_source_destination" */
export type Enum_Source_Destination_On_Conflict = {
  constraint: Enum_Source_Destination_Constraint;
  update_columns?: Array<Enum_Source_Destination_Update_Column>;
  where?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_source_destination". */
export type Enum_Source_Destination_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_source_destination */
export type Enum_Source_Destination_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_source_destination" */
export enum Enum_Source_Destination_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_source_destination" */
export type Enum_Source_Destination_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_source_destination" */
export type Enum_Source_Destination_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_Source_Destination_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_Source_Destination_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_source_destination" */
export enum Enum_Source_Destination_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_Source_Destination_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Source_Destination_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Source_Destination_Bool_Exp;
};

/** columns and relationships of "enum_user_role" */
export type Enum_User_Role = {
  __typename?: 'enum_user_role';
  display_name?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "enum_user_role" */
export type Enum_User_Role_Aggregate = {
  __typename?: 'enum_user_role_aggregate';
  aggregate?: Maybe<Enum_User_Role_Aggregate_Fields>;
  nodes: Array<Enum_User_Role>;
};

/** aggregate fields of "enum_user_role" */
export type Enum_User_Role_Aggregate_Fields = {
  __typename?: 'enum_user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_User_Role_Max_Fields>;
  min?: Maybe<Enum_User_Role_Min_Fields>;
};


/** aggregate fields of "enum_user_role" */
export type Enum_User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_User_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_user_role". All fields are combined with a logical 'AND'. */
export type Enum_User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_User_Role_Bool_Exp>>;
  _not?: InputMaybe<Enum_User_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_User_Role_Bool_Exp>>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_role" */
export enum Enum_User_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUserRolePkey = 'enum_user_role_pkey'
}

export enum Enum_User_Role_Enum {
  Admin = 'admin',
  Manager = 'manager',
  Staff = 'staff'
}

/** Boolean expression to compare columns of type "enum_user_role_enum". All fields are combined with logical 'AND'. */
export type Enum_User_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_User_Role_Enum>;
  _in?: InputMaybe<Array<Enum_User_Role_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_User_Role_Enum>;
  _nin?: InputMaybe<Array<Enum_User_Role_Enum>>;
};

/** input type for inserting data into table "enum_user_role" */
export type Enum_User_Role_Insert_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Role_Max_Fields = {
  __typename?: 'enum_user_role_max_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_User_Role_Min_Fields = {
  __typename?: 'enum_user_role_min_fields';
  display_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_user_role" */
export type Enum_User_Role_Mutation_Response = {
  __typename?: 'enum_user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_User_Role>;
};

/** on_conflict condition type for table "enum_user_role" */
export type Enum_User_Role_On_Conflict = {
  constraint: Enum_User_Role_Constraint;
  update_columns?: Array<Enum_User_Role_Update_Column>;
  where?: InputMaybe<Enum_User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_user_role". */
export type Enum_User_Role_Order_By = {
  display_name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_user_role */
export type Enum_User_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_role" */
export enum Enum_User_Role_Select_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_user_role" */
export type Enum_User_Role_Set_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "enum_user_role" */
export type Enum_User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enum_User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enum_User_Role_Stream_Cursor_Value_Input = {
  display_name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_user_role" */
export enum Enum_User_Role_Update_Column {
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Value = 'value'
}

export type Enum_User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_User_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_User_Role_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type GetWeighbridgeWeightOutput = {
  __typename?: 'getWeighbridgeWeightOutput';
  weight: Scalars['Float'];
};

/** columns and relationships of "godownRawMaterialSummary" */
export type GodownRawMaterialSummary = {
  __typename?: 'godownRawMaterialSummary';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  godown?: Maybe<Godowns>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "godownRawMaterialSummary" */
export type GodownRawMaterialSummary_Aggregate = {
  __typename?: 'godownRawMaterialSummary_aggregate';
  aggregate?: Maybe<GodownRawMaterialSummary_Aggregate_Fields>;
  nodes: Array<GodownRawMaterialSummary>;
};

/** aggregate fields of "godownRawMaterialSummary" */
export type GodownRawMaterialSummary_Aggregate_Fields = {
  __typename?: 'godownRawMaterialSummary_aggregate_fields';
  avg?: Maybe<GodownRawMaterialSummary_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GodownRawMaterialSummary_Max_Fields>;
  min?: Maybe<GodownRawMaterialSummary_Min_Fields>;
  stddev?: Maybe<GodownRawMaterialSummary_Stddev_Fields>;
  stddev_pop?: Maybe<GodownRawMaterialSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GodownRawMaterialSummary_Stddev_Samp_Fields>;
  sum?: Maybe<GodownRawMaterialSummary_Sum_Fields>;
  var_pop?: Maybe<GodownRawMaterialSummary_Var_Pop_Fields>;
  var_samp?: Maybe<GodownRawMaterialSummary_Var_Samp_Fields>;
  variance?: Maybe<GodownRawMaterialSummary_Variance_Fields>;
};


/** aggregate fields of "godownRawMaterialSummary" */
export type GodownRawMaterialSummary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GodownRawMaterialSummary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GodownRawMaterialSummary_Avg_Fields = {
  __typename?: 'godownRawMaterialSummary_avg_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "godownRawMaterialSummary". All fields are combined with a logical 'AND'. */
export type GodownRawMaterialSummary_Bool_Exp = {
  _and?: InputMaybe<Array<GodownRawMaterialSummary_Bool_Exp>>;
  _not?: InputMaybe<GodownRawMaterialSummary_Bool_Exp>;
  _or?: InputMaybe<Array<GodownRawMaterialSummary_Bool_Exp>>;
  currentBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  godown?: InputMaybe<Godowns_Bool_Exp>;
  godownId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  inBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  outBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type GodownRawMaterialSummary_Max_Fields = {
  __typename?: 'godownRawMaterialSummary_max_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type GodownRawMaterialSummary_Min_Fields = {
  __typename?: 'godownRawMaterialSummary_min_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "godownRawMaterialSummary". */
export type GodownRawMaterialSummary_Order_By = {
  currentBagsCount?: InputMaybe<Order_By>;
  godown?: InputMaybe<Godowns_Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** select columns of table "godownRawMaterialSummary" */
export enum GodownRawMaterialSummary_Select_Column {
  /** column name */
  CurrentBagsCount = 'currentBagsCount',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  InBagsCount = 'inBagsCount',
  /** column name */
  OutBagsCount = 'outBagsCount',
  /** column name */
  RawMaterialId = 'rawMaterialId'
}

/** aggregate stddev on columns */
export type GodownRawMaterialSummary_Stddev_Fields = {
  __typename?: 'godownRawMaterialSummary_stddev_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GodownRawMaterialSummary_Stddev_Pop_Fields = {
  __typename?: 'godownRawMaterialSummary_stddev_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GodownRawMaterialSummary_Stddev_Samp_Fields = {
  __typename?: 'godownRawMaterialSummary_stddev_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "godownRawMaterialSummary" */
export type GodownRawMaterialSummary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GodownRawMaterialSummary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GodownRawMaterialSummary_Stream_Cursor_Value_Input = {
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type GodownRawMaterialSummary_Sum_Fields = {
  __typename?: 'godownRawMaterialSummary_sum_fields';
  currentBagsCount?: Maybe<Scalars['bigint']>;
  godownId?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type GodownRawMaterialSummary_Var_Pop_Fields = {
  __typename?: 'godownRawMaterialSummary_var_pop_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GodownRawMaterialSummary_Var_Samp_Fields = {
  __typename?: 'godownRawMaterialSummary_var_samp_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GodownRawMaterialSummary_Variance_Fields = {
  __typename?: 'godownRawMaterialSummary_variance_fields';
  currentBagsCount?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "godownSummary" */
export type GodownSummary = {
  __typename?: 'godownSummary';
  capacityBags?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "godownSummary" */
export type GodownSummary_Aggregate = {
  __typename?: 'godownSummary_aggregate';
  aggregate?: Maybe<GodownSummary_Aggregate_Fields>;
  nodes: Array<GodownSummary>;
};

/** aggregate fields of "godownSummary" */
export type GodownSummary_Aggregate_Fields = {
  __typename?: 'godownSummary_aggregate_fields';
  avg?: Maybe<GodownSummary_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<GodownSummary_Max_Fields>;
  min?: Maybe<GodownSummary_Min_Fields>;
  stddev?: Maybe<GodownSummary_Stddev_Fields>;
  stddev_pop?: Maybe<GodownSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GodownSummary_Stddev_Samp_Fields>;
  sum?: Maybe<GodownSummary_Sum_Fields>;
  var_pop?: Maybe<GodownSummary_Var_Pop_Fields>;
  var_samp?: Maybe<GodownSummary_Var_Samp_Fields>;
  variance?: Maybe<GodownSummary_Variance_Fields>;
};


/** aggregate fields of "godownSummary" */
export type GodownSummary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GodownSummary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GodownSummary_Avg_Fields = {
  __typename?: 'godownSummary_avg_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "godownSummary". All fields are combined with a logical 'AND'. */
export type GodownSummary_Bool_Exp = {
  _and?: InputMaybe<Array<GodownSummary_Bool_Exp>>;
  _not?: InputMaybe<GodownSummary_Bool_Exp>;
  _or?: InputMaybe<Array<GodownSummary_Bool_Exp>>;
  capacityBags?: InputMaybe<Int_Comparison_Exp>;
  currentBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  outBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "godownSummary" */
export type GodownSummary_Insert_Input = {
  capacityBags?: InputMaybe<Scalars['Int']>;
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type GodownSummary_Max_Fields = {
  __typename?: 'godownSummary_max_fields';
  capacityBags?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type GodownSummary_Min_Fields = {
  __typename?: 'godownSummary_min_fields';
  capacityBags?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "godownSummary" */
export type GodownSummary_Obj_Rel_Insert_Input = {
  data: GodownSummary_Insert_Input;
};

/** Ordering options when selecting data from "godownSummary". */
export type GodownSummary_Order_By = {
  capacityBags?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBagsCount?: InputMaybe<Order_By>;
  outBagsCount?: InputMaybe<Order_By>;
};

/** select columns of table "godownSummary" */
export enum GodownSummary_Select_Column {
  /** column name */
  CapacityBags = 'capacityBags',
  /** column name */
  CurrentBagsCount = 'currentBagsCount',
  /** column name */
  Id = 'id',
  /** column name */
  InBagsCount = 'inBagsCount',
  /** column name */
  OutBagsCount = 'outBagsCount'
}

/** aggregate stddev on columns */
export type GodownSummary_Stddev_Fields = {
  __typename?: 'godownSummary_stddev_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type GodownSummary_Stddev_Pop_Fields = {
  __typename?: 'godownSummary_stddev_pop_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type GodownSummary_Stddev_Samp_Fields = {
  __typename?: 'godownSummary_stddev_samp_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "godownSummary" */
export type GodownSummary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GodownSummary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type GodownSummary_Stream_Cursor_Value_Input = {
  capacityBags?: InputMaybe<Scalars['Int']>;
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  inBagsCount?: InputMaybe<Scalars['bigint']>;
  outBagsCount?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type GodownSummary_Sum_Fields = {
  __typename?: 'godownSummary_sum_fields';
  capacityBags?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  inBagsCount?: Maybe<Scalars['bigint']>;
  outBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type GodownSummary_Var_Pop_Fields = {
  __typename?: 'godownSummary_var_pop_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type GodownSummary_Var_Samp_Fields = {
  __typename?: 'godownSummary_var_samp_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type GodownSummary_Variance_Fields = {
  __typename?: 'godownSummary_variance_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inBagsCount?: Maybe<Scalars['Float']>;
  outBagsCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "godowns" */
export type Godowns = {
  __typename?: 'godowns';
  capacityBags?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  currentState: Array<GodownCurrentState>;
  /** An aggregate relationship */
  currentState_aggregate: GodownCurrentState_Aggregate;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  inwardShipmentItems: Array<InwardShipmentItems>;
  /** An aggregate relationship */
  inwardShipmentItems_aggregate: InwardShipmentItems_Aggregate;
  location?: Maybe<Scalars['String']>;
  /** An array relationship */
  millingInItems: Array<MillingInItems>;
  /** An aggregate relationship */
  millingInItems_aggregate: MillingInItems_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  summary?: Maybe<GodownSummary>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "godowns" */
export type GodownsCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownCurrentState_Order_By>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


/** columns and relationships of "godowns" */
export type GodownsCurrentState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownCurrentState_Order_By>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


/** columns and relationships of "godowns" */
export type GodownsInwardShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "godowns" */
export type GodownsInwardShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "godowns" */
export type GodownsMillingInItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


/** columns and relationships of "godowns" */
export type GodownsMillingInItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};

/** aggregated selection of "godowns" */
export type Godowns_Aggregate = {
  __typename?: 'godowns_aggregate';
  aggregate?: Maybe<Godowns_Aggregate_Fields>;
  nodes: Array<Godowns>;
};

/** aggregate fields of "godowns" */
export type Godowns_Aggregate_Fields = {
  __typename?: 'godowns_aggregate_fields';
  avg?: Maybe<Godowns_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Godowns_Max_Fields>;
  min?: Maybe<Godowns_Min_Fields>;
  stddev?: Maybe<Godowns_Stddev_Fields>;
  stddev_pop?: Maybe<Godowns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Godowns_Stddev_Samp_Fields>;
  sum?: Maybe<Godowns_Sum_Fields>;
  var_pop?: Maybe<Godowns_Var_Pop_Fields>;
  var_samp?: Maybe<Godowns_Var_Samp_Fields>;
  variance?: Maybe<Godowns_Variance_Fields>;
};


/** aggregate fields of "godowns" */
export type Godowns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Godowns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Godowns_Avg_Fields = {
  __typename?: 'godowns_avg_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "godowns". All fields are combined with a logical 'AND'. */
export type Godowns_Bool_Exp = {
  _and?: InputMaybe<Array<Godowns_Bool_Exp>>;
  _not?: InputMaybe<Godowns_Bool_Exp>;
  _or?: InputMaybe<Array<Godowns_Bool_Exp>>;
  capacityBags?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currentState?: InputMaybe<GodownCurrentState_Bool_Exp>;
  currentState_aggregate?: InputMaybe<GodownCurrentState_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inwardShipmentItems?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  inwardShipmentItems_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  millingInItems?: InputMaybe<MillingInItems_Bool_Exp>;
  millingInItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  summary?: InputMaybe<GodownSummary_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "godowns" */
export enum Godowns_Constraint {
  /** unique or primary key constraint on columns "name" */
  GodownsNameKey = 'godowns_name_key',
  /** unique or primary key constraint on columns "id" */
  GodownsPkey = 'godowns_pkey'
}

/** input type for incrementing numeric columns in table "godowns" */
export type Godowns_Inc_Input = {
  capacityBags?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "godowns" */
export type Godowns_Insert_Input = {
  capacityBags?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currentState?: InputMaybe<GodownCurrentState_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inwardShipmentItems?: InputMaybe<InwardShipmentItems_Arr_Rel_Insert_Input>;
  location?: InputMaybe<Scalars['String']>;
  millingInItems?: InputMaybe<MillingInItems_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<GodownSummary_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Godowns_Max_Fields = {
  __typename?: 'godowns_max_fields';
  capacityBags?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Godowns_Min_Fields = {
  __typename?: 'godowns_min_fields';
  capacityBags?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "godowns" */
export type Godowns_Mutation_Response = {
  __typename?: 'godowns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Godowns>;
};

/** input type for inserting object relation for remote table "godowns" */
export type Godowns_Obj_Rel_Insert_Input = {
  data: Godowns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Godowns_On_Conflict>;
};

/** on_conflict condition type for table "godowns" */
export type Godowns_On_Conflict = {
  constraint: Godowns_Constraint;
  update_columns?: Array<Godowns_Update_Column>;
  where?: InputMaybe<Godowns_Bool_Exp>;
};

/** Ordering options when selecting data from "godowns". */
export type Godowns_Order_By = {
  capacityBags?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentState_aggregate?: InputMaybe<GodownCurrentState_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentItems_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Order_By>;
  location?: InputMaybe<Order_By>;
  millingInItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  summary?: InputMaybe<GodownSummary_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: godowns */
export type Godowns_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "godowns" */
export enum Godowns_Select_Column {
  /** column name */
  CapacityBags = 'capacityBags',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "godowns" */
export type Godowns_Set_Input = {
  capacityBags?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Godowns_Stddev_Fields = {
  __typename?: 'godowns_stddev_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Godowns_Stddev_Pop_Fields = {
  __typename?: 'godowns_stddev_pop_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Godowns_Stddev_Samp_Fields = {
  __typename?: 'godowns_stddev_samp_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "godowns" */
export type Godowns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Godowns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Godowns_Stream_Cursor_Value_Input = {
  capacityBags?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Godowns_Sum_Fields = {
  __typename?: 'godowns_sum_fields';
  capacityBags?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "godowns" */
export enum Godowns_Update_Column {
  /** column name */
  CapacityBags = 'capacityBags',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Godowns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Godowns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Godowns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Godowns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Godowns_Var_Pop_Fields = {
  __typename?: 'godowns_var_pop_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Godowns_Var_Samp_Fields = {
  __typename?: 'godowns_var_samp_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Godowns_Variance_Fields = {
  __typename?: 'godowns_variance_fields';
  capacityBags?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "individual_in_out_for_combined_process" */
export type Individual_In_Out_For_Combined_Process = {
  __typename?: 'individual_in_out_for_combined_process';
  individual_item_in_wt?: Maybe<Scalars['float8']>;
  individual_item_out_wt?: Maybe<Scalars['float8']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "individual_in_out_for_combined_process" */
export type Individual_In_Out_For_Combined_Process_Aggregate = {
  __typename?: 'individual_in_out_for_combined_process_aggregate';
  aggregate?: Maybe<Individual_In_Out_For_Combined_Process_Aggregate_Fields>;
  nodes: Array<Individual_In_Out_For_Combined_Process>;
};

/** aggregate fields of "individual_in_out_for_combined_process" */
export type Individual_In_Out_For_Combined_Process_Aggregate_Fields = {
  __typename?: 'individual_in_out_for_combined_process_aggregate_fields';
  avg?: Maybe<Individual_In_Out_For_Combined_Process_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Individual_In_Out_For_Combined_Process_Max_Fields>;
  min?: Maybe<Individual_In_Out_For_Combined_Process_Min_Fields>;
  stddev?: Maybe<Individual_In_Out_For_Combined_Process_Stddev_Fields>;
  stddev_pop?: Maybe<Individual_In_Out_For_Combined_Process_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Individual_In_Out_For_Combined_Process_Stddev_Samp_Fields>;
  sum?: Maybe<Individual_In_Out_For_Combined_Process_Sum_Fields>;
  var_pop?: Maybe<Individual_In_Out_For_Combined_Process_Var_Pop_Fields>;
  var_samp?: Maybe<Individual_In_Out_For_Combined_Process_Var_Samp_Fields>;
  variance?: Maybe<Individual_In_Out_For_Combined_Process_Variance_Fields>;
};


/** aggregate fields of "individual_in_out_for_combined_process" */
export type Individual_In_Out_For_Combined_Process_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Individual_In_Out_For_Combined_Process_Avg_Fields = {
  __typename?: 'individual_in_out_for_combined_process_avg_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "individual_in_out_for_combined_process". All fields are combined with a logical 'AND'. */
export type Individual_In_Out_For_Combined_Process_Bool_Exp = {
  _and?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Bool_Exp>>;
  _not?: InputMaybe<Individual_In_Out_For_Combined_Process_Bool_Exp>;
  _or?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Bool_Exp>>;
  individual_item_in_wt?: InputMaybe<Float8_Comparison_Exp>;
  individual_item_out_wt?: InputMaybe<Float8_Comparison_Exp>;
  millingProcessId?: InputMaybe<Int_Comparison_Exp>;
  sourceMillingProcessId?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Individual_In_Out_For_Combined_Process_Max_Fields = {
  __typename?: 'individual_in_out_for_combined_process_max_fields';
  individual_item_in_wt?: Maybe<Scalars['float8']>;
  individual_item_out_wt?: Maybe<Scalars['float8']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Individual_In_Out_For_Combined_Process_Min_Fields = {
  __typename?: 'individual_in_out_for_combined_process_min_fields';
  individual_item_in_wt?: Maybe<Scalars['float8']>;
  individual_item_out_wt?: Maybe<Scalars['float8']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "individual_in_out_for_combined_process". */
export type Individual_In_Out_For_Combined_Process_Order_By = {
  individual_item_in_wt?: InputMaybe<Order_By>;
  individual_item_out_wt?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
};

/** select columns of table "individual_in_out_for_combined_process" */
export enum Individual_In_Out_For_Combined_Process_Select_Column {
  /** column name */
  IndividualItemInWt = 'individual_item_in_wt',
  /** column name */
  IndividualItemOutWt = 'individual_item_out_wt',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  SourceMillingProcessId = 'sourceMillingProcessId'
}

/** aggregate stddev on columns */
export type Individual_In_Out_For_Combined_Process_Stddev_Fields = {
  __typename?: 'individual_in_out_for_combined_process_stddev_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Individual_In_Out_For_Combined_Process_Stddev_Pop_Fields = {
  __typename?: 'individual_in_out_for_combined_process_stddev_pop_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Individual_In_Out_For_Combined_Process_Stddev_Samp_Fields = {
  __typename?: 'individual_in_out_for_combined_process_stddev_samp_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "individual_in_out_for_combined_process" */
export type Individual_In_Out_For_Combined_Process_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Individual_In_Out_For_Combined_Process_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Individual_In_Out_For_Combined_Process_Stream_Cursor_Value_Input = {
  individual_item_in_wt?: InputMaybe<Scalars['float8']>;
  individual_item_out_wt?: InputMaybe<Scalars['float8']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  sourceMillingProcessId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Individual_In_Out_For_Combined_Process_Sum_Fields = {
  __typename?: 'individual_in_out_for_combined_process_sum_fields';
  individual_item_in_wt?: Maybe<Scalars['float8']>;
  individual_item_out_wt?: Maybe<Scalars['float8']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Individual_In_Out_For_Combined_Process_Var_Pop_Fields = {
  __typename?: 'individual_in_out_for_combined_process_var_pop_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Individual_In_Out_For_Combined_Process_Var_Samp_Fields = {
  __typename?: 'individual_in_out_for_combined_process_var_samp_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Individual_In_Out_For_Combined_Process_Variance_Fields = {
  __typename?: 'individual_in_out_for_combined_process_variance_fields';
  individual_item_in_wt?: Maybe<Scalars['Float']>;
  individual_item_out_wt?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "internalTransferShipmentItems" */
export type InternalTransferShipmentItems = {
  __typename?: 'internalTransferShipmentItems';
  bagWtKg?: Maybe<Scalars['numeric']>;
  bagsCount?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  destination?: Maybe<Enum_InternalTransfer_Source_Destination_Enum>;
  /** An object relationship */
  destinationGodown?: Maybe<Godowns>;
  destinationGodownId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  destinationMill?: Maybe<Mills>;
  destinationMillId?: Maybe<Scalars['Int']>;
  destinationMillingProcessId?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An object relationship */
  internalTransferShipment: InternalTransferShipments;
  internalTransferShipmentId: Scalars['uuid'];
  /** An object relationship */
  item?: Maybe<Items>;
  itemId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  millingInItems: Array<MillingInItems>;
  /** An aggregate relationship */
  millingInItems_aggregate: MillingInItems_Aggregate;
  /** An object relationship */
  millingProcess?: Maybe<MillingProcesses>;
  netMaterialWtPerBagKg?: Maybe<Scalars['numeric']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product?: Maybe<Products>;
  productId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['numeric']>;
  rmPaddyGrade?: Maybe<Scalars['String']>;
  source?: Maybe<Enum_InternalTransfer_Source_Destination_Enum>;
  /** An object relationship */
  sourceGodown?: Maybe<Godowns>;
  sourceGodownId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  sourceMill?: Maybe<Mills>;
  sourceMillId?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "internalTransferShipmentItems" */
export type InternalTransferShipmentItemsMillingInItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


/** columns and relationships of "internalTransferShipmentItems" */
export type InternalTransferShipmentItemsMillingInItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};

/** aggregated selection of "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Aggregate = {
  __typename?: 'internalTransferShipmentItems_aggregate';
  aggregate?: Maybe<InternalTransferShipmentItems_Aggregate_Fields>;
  nodes: Array<InternalTransferShipmentItems>;
};

export type InternalTransferShipmentItems_Aggregate_Bool_Exp = {
  count?: InputMaybe<InternalTransferShipmentItems_Aggregate_Bool_Exp_Count>;
};

export type InternalTransferShipmentItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Aggregate_Fields = {
  __typename?: 'internalTransferShipmentItems_aggregate_fields';
  avg?: Maybe<InternalTransferShipmentItems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InternalTransferShipmentItems_Max_Fields>;
  min?: Maybe<InternalTransferShipmentItems_Min_Fields>;
  stddev?: Maybe<InternalTransferShipmentItems_Stddev_Fields>;
  stddev_pop?: Maybe<InternalTransferShipmentItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InternalTransferShipmentItems_Stddev_Samp_Fields>;
  sum?: Maybe<InternalTransferShipmentItems_Sum_Fields>;
  var_pop?: Maybe<InternalTransferShipmentItems_Var_Pop_Fields>;
  var_samp?: Maybe<InternalTransferShipmentItems_Var_Samp_Fields>;
  variance?: Maybe<InternalTransferShipmentItems_Variance_Fields>;
};


/** aggregate fields of "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Aggregate_Order_By = {
  avg?: InputMaybe<InternalTransferShipmentItems_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InternalTransferShipmentItems_Max_Order_By>;
  min?: InputMaybe<InternalTransferShipmentItems_Min_Order_By>;
  stddev?: InputMaybe<InternalTransferShipmentItems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InternalTransferShipmentItems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InternalTransferShipmentItems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InternalTransferShipmentItems_Sum_Order_By>;
  var_pop?: InputMaybe<InternalTransferShipmentItems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InternalTransferShipmentItems_Var_Samp_Order_By>;
  variance?: InputMaybe<InternalTransferShipmentItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Arr_Rel_Insert_Input = {
  data: Array<InternalTransferShipmentItems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalTransferShipmentItems_On_Conflict>;
};

/** aggregate avg on columns */
export type InternalTransferShipmentItems_Avg_Fields = {
  __typename?: 'internalTransferShipmentItems_avg_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Avg_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "internalTransferShipmentItems". All fields are combined with a logical 'AND'. */
export type InternalTransferShipmentItems_Bool_Exp = {
  _and?: InputMaybe<Array<InternalTransferShipmentItems_Bool_Exp>>;
  _not?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
  _or?: InputMaybe<Array<InternalTransferShipmentItems_Bool_Exp>>;
  bagWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  bagsCount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  destination?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum_Comparison_Exp>;
  destinationGodown?: InputMaybe<Godowns_Bool_Exp>;
  destinationGodownId?: InputMaybe<Int_Comparison_Exp>;
  destinationMill?: InputMaybe<Mills_Bool_Exp>;
  destinationMillId?: InputMaybe<Int_Comparison_Exp>;
  destinationMillingProcessId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internalTransferShipment?: InputMaybe<InternalTransferShipments_Bool_Exp>;
  internalTransferShipmentId?: InputMaybe<Uuid_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  itemId?: InputMaybe<Uuid_Comparison_Exp>;
  millingInItems?: InputMaybe<MillingInItems_Bool_Exp>;
  millingInItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Bool_Exp>;
  millingProcess?: InputMaybe<MillingProcesses_Bool_Exp>;
  netMaterialWtPerBagKg?: InputMaybe<Numeric_Comparison_Exp>;
  othersItemName?: InputMaybe<String_Comparison_Exp>;
  othersQuantity?: InputMaybe<Numeric_Comparison_Exp>;
  othersWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Int_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
  remark?: InputMaybe<String_Comparison_Exp>;
  rmEmptyBagsWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  rmPaddyGrade?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum_Comparison_Exp>;
  sourceGodown?: InputMaybe<Godowns_Bool_Exp>;
  sourceGodownId?: InputMaybe<Int_Comparison_Exp>;
  sourceMill?: InputMaybe<Mills_Bool_Exp>;
  sourceMillId?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "internalTransferShipmentItems" */
export enum InternalTransferShipmentItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalTransferShipmentItemsPkey = 'internalTransferShipmentItems_pkey'
}

/** input type for incrementing numeric columns in table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Inc_Input = {
  bagWtKg?: InputMaybe<Scalars['numeric']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  destinationGodownId?: InputMaybe<Scalars['Int']>;
  destinationMillId?: InputMaybe<Scalars['Int']>;
  destinationMillingProcessId?: InputMaybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['numeric']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  rmEmptyBagsWtKg?: InputMaybe<Scalars['numeric']>;
  sourceGodownId?: InputMaybe<Scalars['Int']>;
  sourceMillId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Insert_Input = {
  bagWtKg?: InputMaybe<Scalars['numeric']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  destinationGodown?: InputMaybe<Godowns_Obj_Rel_Insert_Input>;
  destinationGodownId?: InputMaybe<Scalars['Int']>;
  destinationMill?: InputMaybe<Mills_Obj_Rel_Insert_Input>;
  destinationMillId?: InputMaybe<Scalars['Int']>;
  destinationMillingProcessId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalTransferShipment?: InputMaybe<InternalTransferShipments_Obj_Rel_Insert_Input>;
  internalTransferShipmentId?: InputMaybe<Scalars['uuid']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  itemId?: InputMaybe<Scalars['uuid']>;
  millingInItems?: InputMaybe<MillingInItems_Arr_Rel_Insert_Input>;
  millingProcess?: InputMaybe<MillingProcesses_Obj_Rel_Insert_Input>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['numeric']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmEmptyBagsWtKg?: InputMaybe<Scalars['numeric']>;
  rmPaddyGrade?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  sourceGodown?: InputMaybe<Godowns_Obj_Rel_Insert_Input>;
  sourceGodownId?: InputMaybe<Scalars['Int']>;
  sourceMill?: InputMaybe<Mills_Obj_Rel_Insert_Input>;
  sourceMillId?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type InternalTransferShipmentItems_Max_Fields = {
  __typename?: 'internalTransferShipmentItems_max_fields';
  bagWtKg?: Maybe<Scalars['numeric']>;
  bagsCount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  destinationGodownId?: Maybe<Scalars['Int']>;
  destinationMillId?: Maybe<Scalars['Int']>;
  destinationMillingProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  internalTransferShipmentId?: Maybe<Scalars['uuid']>;
  itemId?: Maybe<Scalars['uuid']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['numeric']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['numeric']>;
  rmPaddyGrade?: Maybe<Scalars['String']>;
  sourceGodownId?: Maybe<Scalars['Int']>;
  sourceMillId?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Max_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalTransferShipmentId?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  rmPaddyGrade?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InternalTransferShipmentItems_Min_Fields = {
  __typename?: 'internalTransferShipmentItems_min_fields';
  bagWtKg?: Maybe<Scalars['numeric']>;
  bagsCount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  destinationGodownId?: Maybe<Scalars['Int']>;
  destinationMillId?: Maybe<Scalars['Int']>;
  destinationMillingProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  internalTransferShipmentId?: Maybe<Scalars['uuid']>;
  itemId?: Maybe<Scalars['uuid']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['numeric']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['numeric']>;
  rmPaddyGrade?: Maybe<Scalars['String']>;
  sourceGodownId?: Maybe<Scalars['Int']>;
  sourceMillId?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Min_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalTransferShipmentId?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  rmPaddyGrade?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Mutation_Response = {
  __typename?: 'internalTransferShipmentItems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InternalTransferShipmentItems>;
};

/** input type for inserting object relation for remote table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Obj_Rel_Insert_Input = {
  data: InternalTransferShipmentItems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalTransferShipmentItems_On_Conflict>;
};

/** on_conflict condition type for table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_On_Conflict = {
  constraint: InternalTransferShipmentItems_Constraint;
  update_columns?: Array<InternalTransferShipmentItems_Update_Column>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};

/** Ordering options when selecting data from "internalTransferShipmentItems". */
export type InternalTransferShipmentItems_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination?: InputMaybe<Order_By>;
  destinationGodown?: InputMaybe<Godowns_Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMill?: InputMaybe<Mills_Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalTransferShipment?: InputMaybe<InternalTransferShipments_Order_By>;
  internalTransferShipmentId?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  itemId?: InputMaybe<Order_By>;
  millingInItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Order_By>;
  millingProcess?: InputMaybe<MillingProcesses_Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  rmPaddyGrade?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  sourceGodown?: InputMaybe<Godowns_Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMill?: InputMaybe<Mills_Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: internalTransferShipmentItems */
export type InternalTransferShipmentItems_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "internalTransferShipmentItems" */
export enum InternalTransferShipmentItems_Select_Column {
  /** column name */
  BagWtKg = 'bagWtKg',
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Destination = 'destination',
  /** column name */
  DestinationGodownId = 'destinationGodownId',
  /** column name */
  DestinationMillId = 'destinationMillId',
  /** column name */
  DestinationMillingProcessId = 'destinationMillingProcessId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalTransferShipmentId = 'internalTransferShipmentId',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  OthersItemName = 'othersItemName',
  /** column name */
  OthersQuantity = 'othersQuantity',
  /** column name */
  OthersWtKg = 'othersWtKg',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Remark = 'remark',
  /** column name */
  RmEmptyBagsWtKg = 'rmEmptyBagsWtKg',
  /** column name */
  RmPaddyGrade = 'rmPaddyGrade',
  /** column name */
  Source = 'source',
  /** column name */
  SourceGodownId = 'sourceGodownId',
  /** column name */
  SourceMillId = 'sourceMillId',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Set_Input = {
  bagWtKg?: InputMaybe<Scalars['numeric']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  destinationGodownId?: InputMaybe<Scalars['Int']>;
  destinationMillId?: InputMaybe<Scalars['Int']>;
  destinationMillingProcessId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalTransferShipmentId?: InputMaybe<Scalars['uuid']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['numeric']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmEmptyBagsWtKg?: InputMaybe<Scalars['numeric']>;
  rmPaddyGrade?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  sourceGodownId?: InputMaybe<Scalars['Int']>;
  sourceMillId?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type InternalTransferShipmentItems_Stddev_Fields = {
  __typename?: 'internalTransferShipmentItems_stddev_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Stddev_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InternalTransferShipmentItems_Stddev_Pop_Fields = {
  __typename?: 'internalTransferShipmentItems_stddev_pop_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Stddev_Pop_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InternalTransferShipmentItems_Stddev_Samp_Fields = {
  __typename?: 'internalTransferShipmentItems_stddev_samp_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Stddev_Samp_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InternalTransferShipmentItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InternalTransferShipmentItems_Stream_Cursor_Value_Input = {
  bagWtKg?: InputMaybe<Scalars['numeric']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  destinationGodownId?: InputMaybe<Scalars['Int']>;
  destinationMillId?: InputMaybe<Scalars['Int']>;
  destinationMillingProcessId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalTransferShipmentId?: InputMaybe<Scalars['uuid']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['numeric']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmEmptyBagsWtKg?: InputMaybe<Scalars['numeric']>;
  rmPaddyGrade?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_InternalTransfer_Source_Destination_Enum>;
  sourceGodownId?: InputMaybe<Scalars['Int']>;
  sourceMillId?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type InternalTransferShipmentItems_Sum_Fields = {
  __typename?: 'internalTransferShipmentItems_sum_fields';
  bagWtKg?: Maybe<Scalars['numeric']>;
  bagsCount?: Maybe<Scalars['Int']>;
  destinationGodownId?: Maybe<Scalars['Int']>;
  destinationMillId?: Maybe<Scalars['Int']>;
  destinationMillingProcessId?: Maybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['numeric']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['numeric']>;
  sourceGodownId?: Maybe<Scalars['Int']>;
  sourceMillId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Sum_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** update columns of table "internalTransferShipmentItems" */
export enum InternalTransferShipmentItems_Update_Column {
  /** column name */
  BagWtKg = 'bagWtKg',
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Destination = 'destination',
  /** column name */
  DestinationGodownId = 'destinationGodownId',
  /** column name */
  DestinationMillId = 'destinationMillId',
  /** column name */
  DestinationMillingProcessId = 'destinationMillingProcessId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalTransferShipmentId = 'internalTransferShipmentId',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  OthersItemName = 'othersItemName',
  /** column name */
  OthersQuantity = 'othersQuantity',
  /** column name */
  OthersWtKg = 'othersWtKg',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Remark = 'remark',
  /** column name */
  RmEmptyBagsWtKg = 'rmEmptyBagsWtKg',
  /** column name */
  RmPaddyGrade = 'rmPaddyGrade',
  /** column name */
  Source = 'source',
  /** column name */
  SourceGodownId = 'sourceGodownId',
  /** column name */
  SourceMillId = 'sourceMillId',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type InternalTransferShipmentItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InternalTransferShipmentItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalTransferShipmentItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: InternalTransferShipmentItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InternalTransferShipmentItems_Var_Pop_Fields = {
  __typename?: 'internalTransferShipmentItems_var_pop_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Var_Pop_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InternalTransferShipmentItems_Var_Samp_Fields = {
  __typename?: 'internalTransferShipmentItems_var_samp_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Var_Samp_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InternalTransferShipmentItems_Variance_Fields = {
  __typename?: 'internalTransferShipmentItems_variance_fields';
  bagWtKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  destinationGodownId?: Maybe<Scalars['Float']>;
  destinationMillId?: Maybe<Scalars['Float']>;
  destinationMillingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmEmptyBagsWtKg?: Maybe<Scalars['Float']>;
  sourceGodownId?: Maybe<Scalars['Float']>;
  sourceMillId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "internalTransferShipmentItems" */
export type InternalTransferShipmentItems_Variance_Order_By = {
  bagWtKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  destinationGodownId?: InputMaybe<Order_By>;
  destinationMillId?: InputMaybe<Order_By>;
  destinationMillingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmEmptyBagsWtKg?: InputMaybe<Order_By>;
  sourceGodownId?: InputMaybe<Order_By>;
  sourceMillId?: InputMaybe<Order_By>;
};

/** columns and relationships of "internalTransferShipments" */
export type InternalTransferShipments = {
  __typename?: 'internalTransferShipments';
  created_at: Scalars['timestamptz'];
  detailsEnteredAt?: Maybe<Scalars['timestamptz']>;
  firstVehicleImage?: Maybe<Scalars['String']>;
  firstVehicleWtKg?: Maybe<Scalars['numeric']>;
  firstVehicleWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  internalTransferShipmentItems: Array<InternalTransferShipmentItems>;
  /** An aggregate relationship */
  internalTransferShipmentItems_aggregate: InternalTransferShipmentItems_Aggregate;
  materialType?: Maybe<Enum_Item_Category_Enum>;
  /** An object relationship */
  materialTypeValue?: Maybe<Enum_Item_Category>;
  personMobile?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  secondVehicleImage?: Maybe<Scalars['String']>;
  secondVehicleWtKg?: Maybe<Scalars['numeric']>;
  secondVehicleWtTakenAt?: Maybe<Scalars['timestamptz']>;
  serialId: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  vehicleNumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "internalTransferShipments" */
export type InternalTransferShipmentsInternalTransferShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipmentItems_Order_By>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};


/** columns and relationships of "internalTransferShipments" */
export type InternalTransferShipmentsInternalTransferShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipmentItems_Order_By>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};

/** aggregated selection of "internalTransferShipments" */
export type InternalTransferShipments_Aggregate = {
  __typename?: 'internalTransferShipments_aggregate';
  aggregate?: Maybe<InternalTransferShipments_Aggregate_Fields>;
  nodes: Array<InternalTransferShipments>;
};

/** aggregate fields of "internalTransferShipments" */
export type InternalTransferShipments_Aggregate_Fields = {
  __typename?: 'internalTransferShipments_aggregate_fields';
  avg?: Maybe<InternalTransferShipments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InternalTransferShipments_Max_Fields>;
  min?: Maybe<InternalTransferShipments_Min_Fields>;
  stddev?: Maybe<InternalTransferShipments_Stddev_Fields>;
  stddev_pop?: Maybe<InternalTransferShipments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InternalTransferShipments_Stddev_Samp_Fields>;
  sum?: Maybe<InternalTransferShipments_Sum_Fields>;
  var_pop?: Maybe<InternalTransferShipments_Var_Pop_Fields>;
  var_samp?: Maybe<InternalTransferShipments_Var_Samp_Fields>;
  variance?: Maybe<InternalTransferShipments_Variance_Fields>;
};


/** aggregate fields of "internalTransferShipments" */
export type InternalTransferShipments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InternalTransferShipments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type InternalTransferShipments_Avg_Fields = {
  __typename?: 'internalTransferShipments_avg_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "internalTransferShipments". All fields are combined with a logical 'AND'. */
export type InternalTransferShipments_Bool_Exp = {
  _and?: InputMaybe<Array<InternalTransferShipments_Bool_Exp>>;
  _not?: InputMaybe<InternalTransferShipments_Bool_Exp>;
  _or?: InputMaybe<Array<InternalTransferShipments_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  detailsEnteredAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstVehicleImage?: InputMaybe<String_Comparison_Exp>;
  firstVehicleWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  firstVehicleWtTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  grn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internalTransferShipmentItems?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
  internalTransferShipmentItems_aggregate?: InputMaybe<InternalTransferShipmentItems_Aggregate_Bool_Exp>;
  materialType?: InputMaybe<Enum_Item_Category_Enum_Comparison_Exp>;
  materialTypeValue?: InputMaybe<Enum_Item_Category_Bool_Exp>;
  personMobile?: InputMaybe<String_Comparison_Exp>;
  personName?: InputMaybe<String_Comparison_Exp>;
  secondVehicleImage?: InputMaybe<String_Comparison_Exp>;
  secondVehicleWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  secondVehicleWtTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  serialId?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicleNumber?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "internalTransferShipments" */
export enum InternalTransferShipments_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalTransferShipmentsPkey = 'internalTransferShipments_pkey',
  /** unique or primary key constraint on columns "serialId" */
  InternalTransferShipmentsSerialIdKey = 'internalTransferShipments_serialId_key'
}

/** input type for incrementing numeric columns in table "internalTransferShipments" */
export type InternalTransferShipments_Inc_Input = {
  firstVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  secondVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  serialId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "internalTransferShipments" */
export type InternalTransferShipments_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detailsEnteredAt?: InputMaybe<Scalars['timestamptz']>;
  firstVehicleImage?: InputMaybe<Scalars['String']>;
  firstVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  firstVehicleWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  internalTransferShipmentItems?: InputMaybe<InternalTransferShipmentItems_Arr_Rel_Insert_Input>;
  materialType?: InputMaybe<Enum_Item_Category_Enum>;
  materialTypeValue?: InputMaybe<Enum_Item_Category_Obj_Rel_Insert_Input>;
  personMobile?: InputMaybe<Scalars['String']>;
  personName?: InputMaybe<Scalars['String']>;
  secondVehicleImage?: InputMaybe<Scalars['String']>;
  secondVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  secondVehicleWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  serialId?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type InternalTransferShipments_Max_Fields = {
  __typename?: 'internalTransferShipments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  detailsEnteredAt?: Maybe<Scalars['timestamptz']>;
  firstVehicleImage?: Maybe<Scalars['String']>;
  firstVehicleWtKg?: Maybe<Scalars['numeric']>;
  firstVehicleWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personMobile?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  secondVehicleImage?: Maybe<Scalars['String']>;
  secondVehicleWtKg?: Maybe<Scalars['numeric']>;
  secondVehicleWtTakenAt?: Maybe<Scalars['timestamptz']>;
  serialId?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type InternalTransferShipments_Min_Fields = {
  __typename?: 'internalTransferShipments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  detailsEnteredAt?: Maybe<Scalars['timestamptz']>;
  firstVehicleImage?: Maybe<Scalars['String']>;
  firstVehicleWtKg?: Maybe<Scalars['numeric']>;
  firstVehicleWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personMobile?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  secondVehicleImage?: Maybe<Scalars['String']>;
  secondVehicleWtKg?: Maybe<Scalars['numeric']>;
  secondVehicleWtTakenAt?: Maybe<Scalars['timestamptz']>;
  serialId?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "internalTransferShipments" */
export type InternalTransferShipments_Mutation_Response = {
  __typename?: 'internalTransferShipments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InternalTransferShipments>;
};

/** input type for inserting object relation for remote table "internalTransferShipments" */
export type InternalTransferShipments_Obj_Rel_Insert_Input = {
  data: InternalTransferShipments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalTransferShipments_On_Conflict>;
};

/** on_conflict condition type for table "internalTransferShipments" */
export type InternalTransferShipments_On_Conflict = {
  constraint: InternalTransferShipments_Constraint;
  update_columns?: Array<InternalTransferShipments_Update_Column>;
  where?: InputMaybe<InternalTransferShipments_Bool_Exp>;
};

/** Ordering options when selecting data from "internalTransferShipments". */
export type InternalTransferShipments_Order_By = {
  created_at?: InputMaybe<Order_By>;
  detailsEnteredAt?: InputMaybe<Order_By>;
  firstVehicleImage?: InputMaybe<Order_By>;
  firstVehicleWtKg?: InputMaybe<Order_By>;
  firstVehicleWtTakenAt?: InputMaybe<Order_By>;
  grn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalTransferShipmentItems_aggregate?: InputMaybe<InternalTransferShipmentItems_Aggregate_Order_By>;
  materialType?: InputMaybe<Order_By>;
  materialTypeValue?: InputMaybe<Enum_Item_Category_Order_By>;
  personMobile?: InputMaybe<Order_By>;
  personName?: InputMaybe<Order_By>;
  secondVehicleImage?: InputMaybe<Order_By>;
  secondVehicleWtKg?: InputMaybe<Order_By>;
  secondVehicleWtTakenAt?: InputMaybe<Order_By>;
  serialId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vehicleNumber?: InputMaybe<Order_By>;
};

/** primary key columns input for table: internalTransferShipments */
export type InternalTransferShipments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "internalTransferShipments" */
export enum InternalTransferShipments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetailsEnteredAt = 'detailsEnteredAt',
  /** column name */
  FirstVehicleImage = 'firstVehicleImage',
  /** column name */
  FirstVehicleWtKg = 'firstVehicleWtKg',
  /** column name */
  FirstVehicleWtTakenAt = 'firstVehicleWtTakenAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaterialType = 'materialType',
  /** column name */
  PersonMobile = 'personMobile',
  /** column name */
  PersonName = 'personName',
  /** column name */
  SecondVehicleImage = 'secondVehicleImage',
  /** column name */
  SecondVehicleWtKg = 'secondVehicleWtKg',
  /** column name */
  SecondVehicleWtTakenAt = 'secondVehicleWtTakenAt',
  /** column name */
  SerialId = 'serialId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleNumber = 'vehicleNumber'
}

/** input type for updating data in table "internalTransferShipments" */
export type InternalTransferShipments_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detailsEnteredAt?: InputMaybe<Scalars['timestamptz']>;
  firstVehicleImage?: InputMaybe<Scalars['String']>;
  firstVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  firstVehicleWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  materialType?: InputMaybe<Enum_Item_Category_Enum>;
  personMobile?: InputMaybe<Scalars['String']>;
  personName?: InputMaybe<Scalars['String']>;
  secondVehicleImage?: InputMaybe<Scalars['String']>;
  secondVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  secondVehicleWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  serialId?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type InternalTransferShipments_Stddev_Fields = {
  __typename?: 'internalTransferShipments_stddev_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type InternalTransferShipments_Stddev_Pop_Fields = {
  __typename?: 'internalTransferShipments_stddev_pop_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type InternalTransferShipments_Stddev_Samp_Fields = {
  __typename?: 'internalTransferShipments_stddev_samp_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "internalTransferShipments" */
export type InternalTransferShipments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InternalTransferShipments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InternalTransferShipments_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  detailsEnteredAt?: InputMaybe<Scalars['timestamptz']>;
  firstVehicleImage?: InputMaybe<Scalars['String']>;
  firstVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  firstVehicleWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  materialType?: InputMaybe<Enum_Item_Category_Enum>;
  personMobile?: InputMaybe<Scalars['String']>;
  personName?: InputMaybe<Scalars['String']>;
  secondVehicleImage?: InputMaybe<Scalars['String']>;
  secondVehicleWtKg?: InputMaybe<Scalars['numeric']>;
  secondVehicleWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  serialId?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type InternalTransferShipments_Sum_Fields = {
  __typename?: 'internalTransferShipments_sum_fields';
  firstVehicleWtKg?: Maybe<Scalars['numeric']>;
  secondVehicleWtKg?: Maybe<Scalars['numeric']>;
  serialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "internalTransferShipments" */
export enum InternalTransferShipments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DetailsEnteredAt = 'detailsEnteredAt',
  /** column name */
  FirstVehicleImage = 'firstVehicleImage',
  /** column name */
  FirstVehicleWtKg = 'firstVehicleWtKg',
  /** column name */
  FirstVehicleWtTakenAt = 'firstVehicleWtTakenAt',
  /** column name */
  Id = 'id',
  /** column name */
  MaterialType = 'materialType',
  /** column name */
  PersonMobile = 'personMobile',
  /** column name */
  PersonName = 'personName',
  /** column name */
  SecondVehicleImage = 'secondVehicleImage',
  /** column name */
  SecondVehicleWtKg = 'secondVehicleWtKg',
  /** column name */
  SecondVehicleWtTakenAt = 'secondVehicleWtTakenAt',
  /** column name */
  SerialId = 'serialId',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VehicleNumber = 'vehicleNumber'
}

export type InternalTransferShipments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InternalTransferShipments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalTransferShipments_Set_Input>;
  /** filter the rows which have to be updated */
  where: InternalTransferShipments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InternalTransferShipments_Var_Pop_Fields = {
  __typename?: 'internalTransferShipments_var_pop_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type InternalTransferShipments_Var_Samp_Fields = {
  __typename?: 'internalTransferShipments_var_samp_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type InternalTransferShipments_Variance_Fields = {
  __typename?: 'internalTransferShipments_variance_fields';
  firstVehicleWtKg?: Maybe<Scalars['Float']>;
  secondVehicleWtKg?: Maybe<Scalars['Float']>;
  serialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "inwardShipmentItems" */
export type InwardShipmentItems = {
  __typename?: 'inwardShipmentItems';
  bagsCount?: Maybe<Scalars['Int']>;
  brokeragePerQuintal?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  destination?: Maybe<Enum_Source_Destination_Enum>;
  emptyBagCost?: Maybe<Scalars['float8']>;
  emptyBagsReturned?: Maybe<Scalars['Boolean']>;
  emptyBagsWtKg?: Maybe<Scalars['float8']>;
  /** An object relationship */
  godown?: Maybe<Godowns>;
  godownId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  invoice?: Maybe<Scalars['String']>;
  inwardShipmentId: Scalars['Int'];
  isBackFromMaintenance?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  item?: Maybe<Items>;
  itemId?: Maybe<Scalars['uuid']>;
  materialReturned?: Maybe<Scalars['Boolean']>;
  materialReturnedReason?: Maybe<Scalars['String']>;
  /** An object relationship */
  mill?: Maybe<Mills>;
  millId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  millingInItems: Array<MillingInItems>;
  /** An aggregate relationship */
  millingInItems_aggregate: MillingInItems_Aggregate;
  /** An object relationship */
  millingProcess?: Maybe<MillingProcesses>;
  millingProcessId?: Maybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['float8']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  paddyGrade?: Maybe<Scalars['String']>;
  pricePerKg?: Maybe<Scalars['float8']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmSellerId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  seller?: Maybe<RmSellers>;
  /** An object relationship */
  shipment: InwardShipments;
  source?: Maybe<Enum_InwardShipmentItem_Source_Enum>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userEmptyBagWtUnit?: Maybe<Enum_Empty_Bag_Wt_Unit_Enum>;
};


/** columns and relationships of "inwardShipmentItems" */
export type InwardShipmentItemsMillingInItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


/** columns and relationships of "inwardShipmentItems" */
export type InwardShipmentItemsMillingInItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};

/** aggregated selection of "inwardShipmentItems" */
export type InwardShipmentItems_Aggregate = {
  __typename?: 'inwardShipmentItems_aggregate';
  aggregate?: Maybe<InwardShipmentItems_Aggregate_Fields>;
  nodes: Array<InwardShipmentItems>;
};

export type InwardShipmentItems_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp_Var_Samp>;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Avg = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Bool_And = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Bool_Or = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Corr = {
  arguments: InwardShipmentItems_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Max = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Min = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Sum = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipmentItems_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "inwardShipmentItems" */
export type InwardShipmentItems_Aggregate_Fields = {
  __typename?: 'inwardShipmentItems_aggregate_fields';
  avg?: Maybe<InwardShipmentItems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InwardShipmentItems_Max_Fields>;
  min?: Maybe<InwardShipmentItems_Min_Fields>;
  stddev?: Maybe<InwardShipmentItems_Stddev_Fields>;
  stddev_pop?: Maybe<InwardShipmentItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InwardShipmentItems_Stddev_Samp_Fields>;
  sum?: Maybe<InwardShipmentItems_Sum_Fields>;
  var_pop?: Maybe<InwardShipmentItems_Var_Pop_Fields>;
  var_samp?: Maybe<InwardShipmentItems_Var_Samp_Fields>;
  variance?: Maybe<InwardShipmentItems_Variance_Fields>;
};


/** aggregate fields of "inwardShipmentItems" */
export type InwardShipmentItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inwardShipmentItems" */
export type InwardShipmentItems_Aggregate_Order_By = {
  avg?: InputMaybe<InwardShipmentItems_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InwardShipmentItems_Max_Order_By>;
  min?: InputMaybe<InwardShipmentItems_Min_Order_By>;
  stddev?: InputMaybe<InwardShipmentItems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InwardShipmentItems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InwardShipmentItems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InwardShipmentItems_Sum_Order_By>;
  var_pop?: InputMaybe<InwardShipmentItems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InwardShipmentItems_Var_Samp_Order_By>;
  variance?: InputMaybe<InwardShipmentItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inwardShipmentItems" */
export type InwardShipmentItems_Arr_Rel_Insert_Input = {
  data: Array<InwardShipmentItems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InwardShipmentItems_On_Conflict>;
};

/** aggregate avg on columns */
export type InwardShipmentItems_Avg_Fields = {
  __typename?: 'inwardShipmentItems_avg_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Avg_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inwardShipmentItems". All fields are combined with a logical 'AND'. */
export type InwardShipmentItems_Bool_Exp = {
  _and?: InputMaybe<Array<InwardShipmentItems_Bool_Exp>>;
  _not?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  _or?: InputMaybe<Array<InwardShipmentItems_Bool_Exp>>;
  bagsCount?: InputMaybe<Int_Comparison_Exp>;
  brokeragePerQuintal?: InputMaybe<Float8_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  destination?: InputMaybe<Enum_Source_Destination_Enum_Comparison_Exp>;
  emptyBagCost?: InputMaybe<Float8_Comparison_Exp>;
  emptyBagsReturned?: InputMaybe<Boolean_Comparison_Exp>;
  emptyBagsWtKg?: InputMaybe<Float8_Comparison_Exp>;
  godown?: InputMaybe<Godowns_Bool_Exp>;
  godownId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice?: InputMaybe<String_Comparison_Exp>;
  inwardShipmentId?: InputMaybe<Int_Comparison_Exp>;
  isBackFromMaintenance?: InputMaybe<Boolean_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  itemId?: InputMaybe<Uuid_Comparison_Exp>;
  materialReturned?: InputMaybe<Boolean_Comparison_Exp>;
  materialReturnedReason?: InputMaybe<String_Comparison_Exp>;
  mill?: InputMaybe<Mills_Bool_Exp>;
  millId?: InputMaybe<Int_Comparison_Exp>;
  millingInItems?: InputMaybe<MillingInItems_Bool_Exp>;
  millingInItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Bool_Exp>;
  millingProcess?: InputMaybe<MillingProcesses_Bool_Exp>;
  millingProcessId?: InputMaybe<Int_Comparison_Exp>;
  netMaterialWtPerBagKg?: InputMaybe<Float8_Comparison_Exp>;
  othersItemName?: InputMaybe<String_Comparison_Exp>;
  othersQuantity?: InputMaybe<Numeric_Comparison_Exp>;
  othersWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  paddyGrade?: InputMaybe<String_Comparison_Exp>;
  pricePerKg?: InputMaybe<Float8_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
  remark?: InputMaybe<String_Comparison_Exp>;
  rmSellerId?: InputMaybe<Int_Comparison_Exp>;
  seller?: InputMaybe<RmSellers_Bool_Exp>;
  shipment?: InputMaybe<InwardShipments_Bool_Exp>;
  source?: InputMaybe<Enum_InwardShipmentItem_Source_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userEmptyBagWtUnit?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "inwardShipmentItems" */
export enum InwardShipmentItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  InwardShipmentItemsPkey = 'inwardShipmentItems_pkey'
}

/** input type for incrementing numeric columns in table "inwardShipmentItems" */
export type InwardShipmentItems_Inc_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  brokeragePerQuintal?: InputMaybe<Scalars['float8']>;
  emptyBagCost?: InputMaybe<Scalars['float8']>;
  emptyBagsWtKg?: InputMaybe<Scalars['float8']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  inwardShipmentId?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['float8']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  pricePerKg?: InputMaybe<Scalars['float8']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  rmSellerId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "inwardShipmentItems" */
export type InwardShipmentItems_Insert_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  brokeragePerQuintal?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<Enum_Source_Destination_Enum>;
  emptyBagCost?: InputMaybe<Scalars['float8']>;
  emptyBagsReturned?: InputMaybe<Scalars['Boolean']>;
  emptyBagsWtKg?: InputMaybe<Scalars['float8']>;
  godown?: InputMaybe<Godowns_Obj_Rel_Insert_Input>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  inwardShipmentId?: InputMaybe<Scalars['Int']>;
  isBackFromMaintenance?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  itemId?: InputMaybe<Scalars['uuid']>;
  materialReturned?: InputMaybe<Scalars['Boolean']>;
  materialReturnedReason?: InputMaybe<Scalars['String']>;
  mill?: InputMaybe<Mills_Obj_Rel_Insert_Input>;
  millId?: InputMaybe<Scalars['Int']>;
  millingInItems?: InputMaybe<MillingInItems_Arr_Rel_Insert_Input>;
  millingProcess?: InputMaybe<MillingProcesses_Obj_Rel_Insert_Input>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['float8']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  pricePerKg?: InputMaybe<Scalars['float8']>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmSellerId?: InputMaybe<Scalars['Int']>;
  seller?: InputMaybe<RmSellers_Obj_Rel_Insert_Input>;
  shipment?: InputMaybe<InwardShipments_Obj_Rel_Insert_Input>;
  source?: InputMaybe<Enum_InwardShipmentItem_Source_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userEmptyBagWtUnit?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Enum>;
};

/** aggregate max on columns */
export type InwardShipmentItems_Max_Fields = {
  __typename?: 'inwardShipmentItems_max_fields';
  bagsCount?: Maybe<Scalars['Int']>;
  brokeragePerQuintal?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyBagCost?: Maybe<Scalars['float8']>;
  emptyBagsWtKg?: Maybe<Scalars['float8']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Scalars['String']>;
  inwardShipmentId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['uuid']>;
  materialReturnedReason?: Maybe<Scalars['String']>;
  millId?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['float8']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  paddyGrade?: Maybe<Scalars['String']>;
  pricePerKg?: Maybe<Scalars['float8']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmSellerId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Max_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  materialReturnedReason?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InwardShipmentItems_Min_Fields = {
  __typename?: 'inwardShipmentItems_min_fields';
  bagsCount?: Maybe<Scalars['Int']>;
  brokeragePerQuintal?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyBagCost?: Maybe<Scalars['float8']>;
  emptyBagsWtKg?: Maybe<Scalars['float8']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Scalars['String']>;
  inwardShipmentId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['uuid']>;
  materialReturnedReason?: Maybe<Scalars['String']>;
  millId?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['float8']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  paddyGrade?: Maybe<Scalars['String']>;
  pricePerKg?: Maybe<Scalars['float8']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmSellerId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Min_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  materialReturnedReason?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "inwardShipmentItems" */
export type InwardShipmentItems_Mutation_Response = {
  __typename?: 'inwardShipmentItems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InwardShipmentItems>;
};

/** input type for inserting object relation for remote table "inwardShipmentItems" */
export type InwardShipmentItems_Obj_Rel_Insert_Input = {
  data: InwardShipmentItems_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<InwardShipmentItems_On_Conflict>;
};

/** on_conflict condition type for table "inwardShipmentItems" */
export type InwardShipmentItems_On_Conflict = {
  constraint: InwardShipmentItems_Constraint;
  update_columns?: Array<InwardShipmentItems_Update_Column>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};

/** Ordering options when selecting data from "inwardShipmentItems". */
export type InwardShipmentItems_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  destination?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsReturned?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godown?: InputMaybe<Godowns_Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  isBackFromMaintenance?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  itemId?: InputMaybe<Order_By>;
  materialReturned?: InputMaybe<Order_By>;
  materialReturnedReason?: InputMaybe<Order_By>;
  mill?: InputMaybe<Mills_Order_By>;
  millId?: InputMaybe<Order_By>;
  millingInItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Order_By>;
  millingProcess?: InputMaybe<MillingProcesses_Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
  seller?: InputMaybe<RmSellers_Order_By>;
  shipment?: InputMaybe<InwardShipments_Order_By>;
  source?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userEmptyBagWtUnit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inwardShipmentItems */
export type InwardShipmentItems_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column {
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Destination = 'destination',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsReturned = 'emptyBagsReturned',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  InwardShipmentId = 'inwardShipmentId',
  /** column name */
  IsBackFromMaintenance = 'isBackFromMaintenance',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  MaterialReturned = 'materialReturned',
  /** column name */
  MaterialReturnedReason = 'materialReturnedReason',
  /** column name */
  MillId = 'millId',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  OthersItemName = 'othersItemName',
  /** column name */
  OthersQuantity = 'othersQuantity',
  /** column name */
  OthersWtKg = 'othersWtKg',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  PricePerKg = 'pricePerKg',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Remark = 'remark',
  /** column name */
  RmSellerId = 'rmSellerId',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserEmptyBagWtUnit = 'userEmptyBagWtUnit'
}

/** select "inwardShipmentItems_aggregate_bool_exp_avg_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EmptyBagsReturned = 'emptyBagsReturned',
  /** column name */
  IsBackFromMaintenance = 'isBackFromMaintenance',
  /** column name */
  MaterialReturned = 'materialReturned'
}

/** select "inwardShipmentItems_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EmptyBagsReturned = 'emptyBagsReturned',
  /** column name */
  IsBackFromMaintenance = 'isBackFromMaintenance',
  /** column name */
  MaterialReturned = 'materialReturned'
}

/** select "inwardShipmentItems_aggregate_bool_exp_corr_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_max_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_min_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_sum_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** select "inwardShipmentItems_aggregate_bool_exp_var_samp_arguments_columns" columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Select_Column_InwardShipmentItems_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  PricePerKg = 'pricePerKg'
}

/** input type for updating data in table "inwardShipmentItems" */
export type InwardShipmentItems_Set_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  brokeragePerQuintal?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<Enum_Source_Destination_Enum>;
  emptyBagCost?: InputMaybe<Scalars['float8']>;
  emptyBagsReturned?: InputMaybe<Scalars['Boolean']>;
  emptyBagsWtKg?: InputMaybe<Scalars['float8']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  inwardShipmentId?: InputMaybe<Scalars['Int']>;
  isBackFromMaintenance?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  materialReturned?: InputMaybe<Scalars['Boolean']>;
  materialReturnedReason?: InputMaybe<Scalars['String']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['float8']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  pricePerKg?: InputMaybe<Scalars['float8']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmSellerId?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Enum_InwardShipmentItem_Source_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userEmptyBagWtUnit?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Enum>;
};

/** aggregate stddev on columns */
export type InwardShipmentItems_Stddev_Fields = {
  __typename?: 'inwardShipmentItems_stddev_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Stddev_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InwardShipmentItems_Stddev_Pop_Fields = {
  __typename?: 'inwardShipmentItems_stddev_pop_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Stddev_Pop_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InwardShipmentItems_Stddev_Samp_Fields = {
  __typename?: 'inwardShipmentItems_stddev_samp_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Stddev_Samp_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inwardShipmentItems" */
export type InwardShipmentItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InwardShipmentItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InwardShipmentItems_Stream_Cursor_Value_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  brokeragePerQuintal?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  destination?: InputMaybe<Enum_Source_Destination_Enum>;
  emptyBagCost?: InputMaybe<Scalars['float8']>;
  emptyBagsReturned?: InputMaybe<Scalars['Boolean']>;
  emptyBagsWtKg?: InputMaybe<Scalars['float8']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  inwardShipmentId?: InputMaybe<Scalars['Int']>;
  isBackFromMaintenance?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  materialReturned?: InputMaybe<Scalars['Boolean']>;
  materialReturnedReason?: InputMaybe<Scalars['String']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: InputMaybe<Scalars['float8']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  pricePerKg?: InputMaybe<Scalars['float8']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmSellerId?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Enum_InwardShipmentItem_Source_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userEmptyBagWtUnit?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Enum>;
};

/** aggregate sum on columns */
export type InwardShipmentItems_Sum_Fields = {
  __typename?: 'inwardShipmentItems_sum_fields';
  bagsCount?: Maybe<Scalars['Int']>;
  brokeragePerQuintal?: Maybe<Scalars['float8']>;
  emptyBagCost?: Maybe<Scalars['float8']>;
  emptyBagsWtKg?: Maybe<Scalars['float8']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inwardShipmentId?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['float8']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  pricePerKg?: Maybe<Scalars['float8']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  rmSellerId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Sum_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** update columns of table "inwardShipmentItems" */
export enum InwardShipmentItems_Update_Column {
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  BrokeragePerQuintal = 'brokeragePerQuintal',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Destination = 'destination',
  /** column name */
  EmptyBagCost = 'emptyBagCost',
  /** column name */
  EmptyBagsReturned = 'emptyBagsReturned',
  /** column name */
  EmptyBagsWtKg = 'emptyBagsWtKg',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  Invoice = 'invoice',
  /** column name */
  InwardShipmentId = 'inwardShipmentId',
  /** column name */
  IsBackFromMaintenance = 'isBackFromMaintenance',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  MaterialReturned = 'materialReturned',
  /** column name */
  MaterialReturnedReason = 'materialReturnedReason',
  /** column name */
  MillId = 'millId',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  NetMaterialWtPerBagKg = 'netMaterialWtPerBagKg',
  /** column name */
  OthersItemName = 'othersItemName',
  /** column name */
  OthersQuantity = 'othersQuantity',
  /** column name */
  OthersWtKg = 'othersWtKg',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  PricePerKg = 'pricePerKg',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Remark = 'remark',
  /** column name */
  RmSellerId = 'rmSellerId',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserEmptyBagWtUnit = 'userEmptyBagWtUnit'
}

export type InwardShipmentItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InwardShipmentItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InwardShipmentItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: InwardShipmentItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InwardShipmentItems_Var_Pop_Fields = {
  __typename?: 'inwardShipmentItems_var_pop_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Var_Pop_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InwardShipmentItems_Var_Samp_Fields = {
  __typename?: 'inwardShipmentItems_var_samp_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Var_Samp_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InwardShipmentItems_Variance_Fields = {
  __typename?: 'inwardShipmentItems_variance_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  brokeragePerQuintal?: Maybe<Scalars['Float']>;
  emptyBagCost?: Maybe<Scalars['Float']>;
  emptyBagsWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inwardShipmentId?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  netMaterialWtPerBagKg?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  pricePerKg?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  rmSellerId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inwardShipmentItems" */
export type InwardShipmentItems_Variance_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  brokeragePerQuintal?: InputMaybe<Order_By>;
  emptyBagCost?: InputMaybe<Order_By>;
  emptyBagsWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentId?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  netMaterialWtPerBagKg?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  pricePerKg?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  rmSellerId?: InputMaybe<Order_By>;
};

/** columns and relationships of "inwardShipments" */
export type InwardShipments = {
  __typename?: 'inwardShipments';
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: Maybe<Scalars['jsonb']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryPersonMobile?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  emptyVehicleImage?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  emptyWtTakenAt?: Maybe<Scalars['timestamptz']>;
  fullVehicleImage?: Maybe<Scalars['String']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  fullWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPaymentDone: Scalars['Boolean'];
  /** An array relationship */
  items: Array<InwardShipmentItems>;
  /** An aggregate relationship */
  items_aggregate: InwardShipmentItems_Aggregate;
  laborChargePerBag?: Maybe<Scalars['float8']>;
  laborChargeType?: Maybe<Enum_Labor_Charge_Type_Enum>;
  paymentComments?: Maybe<Scalars['String']>;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  po_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  purchaseOrder?: Maybe<PurchaseOrders>;
  status?: Maybe<Enum_Inward_Shipment_Status_Enum>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
  weighbridgeCharges?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "inwardShipments" */
export type InwardShipmentsBilling_DetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "inwardShipments" */
export type InwardShipmentsItemsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "inwardShipments" */
export type InwardShipmentsItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "inwardShipments" */
export type InwardShipmentsPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


/** columns and relationships of "inwardShipments" */
export type InwardShipmentsPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};

/** aggregated selection of "inwardShipments" */
export type InwardShipments_Aggregate = {
  __typename?: 'inwardShipments_aggregate';
  aggregate?: Maybe<InwardShipments_Aggregate_Fields>;
  nodes: Array<InwardShipments>;
};

export type InwardShipments_Aggregate_Bool_Exp = {
  avg?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<InwardShipments_Aggregate_Bool_Exp_Var_Samp>;
};

export type InwardShipments_Aggregate_Bool_Exp_Avg = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Bool_And = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Corr = {
  arguments: InwardShipments_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Corr_Arguments = {
  X: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type InwardShipments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<InwardShipments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: InwardShipments_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type InwardShipments_Aggregate_Bool_Exp_Max = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Min = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Sum = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type InwardShipments_Aggregate_Bool_Exp_Var_Samp = {
  arguments: InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<InwardShipments_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "inwardShipments" */
export type InwardShipments_Aggregate_Fields = {
  __typename?: 'inwardShipments_aggregate_fields';
  avg?: Maybe<InwardShipments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<InwardShipments_Max_Fields>;
  min?: Maybe<InwardShipments_Min_Fields>;
  stddev?: Maybe<InwardShipments_Stddev_Fields>;
  stddev_pop?: Maybe<InwardShipments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<InwardShipments_Stddev_Samp_Fields>;
  sum?: Maybe<InwardShipments_Sum_Fields>;
  var_pop?: Maybe<InwardShipments_Var_Pop_Fields>;
  var_samp?: Maybe<InwardShipments_Var_Samp_Fields>;
  variance?: Maybe<InwardShipments_Variance_Fields>;
};


/** aggregate fields of "inwardShipments" */
export type InwardShipments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<InwardShipments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inwardShipments" */
export type InwardShipments_Aggregate_Order_By = {
  avg?: InputMaybe<InwardShipments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<InwardShipments_Max_Order_By>;
  min?: InputMaybe<InwardShipments_Min_Order_By>;
  stddev?: InputMaybe<InwardShipments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<InwardShipments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<InwardShipments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<InwardShipments_Sum_Order_By>;
  var_pop?: InputMaybe<InwardShipments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<InwardShipments_Var_Samp_Order_By>;
  variance?: InputMaybe<InwardShipments_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type InwardShipments_Append_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "inwardShipments" */
export type InwardShipments_Arr_Rel_Insert_Input = {
  data: Array<InwardShipments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<InwardShipments_On_Conflict>;
};

/** aggregate avg on columns */
export type InwardShipments_Avg_Fields = {
  __typename?: 'inwardShipments_avg_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inwardShipments" */
export type InwardShipments_Avg_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inwardShipments". All fields are combined with a logical 'AND'. */
export type InwardShipments_Bool_Exp = {
  _and?: InputMaybe<Array<InwardShipments_Bool_Exp>>;
  _not?: InputMaybe<InwardShipments_Bool_Exp>;
  _or?: InputMaybe<Array<InwardShipments_Bool_Exp>>;
  billing_details?: InputMaybe<Jsonb_Comparison_Exp>;
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveryPersonMobile?: InputMaybe<String_Comparison_Exp>;
  driverName?: InputMaybe<String_Comparison_Exp>;
  emptyVehicleImage?: InputMaybe<String_Comparison_Exp>;
  emptyVehicleWtKg?: InputMaybe<Float8_Comparison_Exp>;
  emptyWtTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fullVehicleImage?: InputMaybe<String_Comparison_Exp>;
  fullVehicleWtKg?: InputMaybe<Float8_Comparison_Exp>;
  fullWtTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  grn?: InputMaybe<String_Comparison_Exp>;
  grn_v2?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isPaymentDone?: InputMaybe<Boolean_Comparison_Exp>;
  items?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  items_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp>;
  laborChargePerBag?: InputMaybe<Float8_Comparison_Exp>;
  laborChargeType?: InputMaybe<Enum_Labor_Charge_Type_Enum_Comparison_Exp>;
  paymentComments?: InputMaybe<String_Comparison_Exp>;
  payments?: InputMaybe<Payment_Bool_Exp>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>;
  po_id?: InputMaybe<Int_Comparison_Exp>;
  purchaseOrder?: InputMaybe<PurchaseOrders_Bool_Exp>;
  status?: InputMaybe<Enum_Inward_Shipment_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicleNumber?: InputMaybe<String_Comparison_Exp>;
  weighbridgeCharges?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "inwardShipments" */
export enum InwardShipments_Constraint {
  /** unique or primary key constraint on columns "id" */
  InwardShipmentsPkey = 'inwardShipments_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type InwardShipments_Delete_At_Path_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type InwardShipments_Delete_Elem_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type InwardShipments_Delete_Key_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "inwardShipments" */
export type InwardShipments_Inc_Input = {
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  laborChargePerBag?: InputMaybe<Scalars['float8']>;
  po_id?: InputMaybe<Scalars['Int']>;
  weighbridgeCharges?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "inwardShipments" */
export type InwardShipments_Insert_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['jsonb']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryPersonMobile?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  emptyWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  fullVehicleImage?: InputMaybe<Scalars['String']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isPaymentDone?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<InwardShipmentItems_Arr_Rel_Insert_Input>;
  laborChargePerBag?: InputMaybe<Scalars['float8']>;
  laborChargeType?: InputMaybe<Enum_Labor_Charge_Type_Enum>;
  paymentComments?: InputMaybe<Scalars['String']>;
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>;
  po_id?: InputMaybe<Scalars['Int']>;
  purchaseOrder?: InputMaybe<PurchaseOrders_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Enum_Inward_Shipment_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
  weighbridgeCharges?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type InwardShipments_Max_Fields = {
  __typename?: 'inwardShipments_max_fields';
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryPersonMobile?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  emptyVehicleImage?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  emptyWtTakenAt?: Maybe<Scalars['timestamptz']>;
  fullVehicleImage?: Maybe<Scalars['String']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  fullWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  laborChargePerBag?: Maybe<Scalars['float8']>;
  paymentComments?: Maybe<Scalars['String']>;
  po_id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
  weighbridgeCharges?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "inwardShipments" */
export type InwardShipments_Max_Order_By = {
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryPersonMobile?: InputMaybe<Order_By>;
  driverName?: InputMaybe<Order_By>;
  emptyVehicleImage?: InputMaybe<Order_By>;
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  emptyWtTakenAt?: InputMaybe<Order_By>;
  fullVehicleImage?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  fullWtTakenAt?: InputMaybe<Order_By>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  paymentComments?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vehicleNumber?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type InwardShipments_Min_Fields = {
  __typename?: 'inwardShipments_min_fields';
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveryPersonMobile?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  emptyVehicleImage?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  emptyWtTakenAt?: Maybe<Scalars['timestamptz']>;
  fullVehicleImage?: Maybe<Scalars['String']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  fullWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  laborChargePerBag?: Maybe<Scalars['float8']>;
  paymentComments?: Maybe<Scalars['String']>;
  po_id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
  weighbridgeCharges?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "inwardShipments" */
export type InwardShipments_Min_Order_By = {
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryPersonMobile?: InputMaybe<Order_By>;
  driverName?: InputMaybe<Order_By>;
  emptyVehicleImage?: InputMaybe<Order_By>;
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  emptyWtTakenAt?: InputMaybe<Order_By>;
  fullVehicleImage?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  fullWtTakenAt?: InputMaybe<Order_By>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  paymentComments?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vehicleNumber?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "inwardShipments" */
export type InwardShipments_Mutation_Response = {
  __typename?: 'inwardShipments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InwardShipments>;
};

/** input type for inserting object relation for remote table "inwardShipments" */
export type InwardShipments_Obj_Rel_Insert_Input = {
  data: InwardShipments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<InwardShipments_On_Conflict>;
};

/** on_conflict condition type for table "inwardShipments" */
export type InwardShipments_On_Conflict = {
  constraint: InwardShipments_Constraint;
  update_columns?: Array<InwardShipments_Update_Column>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};

/** Ordering options when selecting data from "inwardShipments". */
export type InwardShipments_Order_By = {
  billing_details?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryPersonMobile?: InputMaybe<Order_By>;
  driverName?: InputMaybe<Order_By>;
  emptyVehicleImage?: InputMaybe<Order_By>;
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  emptyWtTakenAt?: InputMaybe<Order_By>;
  fullVehicleImage?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  fullWtTakenAt?: InputMaybe<Order_By>;
  grn?: InputMaybe<Order_By>;
  grn_v2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPaymentDone?: InputMaybe<Order_By>;
  items_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  laborChargeType?: InputMaybe<Order_By>;
  paymentComments?: InputMaybe<Order_By>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>;
  po_id?: InputMaybe<Order_By>;
  purchaseOrder?: InputMaybe<PurchaseOrders_Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vehicleNumber?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inwardShipments */
export type InwardShipments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type InwardShipments_Prepend_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "inwardShipments" */
export enum InwardShipments_Select_Column {
  /** column name */
  BillingDetails = 'billing_details',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryPersonMobile = 'deliveryPersonMobile',
  /** column name */
  DriverName = 'driverName',
  /** column name */
  EmptyVehicleImage = 'emptyVehicleImage',
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  EmptyWtTakenAt = 'emptyWtTakenAt',
  /** column name */
  FullVehicleImage = 'fullVehicleImage',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  FullWtTakenAt = 'fullWtTakenAt',
  /** column name */
  GrnV2 = 'grn_v2',
  /** column name */
  Id = 'id',
  /** column name */
  IsPaymentDone = 'isPaymentDone',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  LaborChargeType = 'laborChargeType',
  /** column name */
  PaymentComments = 'paymentComments',
  /** column name */
  PoId = 'po_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VehicleNumber = 'vehicleNumber',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_avg_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPaymentDone = 'isPaymentDone'
}

/** select "inwardShipments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPaymentDone = 'isPaymentDone'
}

/** select "inwardShipments_aggregate_bool_exp_corr_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_max_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_min_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_sum_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** select "inwardShipments_aggregate_bool_exp_var_samp_arguments_columns" columns of table "inwardShipments" */
export enum InwardShipments_Select_Column_InwardShipments_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

/** input type for updating data in table "inwardShipments" */
export type InwardShipments_Set_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['jsonb']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryPersonMobile?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  emptyWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  fullVehicleImage?: InputMaybe<Scalars['String']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isPaymentDone?: InputMaybe<Scalars['Boolean']>;
  laborChargePerBag?: InputMaybe<Scalars['float8']>;
  laborChargeType?: InputMaybe<Enum_Labor_Charge_Type_Enum>;
  paymentComments?: InputMaybe<Scalars['String']>;
  po_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Enum_Inward_Shipment_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
  weighbridgeCharges?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type InwardShipments_Stddev_Fields = {
  __typename?: 'inwardShipments_stddev_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inwardShipments" */
export type InwardShipments_Stddev_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type InwardShipments_Stddev_Pop_Fields = {
  __typename?: 'inwardShipments_stddev_pop_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inwardShipments" */
export type InwardShipments_Stddev_Pop_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type InwardShipments_Stddev_Samp_Fields = {
  __typename?: 'inwardShipments_stddev_samp_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inwardShipments" */
export type InwardShipments_Stddev_Samp_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "inwardShipments" */
export type InwardShipments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: InwardShipments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type InwardShipments_Stream_Cursor_Value_Input = {
  /** Billing details for raw material (paddy) provided by the party. */
  billing_details?: InputMaybe<Scalars['jsonb']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryPersonMobile?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  emptyWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  fullVehicleImage?: InputMaybe<Scalars['String']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isPaymentDone?: InputMaybe<Scalars['Boolean']>;
  laborChargePerBag?: InputMaybe<Scalars['float8']>;
  laborChargeType?: InputMaybe<Enum_Labor_Charge_Type_Enum>;
  paymentComments?: InputMaybe<Scalars['String']>;
  po_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Enum_Inward_Shipment_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
  weighbridgeCharges?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type InwardShipments_Sum_Fields = {
  __typename?: 'inwardShipments_sum_fields';
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  laborChargePerBag?: Maybe<Scalars['float8']>;
  po_id?: Maybe<Scalars['Int']>;
  weighbridgeCharges?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "inwardShipments" */
export type InwardShipments_Sum_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** update columns of table "inwardShipments" */
export enum InwardShipments_Update_Column {
  /** column name */
  BillingDetails = 'billing_details',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryPersonMobile = 'deliveryPersonMobile',
  /** column name */
  DriverName = 'driverName',
  /** column name */
  EmptyVehicleImage = 'emptyVehicleImage',
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  EmptyWtTakenAt = 'emptyWtTakenAt',
  /** column name */
  FullVehicleImage = 'fullVehicleImage',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  FullWtTakenAt = 'fullWtTakenAt',
  /** column name */
  GrnV2 = 'grn_v2',
  /** column name */
  Id = 'id',
  /** column name */
  IsPaymentDone = 'isPaymentDone',
  /** column name */
  LaborChargePerBag = 'laborChargePerBag',
  /** column name */
  LaborChargeType = 'laborChargeType',
  /** column name */
  PaymentComments = 'paymentComments',
  /** column name */
  PoId = 'po_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VehicleNumber = 'vehicleNumber',
  /** column name */
  WeighbridgeCharges = 'weighbridgeCharges'
}

export type InwardShipments_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<InwardShipments_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<InwardShipments_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<InwardShipments_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<InwardShipments_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InwardShipments_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<InwardShipments_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InwardShipments_Set_Input>;
  /** filter the rows which have to be updated */
  where: InwardShipments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type InwardShipments_Var_Pop_Fields = {
  __typename?: 'inwardShipments_var_pop_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inwardShipments" */
export type InwardShipments_Var_Pop_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type InwardShipments_Var_Samp_Fields = {
  __typename?: 'inwardShipments_var_samp_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inwardShipments" */
export type InwardShipments_Var_Samp_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type InwardShipments_Variance_Fields = {
  __typename?: 'inwardShipments_variance_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  laborChargePerBag?: Maybe<Scalars['Float']>;
  po_id?: Maybe<Scalars['Float']>;
  weighbridgeCharges?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inwardShipments" */
export type InwardShipments_Variance_Order_By = {
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  laborChargePerBag?: InputMaybe<Order_By>;
  po_id?: InputMaybe<Order_By>;
  weighbridgeCharges?: InputMaybe<Order_By>;
};

/** columns and relationships of "items" */
export type Items = {
  __typename?: 'items';
  category: Enum_Item_Category_Enum;
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "items" */
export type Items_Aggregate = {
  __typename?: 'items_aggregate';
  aggregate?: Maybe<Items_Aggregate_Fields>;
  nodes: Array<Items>;
};

/** aggregate fields of "items" */
export type Items_Aggregate_Fields = {
  __typename?: 'items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Items_Max_Fields>;
  min?: Maybe<Items_Min_Fields>;
};


/** aggregate fields of "items" */
export type Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "items". All fields are combined with a logical 'AND'. */
export type Items_Bool_Exp = {
  _and?: InputMaybe<Array<Items_Bool_Exp>>;
  _not?: InputMaybe<Items_Bool_Exp>;
  _or?: InputMaybe<Array<Items_Bool_Exp>>;
  category?: InputMaybe<Enum_Item_Category_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "items" */
export enum Items_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemsPkey = 'items_pkey'
}

/** input type for inserting data into table "items" */
export type Items_Insert_Input = {
  category?: InputMaybe<Enum_Item_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Items_Max_Fields = {
  __typename?: 'items_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Items_Min_Fields = {
  __typename?: 'items_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "items" */
export type Items_Mutation_Response = {
  __typename?: 'items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Items>;
};

/** input type for inserting object relation for remote table "items" */
export type Items_Obj_Rel_Insert_Input = {
  data: Items_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Items_On_Conflict>;
};

/** on_conflict condition type for table "items" */
export type Items_On_Conflict = {
  constraint: Items_Constraint;
  update_columns?: Array<Items_Update_Column>;
  where?: InputMaybe<Items_Bool_Exp>;
};

/** Ordering options when selecting data from "items". */
export type Items_Order_By = {
  category?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: items */
export type Items_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "items" */
export enum Items_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "items" */
export type Items_Set_Input = {
  category?: InputMaybe<Enum_Item_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "items" */
export type Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Items_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Items_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Enum_Item_Category_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "items" */
export enum Items_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Items_Set_Input>;
  /** filter the rows which have to be updated */
  where: Items_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "millingInItems" */
export type MillingInItems = {
  __typename?: 'millingInItems';
  bagsCount?: Maybe<Scalars['Int']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['float8']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyBagWtKg?: Maybe<Scalars['float8']>;
  /** An object relationship */
  godown?: Maybe<Godowns>;
  godownId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  millId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  millingProcess: MillingProcesses;
  millingProcessId: Scalars['Int'];
  paddyGrade?: Maybe<Scalars['String']>;
  /** An object relationship */
  product?: Maybe<Products>;
  productId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  source?: Maybe<Enum_Source_Destination_Enum>;
  /** An object relationship */
  sourceInternalTransferShipmentItem?: Maybe<InternalTransferShipmentItems>;
  sourceInternalTransferShipmentItemsId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sourceInwardShipmentItem?: Maybe<InwardShipmentItems>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  sourceMillingProcess?: Maybe<MillingProcesses>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  wtPerBagKg?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "millingInItems" */
export type MillingInItems_Aggregate = {
  __typename?: 'millingInItems_aggregate';
  aggregate?: Maybe<MillingInItems_Aggregate_Fields>;
  nodes: Array<MillingInItems>;
};

export type MillingInItems_Aggregate_Bool_Exp = {
  avg?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<MillingInItems_Aggregate_Bool_Exp_Var_Samp>;
};

export type MillingInItems_Aggregate_Bool_Exp_Avg = {
  arguments: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Corr = {
  arguments: MillingInItems_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Corr_Arguments = {
  X: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type MillingInItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MillingInItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: MillingInItems_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type MillingInItems_Aggregate_Bool_Exp_Max = {
  arguments: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Min = {
  arguments: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Sum = {
  arguments: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type MillingInItems_Aggregate_Bool_Exp_Var_Samp = {
  arguments: MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingInItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "millingInItems" */
export type MillingInItems_Aggregate_Fields = {
  __typename?: 'millingInItems_aggregate_fields';
  avg?: Maybe<MillingInItems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MillingInItems_Max_Fields>;
  min?: Maybe<MillingInItems_Min_Fields>;
  stddev?: Maybe<MillingInItems_Stddev_Fields>;
  stddev_pop?: Maybe<MillingInItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MillingInItems_Stddev_Samp_Fields>;
  sum?: Maybe<MillingInItems_Sum_Fields>;
  var_pop?: Maybe<MillingInItems_Var_Pop_Fields>;
  var_samp?: Maybe<MillingInItems_Var_Samp_Fields>;
  variance?: Maybe<MillingInItems_Variance_Fields>;
};


/** aggregate fields of "millingInItems" */
export type MillingInItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MillingInItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "millingInItems" */
export type MillingInItems_Aggregate_Order_By = {
  avg?: InputMaybe<MillingInItems_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MillingInItems_Max_Order_By>;
  min?: InputMaybe<MillingInItems_Min_Order_By>;
  stddev?: InputMaybe<MillingInItems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<MillingInItems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<MillingInItems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<MillingInItems_Sum_Order_By>;
  var_pop?: InputMaybe<MillingInItems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<MillingInItems_Var_Samp_Order_By>;
  variance?: InputMaybe<MillingInItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "millingInItems" */
export type MillingInItems_Arr_Rel_Insert_Input = {
  data: Array<MillingInItems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MillingInItems_On_Conflict>;
};

/** aggregate avg on columns */
export type MillingInItems_Avg_Fields = {
  __typename?: 'millingInItems_avg_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "millingInItems" */
export type MillingInItems_Avg_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "millingInItems". All fields are combined with a logical 'AND'. */
export type MillingInItems_Bool_Exp = {
  _and?: InputMaybe<Array<MillingInItems_Bool_Exp>>;
  _not?: InputMaybe<MillingInItems_Bool_Exp>;
  _or?: InputMaybe<Array<MillingInItems_Bool_Exp>>;
  bagsCount?: InputMaybe<Int_Comparison_Exp>;
  calcMaterialWtKg?: InputMaybe<Float8_Comparison_Exp>;
  combinedNetWtKg?: InputMaybe<Float8_Comparison_Exp>;
  computedWtPerBagKg?: InputMaybe<Float8_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  emptyBagWtKg?: InputMaybe<Float8_Comparison_Exp>;
  godown?: InputMaybe<Godowns_Bool_Exp>;
  godownId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  millId?: InputMaybe<Int_Comparison_Exp>;
  millingProcess?: InputMaybe<MillingProcesses_Bool_Exp>;
  millingProcessId?: InputMaybe<Int_Comparison_Exp>;
  paddyGrade?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Int_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Enum_Source_Destination_Enum_Comparison_Exp>;
  sourceInternalTransferShipmentItem?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Uuid_Comparison_Exp>;
  sourceInwardShipmentItem?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  sourceInwardShipmentItemsId?: InputMaybe<Int_Comparison_Exp>;
  sourceMillingProcess?: InputMaybe<MillingProcesses_Bool_Exp>;
  sourceMillingProcessId?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  wtPerBagKg?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "millingInItems" */
export enum MillingInItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  MillingInItemsPkey = 'millingInItems_pkey'
}

/** input type for incrementing numeric columns in table "millingInItems" */
export type MillingInItems_Inc_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  combinedNetWtKg?: InputMaybe<Scalars['float8']>;
  emptyBagWtKg?: InputMaybe<Scalars['float8']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  sourceInwardShipmentItemsId?: InputMaybe<Scalars['Int']>;
  sourceMillingProcessId?: InputMaybe<Scalars['Int']>;
  wtPerBagKg?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "millingInItems" */
export type MillingInItems_Insert_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  combinedNetWtKg?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emptyBagWtKg?: InputMaybe<Scalars['float8']>;
  godown?: InputMaybe<Godowns_Obj_Rel_Insert_Input>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcess?: InputMaybe<MillingProcesses_Obj_Rel_Insert_Input>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Enum_Source_Destination_Enum>;
  sourceInternalTransferShipmentItem?: InputMaybe<InternalTransferShipmentItems_Obj_Rel_Insert_Input>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Scalars['uuid']>;
  sourceInwardShipmentItem?: InputMaybe<InwardShipmentItems_Obj_Rel_Insert_Input>;
  sourceInwardShipmentItemsId?: InputMaybe<Scalars['Int']>;
  sourceMillingProcess?: InputMaybe<MillingProcesses_Obj_Rel_Insert_Input>;
  sourceMillingProcessId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wtPerBagKg?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type MillingInItems_Max_Fields = {
  __typename?: 'millingInItems_max_fields';
  bagsCount?: Maybe<Scalars['Int']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['float8']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyBagWtKg?: Maybe<Scalars['float8']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  paddyGrade?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  sourceInternalTransferShipmentItemsId?: Maybe<Scalars['uuid']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  wtPerBagKg?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "millingInItems" */
export type MillingInItems_Max_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MillingInItems_Min_Fields = {
  __typename?: 'millingInItems_min_fields';
  bagsCount?: Maybe<Scalars['Int']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['float8']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyBagWtKg?: Maybe<Scalars['float8']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  paddyGrade?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  sourceInternalTransferShipmentItemsId?: Maybe<Scalars['uuid']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  wtPerBagKg?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "millingInItems" */
export type MillingInItems_Min_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "millingInItems" */
export type MillingInItems_Mutation_Response = {
  __typename?: 'millingInItems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MillingInItems>;
};

/** on_conflict condition type for table "millingInItems" */
export type MillingInItems_On_Conflict = {
  constraint: MillingInItems_Constraint;
  update_columns?: Array<MillingInItems_Update_Column>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};

/** Ordering options when selecting data from "millingInItems". */
export type MillingInItems_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  calcMaterialWtKg?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  computedWtPerBagKg?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godown?: InputMaybe<Godowns_Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcess?: InputMaybe<MillingProcesses_Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  paddyGrade?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  sourceInternalTransferShipmentItem?: InputMaybe<InternalTransferShipmentItems_Order_By>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Order_By>;
  sourceInwardShipmentItem?: InputMaybe<InwardShipmentItems_Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcess?: InputMaybe<MillingProcesses_Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** primary key columns input for table: millingInItems */
export type MillingInItems_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "millingInItems" */
export enum MillingInItems_Select_Column {
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  MillId = 'millId',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Source = 'source',
  /** column name */
  SourceInternalTransferShipmentItemsId = 'sourceInternalTransferShipmentItemsId',
  /** column name */
  SourceInwardShipmentItemsId = 'sourceInwardShipmentItemsId',
  /** column name */
  SourceMillingProcessId = 'sourceMillingProcessId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_avg_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_corr_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_max_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_min_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_sum_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** select "millingInItems_aggregate_bool_exp_var_samp_arguments_columns" columns of table "millingInItems" */
export enum MillingInItems_Select_Column_MillingInItems_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

/** input type for updating data in table "millingInItems" */
export type MillingInItems_Set_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  combinedNetWtKg?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emptyBagWtKg?: InputMaybe<Scalars['float8']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Enum_Source_Destination_Enum>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Scalars['uuid']>;
  sourceInwardShipmentItemsId?: InputMaybe<Scalars['Int']>;
  sourceMillingProcessId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wtPerBagKg?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type MillingInItems_Stddev_Fields = {
  __typename?: 'millingInItems_stddev_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "millingInItems" */
export type MillingInItems_Stddev_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MillingInItems_Stddev_Pop_Fields = {
  __typename?: 'millingInItems_stddev_pop_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "millingInItems" */
export type MillingInItems_Stddev_Pop_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MillingInItems_Stddev_Samp_Fields = {
  __typename?: 'millingInItems_stddev_samp_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "millingInItems" */
export type MillingInItems_Stddev_Samp_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "millingInItems" */
export type MillingInItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MillingInItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MillingInItems_Stream_Cursor_Value_Input = {
  bagsCount?: InputMaybe<Scalars['Int']>;
  combinedNetWtKg?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emptyBagWtKg?: InputMaybe<Scalars['float8']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  paddyGrade?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Enum_Source_Destination_Enum>;
  sourceInternalTransferShipmentItemsId?: InputMaybe<Scalars['uuid']>;
  sourceInwardShipmentItemsId?: InputMaybe<Scalars['Int']>;
  sourceMillingProcessId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wtPerBagKg?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type MillingInItems_Sum_Fields = {
  __typename?: 'millingInItems_sum_fields';
  bagsCount?: Maybe<Scalars['Int']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['float8']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['float8']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Int']>;
  sourceMillingProcessId?: Maybe<Scalars['Int']>;
  wtPerBagKg?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "millingInItems" */
export type MillingInItems_Sum_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** update columns of table "millingInItems" */
export enum MillingInItems_Update_Column {
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  CombinedNetWtKg = 'combinedNetWtKg',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmptyBagWtKg = 'emptyBagWtKg',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  MillId = 'millId',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  PaddyGrade = 'paddyGrade',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Source = 'source',
  /** column name */
  SourceInternalTransferShipmentItemsId = 'sourceInternalTransferShipmentItemsId',
  /** column name */
  SourceInwardShipmentItemsId = 'sourceInwardShipmentItemsId',
  /** column name */
  SourceMillingProcessId = 'sourceMillingProcessId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WtPerBagKg = 'wtPerBagKg'
}

export type MillingInItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MillingInItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MillingInItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: MillingInItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MillingInItems_Var_Pop_Fields = {
  __typename?: 'millingInItems_var_pop_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "millingInItems" */
export type MillingInItems_Var_Pop_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MillingInItems_Var_Samp_Fields = {
  __typename?: 'millingInItems_var_samp_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "millingInItems" */
export type MillingInItems_Var_Samp_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type MillingInItems_Variance_Fields = {
  __typename?: 'millingInItems_variance_fields';
  bagsCount?: Maybe<Scalars['Float']>;
  calcMaterialWtKg?: Maybe<Scalars['float8']>;
  combinedNetWtKg?: Maybe<Scalars['Float']>;
  computedWtPerBagKg?: Maybe<Scalars['float8']>;
  emptyBagWtKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
  sourceInwardShipmentItemsId?: Maybe<Scalars['Float']>;
  sourceMillingProcessId?: Maybe<Scalars['Float']>;
  wtPerBagKg?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "millingInItems" */
export type MillingInItems_Variance_Order_By = {
  bagsCount?: InputMaybe<Order_By>;
  combinedNetWtKg?: InputMaybe<Order_By>;
  emptyBagWtKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  sourceInwardShipmentItemsId?: InputMaybe<Order_By>;
  sourceMillingProcessId?: InputMaybe<Order_By>;
  wtPerBagKg?: InputMaybe<Order_By>;
};

/** columns and relationships of "millingOutItems" */
export type MillingOutItems = {
  __typename?: 'millingOutItems';
  bagSizeKg?: Maybe<Scalars['Int']>;
  bagsCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  millingProcess: MillingProcesses;
  millingProcessId: Scalars['Int'];
  /** An object relationship */
  product?: Maybe<Products>;
  productId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "millingOutItems" */
export type MillingOutItems_Aggregate = {
  __typename?: 'millingOutItems_aggregate';
  aggregate?: Maybe<MillingOutItems_Aggregate_Fields>;
  nodes: Array<MillingOutItems>;
};

export type MillingOutItems_Aggregate_Bool_Exp = {
  count?: InputMaybe<MillingOutItems_Aggregate_Bool_Exp_Count>;
};

export type MillingOutItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MillingOutItems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "millingOutItems" */
export type MillingOutItems_Aggregate_Fields = {
  __typename?: 'millingOutItems_aggregate_fields';
  avg?: Maybe<MillingOutItems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MillingOutItems_Max_Fields>;
  min?: Maybe<MillingOutItems_Min_Fields>;
  stddev?: Maybe<MillingOutItems_Stddev_Fields>;
  stddev_pop?: Maybe<MillingOutItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MillingOutItems_Stddev_Samp_Fields>;
  sum?: Maybe<MillingOutItems_Sum_Fields>;
  var_pop?: Maybe<MillingOutItems_Var_Pop_Fields>;
  var_samp?: Maybe<MillingOutItems_Var_Samp_Fields>;
  variance?: Maybe<MillingOutItems_Variance_Fields>;
};


/** aggregate fields of "millingOutItems" */
export type MillingOutItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "millingOutItems" */
export type MillingOutItems_Aggregate_Order_By = {
  avg?: InputMaybe<MillingOutItems_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<MillingOutItems_Max_Order_By>;
  min?: InputMaybe<MillingOutItems_Min_Order_By>;
  stddev?: InputMaybe<MillingOutItems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<MillingOutItems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<MillingOutItems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<MillingOutItems_Sum_Order_By>;
  var_pop?: InputMaybe<MillingOutItems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<MillingOutItems_Var_Samp_Order_By>;
  variance?: InputMaybe<MillingOutItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "millingOutItems" */
export type MillingOutItems_Arr_Rel_Insert_Input = {
  data: Array<MillingOutItems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<MillingOutItems_On_Conflict>;
};

/** aggregate avg on columns */
export type MillingOutItems_Avg_Fields = {
  __typename?: 'millingOutItems_avg_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "millingOutItems" */
export type MillingOutItems_Avg_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "millingOutItems". All fields are combined with a logical 'AND'. */
export type MillingOutItems_Bool_Exp = {
  _and?: InputMaybe<Array<MillingOutItems_Bool_Exp>>;
  _not?: InputMaybe<MillingOutItems_Bool_Exp>;
  _or?: InputMaybe<Array<MillingOutItems_Bool_Exp>>;
  bagSizeKg?: InputMaybe<Int_Comparison_Exp>;
  bagsCount?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  millingProcess?: InputMaybe<MillingProcesses_Bool_Exp>;
  millingProcessId?: InputMaybe<Int_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "millingOutItems" */
export enum MillingOutItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  MillingOutItemsPkey = 'millingOutItems_pkey'
}

/** input type for incrementing numeric columns in table "millingOutItems" */
export type MillingOutItems_Inc_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "millingOutItems" */
export type MillingOutItems_Insert_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  millingProcess?: InputMaybe<MillingProcesses_Obj_Rel_Insert_Input>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MillingOutItems_Max_Fields = {
  __typename?: 'millingOutItems_max_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  bagsCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "millingOutItems" */
export type MillingOutItems_Max_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type MillingOutItems_Min_Fields = {
  __typename?: 'millingOutItems_min_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  bagsCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "millingOutItems" */
export type MillingOutItems_Min_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "millingOutItems" */
export type MillingOutItems_Mutation_Response = {
  __typename?: 'millingOutItems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MillingOutItems>;
};

/** on_conflict condition type for table "millingOutItems" */
export type MillingOutItems_On_Conflict = {
  constraint: MillingOutItems_Constraint;
  update_columns?: Array<MillingOutItems_Update_Column>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};

/** Ordering options when selecting data from "millingOutItems". */
export type MillingOutItems_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcess?: InputMaybe<MillingProcesses_Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: millingOutItems */
export type MillingOutItems_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "millingOutItems" */
export enum MillingOutItems_Select_Column {
  /** column name */
  BagSizeKg = 'bagSizeKg',
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "millingOutItems" */
export type MillingOutItems_Set_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MillingOutItems_Stddev_Fields = {
  __typename?: 'millingOutItems_stddev_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "millingOutItems" */
export type MillingOutItems_Stddev_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type MillingOutItems_Stddev_Pop_Fields = {
  __typename?: 'millingOutItems_stddev_pop_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "millingOutItems" */
export type MillingOutItems_Stddev_Pop_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type MillingOutItems_Stddev_Samp_Fields = {
  __typename?: 'millingOutItems_stddev_samp_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "millingOutItems" */
export type MillingOutItems_Stddev_Samp_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "millingOutItems" */
export type MillingOutItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MillingOutItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MillingOutItems_Stream_Cursor_Value_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  bagsCount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  millingProcessId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type MillingOutItems_Sum_Fields = {
  __typename?: 'millingOutItems_sum_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  bagsCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  millingProcessId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "millingOutItems" */
export type MillingOutItems_Sum_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** update columns of table "millingOutItems" */
export enum MillingOutItems_Update_Column {
  /** column name */
  BagSizeKg = 'bagSizeKg',
  /** column name */
  BagsCount = 'bagsCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MillingProcessId = 'millingProcessId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type MillingOutItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MillingOutItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MillingOutItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: MillingOutItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MillingOutItems_Var_Pop_Fields = {
  __typename?: 'millingOutItems_var_pop_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "millingOutItems" */
export type MillingOutItems_Var_Pop_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type MillingOutItems_Var_Samp_Fields = {
  __typename?: 'millingOutItems_var_samp_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "millingOutItems" */
export type MillingOutItems_Var_Samp_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type MillingOutItems_Variance_Fields = {
  __typename?: 'millingOutItems_variance_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  bagsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millingProcessId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "millingOutItems" */
export type MillingOutItems_Variance_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  bagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  millingProcessId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
};

/** columns and relationships of "millingProcesses" */
export type MillingProcesses = {
  __typename?: 'millingProcesses';
  /** An object relationship */
  combinedMillingProcess?: Maybe<MillingProcesses>;
  combinedProcessId?: Maybe<Scalars['Int']>;
  completionDate?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  inItems: Array<MillingInItems>;
  /** An aggregate relationship */
  inItems_aggregate: MillingInItems_Aggregate;
  /** An object relationship */
  mill: Mills;
  millId: Scalars['Int'];
  /** An object relationship */
  millingProcessStatus: Enum_Milling_Process_Status;
  /** An object relationship */
  millingProcessType?: Maybe<Enum_Milling_Process_Type>;
  /** An array relationship */
  outItems: Array<MillingOutItems>;
  /** An aggregate relationship */
  outItems_aggregate: MillingOutItems_Aggregate;
  processNumber?: Maybe<Scalars['String']>;
  processType?: Maybe<Enum_Milling_Process_Type_Enum>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  status: Enum_Milling_Process_Status_Enum;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "millingProcesses" */
export type MillingProcessesInItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


/** columns and relationships of "millingProcesses" */
export type MillingProcessesInItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


/** columns and relationships of "millingProcesses" */
export type MillingProcessesOutItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingOutItems_Order_By>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};


/** columns and relationships of "millingProcesses" */
export type MillingProcessesOutItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingOutItems_Order_By>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};

/** aggregated selection of "millingProcesses" */
export type MillingProcesses_Aggregate = {
  __typename?: 'millingProcesses_aggregate';
  aggregate?: Maybe<MillingProcesses_Aggregate_Fields>;
  nodes: Array<MillingProcesses>;
};

/** aggregate fields of "millingProcesses" */
export type MillingProcesses_Aggregate_Fields = {
  __typename?: 'millingProcesses_aggregate_fields';
  avg?: Maybe<MillingProcesses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MillingProcesses_Max_Fields>;
  min?: Maybe<MillingProcesses_Min_Fields>;
  stddev?: Maybe<MillingProcesses_Stddev_Fields>;
  stddev_pop?: Maybe<MillingProcesses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MillingProcesses_Stddev_Samp_Fields>;
  sum?: Maybe<MillingProcesses_Sum_Fields>;
  var_pop?: Maybe<MillingProcesses_Var_Pop_Fields>;
  var_samp?: Maybe<MillingProcesses_Var_Samp_Fields>;
  variance?: Maybe<MillingProcesses_Variance_Fields>;
};


/** aggregate fields of "millingProcesses" */
export type MillingProcesses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MillingProcesses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MillingProcesses_Avg_Fields = {
  __typename?: 'millingProcesses_avg_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "millingProcesses". All fields are combined with a logical 'AND'. */
export type MillingProcesses_Bool_Exp = {
  _and?: InputMaybe<Array<MillingProcesses_Bool_Exp>>;
  _not?: InputMaybe<MillingProcesses_Bool_Exp>;
  _or?: InputMaybe<Array<MillingProcesses_Bool_Exp>>;
  combinedMillingProcess?: InputMaybe<MillingProcesses_Bool_Exp>;
  combinedProcessId?: InputMaybe<Int_Comparison_Exp>;
  completionDate?: InputMaybe<Date_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inItems?: InputMaybe<MillingInItems_Bool_Exp>;
  inItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Bool_Exp>;
  mill?: InputMaybe<Mills_Bool_Exp>;
  millId?: InputMaybe<Int_Comparison_Exp>;
  millingProcessStatus?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
  millingProcessType?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
  outItems?: InputMaybe<MillingOutItems_Bool_Exp>;
  outItems_aggregate?: InputMaybe<MillingOutItems_Aggregate_Bool_Exp>;
  processNumber?: InputMaybe<String_Comparison_Exp>;
  processType?: InputMaybe<Enum_Milling_Process_Type_Enum_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Enum_Milling_Process_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "millingProcesses" */
export enum MillingProcesses_Constraint {
  /** unique or primary key constraint on columns "id" */
  MillingProcessesPkey = 'millingProcesses_pkey'
}

/** input type for incrementing numeric columns in table "millingProcesses" */
export type MillingProcesses_Inc_Input = {
  combinedProcessId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "millingProcesses" */
export type MillingProcesses_Insert_Input = {
  combinedMillingProcess?: InputMaybe<MillingProcesses_Obj_Rel_Insert_Input>;
  combinedProcessId?: InputMaybe<Scalars['Int']>;
  completionDate?: InputMaybe<Scalars['date']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  inItems?: InputMaybe<MillingInItems_Arr_Rel_Insert_Input>;
  mill?: InputMaybe<Mills_Obj_Rel_Insert_Input>;
  millId?: InputMaybe<Scalars['Int']>;
  millingProcessStatus?: InputMaybe<Enum_Milling_Process_Status_Obj_Rel_Insert_Input>;
  millingProcessType?: InputMaybe<Enum_Milling_Process_Type_Obj_Rel_Insert_Input>;
  outItems?: InputMaybe<MillingOutItems_Arr_Rel_Insert_Input>;
  processType?: InputMaybe<Enum_Milling_Process_Type_Enum>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Enum_Milling_Process_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MillingProcesses_Max_Fields = {
  __typename?: 'millingProcesses_max_fields';
  combinedProcessId?: Maybe<Scalars['Int']>;
  completionDate?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  processNumber?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type MillingProcesses_Min_Fields = {
  __typename?: 'millingProcesses_min_fields';
  combinedProcessId?: Maybe<Scalars['Int']>;
  completionDate?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  processNumber?: Maybe<Scalars['String']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "millingProcesses" */
export type MillingProcesses_Mutation_Response = {
  __typename?: 'millingProcesses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MillingProcesses>;
};

/** input type for inserting object relation for remote table "millingProcesses" */
export type MillingProcesses_Obj_Rel_Insert_Input = {
  data: MillingProcesses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<MillingProcesses_On_Conflict>;
};

/** on_conflict condition type for table "millingProcesses" */
export type MillingProcesses_On_Conflict = {
  constraint: MillingProcesses_Constraint;
  update_columns?: Array<MillingProcesses_Update_Column>;
  where?: InputMaybe<MillingProcesses_Bool_Exp>;
};

/** Ordering options when selecting data from "millingProcesses". */
export type MillingProcesses_Order_By = {
  combinedMillingProcess?: InputMaybe<MillingProcesses_Order_By>;
  combinedProcessId?: InputMaybe<Order_By>;
  completionDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inItems_aggregate?: InputMaybe<MillingInItems_Aggregate_Order_By>;
  mill?: InputMaybe<Mills_Order_By>;
  millId?: InputMaybe<Order_By>;
  millingProcessStatus?: InputMaybe<Enum_Milling_Process_Status_Order_By>;
  millingProcessType?: InputMaybe<Enum_Milling_Process_Type_Order_By>;
  outItems_aggregate?: InputMaybe<MillingOutItems_Aggregate_Order_By>;
  processNumber?: InputMaybe<Order_By>;
  processType?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: millingProcesses */
export type MillingProcesses_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "millingProcesses" */
export enum MillingProcesses_Select_Column {
  /** column name */
  CombinedProcessId = 'combinedProcessId',
  /** column name */
  CompletionDate = 'completionDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MillId = 'millId',
  /** column name */
  ProcessType = 'processType',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "millingProcesses" */
export type MillingProcesses_Set_Input = {
  combinedProcessId?: InputMaybe<Scalars['Int']>;
  completionDate?: InputMaybe<Scalars['date']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  processType?: InputMaybe<Enum_Milling_Process_Type_Enum>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Enum_Milling_Process_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type MillingProcesses_Stddev_Fields = {
  __typename?: 'millingProcesses_stddev_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MillingProcesses_Stddev_Pop_Fields = {
  __typename?: 'millingProcesses_stddev_pop_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MillingProcesses_Stddev_Samp_Fields = {
  __typename?: 'millingProcesses_stddev_samp_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "millingProcesses" */
export type MillingProcesses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MillingProcesses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type MillingProcesses_Stream_Cursor_Value_Input = {
  combinedProcessId?: InputMaybe<Scalars['Int']>;
  completionDate?: InputMaybe<Scalars['date']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  processType?: InputMaybe<Enum_Milling_Process_Type_Enum>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Enum_Milling_Process_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type MillingProcesses_Sum_Fields = {
  __typename?: 'millingProcesses_sum_fields';
  combinedProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** update columns of table "millingProcesses" */
export enum MillingProcesses_Update_Column {
  /** column name */
  CombinedProcessId = 'combinedProcessId',
  /** column name */
  CompletionDate = 'completionDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MillId = 'millId',
  /** column name */
  ProcessType = 'processType',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type MillingProcesses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MillingProcesses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MillingProcesses_Set_Input>;
  /** filter the rows which have to be updated */
  where: MillingProcesses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type MillingProcesses_Var_Pop_Fields = {
  __typename?: 'millingProcesses_var_pop_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MillingProcesses_Var_Samp_Fields = {
  __typename?: 'millingProcesses_var_samp_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MillingProcesses_Variance_Fields = {
  __typename?: 'millingProcesses_variance_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "mills" */
export type Mills = {
  __typename?: 'mills';
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "mills" */
export type Mills_Aggregate = {
  __typename?: 'mills_aggregate';
  aggregate?: Maybe<Mills_Aggregate_Fields>;
  nodes: Array<Mills>;
};

/** aggregate fields of "mills" */
export type Mills_Aggregate_Fields = {
  __typename?: 'mills_aggregate_fields';
  avg?: Maybe<Mills_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mills_Max_Fields>;
  min?: Maybe<Mills_Min_Fields>;
  stddev?: Maybe<Mills_Stddev_Fields>;
  stddev_pop?: Maybe<Mills_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mills_Stddev_Samp_Fields>;
  sum?: Maybe<Mills_Sum_Fields>;
  var_pop?: Maybe<Mills_Var_Pop_Fields>;
  var_samp?: Maybe<Mills_Var_Samp_Fields>;
  variance?: Maybe<Mills_Variance_Fields>;
};


/** aggregate fields of "mills" */
export type Mills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Mills_Avg_Fields = {
  __typename?: 'mills_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "mills". All fields are combined with a logical 'AND'. */
export type Mills_Bool_Exp = {
  _and?: InputMaybe<Array<Mills_Bool_Exp>>;
  _not?: InputMaybe<Mills_Bool_Exp>;
  _or?: InputMaybe<Array<Mills_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  company_name?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mills" */
export enum Mills_Constraint {
  /** unique or primary key constraint on columns "name" */
  MillsNameKey = 'mills_name_key',
  /** unique or primary key constraint on columns "id" */
  MillsPkey = 'mills_pkey'
}

/** input type for incrementing numeric columns in table "mills" */
export type Mills_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "mills" */
export type Mills_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Mills_Max_Fields = {
  __typename?: 'mills_max_fields';
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Mills_Min_Fields = {
  __typename?: 'mills_min_fields';
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "mills" */
export type Mills_Mutation_Response = {
  __typename?: 'mills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Mills>;
};

/** input type for inserting object relation for remote table "mills" */
export type Mills_Obj_Rel_Insert_Input = {
  data: Mills_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Mills_On_Conflict>;
};

/** on_conflict condition type for table "mills" */
export type Mills_On_Conflict = {
  constraint: Mills_Constraint;
  update_columns?: Array<Mills_Update_Column>;
  where?: InputMaybe<Mills_Bool_Exp>;
};

/** Ordering options when selecting data from "mills". */
export type Mills_Order_By = {
  address?: InputMaybe<Order_By>;
  company_name?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mills */
export type Mills_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "mills" */
export enum Mills_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "mills" */
export type Mills_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Mills_Stddev_Fields = {
  __typename?: 'mills_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Mills_Stddev_Pop_Fields = {
  __typename?: 'mills_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Mills_Stddev_Samp_Fields = {
  __typename?: 'mills_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "mills" */
export type Mills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mills_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Mills_Sum_Fields = {
  __typename?: 'mills_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "mills" */
export enum Mills_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Mills_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Mills_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Mills_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Mills_Var_Pop_Fields = {
  __typename?: 'mills_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Mills_Var_Samp_Fields = {
  __typename?: 'mills_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Mills_Variance_Fields = {
  __typename?: 'mills_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  adminSetPassword?: Maybe<StatusOutput>;
  createUser?: Maybe<CreateUserOutputType>;
  deleteS3File?: Maybe<DeleteS3FileOutputType>;
  /** delete data from the table: "brokers" */
  delete_brokers?: Maybe<Brokers_Mutation_Response>;
  /** delete single row from the table: "brokers" */
  delete_brokers_by_pk?: Maybe<Brokers>;
  /** delete data from the table: "counters" */
  delete_counters?: Maybe<Counters_Mutation_Response>;
  /** delete single row from the table: "counters" */
  delete_counters_by_pk?: Maybe<Counters>;
  /** delete data from the table: "enum_empty_bag_wt_unit" */
  delete_enum_empty_bag_wt_unit?: Maybe<Enum_Empty_Bag_Wt_Unit_Mutation_Response>;
  /** delete single row from the table: "enum_empty_bag_wt_unit" */
  delete_enum_empty_bag_wt_unit_by_pk?: Maybe<Enum_Empty_Bag_Wt_Unit>;
  /** delete data from the table: "enum_internalTransfer_source_destination" */
  delete_enum_internalTransfer_source_destination?: Maybe<Enum_InternalTransfer_Source_Destination_Mutation_Response>;
  /** delete single row from the table: "enum_internalTransfer_source_destination" */
  delete_enum_internalTransfer_source_destination_by_pk?: Maybe<Enum_InternalTransfer_Source_Destination>;
  /** delete data from the table: "enum_inwardShipmentItem_source" */
  delete_enum_inwardShipmentItem_source?: Maybe<Enum_InwardShipmentItem_Source_Mutation_Response>;
  /** delete single row from the table: "enum_inwardShipmentItem_source" */
  delete_enum_inwardShipmentItem_source_by_pk?: Maybe<Enum_InwardShipmentItem_Source>;
  /** delete data from the table: "enum_inward_shipment_status" */
  delete_enum_inward_shipment_status?: Maybe<Enum_Inward_Shipment_Status_Mutation_Response>;
  /** delete single row from the table: "enum_inward_shipment_status" */
  delete_enum_inward_shipment_status_by_pk?: Maybe<Enum_Inward_Shipment_Status>;
  /** delete data from the table: "enum_item_category" */
  delete_enum_item_category?: Maybe<Enum_Item_Category_Mutation_Response>;
  /** delete single row from the table: "enum_item_category" */
  delete_enum_item_category_by_pk?: Maybe<Enum_Item_Category>;
  /** delete data from the table: "enum_labor_charge_type" */
  delete_enum_labor_charge_type?: Maybe<Enum_Labor_Charge_Type_Mutation_Response>;
  /** delete single row from the table: "enum_labor_charge_type" */
  delete_enum_labor_charge_type_by_pk?: Maybe<Enum_Labor_Charge_Type>;
  /** delete data from the table: "enum_milling_process_status" */
  delete_enum_milling_process_status?: Maybe<Enum_Milling_Process_Status_Mutation_Response>;
  /** delete single row from the table: "enum_milling_process_status" */
  delete_enum_milling_process_status_by_pk?: Maybe<Enum_Milling_Process_Status>;
  /** delete data from the table: "enum_milling_process_type" */
  delete_enum_milling_process_type?: Maybe<Enum_Milling_Process_Type_Mutation_Response>;
  /** delete single row from the table: "enum_milling_process_type" */
  delete_enum_milling_process_type_by_pk?: Maybe<Enum_Milling_Process_Type>;
  /** delete data from the table: "enum_outwardShipmentOrder_source" */
  delete_enum_outwardShipmentOrder_source?: Maybe<Enum_OutwardShipmentOrder_Source_Mutation_Response>;
  /** delete single row from the table: "enum_outwardShipmentOrder_source" */
  delete_enum_outwardShipmentOrder_source_by_pk?: Maybe<Enum_OutwardShipmentOrder_Source>;
  /** delete data from the table: "enum_paddy_grade" */
  delete_enum_paddy_grade?: Maybe<Enum_Paddy_Grade_Mutation_Response>;
  /** delete single row from the table: "enum_paddy_grade" */
  delete_enum_paddy_grade_by_pk?: Maybe<Enum_Paddy_Grade>;
  /** delete data from the table: "enum_raw_material_type" */
  delete_enum_raw_material_type?: Maybe<Enum_Raw_Material_Type_Mutation_Response>;
  /** delete single row from the table: "enum_raw_material_type" */
  delete_enum_raw_material_type_by_pk?: Maybe<Enum_Raw_Material_Type>;
  /** delete data from the table: "enum_setting_value_type" */
  delete_enum_setting_value_type?: Maybe<Enum_Setting_Value_Type_Mutation_Response>;
  /** delete single row from the table: "enum_setting_value_type" */
  delete_enum_setting_value_type_by_pk?: Maybe<Enum_Setting_Value_Type>;
  /** delete data from the table: "enum_source_destination" */
  delete_enum_source_destination?: Maybe<Enum_Source_Destination_Mutation_Response>;
  /** delete single row from the table: "enum_source_destination" */
  delete_enum_source_destination_by_pk?: Maybe<Enum_Source_Destination>;
  /** delete data from the table: "enum_user_role" */
  delete_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** delete single row from the table: "enum_user_role" */
  delete_enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** delete data from the table: "godowns" */
  delete_godowns?: Maybe<Godowns_Mutation_Response>;
  /** delete single row from the table: "godowns" */
  delete_godowns_by_pk?: Maybe<Godowns>;
  /** delete data from the table: "internalTransferShipmentItems" */
  delete_internalTransferShipmentItems?: Maybe<InternalTransferShipmentItems_Mutation_Response>;
  /** delete single row from the table: "internalTransferShipmentItems" */
  delete_internalTransferShipmentItems_by_pk?: Maybe<InternalTransferShipmentItems>;
  /** delete data from the table: "internalTransferShipments" */
  delete_internalTransferShipments?: Maybe<InternalTransferShipments_Mutation_Response>;
  /** delete single row from the table: "internalTransferShipments" */
  delete_internalTransferShipments_by_pk?: Maybe<InternalTransferShipments>;
  /** delete data from the table: "inwardShipmentItems" */
  delete_inwardShipmentItems?: Maybe<InwardShipmentItems_Mutation_Response>;
  /** delete single row from the table: "inwardShipmentItems" */
  delete_inwardShipmentItems_by_pk?: Maybe<InwardShipmentItems>;
  /** delete data from the table: "inwardShipments" */
  delete_inwardShipments?: Maybe<InwardShipments_Mutation_Response>;
  /** delete single row from the table: "inwardShipments" */
  delete_inwardShipments_by_pk?: Maybe<InwardShipments>;
  /** delete data from the table: "items" */
  delete_items?: Maybe<Items_Mutation_Response>;
  /** delete single row from the table: "items" */
  delete_items_by_pk?: Maybe<Items>;
  /** delete data from the table: "millingInItems" */
  delete_millingInItems?: Maybe<MillingInItems_Mutation_Response>;
  /** delete single row from the table: "millingInItems" */
  delete_millingInItems_by_pk?: Maybe<MillingInItems>;
  /** delete data from the table: "millingOutItems" */
  delete_millingOutItems?: Maybe<MillingOutItems_Mutation_Response>;
  /** delete single row from the table: "millingOutItems" */
  delete_millingOutItems_by_pk?: Maybe<MillingOutItems>;
  /** delete data from the table: "millingProcesses" */
  delete_millingProcesses?: Maybe<MillingProcesses_Mutation_Response>;
  /** delete single row from the table: "millingProcesses" */
  delete_millingProcesses_by_pk?: Maybe<MillingProcesses>;
  /** delete data from the table: "mills" */
  delete_mills?: Maybe<Mills_Mutation_Response>;
  /** delete single row from the table: "mills" */
  delete_mills_by_pk?: Maybe<Mills>;
  /** delete data from the table: "outwardShipmentOrders" */
  delete_outwardShipmentOrders?: Maybe<OutwardShipmentOrders_Mutation_Response>;
  /** delete single row from the table: "outwardShipmentOrders" */
  delete_outwardShipmentOrders_by_pk?: Maybe<OutwardShipmentOrders>;
  /** delete data from the table: "outwardShipments" */
  delete_outwardShipments?: Maybe<OutwardShipments_Mutation_Response>;
  /** delete data from the table: "outwardShipmentsDrivers" */
  delete_outwardShipmentsDrivers?: Maybe<OutwardShipmentsDrivers_Mutation_Response>;
  /** delete single row from the table: "outwardShipments" */
  delete_outwardShipments_by_pk?: Maybe<OutwardShipments>;
  /** delete data from the table: "paddyBagOrders" */
  delete_paddyBagOrders?: Maybe<PaddyBagOrders_Mutation_Response>;
  /** delete single row from the table: "paddyBagOrders" */
  delete_paddyBagOrders_by_pk?: Maybe<PaddyBagOrders>;
  /** delete data from the table: "payment" */
  delete_payment?: Maybe<Payment_Mutation_Response>;
  /** delete single row from the table: "payment" */
  delete_payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "productBagOrders" */
  delete_productBagOrders?: Maybe<ProductBagOrders_Mutation_Response>;
  /** delete single row from the table: "productBagOrders" */
  delete_productBagOrders_by_pk?: Maybe<ProductBagOrders>;
  /** delete data from the table: "productBuyers" */
  delete_productBuyers?: Maybe<ProductBuyers_Mutation_Response>;
  /** delete single row from the table: "productBuyers" */
  delete_productBuyers_by_pk?: Maybe<ProductBuyers>;
  /** delete data from the table: "productTypes" */
  delete_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** delete single row from the table: "productTypes" */
  delete_productTypes_by_pk?: Maybe<ProductTypes>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "purchaseOrderItems" */
  delete_purchaseOrderItems?: Maybe<PurchaseOrderItems_Mutation_Response>;
  /** delete single row from the table: "purchaseOrderItems" */
  delete_purchaseOrderItems_by_pk?: Maybe<PurchaseOrderItems>;
  /** delete data from the table: "purchaseOrders" */
  delete_purchaseOrders?: Maybe<PurchaseOrders_Mutation_Response>;
  /** delete single row from the table: "purchaseOrders" */
  delete_purchaseOrders_by_pk?: Maybe<PurchaseOrders>;
  /** delete data from the table: "rawMaterials" */
  delete_rawMaterials?: Maybe<RawMaterials_Mutation_Response>;
  /** delete data from the table: "rawMaterialsProductTypes" */
  delete_rawMaterialsProductTypes?: Maybe<RawMaterialsProductTypes_Mutation_Response>;
  /** delete single row from the table: "rawMaterialsProductTypes" */
  delete_rawMaterialsProductTypes_by_pk?: Maybe<RawMaterialsProductTypes>;
  /** delete single row from the table: "rawMaterials" */
  delete_rawMaterials_by_pk?: Maybe<RawMaterials>;
  /** delete data from the table: "rmSellers" */
  delete_rmSellers?: Maybe<RmSellers_Mutation_Response>;
  /** delete single row from the table: "rmSellers" */
  delete_rmSellers_by_pk?: Maybe<RmSellers>;
  /** delete data from the table: "settings" */
  delete_settings?: Maybe<Settings_Mutation_Response>;
  /** delete single row from the table: "settings" */
  delete_settings_by_pk?: Maybe<Settings>;
  /** delete data from the table: "transporterDrivers" */
  delete_transporterDrivers?: Maybe<TransporterDrivers_Mutation_Response>;
  /** delete single row from the table: "transporterDrivers" */
  delete_transporterDrivers_by_pk?: Maybe<TransporterDrivers>;
  /** delete data from the table: "transporterVehicles" */
  delete_transporterVehicles?: Maybe<TransporterVehicles_Mutation_Response>;
  /** delete single row from the table: "transporterVehicles" */
  delete_transporterVehicles_by_pk?: Maybe<TransporterVehicles>;
  /** delete data from the table: "transporters" */
  delete_transporters?: Maybe<Transporters_Mutation_Response>;
  /** delete single row from the table: "transporters" */
  delete_transporters_by_pk?: Maybe<Transporters>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vendorsItemCategories" */
  delete_vendorsItemCategories?: Maybe<VendorsItemCategories_Mutation_Response>;
  /** delete single row from the table: "vendorsItemCategories" */
  delete_vendorsItemCategories_by_pk?: Maybe<VendorsItemCategories>;
  /** delete data from the table: "weighbridgeReceipts" */
  delete_weighbridgeReceipts?: Maybe<WeighbridgeReceipts_Mutation_Response>;
  /** delete single row from the table: "weighbridgeReceipts" */
  delete_weighbridgeReceipts_by_pk?: Maybe<WeighbridgeReceipts>;
  ecp13?: Maybe<Ecp13mutation_Root>;
  /** insert data into the table: "brokers" */
  insert_brokers?: Maybe<Brokers_Mutation_Response>;
  /** insert a single row into the table: "brokers" */
  insert_brokers_one?: Maybe<Brokers>;
  /** insert data into the table: "counters" */
  insert_counters?: Maybe<Counters_Mutation_Response>;
  /** insert a single row into the table: "counters" */
  insert_counters_one?: Maybe<Counters>;
  /** insert data into the table: "enum_empty_bag_wt_unit" */
  insert_enum_empty_bag_wt_unit?: Maybe<Enum_Empty_Bag_Wt_Unit_Mutation_Response>;
  /** insert a single row into the table: "enum_empty_bag_wt_unit" */
  insert_enum_empty_bag_wt_unit_one?: Maybe<Enum_Empty_Bag_Wt_Unit>;
  /** insert data into the table: "enum_internalTransfer_source_destination" */
  insert_enum_internalTransfer_source_destination?: Maybe<Enum_InternalTransfer_Source_Destination_Mutation_Response>;
  /** insert a single row into the table: "enum_internalTransfer_source_destination" */
  insert_enum_internalTransfer_source_destination_one?: Maybe<Enum_InternalTransfer_Source_Destination>;
  /** insert data into the table: "enum_inwardShipmentItem_source" */
  insert_enum_inwardShipmentItem_source?: Maybe<Enum_InwardShipmentItem_Source_Mutation_Response>;
  /** insert a single row into the table: "enum_inwardShipmentItem_source" */
  insert_enum_inwardShipmentItem_source_one?: Maybe<Enum_InwardShipmentItem_Source>;
  /** insert data into the table: "enum_inward_shipment_status" */
  insert_enum_inward_shipment_status?: Maybe<Enum_Inward_Shipment_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_inward_shipment_status" */
  insert_enum_inward_shipment_status_one?: Maybe<Enum_Inward_Shipment_Status>;
  /** insert data into the table: "enum_item_category" */
  insert_enum_item_category?: Maybe<Enum_Item_Category_Mutation_Response>;
  /** insert a single row into the table: "enum_item_category" */
  insert_enum_item_category_one?: Maybe<Enum_Item_Category>;
  /** insert data into the table: "enum_labor_charge_type" */
  insert_enum_labor_charge_type?: Maybe<Enum_Labor_Charge_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_labor_charge_type" */
  insert_enum_labor_charge_type_one?: Maybe<Enum_Labor_Charge_Type>;
  /** insert data into the table: "enum_milling_process_status" */
  insert_enum_milling_process_status?: Maybe<Enum_Milling_Process_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_milling_process_status" */
  insert_enum_milling_process_status_one?: Maybe<Enum_Milling_Process_Status>;
  /** insert data into the table: "enum_milling_process_type" */
  insert_enum_milling_process_type?: Maybe<Enum_Milling_Process_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_milling_process_type" */
  insert_enum_milling_process_type_one?: Maybe<Enum_Milling_Process_Type>;
  /** insert data into the table: "enum_outwardShipmentOrder_source" */
  insert_enum_outwardShipmentOrder_source?: Maybe<Enum_OutwardShipmentOrder_Source_Mutation_Response>;
  /** insert a single row into the table: "enum_outwardShipmentOrder_source" */
  insert_enum_outwardShipmentOrder_source_one?: Maybe<Enum_OutwardShipmentOrder_Source>;
  /** insert data into the table: "enum_paddy_grade" */
  insert_enum_paddy_grade?: Maybe<Enum_Paddy_Grade_Mutation_Response>;
  /** insert a single row into the table: "enum_paddy_grade" */
  insert_enum_paddy_grade_one?: Maybe<Enum_Paddy_Grade>;
  /** insert data into the table: "enum_raw_material_type" */
  insert_enum_raw_material_type?: Maybe<Enum_Raw_Material_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_raw_material_type" */
  insert_enum_raw_material_type_one?: Maybe<Enum_Raw_Material_Type>;
  /** insert data into the table: "enum_setting_value_type" */
  insert_enum_setting_value_type?: Maybe<Enum_Setting_Value_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_setting_value_type" */
  insert_enum_setting_value_type_one?: Maybe<Enum_Setting_Value_Type>;
  /** insert data into the table: "enum_source_destination" */
  insert_enum_source_destination?: Maybe<Enum_Source_Destination_Mutation_Response>;
  /** insert a single row into the table: "enum_source_destination" */
  insert_enum_source_destination_one?: Maybe<Enum_Source_Destination>;
  /** insert data into the table: "enum_user_role" */
  insert_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** insert a single row into the table: "enum_user_role" */
  insert_enum_user_role_one?: Maybe<Enum_User_Role>;
  /** insert data into the table: "godowns" */
  insert_godowns?: Maybe<Godowns_Mutation_Response>;
  /** insert a single row into the table: "godowns" */
  insert_godowns_one?: Maybe<Godowns>;
  /** insert data into the table: "internalTransferShipmentItems" */
  insert_internalTransferShipmentItems?: Maybe<InternalTransferShipmentItems_Mutation_Response>;
  /** insert a single row into the table: "internalTransferShipmentItems" */
  insert_internalTransferShipmentItems_one?: Maybe<InternalTransferShipmentItems>;
  /** insert data into the table: "internalTransferShipments" */
  insert_internalTransferShipments?: Maybe<InternalTransferShipments_Mutation_Response>;
  /** insert a single row into the table: "internalTransferShipments" */
  insert_internalTransferShipments_one?: Maybe<InternalTransferShipments>;
  /** insert data into the table: "inwardShipmentItems" */
  insert_inwardShipmentItems?: Maybe<InwardShipmentItems_Mutation_Response>;
  /** insert a single row into the table: "inwardShipmentItems" */
  insert_inwardShipmentItems_one?: Maybe<InwardShipmentItems>;
  /** insert data into the table: "inwardShipments" */
  insert_inwardShipments?: Maybe<InwardShipments_Mutation_Response>;
  /** insert a single row into the table: "inwardShipments" */
  insert_inwardShipments_one?: Maybe<InwardShipments>;
  /** insert data into the table: "items" */
  insert_items?: Maybe<Items_Mutation_Response>;
  /** insert a single row into the table: "items" */
  insert_items_one?: Maybe<Items>;
  /** insert data into the table: "millingInItems" */
  insert_millingInItems?: Maybe<MillingInItems_Mutation_Response>;
  /** insert a single row into the table: "millingInItems" */
  insert_millingInItems_one?: Maybe<MillingInItems>;
  /** insert data into the table: "millingOutItems" */
  insert_millingOutItems?: Maybe<MillingOutItems_Mutation_Response>;
  /** insert a single row into the table: "millingOutItems" */
  insert_millingOutItems_one?: Maybe<MillingOutItems>;
  /** insert data into the table: "millingProcesses" */
  insert_millingProcesses?: Maybe<MillingProcesses_Mutation_Response>;
  /** insert a single row into the table: "millingProcesses" */
  insert_millingProcesses_one?: Maybe<MillingProcesses>;
  /** insert data into the table: "mills" */
  insert_mills?: Maybe<Mills_Mutation_Response>;
  /** insert a single row into the table: "mills" */
  insert_mills_one?: Maybe<Mills>;
  /** insert data into the table: "outwardShipmentOrders" */
  insert_outwardShipmentOrders?: Maybe<OutwardShipmentOrders_Mutation_Response>;
  /** insert a single row into the table: "outwardShipmentOrders" */
  insert_outwardShipmentOrders_one?: Maybe<OutwardShipmentOrders>;
  /** insert data into the table: "outwardShipments" */
  insert_outwardShipments?: Maybe<OutwardShipments_Mutation_Response>;
  /** insert data into the table: "outwardShipmentsDrivers" */
  insert_outwardShipmentsDrivers?: Maybe<OutwardShipmentsDrivers_Mutation_Response>;
  /** insert a single row into the table: "outwardShipmentsDrivers" */
  insert_outwardShipmentsDrivers_one?: Maybe<OutwardShipmentsDrivers>;
  /** insert a single row into the table: "outwardShipments" */
  insert_outwardShipments_one?: Maybe<OutwardShipments>;
  /** insert data into the table: "paddyBagOrders" */
  insert_paddyBagOrders?: Maybe<PaddyBagOrders_Mutation_Response>;
  /** insert a single row into the table: "paddyBagOrders" */
  insert_paddyBagOrders_one?: Maybe<PaddyBagOrders>;
  /** insert data into the table: "payment" */
  insert_payment?: Maybe<Payment_Mutation_Response>;
  /** insert a single row into the table: "payment" */
  insert_payment_one?: Maybe<Payment>;
  /** insert data into the table: "productBagOrders" */
  insert_productBagOrders?: Maybe<ProductBagOrders_Mutation_Response>;
  /** insert a single row into the table: "productBagOrders" */
  insert_productBagOrders_one?: Maybe<ProductBagOrders>;
  /** insert data into the table: "productBuyers" */
  insert_productBuyers?: Maybe<ProductBuyers_Mutation_Response>;
  /** insert a single row into the table: "productBuyers" */
  insert_productBuyers_one?: Maybe<ProductBuyers>;
  /** insert data into the table: "productTypes" */
  insert_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** insert a single row into the table: "productTypes" */
  insert_productTypes_one?: Maybe<ProductTypes>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "purchaseOrderItems" */
  insert_purchaseOrderItems?: Maybe<PurchaseOrderItems_Mutation_Response>;
  /** insert a single row into the table: "purchaseOrderItems" */
  insert_purchaseOrderItems_one?: Maybe<PurchaseOrderItems>;
  /** insert data into the table: "purchaseOrders" */
  insert_purchaseOrders?: Maybe<PurchaseOrders_Mutation_Response>;
  /** insert a single row into the table: "purchaseOrders" */
  insert_purchaseOrders_one?: Maybe<PurchaseOrders>;
  /** insert data into the table: "rawMaterials" */
  insert_rawMaterials?: Maybe<RawMaterials_Mutation_Response>;
  /** insert data into the table: "rawMaterialsProductTypes" */
  insert_rawMaterialsProductTypes?: Maybe<RawMaterialsProductTypes_Mutation_Response>;
  /** insert a single row into the table: "rawMaterialsProductTypes" */
  insert_rawMaterialsProductTypes_one?: Maybe<RawMaterialsProductTypes>;
  /** insert a single row into the table: "rawMaterials" */
  insert_rawMaterials_one?: Maybe<RawMaterials>;
  /** insert data into the table: "rmSellers" */
  insert_rmSellers?: Maybe<RmSellers_Mutation_Response>;
  /** insert a single row into the table: "rmSellers" */
  insert_rmSellers_one?: Maybe<RmSellers>;
  /** insert data into the table: "settings" */
  insert_settings?: Maybe<Settings_Mutation_Response>;
  /** insert a single row into the table: "settings" */
  insert_settings_one?: Maybe<Settings>;
  /** insert data into the table: "transporterDrivers" */
  insert_transporterDrivers?: Maybe<TransporterDrivers_Mutation_Response>;
  /** insert a single row into the table: "transporterDrivers" */
  insert_transporterDrivers_one?: Maybe<TransporterDrivers>;
  /** insert data into the table: "transporterVehicles" */
  insert_transporterVehicles?: Maybe<TransporterVehicles_Mutation_Response>;
  /** insert a single row into the table: "transporterVehicles" */
  insert_transporterVehicles_one?: Maybe<TransporterVehicles>;
  /** insert data into the table: "transporters" */
  insert_transporters?: Maybe<Transporters_Mutation_Response>;
  /** insert a single row into the table: "transporters" */
  insert_transporters_one?: Maybe<Transporters>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vendorsItemCategories" */
  insert_vendorsItemCategories?: Maybe<VendorsItemCategories_Mutation_Response>;
  /** insert a single row into the table: "vendorsItemCategories" */
  insert_vendorsItemCategories_one?: Maybe<VendorsItemCategories>;
  /** insert data into the table: "weighbridgeReceipts" */
  insert_weighbridgeReceipts?: Maybe<WeighbridgeReceipts_Mutation_Response>;
  /** insert a single row into the table: "weighbridgeReceipts" */
  insert_weighbridgeReceipts_one?: Maybe<WeighbridgeReceipts>;
  login?: Maybe<LoginOutput>;
  resetUserPassword?: Maybe<ResetUserPasswordOutputType>;
  /** update data of the table: "brokers" */
  update_brokers?: Maybe<Brokers_Mutation_Response>;
  /** update single row of the table: "brokers" */
  update_brokers_by_pk?: Maybe<Brokers>;
  /** update multiples rows of table: "brokers" */
  update_brokers_many?: Maybe<Array<Maybe<Brokers_Mutation_Response>>>;
  /** update data of the table: "counters" */
  update_counters?: Maybe<Counters_Mutation_Response>;
  /** update single row of the table: "counters" */
  update_counters_by_pk?: Maybe<Counters>;
  /** update multiples rows of table: "counters" */
  update_counters_many?: Maybe<Array<Maybe<Counters_Mutation_Response>>>;
  /** update data of the table: "enum_empty_bag_wt_unit" */
  update_enum_empty_bag_wt_unit?: Maybe<Enum_Empty_Bag_Wt_Unit_Mutation_Response>;
  /** update single row of the table: "enum_empty_bag_wt_unit" */
  update_enum_empty_bag_wt_unit_by_pk?: Maybe<Enum_Empty_Bag_Wt_Unit>;
  /** update multiples rows of table: "enum_empty_bag_wt_unit" */
  update_enum_empty_bag_wt_unit_many?: Maybe<Array<Maybe<Enum_Empty_Bag_Wt_Unit_Mutation_Response>>>;
  /** update data of the table: "enum_internalTransfer_source_destination" */
  update_enum_internalTransfer_source_destination?: Maybe<Enum_InternalTransfer_Source_Destination_Mutation_Response>;
  /** update single row of the table: "enum_internalTransfer_source_destination" */
  update_enum_internalTransfer_source_destination_by_pk?: Maybe<Enum_InternalTransfer_Source_Destination>;
  /** update multiples rows of table: "enum_internalTransfer_source_destination" */
  update_enum_internalTransfer_source_destination_many?: Maybe<Array<Maybe<Enum_InternalTransfer_Source_Destination_Mutation_Response>>>;
  /** update data of the table: "enum_inwardShipmentItem_source" */
  update_enum_inwardShipmentItem_source?: Maybe<Enum_InwardShipmentItem_Source_Mutation_Response>;
  /** update single row of the table: "enum_inwardShipmentItem_source" */
  update_enum_inwardShipmentItem_source_by_pk?: Maybe<Enum_InwardShipmentItem_Source>;
  /** update multiples rows of table: "enum_inwardShipmentItem_source" */
  update_enum_inwardShipmentItem_source_many?: Maybe<Array<Maybe<Enum_InwardShipmentItem_Source_Mutation_Response>>>;
  /** update data of the table: "enum_inward_shipment_status" */
  update_enum_inward_shipment_status?: Maybe<Enum_Inward_Shipment_Status_Mutation_Response>;
  /** update single row of the table: "enum_inward_shipment_status" */
  update_enum_inward_shipment_status_by_pk?: Maybe<Enum_Inward_Shipment_Status>;
  /** update multiples rows of table: "enum_inward_shipment_status" */
  update_enum_inward_shipment_status_many?: Maybe<Array<Maybe<Enum_Inward_Shipment_Status_Mutation_Response>>>;
  /** update data of the table: "enum_item_category" */
  update_enum_item_category?: Maybe<Enum_Item_Category_Mutation_Response>;
  /** update single row of the table: "enum_item_category" */
  update_enum_item_category_by_pk?: Maybe<Enum_Item_Category>;
  /** update multiples rows of table: "enum_item_category" */
  update_enum_item_category_many?: Maybe<Array<Maybe<Enum_Item_Category_Mutation_Response>>>;
  /** update data of the table: "enum_labor_charge_type" */
  update_enum_labor_charge_type?: Maybe<Enum_Labor_Charge_Type_Mutation_Response>;
  /** update single row of the table: "enum_labor_charge_type" */
  update_enum_labor_charge_type_by_pk?: Maybe<Enum_Labor_Charge_Type>;
  /** update multiples rows of table: "enum_labor_charge_type" */
  update_enum_labor_charge_type_many?: Maybe<Array<Maybe<Enum_Labor_Charge_Type_Mutation_Response>>>;
  /** update data of the table: "enum_milling_process_status" */
  update_enum_milling_process_status?: Maybe<Enum_Milling_Process_Status_Mutation_Response>;
  /** update single row of the table: "enum_milling_process_status" */
  update_enum_milling_process_status_by_pk?: Maybe<Enum_Milling_Process_Status>;
  /** update multiples rows of table: "enum_milling_process_status" */
  update_enum_milling_process_status_many?: Maybe<Array<Maybe<Enum_Milling_Process_Status_Mutation_Response>>>;
  /** update data of the table: "enum_milling_process_type" */
  update_enum_milling_process_type?: Maybe<Enum_Milling_Process_Type_Mutation_Response>;
  /** update single row of the table: "enum_milling_process_type" */
  update_enum_milling_process_type_by_pk?: Maybe<Enum_Milling_Process_Type>;
  /** update multiples rows of table: "enum_milling_process_type" */
  update_enum_milling_process_type_many?: Maybe<Array<Maybe<Enum_Milling_Process_Type_Mutation_Response>>>;
  /** update data of the table: "enum_outwardShipmentOrder_source" */
  update_enum_outwardShipmentOrder_source?: Maybe<Enum_OutwardShipmentOrder_Source_Mutation_Response>;
  /** update single row of the table: "enum_outwardShipmentOrder_source" */
  update_enum_outwardShipmentOrder_source_by_pk?: Maybe<Enum_OutwardShipmentOrder_Source>;
  /** update multiples rows of table: "enum_outwardShipmentOrder_source" */
  update_enum_outwardShipmentOrder_source_many?: Maybe<Array<Maybe<Enum_OutwardShipmentOrder_Source_Mutation_Response>>>;
  /** update data of the table: "enum_paddy_grade" */
  update_enum_paddy_grade?: Maybe<Enum_Paddy_Grade_Mutation_Response>;
  /** update single row of the table: "enum_paddy_grade" */
  update_enum_paddy_grade_by_pk?: Maybe<Enum_Paddy_Grade>;
  /** update multiples rows of table: "enum_paddy_grade" */
  update_enum_paddy_grade_many?: Maybe<Array<Maybe<Enum_Paddy_Grade_Mutation_Response>>>;
  /** update data of the table: "enum_raw_material_type" */
  update_enum_raw_material_type?: Maybe<Enum_Raw_Material_Type_Mutation_Response>;
  /** update single row of the table: "enum_raw_material_type" */
  update_enum_raw_material_type_by_pk?: Maybe<Enum_Raw_Material_Type>;
  /** update multiples rows of table: "enum_raw_material_type" */
  update_enum_raw_material_type_many?: Maybe<Array<Maybe<Enum_Raw_Material_Type_Mutation_Response>>>;
  /** update data of the table: "enum_setting_value_type" */
  update_enum_setting_value_type?: Maybe<Enum_Setting_Value_Type_Mutation_Response>;
  /** update single row of the table: "enum_setting_value_type" */
  update_enum_setting_value_type_by_pk?: Maybe<Enum_Setting_Value_Type>;
  /** update multiples rows of table: "enum_setting_value_type" */
  update_enum_setting_value_type_many?: Maybe<Array<Maybe<Enum_Setting_Value_Type_Mutation_Response>>>;
  /** update data of the table: "enum_source_destination" */
  update_enum_source_destination?: Maybe<Enum_Source_Destination_Mutation_Response>;
  /** update single row of the table: "enum_source_destination" */
  update_enum_source_destination_by_pk?: Maybe<Enum_Source_Destination>;
  /** update multiples rows of table: "enum_source_destination" */
  update_enum_source_destination_many?: Maybe<Array<Maybe<Enum_Source_Destination_Mutation_Response>>>;
  /** update data of the table: "enum_user_role" */
  update_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** update single row of the table: "enum_user_role" */
  update_enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** update multiples rows of table: "enum_user_role" */
  update_enum_user_role_many?: Maybe<Array<Maybe<Enum_User_Role_Mutation_Response>>>;
  /** update data of the table: "godowns" */
  update_godowns?: Maybe<Godowns_Mutation_Response>;
  /** update single row of the table: "godowns" */
  update_godowns_by_pk?: Maybe<Godowns>;
  /** update multiples rows of table: "godowns" */
  update_godowns_many?: Maybe<Array<Maybe<Godowns_Mutation_Response>>>;
  /** update data of the table: "internalTransferShipmentItems" */
  update_internalTransferShipmentItems?: Maybe<InternalTransferShipmentItems_Mutation_Response>;
  /** update single row of the table: "internalTransferShipmentItems" */
  update_internalTransferShipmentItems_by_pk?: Maybe<InternalTransferShipmentItems>;
  /** update multiples rows of table: "internalTransferShipmentItems" */
  update_internalTransferShipmentItems_many?: Maybe<Array<Maybe<InternalTransferShipmentItems_Mutation_Response>>>;
  /** update data of the table: "internalTransferShipments" */
  update_internalTransferShipments?: Maybe<InternalTransferShipments_Mutation_Response>;
  /** update single row of the table: "internalTransferShipments" */
  update_internalTransferShipments_by_pk?: Maybe<InternalTransferShipments>;
  /** update multiples rows of table: "internalTransferShipments" */
  update_internalTransferShipments_many?: Maybe<Array<Maybe<InternalTransferShipments_Mutation_Response>>>;
  /** update data of the table: "inwardShipmentItems" */
  update_inwardShipmentItems?: Maybe<InwardShipmentItems_Mutation_Response>;
  /** update single row of the table: "inwardShipmentItems" */
  update_inwardShipmentItems_by_pk?: Maybe<InwardShipmentItems>;
  /** update multiples rows of table: "inwardShipmentItems" */
  update_inwardShipmentItems_many?: Maybe<Array<Maybe<InwardShipmentItems_Mutation_Response>>>;
  /** update data of the table: "inwardShipments" */
  update_inwardShipments?: Maybe<InwardShipments_Mutation_Response>;
  /** update single row of the table: "inwardShipments" */
  update_inwardShipments_by_pk?: Maybe<InwardShipments>;
  /** update multiples rows of table: "inwardShipments" */
  update_inwardShipments_many?: Maybe<Array<Maybe<InwardShipments_Mutation_Response>>>;
  /** update data of the table: "items" */
  update_items?: Maybe<Items_Mutation_Response>;
  /** update single row of the table: "items" */
  update_items_by_pk?: Maybe<Items>;
  /** update multiples rows of table: "items" */
  update_items_many?: Maybe<Array<Maybe<Items_Mutation_Response>>>;
  /** update data of the table: "millingInItems" */
  update_millingInItems?: Maybe<MillingInItems_Mutation_Response>;
  /** update single row of the table: "millingInItems" */
  update_millingInItems_by_pk?: Maybe<MillingInItems>;
  /** update multiples rows of table: "millingInItems" */
  update_millingInItems_many?: Maybe<Array<Maybe<MillingInItems_Mutation_Response>>>;
  /** update data of the table: "millingOutItems" */
  update_millingOutItems?: Maybe<MillingOutItems_Mutation_Response>;
  /** update single row of the table: "millingOutItems" */
  update_millingOutItems_by_pk?: Maybe<MillingOutItems>;
  /** update multiples rows of table: "millingOutItems" */
  update_millingOutItems_many?: Maybe<Array<Maybe<MillingOutItems_Mutation_Response>>>;
  /** update data of the table: "millingProcesses" */
  update_millingProcesses?: Maybe<MillingProcesses_Mutation_Response>;
  /** update single row of the table: "millingProcesses" */
  update_millingProcesses_by_pk?: Maybe<MillingProcesses>;
  /** update multiples rows of table: "millingProcesses" */
  update_millingProcesses_many?: Maybe<Array<Maybe<MillingProcesses_Mutation_Response>>>;
  /** update data of the table: "mills" */
  update_mills?: Maybe<Mills_Mutation_Response>;
  /** update single row of the table: "mills" */
  update_mills_by_pk?: Maybe<Mills>;
  /** update multiples rows of table: "mills" */
  update_mills_many?: Maybe<Array<Maybe<Mills_Mutation_Response>>>;
  /** update data of the table: "outwardShipmentOrders" */
  update_outwardShipmentOrders?: Maybe<OutwardShipmentOrders_Mutation_Response>;
  /** update single row of the table: "outwardShipmentOrders" */
  update_outwardShipmentOrders_by_pk?: Maybe<OutwardShipmentOrders>;
  /** update multiples rows of table: "outwardShipmentOrders" */
  update_outwardShipmentOrders_many?: Maybe<Array<Maybe<OutwardShipmentOrders_Mutation_Response>>>;
  /** update data of the table: "outwardShipments" */
  update_outwardShipments?: Maybe<OutwardShipments_Mutation_Response>;
  /** update data of the table: "outwardShipmentsDrivers" */
  update_outwardShipmentsDrivers?: Maybe<OutwardShipmentsDrivers_Mutation_Response>;
  /** update multiples rows of table: "outwardShipmentsDrivers" */
  update_outwardShipmentsDrivers_many?: Maybe<Array<Maybe<OutwardShipmentsDrivers_Mutation_Response>>>;
  /** update single row of the table: "outwardShipments" */
  update_outwardShipments_by_pk?: Maybe<OutwardShipments>;
  /** update multiples rows of table: "outwardShipments" */
  update_outwardShipments_many?: Maybe<Array<Maybe<OutwardShipments_Mutation_Response>>>;
  /** update data of the table: "paddyBagOrders" */
  update_paddyBagOrders?: Maybe<PaddyBagOrders_Mutation_Response>;
  /** update single row of the table: "paddyBagOrders" */
  update_paddyBagOrders_by_pk?: Maybe<PaddyBagOrders>;
  /** update multiples rows of table: "paddyBagOrders" */
  update_paddyBagOrders_many?: Maybe<Array<Maybe<PaddyBagOrders_Mutation_Response>>>;
  /** update data of the table: "payment" */
  update_payment?: Maybe<Payment_Mutation_Response>;
  /** update single row of the table: "payment" */
  update_payment_by_pk?: Maybe<Payment>;
  /** update multiples rows of table: "payment" */
  update_payment_many?: Maybe<Array<Maybe<Payment_Mutation_Response>>>;
  /** update data of the table: "productBagOrders" */
  update_productBagOrders?: Maybe<ProductBagOrders_Mutation_Response>;
  /** update single row of the table: "productBagOrders" */
  update_productBagOrders_by_pk?: Maybe<ProductBagOrders>;
  /** update multiples rows of table: "productBagOrders" */
  update_productBagOrders_many?: Maybe<Array<Maybe<ProductBagOrders_Mutation_Response>>>;
  /** update data of the table: "productBuyers" */
  update_productBuyers?: Maybe<ProductBuyers_Mutation_Response>;
  /** update single row of the table: "productBuyers" */
  update_productBuyers_by_pk?: Maybe<ProductBuyers>;
  /** update multiples rows of table: "productBuyers" */
  update_productBuyers_many?: Maybe<Array<Maybe<ProductBuyers_Mutation_Response>>>;
  /** update data of the table: "productTypes" */
  update_productTypes?: Maybe<ProductTypes_Mutation_Response>;
  /** update single row of the table: "productTypes" */
  update_productTypes_by_pk?: Maybe<ProductTypes>;
  /** update multiples rows of table: "productTypes" */
  update_productTypes_many?: Maybe<Array<Maybe<ProductTypes_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "purchaseOrderItems" */
  update_purchaseOrderItems?: Maybe<PurchaseOrderItems_Mutation_Response>;
  /** update single row of the table: "purchaseOrderItems" */
  update_purchaseOrderItems_by_pk?: Maybe<PurchaseOrderItems>;
  /** update multiples rows of table: "purchaseOrderItems" */
  update_purchaseOrderItems_many?: Maybe<Array<Maybe<PurchaseOrderItems_Mutation_Response>>>;
  /** update data of the table: "purchaseOrders" */
  update_purchaseOrders?: Maybe<PurchaseOrders_Mutation_Response>;
  /** update single row of the table: "purchaseOrders" */
  update_purchaseOrders_by_pk?: Maybe<PurchaseOrders>;
  /** update multiples rows of table: "purchaseOrders" */
  update_purchaseOrders_many?: Maybe<Array<Maybe<PurchaseOrders_Mutation_Response>>>;
  /** update data of the table: "rawMaterials" */
  update_rawMaterials?: Maybe<RawMaterials_Mutation_Response>;
  /** update data of the table: "rawMaterialsProductTypes" */
  update_rawMaterialsProductTypes?: Maybe<RawMaterialsProductTypes_Mutation_Response>;
  /** update single row of the table: "rawMaterialsProductTypes" */
  update_rawMaterialsProductTypes_by_pk?: Maybe<RawMaterialsProductTypes>;
  /** update multiples rows of table: "rawMaterialsProductTypes" */
  update_rawMaterialsProductTypes_many?: Maybe<Array<Maybe<RawMaterialsProductTypes_Mutation_Response>>>;
  /** update single row of the table: "rawMaterials" */
  update_rawMaterials_by_pk?: Maybe<RawMaterials>;
  /** update multiples rows of table: "rawMaterials" */
  update_rawMaterials_many?: Maybe<Array<Maybe<RawMaterials_Mutation_Response>>>;
  /** update data of the table: "rmSellers" */
  update_rmSellers?: Maybe<RmSellers_Mutation_Response>;
  /** update single row of the table: "rmSellers" */
  update_rmSellers_by_pk?: Maybe<RmSellers>;
  /** update multiples rows of table: "rmSellers" */
  update_rmSellers_many?: Maybe<Array<Maybe<RmSellers_Mutation_Response>>>;
  /** update data of the table: "settings" */
  update_settings?: Maybe<Settings_Mutation_Response>;
  /** update single row of the table: "settings" */
  update_settings_by_pk?: Maybe<Settings>;
  /** update multiples rows of table: "settings" */
  update_settings_many?: Maybe<Array<Maybe<Settings_Mutation_Response>>>;
  /** update data of the table: "transporterDrivers" */
  update_transporterDrivers?: Maybe<TransporterDrivers_Mutation_Response>;
  /** update single row of the table: "transporterDrivers" */
  update_transporterDrivers_by_pk?: Maybe<TransporterDrivers>;
  /** update multiples rows of table: "transporterDrivers" */
  update_transporterDrivers_many?: Maybe<Array<Maybe<TransporterDrivers_Mutation_Response>>>;
  /** update data of the table: "transporterVehicles" */
  update_transporterVehicles?: Maybe<TransporterVehicles_Mutation_Response>;
  /** update single row of the table: "transporterVehicles" */
  update_transporterVehicles_by_pk?: Maybe<TransporterVehicles>;
  /** update multiples rows of table: "transporterVehicles" */
  update_transporterVehicles_many?: Maybe<Array<Maybe<TransporterVehicles_Mutation_Response>>>;
  /** update data of the table: "transporters" */
  update_transporters?: Maybe<Transporters_Mutation_Response>;
  /** update single row of the table: "transporters" */
  update_transporters_by_pk?: Maybe<Transporters>;
  /** update multiples rows of table: "transporters" */
  update_transporters_many?: Maybe<Array<Maybe<Transporters_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vendorsItemCategories" */
  update_vendorsItemCategories?: Maybe<VendorsItemCategories_Mutation_Response>;
  /** update single row of the table: "vendorsItemCategories" */
  update_vendorsItemCategories_by_pk?: Maybe<VendorsItemCategories>;
  /** update multiples rows of table: "vendorsItemCategories" */
  update_vendorsItemCategories_many?: Maybe<Array<Maybe<VendorsItemCategories_Mutation_Response>>>;
  /** update data of the table: "weighbridgeReceipts" */
  update_weighbridgeReceipts?: Maybe<WeighbridgeReceipts_Mutation_Response>;
  /** update single row of the table: "weighbridgeReceipts" */
  update_weighbridgeReceipts_by_pk?: Maybe<WeighbridgeReceipts>;
  /** update multiples rows of table: "weighbridgeReceipts" */
  update_weighbridgeReceipts_many?: Maybe<Array<Maybe<WeighbridgeReceipts_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAdminSetPasswordArgs = {
  password: Scalars['String'];
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCreateUserArgs = {
  address?: InputMaybe<Scalars['json']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
  name: Scalars['String'];
  role: UserRole;
};


/** mutation root */
export type Mutation_RootDeleteS3FileArgs = {
  s3Key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_BrokersArgs = {
  where: Brokers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Brokers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CountersArgs = {
  where: Counters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Counters_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Empty_Bag_Wt_UnitArgs = {
  where: Enum_Empty_Bag_Wt_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Empty_Bag_Wt_Unit_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_InternalTransfer_Source_DestinationArgs = {
  where: Enum_InternalTransfer_Source_Destination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_InternalTransfer_Source_Destination_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_InwardShipmentItem_SourceArgs = {
  where: Enum_InwardShipmentItem_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_InwardShipmentItem_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Inward_Shipment_StatusArgs = {
  where: Enum_Inward_Shipment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Inward_Shipment_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Item_CategoryArgs = {
  where: Enum_Item_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Item_Category_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Labor_Charge_TypeArgs = {
  where: Enum_Labor_Charge_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Labor_Charge_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Milling_Process_StatusArgs = {
  where: Enum_Milling_Process_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Milling_Process_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Milling_Process_TypeArgs = {
  where: Enum_Milling_Process_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Milling_Process_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_OutwardShipmentOrder_SourceArgs = {
  where: Enum_OutwardShipmentOrder_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_OutwardShipmentOrder_Source_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Paddy_GradeArgs = {
  where: Enum_Paddy_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Paddy_Grade_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Raw_Material_TypeArgs = {
  where: Enum_Raw_Material_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Raw_Material_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Setting_Value_TypeArgs = {
  where: Enum_Setting_Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Setting_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Source_DestinationArgs = {
  where: Enum_Source_Destination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Source_Destination_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_RoleArgs = {
  where: Enum_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_GodownsArgs = {
  where: Godowns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Godowns_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InternalTransferShipmentItemsArgs = {
  where: InternalTransferShipmentItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InternalTransferShipmentItems_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InternalTransferShipmentsArgs = {
  where: InternalTransferShipments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InternalTransferShipments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_InwardShipmentItemsArgs = {
  where: InwardShipmentItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InwardShipmentItems_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_InwardShipmentsArgs = {
  where: InwardShipments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InwardShipments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ItemsArgs = {
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MillingInItemsArgs = {
  where: MillingInItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MillingInItems_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MillingOutItemsArgs = {
  where: MillingOutItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MillingOutItems_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MillingProcessesArgs = {
  where: MillingProcesses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MillingProcesses_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_MillsArgs = {
  where: Mills_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mills_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OutwardShipmentOrdersArgs = {
  where: OutwardShipmentOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OutwardShipmentOrders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OutwardShipmentsArgs = {
  where: OutwardShipments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OutwardShipmentsDriversArgs = {
  where: OutwardShipmentsDrivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_OutwardShipments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PaddyBagOrdersArgs = {
  where: PaddyBagOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PaddyBagOrders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductBagOrdersArgs = {
  where: ProductBagOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductBagOrders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProductBuyersArgs = {
  where: ProductBuyers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductBuyers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProductTypesArgs = {
  where: ProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductTypes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PurchaseOrderItemsArgs = {
  where: PurchaseOrderItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PurchaseOrderItems_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PurchaseOrdersArgs = {
  where: PurchaseOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PurchaseOrders_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RawMaterialsArgs = {
  where: RawMaterials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RawMaterialsProductTypesArgs = {
  where: RawMaterialsProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RawMaterialsProductTypes_By_PkArgs = {
  productTypeId: Scalars['Int'];
  rawMaterialId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RawMaterials_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RmSellersArgs = {
  where: RmSellers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_RmSellers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SettingsArgs = {
  where: Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Settings_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TransporterDriversArgs = {
  where: TransporterDrivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TransporterDrivers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TransporterVehiclesArgs = {
  where: TransporterVehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TransporterVehicles_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TransportersArgs = {
  where: Transporters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transporters_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VendorsItemCategoriesArgs = {
  where: VendorsItemCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VendorsItemCategories_By_PkArgs = {
  itemCategory: Enum_Item_Category_Enum;
  vendorId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WeighbridgeReceiptsArgs = {
  where: WeighbridgeReceipts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WeighbridgeReceipts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_BrokersArgs = {
  objects: Array<Brokers_Insert_Input>;
  on_conflict?: InputMaybe<Brokers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Brokers_OneArgs = {
  object: Brokers_Insert_Input;
  on_conflict?: InputMaybe<Brokers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountersArgs = {
  objects: Array<Counters_Insert_Input>;
  on_conflict?: InputMaybe<Counters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Counters_OneArgs = {
  object: Counters_Insert_Input;
  on_conflict?: InputMaybe<Counters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Empty_Bag_Wt_UnitArgs = {
  objects: Array<Enum_Empty_Bag_Wt_Unit_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Empty_Bag_Wt_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Empty_Bag_Wt_Unit_OneArgs = {
  object: Enum_Empty_Bag_Wt_Unit_Insert_Input;
  on_conflict?: InputMaybe<Enum_Empty_Bag_Wt_Unit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_InternalTransfer_Source_DestinationArgs = {
  objects: Array<Enum_InternalTransfer_Source_Destination_Insert_Input>;
  on_conflict?: InputMaybe<Enum_InternalTransfer_Source_Destination_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_InternalTransfer_Source_Destination_OneArgs = {
  object: Enum_InternalTransfer_Source_Destination_Insert_Input;
  on_conflict?: InputMaybe<Enum_InternalTransfer_Source_Destination_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_InwardShipmentItem_SourceArgs = {
  objects: Array<Enum_InwardShipmentItem_Source_Insert_Input>;
  on_conflict?: InputMaybe<Enum_InwardShipmentItem_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_InwardShipmentItem_Source_OneArgs = {
  object: Enum_InwardShipmentItem_Source_Insert_Input;
  on_conflict?: InputMaybe<Enum_InwardShipmentItem_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Inward_Shipment_StatusArgs = {
  objects: Array<Enum_Inward_Shipment_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Inward_Shipment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Inward_Shipment_Status_OneArgs = {
  object: Enum_Inward_Shipment_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Inward_Shipment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Item_CategoryArgs = {
  objects: Array<Enum_Item_Category_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Item_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Item_Category_OneArgs = {
  object: Enum_Item_Category_Insert_Input;
  on_conflict?: InputMaybe<Enum_Item_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Labor_Charge_TypeArgs = {
  objects: Array<Enum_Labor_Charge_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Labor_Charge_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Labor_Charge_Type_OneArgs = {
  object: Enum_Labor_Charge_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Labor_Charge_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Milling_Process_StatusArgs = {
  objects: Array<Enum_Milling_Process_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Milling_Process_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Milling_Process_Status_OneArgs = {
  object: Enum_Milling_Process_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Milling_Process_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Milling_Process_TypeArgs = {
  objects: Array<Enum_Milling_Process_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Milling_Process_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Milling_Process_Type_OneArgs = {
  object: Enum_Milling_Process_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Milling_Process_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_OutwardShipmentOrder_SourceArgs = {
  objects: Array<Enum_OutwardShipmentOrder_Source_Insert_Input>;
  on_conflict?: InputMaybe<Enum_OutwardShipmentOrder_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_OutwardShipmentOrder_Source_OneArgs = {
  object: Enum_OutwardShipmentOrder_Source_Insert_Input;
  on_conflict?: InputMaybe<Enum_OutwardShipmentOrder_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Paddy_GradeArgs = {
  objects: Array<Enum_Paddy_Grade_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Paddy_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Paddy_Grade_OneArgs = {
  object: Enum_Paddy_Grade_Insert_Input;
  on_conflict?: InputMaybe<Enum_Paddy_Grade_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Raw_Material_TypeArgs = {
  objects: Array<Enum_Raw_Material_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Raw_Material_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Raw_Material_Type_OneArgs = {
  object: Enum_Raw_Material_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Raw_Material_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Setting_Value_TypeArgs = {
  objects: Array<Enum_Setting_Value_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Setting_Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Setting_Value_Type_OneArgs = {
  object: Enum_Setting_Value_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Setting_Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Source_DestinationArgs = {
  objects: Array<Enum_Source_Destination_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Source_Destination_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Source_Destination_OneArgs = {
  object: Enum_Source_Destination_Insert_Input;
  on_conflict?: InputMaybe<Enum_Source_Destination_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_RoleArgs = {
  objects: Array<Enum_User_Role_Insert_Input>;
  on_conflict?: InputMaybe<Enum_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_Role_OneArgs = {
  object: Enum_User_Role_Insert_Input;
  on_conflict?: InputMaybe<Enum_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GodownsArgs = {
  objects: Array<Godowns_Insert_Input>;
  on_conflict?: InputMaybe<Godowns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Godowns_OneArgs = {
  object: Godowns_Insert_Input;
  on_conflict?: InputMaybe<Godowns_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalTransferShipmentItemsArgs = {
  objects: Array<InternalTransferShipmentItems_Insert_Input>;
  on_conflict?: InputMaybe<InternalTransferShipmentItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalTransferShipmentItems_OneArgs = {
  object: InternalTransferShipmentItems_Insert_Input;
  on_conflict?: InputMaybe<InternalTransferShipmentItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalTransferShipmentsArgs = {
  objects: Array<InternalTransferShipments_Insert_Input>;
  on_conflict?: InputMaybe<InternalTransferShipments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InternalTransferShipments_OneArgs = {
  object: InternalTransferShipments_Insert_Input;
  on_conflict?: InputMaybe<InternalTransferShipments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InwardShipmentItemsArgs = {
  objects: Array<InwardShipmentItems_Insert_Input>;
  on_conflict?: InputMaybe<InwardShipmentItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InwardShipmentItems_OneArgs = {
  object: InwardShipmentItems_Insert_Input;
  on_conflict?: InputMaybe<InwardShipmentItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InwardShipmentsArgs = {
  objects: Array<InwardShipments_Insert_Input>;
  on_conflict?: InputMaybe<InwardShipments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InwardShipments_OneArgs = {
  object: InwardShipments_Insert_Input;
  on_conflict?: InputMaybe<InwardShipments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItemsArgs = {
  objects: Array<Items_Insert_Input>;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Items_OneArgs = {
  object: Items_Insert_Input;
  on_conflict?: InputMaybe<Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillingInItemsArgs = {
  objects: Array<MillingInItems_Insert_Input>;
  on_conflict?: InputMaybe<MillingInItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillingInItems_OneArgs = {
  object: MillingInItems_Insert_Input;
  on_conflict?: InputMaybe<MillingInItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillingOutItemsArgs = {
  objects: Array<MillingOutItems_Insert_Input>;
  on_conflict?: InputMaybe<MillingOutItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillingOutItems_OneArgs = {
  object: MillingOutItems_Insert_Input;
  on_conflict?: InputMaybe<MillingOutItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillingProcessesArgs = {
  objects: Array<MillingProcesses_Insert_Input>;
  on_conflict?: InputMaybe<MillingProcesses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillingProcesses_OneArgs = {
  object: MillingProcesses_Insert_Input;
  on_conflict?: InputMaybe<MillingProcesses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MillsArgs = {
  objects: Array<Mills_Insert_Input>;
  on_conflict?: InputMaybe<Mills_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mills_OneArgs = {
  object: Mills_Insert_Input;
  on_conflict?: InputMaybe<Mills_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OutwardShipmentOrdersArgs = {
  objects: Array<OutwardShipmentOrders_Insert_Input>;
  on_conflict?: InputMaybe<OutwardShipmentOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OutwardShipmentOrders_OneArgs = {
  object: OutwardShipmentOrders_Insert_Input;
  on_conflict?: InputMaybe<OutwardShipmentOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OutwardShipmentsArgs = {
  objects: Array<OutwardShipments_Insert_Input>;
  on_conflict?: InputMaybe<OutwardShipments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OutwardShipmentsDriversArgs = {
  objects: Array<OutwardShipmentsDrivers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_OutwardShipmentsDrivers_OneArgs = {
  object: OutwardShipmentsDrivers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_OutwardShipments_OneArgs = {
  object: OutwardShipments_Insert_Input;
  on_conflict?: InputMaybe<OutwardShipments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaddyBagOrdersArgs = {
  objects: Array<PaddyBagOrders_Insert_Input>;
  on_conflict?: InputMaybe<PaddyBagOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaddyBagOrders_OneArgs = {
  object: PaddyBagOrders_Insert_Input;
  on_conflict?: InputMaybe<PaddyBagOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductBagOrdersArgs = {
  objects: Array<ProductBagOrders_Insert_Input>;
  on_conflict?: InputMaybe<ProductBagOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductBagOrders_OneArgs = {
  object: ProductBagOrders_Insert_Input;
  on_conflict?: InputMaybe<ProductBagOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductBuyersArgs = {
  objects: Array<ProductBuyers_Insert_Input>;
  on_conflict?: InputMaybe<ProductBuyers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductBuyers_OneArgs = {
  object: ProductBuyers_Insert_Input;
  on_conflict?: InputMaybe<ProductBuyers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypesArgs = {
  objects: Array<ProductTypes_Insert_Input>;
  on_conflict?: InputMaybe<ProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductTypes_OneArgs = {
  object: ProductTypes_Insert_Input;
  on_conflict?: InputMaybe<ProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseOrderItemsArgs = {
  objects: Array<PurchaseOrderItems_Insert_Input>;
  on_conflict?: InputMaybe<PurchaseOrderItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseOrderItems_OneArgs = {
  object: PurchaseOrderItems_Insert_Input;
  on_conflict?: InputMaybe<PurchaseOrderItems_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseOrdersArgs = {
  objects: Array<PurchaseOrders_Insert_Input>;
  on_conflict?: InputMaybe<PurchaseOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchaseOrders_OneArgs = {
  object: PurchaseOrders_Insert_Input;
  on_conflict?: InputMaybe<PurchaseOrders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RawMaterialsArgs = {
  objects: Array<RawMaterials_Insert_Input>;
  on_conflict?: InputMaybe<RawMaterials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RawMaterialsProductTypesArgs = {
  objects: Array<RawMaterialsProductTypes_Insert_Input>;
  on_conflict?: InputMaybe<RawMaterialsProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RawMaterialsProductTypes_OneArgs = {
  object: RawMaterialsProductTypes_Insert_Input;
  on_conflict?: InputMaybe<RawMaterialsProductTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RawMaterials_OneArgs = {
  object: RawMaterials_Insert_Input;
  on_conflict?: InputMaybe<RawMaterials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RmSellersArgs = {
  objects: Array<RmSellers_Insert_Input>;
  on_conflict?: InputMaybe<RmSellers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RmSellers_OneArgs = {
  object: RmSellers_Insert_Input;
  on_conflict?: InputMaybe<RmSellers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SettingsArgs = {
  objects: Array<Settings_Insert_Input>;
  on_conflict?: InputMaybe<Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Settings_OneArgs = {
  object: Settings_Insert_Input;
  on_conflict?: InputMaybe<Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransporterDriversArgs = {
  objects: Array<TransporterDrivers_Insert_Input>;
  on_conflict?: InputMaybe<TransporterDrivers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransporterDrivers_OneArgs = {
  object: TransporterDrivers_Insert_Input;
  on_conflict?: InputMaybe<TransporterDrivers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransporterVehiclesArgs = {
  objects: Array<TransporterVehicles_Insert_Input>;
  on_conflict?: InputMaybe<TransporterVehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransporterVehicles_OneArgs = {
  object: TransporterVehicles_Insert_Input;
  on_conflict?: InputMaybe<TransporterVehicles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransportersArgs = {
  objects: Array<Transporters_Insert_Input>;
  on_conflict?: InputMaybe<Transporters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transporters_OneArgs = {
  object: Transporters_Insert_Input;
  on_conflict?: InputMaybe<Transporters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VendorsItemCategoriesArgs = {
  objects: Array<VendorsItemCategories_Insert_Input>;
  on_conflict?: InputMaybe<VendorsItemCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VendorsItemCategories_OneArgs = {
  object: VendorsItemCategories_Insert_Input;
  on_conflict?: InputMaybe<VendorsItemCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WeighbridgeReceiptsArgs = {
  objects: Array<WeighbridgeReceipts_Insert_Input>;
  on_conflict?: InputMaybe<WeighbridgeReceipts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WeighbridgeReceipts_OneArgs = {
  object: WeighbridgeReceipts_Insert_Input;
  on_conflict?: InputMaybe<WeighbridgeReceipts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  mobile: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResetUserPasswordArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdate_BrokersArgs = {
  _append?: InputMaybe<Brokers_Append_Input>;
  _delete_at_path?: InputMaybe<Brokers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Brokers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Brokers_Delete_Key_Input>;
  _prepend?: InputMaybe<Brokers_Prepend_Input>;
  _set?: InputMaybe<Brokers_Set_Input>;
  where: Brokers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Brokers_By_PkArgs = {
  _append?: InputMaybe<Brokers_Append_Input>;
  _delete_at_path?: InputMaybe<Brokers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Brokers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Brokers_Delete_Key_Input>;
  _prepend?: InputMaybe<Brokers_Prepend_Input>;
  _set?: InputMaybe<Brokers_Set_Input>;
  pk_columns: Brokers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Brokers_ManyArgs = {
  updates: Array<Brokers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountersArgs = {
  _inc?: InputMaybe<Counters_Inc_Input>;
  _set?: InputMaybe<Counters_Set_Input>;
  where: Counters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Counters_By_PkArgs = {
  _inc?: InputMaybe<Counters_Inc_Input>;
  _set?: InputMaybe<Counters_Set_Input>;
  pk_columns: Counters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Counters_ManyArgs = {
  updates: Array<Counters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Empty_Bag_Wt_UnitArgs = {
  _set?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Set_Input>;
  where: Enum_Empty_Bag_Wt_Unit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Empty_Bag_Wt_Unit_By_PkArgs = {
  _set?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Set_Input>;
  pk_columns: Enum_Empty_Bag_Wt_Unit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Empty_Bag_Wt_Unit_ManyArgs = {
  updates: Array<Enum_Empty_Bag_Wt_Unit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_InternalTransfer_Source_DestinationArgs = {
  _set?: InputMaybe<Enum_InternalTransfer_Source_Destination_Set_Input>;
  where: Enum_InternalTransfer_Source_Destination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_InternalTransfer_Source_Destination_By_PkArgs = {
  _set?: InputMaybe<Enum_InternalTransfer_Source_Destination_Set_Input>;
  pk_columns: Enum_InternalTransfer_Source_Destination_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_InternalTransfer_Source_Destination_ManyArgs = {
  updates: Array<Enum_InternalTransfer_Source_Destination_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_InwardShipmentItem_SourceArgs = {
  _set?: InputMaybe<Enum_InwardShipmentItem_Source_Set_Input>;
  where: Enum_InwardShipmentItem_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_InwardShipmentItem_Source_By_PkArgs = {
  _set?: InputMaybe<Enum_InwardShipmentItem_Source_Set_Input>;
  pk_columns: Enum_InwardShipmentItem_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_InwardShipmentItem_Source_ManyArgs = {
  updates: Array<Enum_InwardShipmentItem_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Inward_Shipment_StatusArgs = {
  _set?: InputMaybe<Enum_Inward_Shipment_Status_Set_Input>;
  where: Enum_Inward_Shipment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Inward_Shipment_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Inward_Shipment_Status_Set_Input>;
  pk_columns: Enum_Inward_Shipment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Inward_Shipment_Status_ManyArgs = {
  updates: Array<Enum_Inward_Shipment_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Item_CategoryArgs = {
  _set?: InputMaybe<Enum_Item_Category_Set_Input>;
  where: Enum_Item_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Item_Category_By_PkArgs = {
  _set?: InputMaybe<Enum_Item_Category_Set_Input>;
  pk_columns: Enum_Item_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Item_Category_ManyArgs = {
  updates: Array<Enum_Item_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Labor_Charge_TypeArgs = {
  _set?: InputMaybe<Enum_Labor_Charge_Type_Set_Input>;
  where: Enum_Labor_Charge_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Labor_Charge_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Labor_Charge_Type_Set_Input>;
  pk_columns: Enum_Labor_Charge_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Labor_Charge_Type_ManyArgs = {
  updates: Array<Enum_Labor_Charge_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Milling_Process_StatusArgs = {
  _set?: InputMaybe<Enum_Milling_Process_Status_Set_Input>;
  where: Enum_Milling_Process_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Milling_Process_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Milling_Process_Status_Set_Input>;
  pk_columns: Enum_Milling_Process_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Milling_Process_Status_ManyArgs = {
  updates: Array<Enum_Milling_Process_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Milling_Process_TypeArgs = {
  _set?: InputMaybe<Enum_Milling_Process_Type_Set_Input>;
  where: Enum_Milling_Process_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Milling_Process_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Milling_Process_Type_Set_Input>;
  pk_columns: Enum_Milling_Process_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Milling_Process_Type_ManyArgs = {
  updates: Array<Enum_Milling_Process_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_OutwardShipmentOrder_SourceArgs = {
  _set?: InputMaybe<Enum_OutwardShipmentOrder_Source_Set_Input>;
  where: Enum_OutwardShipmentOrder_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_OutwardShipmentOrder_Source_By_PkArgs = {
  _set?: InputMaybe<Enum_OutwardShipmentOrder_Source_Set_Input>;
  pk_columns: Enum_OutwardShipmentOrder_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_OutwardShipmentOrder_Source_ManyArgs = {
  updates: Array<Enum_OutwardShipmentOrder_Source_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Paddy_GradeArgs = {
  _set?: InputMaybe<Enum_Paddy_Grade_Set_Input>;
  where: Enum_Paddy_Grade_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Paddy_Grade_By_PkArgs = {
  _set?: InputMaybe<Enum_Paddy_Grade_Set_Input>;
  pk_columns: Enum_Paddy_Grade_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Paddy_Grade_ManyArgs = {
  updates: Array<Enum_Paddy_Grade_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Raw_Material_TypeArgs = {
  _set?: InputMaybe<Enum_Raw_Material_Type_Set_Input>;
  where: Enum_Raw_Material_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Raw_Material_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Raw_Material_Type_Set_Input>;
  pk_columns: Enum_Raw_Material_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Raw_Material_Type_ManyArgs = {
  updates: Array<Enum_Raw_Material_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Setting_Value_TypeArgs = {
  _set?: InputMaybe<Enum_Setting_Value_Type_Set_Input>;
  where: Enum_Setting_Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Setting_Value_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Setting_Value_Type_Set_Input>;
  pk_columns: Enum_Setting_Value_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Setting_Value_Type_ManyArgs = {
  updates: Array<Enum_Setting_Value_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Source_DestinationArgs = {
  _set?: InputMaybe<Enum_Source_Destination_Set_Input>;
  where: Enum_Source_Destination_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Source_Destination_By_PkArgs = {
  _set?: InputMaybe<Enum_Source_Destination_Set_Input>;
  pk_columns: Enum_Source_Destination_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Source_Destination_ManyArgs = {
  updates: Array<Enum_Source_Destination_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_RoleArgs = {
  _set?: InputMaybe<Enum_User_Role_Set_Input>;
  where: Enum_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_Role_By_PkArgs = {
  _set?: InputMaybe<Enum_User_Role_Set_Input>;
  pk_columns: Enum_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_Role_ManyArgs = {
  updates: Array<Enum_User_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GodownsArgs = {
  _inc?: InputMaybe<Godowns_Inc_Input>;
  _set?: InputMaybe<Godowns_Set_Input>;
  where: Godowns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Godowns_By_PkArgs = {
  _inc?: InputMaybe<Godowns_Inc_Input>;
  _set?: InputMaybe<Godowns_Set_Input>;
  pk_columns: Godowns_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Godowns_ManyArgs = {
  updates: Array<Godowns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InternalTransferShipmentItemsArgs = {
  _inc?: InputMaybe<InternalTransferShipmentItems_Inc_Input>;
  _set?: InputMaybe<InternalTransferShipmentItems_Set_Input>;
  where: InternalTransferShipmentItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InternalTransferShipmentItems_By_PkArgs = {
  _inc?: InputMaybe<InternalTransferShipmentItems_Inc_Input>;
  _set?: InputMaybe<InternalTransferShipmentItems_Set_Input>;
  pk_columns: InternalTransferShipmentItems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InternalTransferShipmentItems_ManyArgs = {
  updates: Array<InternalTransferShipmentItems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InternalTransferShipmentsArgs = {
  _inc?: InputMaybe<InternalTransferShipments_Inc_Input>;
  _set?: InputMaybe<InternalTransferShipments_Set_Input>;
  where: InternalTransferShipments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InternalTransferShipments_By_PkArgs = {
  _inc?: InputMaybe<InternalTransferShipments_Inc_Input>;
  _set?: InputMaybe<InternalTransferShipments_Set_Input>;
  pk_columns: InternalTransferShipments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InternalTransferShipments_ManyArgs = {
  updates: Array<InternalTransferShipments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InwardShipmentItemsArgs = {
  _inc?: InputMaybe<InwardShipmentItems_Inc_Input>;
  _set?: InputMaybe<InwardShipmentItems_Set_Input>;
  where: InwardShipmentItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InwardShipmentItems_By_PkArgs = {
  _inc?: InputMaybe<InwardShipmentItems_Inc_Input>;
  _set?: InputMaybe<InwardShipmentItems_Set_Input>;
  pk_columns: InwardShipmentItems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InwardShipmentItems_ManyArgs = {
  updates: Array<InwardShipmentItems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InwardShipmentsArgs = {
  _append?: InputMaybe<InwardShipments_Append_Input>;
  _delete_at_path?: InputMaybe<InwardShipments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<InwardShipments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<InwardShipments_Delete_Key_Input>;
  _inc?: InputMaybe<InwardShipments_Inc_Input>;
  _prepend?: InputMaybe<InwardShipments_Prepend_Input>;
  _set?: InputMaybe<InwardShipments_Set_Input>;
  where: InwardShipments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InwardShipments_By_PkArgs = {
  _append?: InputMaybe<InwardShipments_Append_Input>;
  _delete_at_path?: InputMaybe<InwardShipments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<InwardShipments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<InwardShipments_Delete_Key_Input>;
  _inc?: InputMaybe<InwardShipments_Inc_Input>;
  _prepend?: InputMaybe<InwardShipments_Prepend_Input>;
  _set?: InputMaybe<InwardShipments_Set_Input>;
  pk_columns: InwardShipments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InwardShipments_ManyArgs = {
  updates: Array<InwardShipments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ItemsArgs = {
  _set?: InputMaybe<Items_Set_Input>;
  where: Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Items_By_PkArgs = {
  _set?: InputMaybe<Items_Set_Input>;
  pk_columns: Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Items_ManyArgs = {
  updates: Array<Items_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MillingInItemsArgs = {
  _inc?: InputMaybe<MillingInItems_Inc_Input>;
  _set?: InputMaybe<MillingInItems_Set_Input>;
  where: MillingInItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MillingInItems_By_PkArgs = {
  _inc?: InputMaybe<MillingInItems_Inc_Input>;
  _set?: InputMaybe<MillingInItems_Set_Input>;
  pk_columns: MillingInItems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MillingInItems_ManyArgs = {
  updates: Array<MillingInItems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MillingOutItemsArgs = {
  _inc?: InputMaybe<MillingOutItems_Inc_Input>;
  _set?: InputMaybe<MillingOutItems_Set_Input>;
  where: MillingOutItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MillingOutItems_By_PkArgs = {
  _inc?: InputMaybe<MillingOutItems_Inc_Input>;
  _set?: InputMaybe<MillingOutItems_Set_Input>;
  pk_columns: MillingOutItems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MillingOutItems_ManyArgs = {
  updates: Array<MillingOutItems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MillingProcessesArgs = {
  _inc?: InputMaybe<MillingProcesses_Inc_Input>;
  _set?: InputMaybe<MillingProcesses_Set_Input>;
  where: MillingProcesses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MillingProcesses_By_PkArgs = {
  _inc?: InputMaybe<MillingProcesses_Inc_Input>;
  _set?: InputMaybe<MillingProcesses_Set_Input>;
  pk_columns: MillingProcesses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MillingProcesses_ManyArgs = {
  updates: Array<MillingProcesses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MillsArgs = {
  _inc?: InputMaybe<Mills_Inc_Input>;
  _set?: InputMaybe<Mills_Set_Input>;
  where: Mills_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mills_By_PkArgs = {
  _inc?: InputMaybe<Mills_Inc_Input>;
  _set?: InputMaybe<Mills_Set_Input>;
  pk_columns: Mills_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mills_ManyArgs = {
  updates: Array<Mills_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipmentOrdersArgs = {
  _inc?: InputMaybe<OutwardShipmentOrders_Inc_Input>;
  _set?: InputMaybe<OutwardShipmentOrders_Set_Input>;
  where: OutwardShipmentOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipmentOrders_By_PkArgs = {
  _inc?: InputMaybe<OutwardShipmentOrders_Inc_Input>;
  _set?: InputMaybe<OutwardShipmentOrders_Set_Input>;
  pk_columns: OutwardShipmentOrders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipmentOrders_ManyArgs = {
  updates: Array<OutwardShipmentOrders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipmentsArgs = {
  _inc?: InputMaybe<OutwardShipments_Inc_Input>;
  _set?: InputMaybe<OutwardShipments_Set_Input>;
  where: OutwardShipments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipmentsDriversArgs = {
  _inc?: InputMaybe<OutwardShipmentsDrivers_Inc_Input>;
  _set?: InputMaybe<OutwardShipmentsDrivers_Set_Input>;
  where: OutwardShipmentsDrivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipmentsDrivers_ManyArgs = {
  updates: Array<OutwardShipmentsDrivers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipments_By_PkArgs = {
  _inc?: InputMaybe<OutwardShipments_Inc_Input>;
  _set?: InputMaybe<OutwardShipments_Set_Input>;
  pk_columns: OutwardShipments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OutwardShipments_ManyArgs = {
  updates: Array<OutwardShipments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaddyBagOrdersArgs = {
  _inc?: InputMaybe<PaddyBagOrders_Inc_Input>;
  _set?: InputMaybe<PaddyBagOrders_Set_Input>;
  where: PaddyBagOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PaddyBagOrders_By_PkArgs = {
  _inc?: InputMaybe<PaddyBagOrders_Inc_Input>;
  _set?: InputMaybe<PaddyBagOrders_Set_Input>;
  pk_columns: PaddyBagOrders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaddyBagOrders_ManyArgs = {
  updates: Array<PaddyBagOrders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _append?: InputMaybe<Payment_Append_Input>;
  _delete_at_path?: InputMaybe<Payment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Payment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Payment_Delete_Key_Input>;
  _inc?: InputMaybe<Payment_Inc_Input>;
  _prepend?: InputMaybe<Payment_Prepend_Input>;
  _set?: InputMaybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _append?: InputMaybe<Payment_Append_Input>;
  _delete_at_path?: InputMaybe<Payment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Payment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Payment_Delete_Key_Input>;
  _inc?: InputMaybe<Payment_Inc_Input>;
  _prepend?: InputMaybe<Payment_Prepend_Input>;
  _set?: InputMaybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ManyArgs = {
  updates: Array<Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductBagOrdersArgs = {
  _inc?: InputMaybe<ProductBagOrders_Inc_Input>;
  _set?: InputMaybe<ProductBagOrders_Set_Input>;
  where: ProductBagOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductBagOrders_By_PkArgs = {
  _inc?: InputMaybe<ProductBagOrders_Inc_Input>;
  _set?: InputMaybe<ProductBagOrders_Set_Input>;
  pk_columns: ProductBagOrders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductBagOrders_ManyArgs = {
  updates: Array<ProductBagOrders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductBuyersArgs = {
  _inc?: InputMaybe<ProductBuyers_Inc_Input>;
  _set?: InputMaybe<ProductBuyers_Set_Input>;
  where: ProductBuyers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductBuyers_By_PkArgs = {
  _inc?: InputMaybe<ProductBuyers_Inc_Input>;
  _set?: InputMaybe<ProductBuyers_Set_Input>;
  pk_columns: ProductBuyers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductBuyers_ManyArgs = {
  updates: Array<ProductBuyers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypesArgs = {
  _inc?: InputMaybe<ProductTypes_Inc_Input>;
  _set?: InputMaybe<ProductTypes_Set_Input>;
  where: ProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypes_By_PkArgs = {
  _inc?: InputMaybe<ProductTypes_Inc_Input>;
  _set?: InputMaybe<ProductTypes_Set_Input>;
  pk_columns: ProductTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductTypes_ManyArgs = {
  updates: Array<ProductTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrderItemsArgs = {
  _inc?: InputMaybe<PurchaseOrderItems_Inc_Input>;
  _set?: InputMaybe<PurchaseOrderItems_Set_Input>;
  where: PurchaseOrderItems_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrderItems_By_PkArgs = {
  _inc?: InputMaybe<PurchaseOrderItems_Inc_Input>;
  _set?: InputMaybe<PurchaseOrderItems_Set_Input>;
  pk_columns: PurchaseOrderItems_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrderItems_ManyArgs = {
  updates: Array<PurchaseOrderItems_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrdersArgs = {
  _inc?: InputMaybe<PurchaseOrders_Inc_Input>;
  _set?: InputMaybe<PurchaseOrders_Set_Input>;
  where: PurchaseOrders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrders_By_PkArgs = {
  _inc?: InputMaybe<PurchaseOrders_Inc_Input>;
  _set?: InputMaybe<PurchaseOrders_Set_Input>;
  pk_columns: PurchaseOrders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PurchaseOrders_ManyArgs = {
  updates: Array<PurchaseOrders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RawMaterialsArgs = {
  _inc?: InputMaybe<RawMaterials_Inc_Input>;
  _set?: InputMaybe<RawMaterials_Set_Input>;
  where: RawMaterials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RawMaterialsProductTypesArgs = {
  _inc?: InputMaybe<RawMaterialsProductTypes_Inc_Input>;
  _set?: InputMaybe<RawMaterialsProductTypes_Set_Input>;
  where: RawMaterialsProductTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RawMaterialsProductTypes_By_PkArgs = {
  _inc?: InputMaybe<RawMaterialsProductTypes_Inc_Input>;
  _set?: InputMaybe<RawMaterialsProductTypes_Set_Input>;
  pk_columns: RawMaterialsProductTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RawMaterialsProductTypes_ManyArgs = {
  updates: Array<RawMaterialsProductTypes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RawMaterials_By_PkArgs = {
  _inc?: InputMaybe<RawMaterials_Inc_Input>;
  _set?: InputMaybe<RawMaterials_Set_Input>;
  pk_columns: RawMaterials_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RawMaterials_ManyArgs = {
  updates: Array<RawMaterials_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RmSellersArgs = {
  _inc?: InputMaybe<RmSellers_Inc_Input>;
  _set?: InputMaybe<RmSellers_Set_Input>;
  where: RmSellers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_RmSellers_By_PkArgs = {
  _inc?: InputMaybe<RmSellers_Inc_Input>;
  _set?: InputMaybe<RmSellers_Set_Input>;
  pk_columns: RmSellers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RmSellers_ManyArgs = {
  updates: Array<RmSellers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SettingsArgs = {
  _inc?: InputMaybe<Settings_Inc_Input>;
  _set?: InputMaybe<Settings_Set_Input>;
  where: Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Settings_By_PkArgs = {
  _inc?: InputMaybe<Settings_Inc_Input>;
  _set?: InputMaybe<Settings_Set_Input>;
  pk_columns: Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Settings_ManyArgs = {
  updates: Array<Settings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransporterDriversArgs = {
  _inc?: InputMaybe<TransporterDrivers_Inc_Input>;
  _set?: InputMaybe<TransporterDrivers_Set_Input>;
  where: TransporterDrivers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TransporterDrivers_By_PkArgs = {
  _inc?: InputMaybe<TransporterDrivers_Inc_Input>;
  _set?: InputMaybe<TransporterDrivers_Set_Input>;
  pk_columns: TransporterDrivers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransporterDrivers_ManyArgs = {
  updates: Array<TransporterDrivers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransporterVehiclesArgs = {
  _inc?: InputMaybe<TransporterVehicles_Inc_Input>;
  _set?: InputMaybe<TransporterVehicles_Set_Input>;
  where: TransporterVehicles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_TransporterVehicles_By_PkArgs = {
  _inc?: InputMaybe<TransporterVehicles_Inc_Input>;
  _set?: InputMaybe<TransporterVehicles_Set_Input>;
  pk_columns: TransporterVehicles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransporterVehicles_ManyArgs = {
  updates: Array<TransporterVehicles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransportersArgs = {
  _inc?: InputMaybe<Transporters_Inc_Input>;
  _set?: InputMaybe<Transporters_Set_Input>;
  where: Transporters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transporters_By_PkArgs = {
  _inc?: InputMaybe<Transporters_Inc_Input>;
  _set?: InputMaybe<Transporters_Set_Input>;
  pk_columns: Transporters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transporters_ManyArgs = {
  updates: Array<Transporters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VendorsItemCategoriesArgs = {
  _inc?: InputMaybe<VendorsItemCategories_Inc_Input>;
  _set?: InputMaybe<VendorsItemCategories_Set_Input>;
  where: VendorsItemCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VendorsItemCategories_By_PkArgs = {
  _inc?: InputMaybe<VendorsItemCategories_Inc_Input>;
  _set?: InputMaybe<VendorsItemCategories_Set_Input>;
  pk_columns: VendorsItemCategories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VendorsItemCategories_ManyArgs = {
  updates: Array<VendorsItemCategories_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WeighbridgeReceiptsArgs = {
  _inc?: InputMaybe<WeighbridgeReceipts_Inc_Input>;
  _set?: InputMaybe<WeighbridgeReceipts_Set_Input>;
  where: WeighbridgeReceipts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WeighbridgeReceipts_By_PkArgs = {
  _inc?: InputMaybe<WeighbridgeReceipts_Inc_Input>;
  _set?: InputMaybe<WeighbridgeReceipts_Set_Input>;
  pk_columns: WeighbridgeReceipts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WeighbridgeReceipts_ManyArgs = {
  updates: Array<WeighbridgeReceipts_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "outwardShipmentOrders" */
export type OutwardShipmentOrders = {
  __typename?: 'outwardShipmentOrders';
  bagSizeKg?: Maybe<Scalars['Int']>;
  /** An object relationship */
  broker?: Maybe<Brokers>;
  brokerId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  buyer?: Maybe<ProductBuyers>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  godown?: Maybe<Godowns>;
  godownId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  invoiceNo?: Maybe<Scalars['String']>;
  isOutForMaintenance?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  item?: Maybe<Items>;
  itemId?: Maybe<Scalars['uuid']>;
  loadedBagsCount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  mill?: Maybe<Mills>;
  millId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  othersDestination?: Maybe<Scalars['String']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  outwardShipment: OutwardShipments;
  outwardShipmentId: Scalars['Int'];
  /** An object relationship */
  paddyBagBuyer?: Maybe<RmSellers>;
  paddyBagBuyerId?: Maybe<Scalars['Int']>;
  plannedBagsCount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  product?: Maybe<Products>;
  productBuyerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  rawMaterial?: Maybe<RawMaterials>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmPaddyGrade?: Maybe<Scalars['String']>;
  source?: Maybe<Enum_OutwardShipmentOrder_Source_Enum>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "outwardShipmentOrders" */
export type OutwardShipmentOrders_Aggregate = {
  __typename?: 'outwardShipmentOrders_aggregate';
  aggregate?: Maybe<OutwardShipmentOrders_Aggregate_Fields>;
  nodes: Array<OutwardShipmentOrders>;
};

export type OutwardShipmentOrders_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<OutwardShipmentOrders_Aggregate_Bool_Exp_Count>;
};

export type OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_And = {
  arguments: OutwardShipmentOrders_Select_Column_OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_Or = {
  arguments: OutwardShipmentOrders_Select_Column_OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type OutwardShipmentOrders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outwardShipmentOrders" */
export type OutwardShipmentOrders_Aggregate_Fields = {
  __typename?: 'outwardShipmentOrders_aggregate_fields';
  avg?: Maybe<OutwardShipmentOrders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<OutwardShipmentOrders_Max_Fields>;
  min?: Maybe<OutwardShipmentOrders_Min_Fields>;
  stddev?: Maybe<OutwardShipmentOrders_Stddev_Fields>;
  stddev_pop?: Maybe<OutwardShipmentOrders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OutwardShipmentOrders_Stddev_Samp_Fields>;
  sum?: Maybe<OutwardShipmentOrders_Sum_Fields>;
  var_pop?: Maybe<OutwardShipmentOrders_Var_Pop_Fields>;
  var_samp?: Maybe<OutwardShipmentOrders_Var_Samp_Fields>;
  variance?: Maybe<OutwardShipmentOrders_Variance_Fields>;
};


/** aggregate fields of "outwardShipmentOrders" */
export type OutwardShipmentOrders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Aggregate_Order_By = {
  avg?: InputMaybe<OutwardShipmentOrders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OutwardShipmentOrders_Max_Order_By>;
  min?: InputMaybe<OutwardShipmentOrders_Min_Order_By>;
  stddev?: InputMaybe<OutwardShipmentOrders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OutwardShipmentOrders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OutwardShipmentOrders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OutwardShipmentOrders_Sum_Order_By>;
  var_pop?: InputMaybe<OutwardShipmentOrders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OutwardShipmentOrders_Var_Samp_Order_By>;
  variance?: InputMaybe<OutwardShipmentOrders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Arr_Rel_Insert_Input = {
  data: Array<OutwardShipmentOrders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<OutwardShipmentOrders_On_Conflict>;
};

/** aggregate avg on columns */
export type OutwardShipmentOrders_Avg_Fields = {
  __typename?: 'outwardShipmentOrders_avg_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Avg_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outwardShipmentOrders". All fields are combined with a logical 'AND'. */
export type OutwardShipmentOrders_Bool_Exp = {
  _and?: InputMaybe<Array<OutwardShipmentOrders_Bool_Exp>>;
  _not?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
  _or?: InputMaybe<Array<OutwardShipmentOrders_Bool_Exp>>;
  bagSizeKg?: InputMaybe<Int_Comparison_Exp>;
  broker?: InputMaybe<Brokers_Bool_Exp>;
  brokerId?: InputMaybe<Uuid_Comparison_Exp>;
  buyer?: InputMaybe<ProductBuyers_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  godown?: InputMaybe<Godowns_Bool_Exp>;
  godownId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoiceNo?: InputMaybe<String_Comparison_Exp>;
  isOutForMaintenance?: InputMaybe<Boolean_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  itemId?: InputMaybe<Uuid_Comparison_Exp>;
  loadedBagsCount?: InputMaybe<Int_Comparison_Exp>;
  mill?: InputMaybe<Mills_Bool_Exp>;
  millId?: InputMaybe<Int_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  othersDestination?: InputMaybe<String_Comparison_Exp>;
  othersItemName?: InputMaybe<String_Comparison_Exp>;
  othersQuantity?: InputMaybe<Numeric_Comparison_Exp>;
  othersWtKg?: InputMaybe<Numeric_Comparison_Exp>;
  outwardShipment?: InputMaybe<OutwardShipments_Bool_Exp>;
  outwardShipmentId?: InputMaybe<Int_Comparison_Exp>;
  paddyBagBuyer?: InputMaybe<RmSellers_Bool_Exp>;
  paddyBagBuyerId?: InputMaybe<Int_Comparison_Exp>;
  plannedBagsCount?: InputMaybe<Int_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productBuyerId?: InputMaybe<Int_Comparison_Exp>;
  productId?: InputMaybe<Int_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
  remark?: InputMaybe<String_Comparison_Exp>;
  rmPaddyGrade?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<Enum_OutwardShipmentOrder_Source_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outwardShipmentOrders" */
export enum OutwardShipmentOrders_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutwardShipmentOrdersPkey = 'outwardShipmentOrders_pkey'
}

/** input type for incrementing numeric columns in table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Inc_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  loadedBagsCount?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  paddyBagBuyerId?: InputMaybe<Scalars['Int']>;
  plannedBagsCount?: InputMaybe<Scalars['Int']>;
  productBuyerId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Insert_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  broker?: InputMaybe<Brokers_Obj_Rel_Insert_Input>;
  brokerId?: InputMaybe<Scalars['uuid']>;
  buyer?: InputMaybe<ProductBuyers_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  godown?: InputMaybe<Godowns_Obj_Rel_Insert_Input>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  isOutForMaintenance?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  itemId?: InputMaybe<Scalars['uuid']>;
  loadedBagsCount?: InputMaybe<Scalars['Int']>;
  mill?: InputMaybe<Mills_Obj_Rel_Insert_Input>;
  millId?: InputMaybe<Scalars['Int']>;
  othersDestination?: InputMaybe<Scalars['String']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  outwardShipment?: InputMaybe<OutwardShipments_Obj_Rel_Insert_Input>;
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  paddyBagBuyer?: InputMaybe<RmSellers_Obj_Rel_Insert_Input>;
  paddyBagBuyerId?: InputMaybe<Scalars['Int']>;
  plannedBagsCount?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productBuyerId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmPaddyGrade?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_OutwardShipmentOrder_Source_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type OutwardShipmentOrders_Max_Fields = {
  __typename?: 'outwardShipmentOrders_max_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  brokerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoiceNo?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['uuid']>;
  loadedBagsCount?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  othersDestination?: Maybe<Scalars['String']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  outwardShipmentId?: Maybe<Scalars['Int']>;
  paddyBagBuyerId?: Maybe<Scalars['Int']>;
  plannedBagsCount?: Maybe<Scalars['Int']>;
  productBuyerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmPaddyGrade?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Max_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  brokerId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceNo?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersDestination?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmPaddyGrade?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OutwardShipmentOrders_Min_Fields = {
  __typename?: 'outwardShipmentOrders_min_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  brokerId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  invoiceNo?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['uuid']>;
  loadedBagsCount?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  othersDestination?: Maybe<Scalars['String']>;
  othersItemName?: Maybe<Scalars['String']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  outwardShipmentId?: Maybe<Scalars['Int']>;
  paddyBagBuyerId?: Maybe<Scalars['Int']>;
  plannedBagsCount?: Maybe<Scalars['Int']>;
  productBuyerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
  remark?: Maybe<Scalars['String']>;
  rmPaddyGrade?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Min_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  brokerId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceNo?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersDestination?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmPaddyGrade?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Mutation_Response = {
  __typename?: 'outwardShipmentOrders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OutwardShipmentOrders>;
};

/** on_conflict condition type for table "outwardShipmentOrders" */
export type OutwardShipmentOrders_On_Conflict = {
  constraint: OutwardShipmentOrders_Constraint;
  update_columns?: Array<OutwardShipmentOrders_Update_Column>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};

/** Ordering options when selecting data from "outwardShipmentOrders". */
export type OutwardShipmentOrders_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  broker?: InputMaybe<Brokers_Order_By>;
  brokerId?: InputMaybe<Order_By>;
  buyer?: InputMaybe<ProductBuyers_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  godown?: InputMaybe<Godowns_Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceNo?: InputMaybe<Order_By>;
  isOutForMaintenance?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  itemId?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  mill?: InputMaybe<Mills_Order_By>;
  millId?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  othersDestination?: InputMaybe<Order_By>;
  othersItemName?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipment?: InputMaybe<OutwardShipments_Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyer?: InputMaybe<RmSellers_Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  rmPaddyGrade?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outwardShipmentOrders */
export type OutwardShipmentOrders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outwardShipmentOrders" */
export enum OutwardShipmentOrders_Select_Column {
  /** column name */
  BagSizeKg = 'bagSizeKg',
  /** column name */
  BrokerId = 'brokerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNo = 'invoiceNo',
  /** column name */
  IsOutForMaintenance = 'isOutForMaintenance',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  LoadedBagsCount = 'loadedBagsCount',
  /** column name */
  MillId = 'millId',
  /** column name */
  OthersDestination = 'othersDestination',
  /** column name */
  OthersItemName = 'othersItemName',
  /** column name */
  OthersQuantity = 'othersQuantity',
  /** column name */
  OthersWtKg = 'othersWtKg',
  /** column name */
  OutwardShipmentId = 'outwardShipmentId',
  /** column name */
  PaddyBagBuyerId = 'paddyBagBuyerId',
  /** column name */
  PlannedBagsCount = 'plannedBagsCount',
  /** column name */
  ProductBuyerId = 'productBuyerId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Remark = 'remark',
  /** column name */
  RmPaddyGrade = 'rmPaddyGrade',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "outwardShipmentOrders_aggregate_bool_exp_bool_and_arguments_columns" columns of table "outwardShipmentOrders" */
export enum OutwardShipmentOrders_Select_Column_OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsOutForMaintenance = 'isOutForMaintenance'
}

/** select "outwardShipmentOrders_aggregate_bool_exp_bool_or_arguments_columns" columns of table "outwardShipmentOrders" */
export enum OutwardShipmentOrders_Select_Column_OutwardShipmentOrders_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsOutForMaintenance = 'isOutForMaintenance'
}

/** input type for updating data in table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Set_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  brokerId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  isOutForMaintenance?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  loadedBagsCount?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  othersDestination?: InputMaybe<Scalars['String']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  paddyBagBuyerId?: InputMaybe<Scalars['Int']>;
  plannedBagsCount?: InputMaybe<Scalars['Int']>;
  productBuyerId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmPaddyGrade?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_OutwardShipmentOrder_Source_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OutwardShipmentOrders_Stddev_Fields = {
  __typename?: 'outwardShipmentOrders_stddev_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Stddev_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type OutwardShipmentOrders_Stddev_Pop_Fields = {
  __typename?: 'outwardShipmentOrders_stddev_pop_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Stddev_Pop_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type OutwardShipmentOrders_Stddev_Samp_Fields = {
  __typename?: 'outwardShipmentOrders_stddev_samp_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Stddev_Samp_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OutwardShipmentOrders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OutwardShipmentOrders_Stream_Cursor_Value_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  brokerId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  godownId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  isOutForMaintenance?: InputMaybe<Scalars['Boolean']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  loadedBagsCount?: InputMaybe<Scalars['Int']>;
  millId?: InputMaybe<Scalars['Int']>;
  othersDestination?: InputMaybe<Scalars['String']>;
  othersItemName?: InputMaybe<Scalars['String']>;
  othersQuantity?: InputMaybe<Scalars['numeric']>;
  othersWtKg?: InputMaybe<Scalars['numeric']>;
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  paddyBagBuyerId?: InputMaybe<Scalars['Int']>;
  plannedBagsCount?: InputMaybe<Scalars['Int']>;
  productBuyerId?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  rmPaddyGrade?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_OutwardShipmentOrder_Source_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type OutwardShipmentOrders_Sum_Fields = {
  __typename?: 'outwardShipmentOrders_sum_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  godownId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  loadedBagsCount?: Maybe<Scalars['Int']>;
  millId?: Maybe<Scalars['Int']>;
  othersQuantity?: Maybe<Scalars['numeric']>;
  othersWtKg?: Maybe<Scalars['numeric']>;
  outwardShipmentId?: Maybe<Scalars['Int']>;
  paddyBagBuyerId?: Maybe<Scalars['Int']>;
  plannedBagsCount?: Maybe<Scalars['Int']>;
  productBuyerId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Sum_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** update columns of table "outwardShipmentOrders" */
export enum OutwardShipmentOrders_Update_Column {
  /** column name */
  BagSizeKg = 'bagSizeKg',
  /** column name */
  BrokerId = 'brokerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GodownId = 'godownId',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceNo = 'invoiceNo',
  /** column name */
  IsOutForMaintenance = 'isOutForMaintenance',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  LoadedBagsCount = 'loadedBagsCount',
  /** column name */
  MillId = 'millId',
  /** column name */
  OthersDestination = 'othersDestination',
  /** column name */
  OthersItemName = 'othersItemName',
  /** column name */
  OthersQuantity = 'othersQuantity',
  /** column name */
  OthersWtKg = 'othersWtKg',
  /** column name */
  OutwardShipmentId = 'outwardShipmentId',
  /** column name */
  PaddyBagBuyerId = 'paddyBagBuyerId',
  /** column name */
  PlannedBagsCount = 'plannedBagsCount',
  /** column name */
  ProductBuyerId = 'productBuyerId',
  /** column name */
  ProductId = 'productId',
  /** column name */
  RawMaterialId = 'rawMaterialId',
  /** column name */
  Remark = 'remark',
  /** column name */
  RmPaddyGrade = 'rmPaddyGrade',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type OutwardShipmentOrders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OutwardShipmentOrders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OutwardShipmentOrders_Set_Input>;
  /** filter the rows which have to be updated */
  where: OutwardShipmentOrders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OutwardShipmentOrders_Var_Pop_Fields = {
  __typename?: 'outwardShipmentOrders_var_pop_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Var_Pop_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type OutwardShipmentOrders_Var_Samp_Fields = {
  __typename?: 'outwardShipmentOrders_var_samp_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Var_Samp_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type OutwardShipmentOrders_Variance_Fields = {
  __typename?: 'outwardShipmentOrders_variance_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  godownId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  loadedBagsCount?: Maybe<Scalars['Float']>;
  millId?: Maybe<Scalars['Float']>;
  othersQuantity?: Maybe<Scalars['Float']>;
  othersWtKg?: Maybe<Scalars['Float']>;
  outwardShipmentId?: Maybe<Scalars['Float']>;
  paddyBagBuyerId?: Maybe<Scalars['Float']>;
  plannedBagsCount?: Maybe<Scalars['Float']>;
  productBuyerId?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outwardShipmentOrders" */
export type OutwardShipmentOrders_Variance_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  godownId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  loadedBagsCount?: InputMaybe<Order_By>;
  millId?: InputMaybe<Order_By>;
  othersQuantity?: InputMaybe<Order_By>;
  othersWtKg?: InputMaybe<Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  paddyBagBuyerId?: InputMaybe<Order_By>;
  plannedBagsCount?: InputMaybe<Order_By>;
  productBuyerId?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** columns and relationships of "outwardShipments" */
export type OutwardShipments = {
  __typename?: 'outwardShipments';
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  drivers: Array<OutwardShipmentsDrivers>;
  /** An aggregate relationship */
  drivers_aggregate: OutwardShipmentsDrivers_Aggregate;
  emptyVehicleImage?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  emptyWtTakenAt?: Maybe<Scalars['timestamptz']>;
  fullVehicleImage?: Maybe<Scalars['String']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  fullWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceAddedAt?: Maybe<Scalars['timestamptz']>;
  loadedAt?: Maybe<Scalars['timestamptz']>;
  nonProductPersonMobile?: Maybe<Scalars['String']>;
  nonProductPersonName?: Maybe<Scalars['String']>;
  nonProductVehicleNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  orders: Array<OutwardShipmentOrders>;
  ordersTakenAt?: Maybe<Scalars['timestamptz']>;
  /** An aggregate relationship */
  orders_aggregate: OutwardShipmentOrders_Aggregate;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  transporter?: Maybe<Transporters>;
  transporterId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  transporterVehicle?: Maybe<TransporterVehicles>;
  transporterVehicleId?: Maybe<Scalars['Int']>;
  truckOutAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "outwardShipments" */
export type OutwardShipmentsDriversArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentsDrivers_Order_By>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


/** columns and relationships of "outwardShipments" */
export type OutwardShipmentsDrivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentsDrivers_Order_By>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


/** columns and relationships of "outwardShipments" */
export type OutwardShipmentsOrdersArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentOrders_Order_By>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};


/** columns and relationships of "outwardShipments" */
export type OutwardShipmentsOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentOrders_Order_By>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};

/** columns and relationships of "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers = {
  __typename?: 'outwardShipmentsDrivers';
  /** An object relationship */
  driver: TransporterDrivers;
  /** An object relationship */
  outwardShipment: OutwardShipments;
  outwardShipmentId: Scalars['Int'];
  transporterDriverId: Scalars['Int'];
};

/** aggregated selection of "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Aggregate = {
  __typename?: 'outwardShipmentsDrivers_aggregate';
  aggregate?: Maybe<OutwardShipmentsDrivers_Aggregate_Fields>;
  nodes: Array<OutwardShipmentsDrivers>;
};

export type OutwardShipmentsDrivers_Aggregate_Bool_Exp = {
  count?: InputMaybe<OutwardShipmentsDrivers_Aggregate_Bool_Exp_Count>;
};

export type OutwardShipmentsDrivers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Aggregate_Fields = {
  __typename?: 'outwardShipmentsDrivers_aggregate_fields';
  avg?: Maybe<OutwardShipmentsDrivers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<OutwardShipmentsDrivers_Max_Fields>;
  min?: Maybe<OutwardShipmentsDrivers_Min_Fields>;
  stddev?: Maybe<OutwardShipmentsDrivers_Stddev_Fields>;
  stddev_pop?: Maybe<OutwardShipmentsDrivers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OutwardShipmentsDrivers_Stddev_Samp_Fields>;
  sum?: Maybe<OutwardShipmentsDrivers_Sum_Fields>;
  var_pop?: Maybe<OutwardShipmentsDrivers_Var_Pop_Fields>;
  var_samp?: Maybe<OutwardShipmentsDrivers_Var_Samp_Fields>;
  variance?: Maybe<OutwardShipmentsDrivers_Variance_Fields>;
};


/** aggregate fields of "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Aggregate_Order_By = {
  avg?: InputMaybe<OutwardShipmentsDrivers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OutwardShipmentsDrivers_Max_Order_By>;
  min?: InputMaybe<OutwardShipmentsDrivers_Min_Order_By>;
  stddev?: InputMaybe<OutwardShipmentsDrivers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<OutwardShipmentsDrivers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<OutwardShipmentsDrivers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<OutwardShipmentsDrivers_Sum_Order_By>;
  var_pop?: InputMaybe<OutwardShipmentsDrivers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<OutwardShipmentsDrivers_Var_Samp_Order_By>;
  variance?: InputMaybe<OutwardShipmentsDrivers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Arr_Rel_Insert_Input = {
  data: Array<OutwardShipmentsDrivers_Insert_Input>;
};

/** aggregate avg on columns */
export type OutwardShipmentsDrivers_Avg_Fields = {
  __typename?: 'outwardShipmentsDrivers_avg_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Avg_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outwardShipmentsDrivers". All fields are combined with a logical 'AND'. */
export type OutwardShipmentsDrivers_Bool_Exp = {
  _and?: InputMaybe<Array<OutwardShipmentsDrivers_Bool_Exp>>;
  _not?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
  _or?: InputMaybe<Array<OutwardShipmentsDrivers_Bool_Exp>>;
  driver?: InputMaybe<TransporterDrivers_Bool_Exp>;
  outwardShipment?: InputMaybe<OutwardShipments_Bool_Exp>;
  outwardShipmentId?: InputMaybe<Int_Comparison_Exp>;
  transporterDriverId?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Inc_Input = {
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  transporterDriverId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Insert_Input = {
  driver?: InputMaybe<TransporterDrivers_Obj_Rel_Insert_Input>;
  outwardShipment?: InputMaybe<OutwardShipments_Obj_Rel_Insert_Input>;
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  transporterDriverId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type OutwardShipmentsDrivers_Max_Fields = {
  __typename?: 'outwardShipmentsDrivers_max_fields';
  outwardShipmentId?: Maybe<Scalars['Int']>;
  transporterDriverId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Max_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OutwardShipmentsDrivers_Min_Fields = {
  __typename?: 'outwardShipmentsDrivers_min_fields';
  outwardShipmentId?: Maybe<Scalars['Int']>;
  transporterDriverId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Min_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Mutation_Response = {
  __typename?: 'outwardShipmentsDrivers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OutwardShipmentsDrivers>;
};

/** Ordering options when selecting data from "outwardShipmentsDrivers". */
export type OutwardShipmentsDrivers_Order_By = {
  driver?: InputMaybe<TransporterDrivers_Order_By>;
  outwardShipment?: InputMaybe<OutwardShipments_Order_By>;
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** select columns of table "outwardShipmentsDrivers" */
export enum OutwardShipmentsDrivers_Select_Column {
  /** column name */
  OutwardShipmentId = 'outwardShipmentId',
  /** column name */
  TransporterDriverId = 'transporterDriverId'
}

/** input type for updating data in table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Set_Input = {
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  transporterDriverId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type OutwardShipmentsDrivers_Stddev_Fields = {
  __typename?: 'outwardShipmentsDrivers_stddev_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Stddev_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type OutwardShipmentsDrivers_Stddev_Pop_Fields = {
  __typename?: 'outwardShipmentsDrivers_stddev_pop_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Stddev_Pop_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type OutwardShipmentsDrivers_Stddev_Samp_Fields = {
  __typename?: 'outwardShipmentsDrivers_stddev_samp_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Stddev_Samp_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OutwardShipmentsDrivers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OutwardShipmentsDrivers_Stream_Cursor_Value_Input = {
  outwardShipmentId?: InputMaybe<Scalars['Int']>;
  transporterDriverId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type OutwardShipmentsDrivers_Sum_Fields = {
  __typename?: 'outwardShipmentsDrivers_sum_fields';
  outwardShipmentId?: Maybe<Scalars['Int']>;
  transporterDriverId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Sum_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

export type OutwardShipmentsDrivers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OutwardShipmentsDrivers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OutwardShipmentsDrivers_Set_Input>;
  /** filter the rows which have to be updated */
  where: OutwardShipmentsDrivers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OutwardShipmentsDrivers_Var_Pop_Fields = {
  __typename?: 'outwardShipmentsDrivers_var_pop_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Var_Pop_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type OutwardShipmentsDrivers_Var_Samp_Fields = {
  __typename?: 'outwardShipmentsDrivers_var_samp_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Var_Samp_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type OutwardShipmentsDrivers_Variance_Fields = {
  __typename?: 'outwardShipmentsDrivers_variance_fields';
  outwardShipmentId?: Maybe<Scalars['Float']>;
  transporterDriverId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outwardShipmentsDrivers" */
export type OutwardShipmentsDrivers_Variance_Order_By = {
  outwardShipmentId?: InputMaybe<Order_By>;
  transporterDriverId?: InputMaybe<Order_By>;
};

/** aggregated selection of "outwardShipments" */
export type OutwardShipments_Aggregate = {
  __typename?: 'outwardShipments_aggregate';
  aggregate?: Maybe<OutwardShipments_Aggregate_Fields>;
  nodes: Array<OutwardShipments>;
};

/** aggregate fields of "outwardShipments" */
export type OutwardShipments_Aggregate_Fields = {
  __typename?: 'outwardShipments_aggregate_fields';
  avg?: Maybe<OutwardShipments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<OutwardShipments_Max_Fields>;
  min?: Maybe<OutwardShipments_Min_Fields>;
  stddev?: Maybe<OutwardShipments_Stddev_Fields>;
  stddev_pop?: Maybe<OutwardShipments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<OutwardShipments_Stddev_Samp_Fields>;
  sum?: Maybe<OutwardShipments_Sum_Fields>;
  var_pop?: Maybe<OutwardShipments_Var_Pop_Fields>;
  var_samp?: Maybe<OutwardShipments_Var_Samp_Fields>;
  variance?: Maybe<OutwardShipments_Variance_Fields>;
};


/** aggregate fields of "outwardShipments" */
export type OutwardShipments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<OutwardShipments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OutwardShipments_Avg_Fields = {
  __typename?: 'outwardShipments_avg_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "outwardShipments". All fields are combined with a logical 'AND'. */
export type OutwardShipments_Bool_Exp = {
  _and?: InputMaybe<Array<OutwardShipments_Bool_Exp>>;
  _not?: InputMaybe<OutwardShipments_Bool_Exp>;
  _or?: InputMaybe<Array<OutwardShipments_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  drivers?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
  drivers_aggregate?: InputMaybe<OutwardShipmentsDrivers_Aggregate_Bool_Exp>;
  emptyVehicleImage?: InputMaybe<String_Comparison_Exp>;
  emptyVehicleWtKg?: InputMaybe<Float8_Comparison_Exp>;
  emptyWtTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fullVehicleImage?: InputMaybe<String_Comparison_Exp>;
  fullVehicleWtKg?: InputMaybe<Float8_Comparison_Exp>;
  fullWtTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  grn?: InputMaybe<String_Comparison_Exp>;
  grn_v2?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoiceAddedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  loadedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  nonProductPersonMobile?: InputMaybe<String_Comparison_Exp>;
  nonProductPersonName?: InputMaybe<String_Comparison_Exp>;
  nonProductVehicleNumber?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
  ordersTakenAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  orders_aggregate?: InputMaybe<OutwardShipmentOrders_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transporter?: InputMaybe<Transporters_Bool_Exp>;
  transporterId?: InputMaybe<Int_Comparison_Exp>;
  transporterVehicle?: InputMaybe<TransporterVehicles_Bool_Exp>;
  transporterVehicleId?: InputMaybe<Int_Comparison_Exp>;
  truckOutAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outwardShipments" */
export enum OutwardShipments_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutwardShipmentsPkey = 'outwardShipments_pkey'
}

/** input type for incrementing numeric columns in table "outwardShipments" */
export type OutwardShipments_Inc_Input = {
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  transporterVehicleId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "outwardShipments" */
export type OutwardShipments_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  drivers?: InputMaybe<OutwardShipmentsDrivers_Arr_Rel_Insert_Input>;
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  emptyWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  fullVehicleImage?: InputMaybe<Scalars['String']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceAddedAt?: InputMaybe<Scalars['timestamptz']>;
  loadedAt?: InputMaybe<Scalars['timestamptz']>;
  nonProductPersonMobile?: InputMaybe<Scalars['String']>;
  nonProductPersonName?: InputMaybe<Scalars['String']>;
  nonProductVehicleNumber?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<OutwardShipmentOrders_Arr_Rel_Insert_Input>;
  ordersTakenAt?: InputMaybe<Scalars['timestamptz']>;
  transporter?: InputMaybe<Transporters_Obj_Rel_Insert_Input>;
  transporterId?: InputMaybe<Scalars['Int']>;
  transporterVehicle?: InputMaybe<TransporterVehicles_Obj_Rel_Insert_Input>;
  transporterVehicleId?: InputMaybe<Scalars['Int']>;
  truckOutAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type OutwardShipments_Max_Fields = {
  __typename?: 'outwardShipments_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyVehicleImage?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  emptyWtTakenAt?: Maybe<Scalars['timestamptz']>;
  fullVehicleImage?: Maybe<Scalars['String']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  fullWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceAddedAt?: Maybe<Scalars['timestamptz']>;
  loadedAt?: Maybe<Scalars['timestamptz']>;
  nonProductPersonMobile?: Maybe<Scalars['String']>;
  nonProductPersonName?: Maybe<Scalars['String']>;
  nonProductVehicleNumber?: Maybe<Scalars['String']>;
  ordersTakenAt?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterVehicleId?: Maybe<Scalars['Int']>;
  truckOutAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type OutwardShipments_Min_Fields = {
  __typename?: 'outwardShipments_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  emptyVehicleImage?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  emptyWtTakenAt?: Maybe<Scalars['timestamptz']>;
  fullVehicleImage?: Maybe<Scalars['String']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  fullWtTakenAt?: Maybe<Scalars['timestamptz']>;
  grn?: Maybe<Scalars['String']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoiceAddedAt?: Maybe<Scalars['timestamptz']>;
  loadedAt?: Maybe<Scalars['timestamptz']>;
  nonProductPersonMobile?: Maybe<Scalars['String']>;
  nonProductPersonName?: Maybe<Scalars['String']>;
  nonProductVehicleNumber?: Maybe<Scalars['String']>;
  ordersTakenAt?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterVehicleId?: Maybe<Scalars['Int']>;
  truckOutAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "outwardShipments" */
export type OutwardShipments_Mutation_Response = {
  __typename?: 'outwardShipments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OutwardShipments>;
};

/** input type for inserting object relation for remote table "outwardShipments" */
export type OutwardShipments_Obj_Rel_Insert_Input = {
  data: OutwardShipments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<OutwardShipments_On_Conflict>;
};

/** on_conflict condition type for table "outwardShipments" */
export type OutwardShipments_On_Conflict = {
  constraint: OutwardShipments_Constraint;
  update_columns?: Array<OutwardShipments_Update_Column>;
  where?: InputMaybe<OutwardShipments_Bool_Exp>;
};

/** Ordering options when selecting data from "outwardShipments". */
export type OutwardShipments_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  drivers_aggregate?: InputMaybe<OutwardShipmentsDrivers_Aggregate_Order_By>;
  emptyVehicleImage?: InputMaybe<Order_By>;
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  emptyWtTakenAt?: InputMaybe<Order_By>;
  fullVehicleImage?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  fullWtTakenAt?: InputMaybe<Order_By>;
  grn?: InputMaybe<Order_By>;
  grn_v2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoiceAddedAt?: InputMaybe<Order_By>;
  loadedAt?: InputMaybe<Order_By>;
  nonProductPersonMobile?: InputMaybe<Order_By>;
  nonProductPersonName?: InputMaybe<Order_By>;
  nonProductVehicleNumber?: InputMaybe<Order_By>;
  ordersTakenAt?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<OutwardShipmentOrders_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  transporter?: InputMaybe<Transporters_Order_By>;
  transporterId?: InputMaybe<Order_By>;
  transporterVehicle?: InputMaybe<TransporterVehicles_Order_By>;
  transporterVehicleId?: InputMaybe<Order_By>;
  truckOutAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: outwardShipments */
export type OutwardShipments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outwardShipments" */
export enum OutwardShipments_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmptyVehicleImage = 'emptyVehicleImage',
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  EmptyWtTakenAt = 'emptyWtTakenAt',
  /** column name */
  FullVehicleImage = 'fullVehicleImage',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  FullWtTakenAt = 'fullWtTakenAt',
  /** column name */
  GrnV2 = 'grn_v2',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceAddedAt = 'invoiceAddedAt',
  /** column name */
  LoadedAt = 'loadedAt',
  /** column name */
  NonProductPersonMobile = 'nonProductPersonMobile',
  /** column name */
  NonProductPersonName = 'nonProductPersonName',
  /** column name */
  NonProductVehicleNumber = 'nonProductVehicleNumber',
  /** column name */
  OrdersTakenAt = 'ordersTakenAt',
  /** column name */
  TransporterId = 'transporterId',
  /** column name */
  TransporterVehicleId = 'transporterVehicleId',
  /** column name */
  TruckOutAt = 'truckOutAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "outwardShipments" */
export type OutwardShipments_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  emptyWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  fullVehicleImage?: InputMaybe<Scalars['String']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceAddedAt?: InputMaybe<Scalars['timestamptz']>;
  loadedAt?: InputMaybe<Scalars['timestamptz']>;
  nonProductPersonMobile?: InputMaybe<Scalars['String']>;
  nonProductPersonName?: InputMaybe<Scalars['String']>;
  nonProductVehicleNumber?: InputMaybe<Scalars['String']>;
  ordersTakenAt?: InputMaybe<Scalars['timestamptz']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  transporterVehicleId?: InputMaybe<Scalars['Int']>;
  truckOutAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OutwardShipments_Stddev_Fields = {
  __typename?: 'outwardShipments_stddev_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OutwardShipments_Stddev_Pop_Fields = {
  __typename?: 'outwardShipments_stddev_pop_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OutwardShipments_Stddev_Samp_Fields = {
  __typename?: 'outwardShipments_stddev_samp_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "outwardShipments" */
export type OutwardShipments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OutwardShipments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OutwardShipments_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  emptyWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  fullVehicleImage?: InputMaybe<Scalars['String']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullWtTakenAt?: InputMaybe<Scalars['timestamptz']>;
  /** New GRN numbering format based on FY reset logic */
  grn_v2?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invoiceAddedAt?: InputMaybe<Scalars['timestamptz']>;
  loadedAt?: InputMaybe<Scalars['timestamptz']>;
  nonProductPersonMobile?: InputMaybe<Scalars['String']>;
  nonProductPersonName?: InputMaybe<Scalars['String']>;
  nonProductVehicleNumber?: InputMaybe<Scalars['String']>;
  ordersTakenAt?: InputMaybe<Scalars['timestamptz']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  transporterVehicleId?: InputMaybe<Scalars['Int']>;
  truckOutAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type OutwardShipments_Sum_Fields = {
  __typename?: 'outwardShipments_sum_fields';
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  transporterId?: Maybe<Scalars['Int']>;
  transporterVehicleId?: Maybe<Scalars['Int']>;
};

/** update columns of table "outwardShipments" */
export enum OutwardShipments_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EmptyVehicleImage = 'emptyVehicleImage',
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  EmptyWtTakenAt = 'emptyWtTakenAt',
  /** column name */
  FullVehicleImage = 'fullVehicleImage',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  FullWtTakenAt = 'fullWtTakenAt',
  /** column name */
  GrnV2 = 'grn_v2',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceAddedAt = 'invoiceAddedAt',
  /** column name */
  LoadedAt = 'loadedAt',
  /** column name */
  NonProductPersonMobile = 'nonProductPersonMobile',
  /** column name */
  NonProductPersonName = 'nonProductPersonName',
  /** column name */
  NonProductVehicleNumber = 'nonProductVehicleNumber',
  /** column name */
  OrdersTakenAt = 'ordersTakenAt',
  /** column name */
  TransporterId = 'transporterId',
  /** column name */
  TransporterVehicleId = 'transporterVehicleId',
  /** column name */
  TruckOutAt = 'truckOutAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type OutwardShipments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OutwardShipments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OutwardShipments_Set_Input>;
  /** filter the rows which have to be updated */
  where: OutwardShipments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OutwardShipments_Var_Pop_Fields = {
  __typename?: 'outwardShipments_var_pop_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OutwardShipments_Var_Samp_Fields = {
  __typename?: 'outwardShipments_var_samp_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OutwardShipments_Variance_Fields = {
  __typename?: 'outwardShipments_variance_fields';
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
  transporterVehicleId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "paddyBagOrders" */
export type PaddyBagOrders = {
  __typename?: 'paddyBagOrders';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveredAt?: Maybe<Scalars['timestamptz']>;
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  item: Scalars['String'];
  orderedBagsCount?: Maybe<Scalars['Int']>;
  poApproved?: Maybe<Scalars['Boolean']>;
  poNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorId: Scalars['Int'];
};

/** aggregated selection of "paddyBagOrders" */
export type PaddyBagOrders_Aggregate = {
  __typename?: 'paddyBagOrders_aggregate';
  aggregate?: Maybe<PaddyBagOrders_Aggregate_Fields>;
  nodes: Array<PaddyBagOrders>;
};

/** aggregate fields of "paddyBagOrders" */
export type PaddyBagOrders_Aggregate_Fields = {
  __typename?: 'paddyBagOrders_aggregate_fields';
  avg?: Maybe<PaddyBagOrders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PaddyBagOrders_Max_Fields>;
  min?: Maybe<PaddyBagOrders_Min_Fields>;
  stddev?: Maybe<PaddyBagOrders_Stddev_Fields>;
  stddev_pop?: Maybe<PaddyBagOrders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PaddyBagOrders_Stddev_Samp_Fields>;
  sum?: Maybe<PaddyBagOrders_Sum_Fields>;
  var_pop?: Maybe<PaddyBagOrders_Var_Pop_Fields>;
  var_samp?: Maybe<PaddyBagOrders_Var_Samp_Fields>;
  variance?: Maybe<PaddyBagOrders_Variance_Fields>;
};


/** aggregate fields of "paddyBagOrders" */
export type PaddyBagOrders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PaddyBagOrders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PaddyBagOrders_Avg_Fields = {
  __typename?: 'paddyBagOrders_avg_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "paddyBagOrders". All fields are combined with a logical 'AND'. */
export type PaddyBagOrders_Bool_Exp = {
  _and?: InputMaybe<Array<PaddyBagOrders_Bool_Exp>>;
  _not?: InputMaybe<PaddyBagOrders_Bool_Exp>;
  _or?: InputMaybe<Array<PaddyBagOrders_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveredAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveredBagsCount?: InputMaybe<Int_Comparison_Exp>;
  gstPercent?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  item?: InputMaybe<String_Comparison_Exp>;
  orderedBagsCount?: InputMaybe<Int_Comparison_Exp>;
  poApproved?: InputMaybe<Boolean_Comparison_Exp>;
  poNumber?: InputMaybe<String_Comparison_Exp>;
  unitPrice?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendorId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "paddyBagOrders" */
export enum PaddyBagOrders_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaddyBagOrdersPkey = 'paddyBagOrders_pkey'
}

/** input type for incrementing numeric columns in table "paddyBagOrders" */
export type PaddyBagOrders_Inc_Input = {
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "paddyBagOrders" */
export type PaddyBagOrders_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Scalars['String']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PaddyBagOrders_Max_Fields = {
  __typename?: 'paddyBagOrders_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveredAt?: Maybe<Scalars['timestamptz']>;
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['String']>;
  orderedBagsCount?: Maybe<Scalars['Int']>;
  poNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type PaddyBagOrders_Min_Fields = {
  __typename?: 'paddyBagOrders_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveredAt?: Maybe<Scalars['timestamptz']>;
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['String']>;
  orderedBagsCount?: Maybe<Scalars['Int']>;
  poNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "paddyBagOrders" */
export type PaddyBagOrders_Mutation_Response = {
  __typename?: 'paddyBagOrders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PaddyBagOrders>;
};

/** on_conflict condition type for table "paddyBagOrders" */
export type PaddyBagOrders_On_Conflict = {
  constraint: PaddyBagOrders_Constraint;
  update_columns?: Array<PaddyBagOrders_Update_Column>;
  where?: InputMaybe<PaddyBagOrders_Bool_Exp>;
};

/** Ordering options when selecting data from "paddyBagOrders". */
export type PaddyBagOrders_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveredAt?: InputMaybe<Order_By>;
  deliveredBagsCount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Order_By>;
  orderedBagsCount?: InputMaybe<Order_By>;
  poApproved?: InputMaybe<Order_By>;
  poNumber?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: paddyBagOrders */
export type PaddyBagOrders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "paddyBagOrders" */
export enum PaddyBagOrders_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveredAt = 'deliveredAt',
  /** column name */
  DeliveredBagsCount = 'deliveredBagsCount',
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  Id = 'id',
  /** column name */
  Item = 'item',
  /** column name */
  OrderedBagsCount = 'orderedBagsCount',
  /** column name */
  PoApproved = 'poApproved',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorId = 'vendorId'
}

/** input type for updating data in table "paddyBagOrders" */
export type PaddyBagOrders_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Scalars['String']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PaddyBagOrders_Stddev_Fields = {
  __typename?: 'paddyBagOrders_stddev_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PaddyBagOrders_Stddev_Pop_Fields = {
  __typename?: 'paddyBagOrders_stddev_pop_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PaddyBagOrders_Stddev_Samp_Fields = {
  __typename?: 'paddyBagOrders_stddev_samp_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "paddyBagOrders" */
export type PaddyBagOrders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PaddyBagOrders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PaddyBagOrders_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Scalars['String']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type PaddyBagOrders_Sum_Fields = {
  __typename?: 'paddyBagOrders_sum_fields';
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  orderedBagsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['float8']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** update columns of table "paddyBagOrders" */
export enum PaddyBagOrders_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveredAt = 'deliveredAt',
  /** column name */
  DeliveredBagsCount = 'deliveredBagsCount',
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  Id = 'id',
  /** column name */
  Item = 'item',
  /** column name */
  OrderedBagsCount = 'orderedBagsCount',
  /** column name */
  PoApproved = 'poApproved',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorId = 'vendorId'
}

export type PaddyBagOrders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaddyBagOrders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaddyBagOrders_Set_Input>;
  /** filter the rows which have to be updated */
  where: PaddyBagOrders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PaddyBagOrders_Var_Pop_Fields = {
  __typename?: 'paddyBagOrders_var_pop_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PaddyBagOrders_Var_Samp_Fields = {
  __typename?: 'paddyBagOrders_var_samp_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PaddyBagOrders_Variance_Fields = {
  __typename?: 'paddyBagOrders_variance_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'payment';
  amount?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  /** For deduction reasons and their respective amount */
  deductions?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  /** An object relationship */
  inwardShipment: InwardShipments;
  inward_shipment_id: Scalars['Int'];
  mode?: Maybe<Scalars['String']>;
  payment_done_at?: Maybe<Scalars['timestamptz']>;
  /** For check or payment  transaction number */
  payment_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  rmSeller: RmSellers;
  rmSeller_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "payment" */
export type PaymentDeductionsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "payment" */
export type Payment_Aggregate = {
  __typename?: 'payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
};

export type Payment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Payment_Aggregate_Bool_Exp_Count>;
};

export type Payment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
};


/** aggregate fields of "payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment" */
export type Payment_Aggregate_Order_By = {
  avg?: InputMaybe<Payment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Payment_Max_Order_By>;
  min?: InputMaybe<Payment_Min_Order_By>;
  stddev?: InputMaybe<Payment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Payment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Payment_Sum_Order_By>;
  var_pop?: InputMaybe<Payment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Payment_Var_Samp_Order_By>;
  variance?: InputMaybe<Payment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Payment_Append_Input = {
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "payment" */
export type Payment_Arr_Rel_Insert_Input = {
  data: Array<Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'payment_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment" */
export type Payment_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Bool_Exp>>;
  _not?: InputMaybe<Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deductions?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inwardShipment?: InputMaybe<InwardShipments_Bool_Exp>;
  inward_shipment_id?: InputMaybe<Int_Comparison_Exp>;
  mode?: InputMaybe<String_Comparison_Exp>;
  payment_done_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  payment_number?: InputMaybe<String_Comparison_Exp>;
  rmSeller?: InputMaybe<RmSellers_Bool_Exp>;
  rmSeller_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentPkey = 'payment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Payment_Delete_At_Path_Input = {
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Payment_Delete_Elem_Input = {
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Payment_Delete_Key_Input = {
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "payment" */
export type Payment_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  inward_shipment_id?: InputMaybe<Scalars['Int']>;
  rmSeller_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment" */
export type Payment_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  inwardShipment?: InputMaybe<InwardShipments_Obj_Rel_Insert_Input>;
  inward_shipment_id?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<Scalars['String']>;
  payment_done_at?: InputMaybe<Scalars['timestamptz']>;
  /** For check or payment  transaction number */
  payment_number?: InputMaybe<Scalars['String']>;
  rmSeller?: InputMaybe<RmSellers_Obj_Rel_Insert_Input>;
  rmSeller_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'payment_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inward_shipment_id?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  payment_done_at?: Maybe<Scalars['timestamptz']>;
  /** For check or payment  transaction number */
  payment_number?: Maybe<Scalars['String']>;
  rmSeller_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payment" */
export type Payment_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  payment_done_at?: InputMaybe<Order_By>;
  /** For check or payment  transaction number */
  payment_number?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'payment_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inward_shipment_id?: Maybe<Scalars['Int']>;
  mode?: Maybe<Scalars['String']>;
  payment_done_at?: Maybe<Scalars['timestamptz']>;
  /** For check or payment  transaction number */
  payment_number?: Maybe<Scalars['String']>;
  rmSeller_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payment" */
export type Payment_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  payment_done_at?: InputMaybe<Order_By>;
  /** For check or payment  transaction number */
  payment_number?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payment" */
export type Payment_Mutation_Response = {
  __typename?: 'payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
};

/** on_conflict condition type for table "payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint;
  update_columns?: Array<Payment_Update_Column>;
  where?: InputMaybe<Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "payment". */
export type Payment_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deductions?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipment?: InputMaybe<InwardShipments_Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  payment_done_at?: InputMaybe<Order_By>;
  payment_number?: InputMaybe<Order_By>;
  rmSeller?: InputMaybe<RmSellers_Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment */
export type Payment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Payment_Prepend_Input = {
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deductions = 'deductions',
  /** column name */
  Id = 'id',
  /** column name */
  InwardShipmentId = 'inward_shipment_id',
  /** column name */
  Mode = 'mode',
  /** column name */
  PaymentDoneAt = 'payment_done_at',
  /** column name */
  PaymentNumber = 'payment_number',
  /** column name */
  RmSellerId = 'rmSeller_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment" */
export type Payment_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  inward_shipment_id?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<Scalars['String']>;
  payment_done_at?: InputMaybe<Scalars['timestamptz']>;
  /** For check or payment  transaction number */
  payment_number?: InputMaybe<Scalars['String']>;
  rmSeller_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'payment_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment" */
export type Payment_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'payment_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment" */
export type Payment_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'payment_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment" */
export type Payment_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "payment" */
export type Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** For deduction reasons and their respective amount */
  deductions?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  inward_shipment_id?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<Scalars['String']>;
  payment_done_at?: InputMaybe<Scalars['timestamptz']>;
  /** For check or payment  transaction number */
  payment_number?: InputMaybe<Scalars['String']>;
  rmSeller_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'payment_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  inward_shipment_id?: Maybe<Scalars['Int']>;
  rmSeller_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment" */
export type Payment_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** update columns of table "payment" */
export enum Payment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deductions = 'deductions',
  /** column name */
  Id = 'id',
  /** column name */
  InwardShipmentId = 'inward_shipment_id',
  /** column name */
  Mode = 'mode',
  /** column name */
  PaymentDoneAt = 'payment_done_at',
  /** column name */
  PaymentNumber = 'payment_number',
  /** column name */
  RmSellerId = 'rmSeller_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Payment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Payment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Payment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Payment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Payment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Payment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'payment_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment" */
export type Payment_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'payment_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment" */
export type Payment_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'payment_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  inward_shipment_id?: Maybe<Scalars['Float']>;
  rmSeller_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment" */
export type Payment_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  inward_shipment_id?: InputMaybe<Order_By>;
  rmSeller_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "process_wise_in_out" */
export type Process_Wise_In_Out = {
  __typename?: 'process_wise_in_out';
  combinedProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalinwt?: Maybe<Scalars['float8']>;
  totaloutwt?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "process_wise_in_out" */
export type Process_Wise_In_Out_Aggregate = {
  __typename?: 'process_wise_in_out_aggregate';
  aggregate?: Maybe<Process_Wise_In_Out_Aggregate_Fields>;
  nodes: Array<Process_Wise_In_Out>;
};

/** aggregate fields of "process_wise_in_out" */
export type Process_Wise_In_Out_Aggregate_Fields = {
  __typename?: 'process_wise_in_out_aggregate_fields';
  avg?: Maybe<Process_Wise_In_Out_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Process_Wise_In_Out_Max_Fields>;
  min?: Maybe<Process_Wise_In_Out_Min_Fields>;
  stddev?: Maybe<Process_Wise_In_Out_Stddev_Fields>;
  stddev_pop?: Maybe<Process_Wise_In_Out_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Process_Wise_In_Out_Stddev_Samp_Fields>;
  sum?: Maybe<Process_Wise_In_Out_Sum_Fields>;
  var_pop?: Maybe<Process_Wise_In_Out_Var_Pop_Fields>;
  var_samp?: Maybe<Process_Wise_In_Out_Var_Samp_Fields>;
  variance?: Maybe<Process_Wise_In_Out_Variance_Fields>;
};


/** aggregate fields of "process_wise_in_out" */
export type Process_Wise_In_Out_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Process_Wise_In_Out_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Process_Wise_In_Out_Avg_Fields = {
  __typename?: 'process_wise_in_out_avg_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "process_wise_in_out". All fields are combined with a logical 'AND'. */
export type Process_Wise_In_Out_Bool_Exp = {
  _and?: InputMaybe<Array<Process_Wise_In_Out_Bool_Exp>>;
  _not?: InputMaybe<Process_Wise_In_Out_Bool_Exp>;
  _or?: InputMaybe<Array<Process_Wise_In_Out_Bool_Exp>>;
  combinedProcessId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  totalinwt?: InputMaybe<Float8_Comparison_Exp>;
  totaloutwt?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Process_Wise_In_Out_Max_Fields = {
  __typename?: 'process_wise_in_out_max_fields';
  combinedProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalinwt?: Maybe<Scalars['float8']>;
  totaloutwt?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Process_Wise_In_Out_Min_Fields = {
  __typename?: 'process_wise_in_out_min_fields';
  combinedProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalinwt?: Maybe<Scalars['float8']>;
  totaloutwt?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "process_wise_in_out". */
export type Process_Wise_In_Out_Order_By = {
  combinedProcessId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  totalinwt?: InputMaybe<Order_By>;
  totaloutwt?: InputMaybe<Order_By>;
};

/** select columns of table "process_wise_in_out" */
export enum Process_Wise_In_Out_Select_Column {
  /** column name */
  CombinedProcessId = 'combinedProcessId',
  /** column name */
  Id = 'id',
  /** column name */
  Totalinwt = 'totalinwt',
  /** column name */
  Totaloutwt = 'totaloutwt'
}

/** aggregate stddev on columns */
export type Process_Wise_In_Out_Stddev_Fields = {
  __typename?: 'process_wise_in_out_stddev_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Process_Wise_In_Out_Stddev_Pop_Fields = {
  __typename?: 'process_wise_in_out_stddev_pop_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Process_Wise_In_Out_Stddev_Samp_Fields = {
  __typename?: 'process_wise_in_out_stddev_samp_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "process_wise_in_out" */
export type Process_Wise_In_Out_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Process_Wise_In_Out_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Process_Wise_In_Out_Stream_Cursor_Value_Input = {
  combinedProcessId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  totalinwt?: InputMaybe<Scalars['float8']>;
  totaloutwt?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Process_Wise_In_Out_Sum_Fields = {
  __typename?: 'process_wise_in_out_sum_fields';
  combinedProcessId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  totalinwt?: Maybe<Scalars['float8']>;
  totaloutwt?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Process_Wise_In_Out_Var_Pop_Fields = {
  __typename?: 'process_wise_in_out_var_pop_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Process_Wise_In_Out_Var_Samp_Fields = {
  __typename?: 'process_wise_in_out_var_samp_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Process_Wise_In_Out_Variance_Fields = {
  __typename?: 'process_wise_in_out_variance_fields';
  combinedProcessId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  totalinwt?: Maybe<Scalars['Float']>;
  totaloutwt?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "productBagOrders" */
export type ProductBagOrders = {
  __typename?: 'productBagOrders';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveredAt?: Maybe<Scalars['timestamptz']>;
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  item: Scalars['String'];
  orderedBagsCount?: Maybe<Scalars['Int']>;
  poApproved?: Maybe<Scalars['Boolean']>;
  poNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorId: Scalars['Int'];
};

/** aggregated selection of "productBagOrders" */
export type ProductBagOrders_Aggregate = {
  __typename?: 'productBagOrders_aggregate';
  aggregate?: Maybe<ProductBagOrders_Aggregate_Fields>;
  nodes: Array<ProductBagOrders>;
};

/** aggregate fields of "productBagOrders" */
export type ProductBagOrders_Aggregate_Fields = {
  __typename?: 'productBagOrders_aggregate_fields';
  avg?: Maybe<ProductBagOrders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductBagOrders_Max_Fields>;
  min?: Maybe<ProductBagOrders_Min_Fields>;
  stddev?: Maybe<ProductBagOrders_Stddev_Fields>;
  stddev_pop?: Maybe<ProductBagOrders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductBagOrders_Stddev_Samp_Fields>;
  sum?: Maybe<ProductBagOrders_Sum_Fields>;
  var_pop?: Maybe<ProductBagOrders_Var_Pop_Fields>;
  var_samp?: Maybe<ProductBagOrders_Var_Samp_Fields>;
  variance?: Maybe<ProductBagOrders_Variance_Fields>;
};


/** aggregate fields of "productBagOrders" */
export type ProductBagOrders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductBagOrders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProductBagOrders_Avg_Fields = {
  __typename?: 'productBagOrders_avg_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "productBagOrders". All fields are combined with a logical 'AND'. */
export type ProductBagOrders_Bool_Exp = {
  _and?: InputMaybe<Array<ProductBagOrders_Bool_Exp>>;
  _not?: InputMaybe<ProductBagOrders_Bool_Exp>;
  _or?: InputMaybe<Array<ProductBagOrders_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveredAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveredBagsCount?: InputMaybe<Int_Comparison_Exp>;
  gstPercent?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  item?: InputMaybe<String_Comparison_Exp>;
  orderedBagsCount?: InputMaybe<Int_Comparison_Exp>;
  poApproved?: InputMaybe<Boolean_Comparison_Exp>;
  poNumber?: InputMaybe<String_Comparison_Exp>;
  unitPrice?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendorId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "productBagOrders" */
export enum ProductBagOrders_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductBagOrdersPkey = 'productBagOrders_pkey'
}

/** input type for incrementing numeric columns in table "productBagOrders" */
export type ProductBagOrders_Inc_Input = {
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "productBagOrders" */
export type ProductBagOrders_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Scalars['String']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProductBagOrders_Max_Fields = {
  __typename?: 'productBagOrders_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveredAt?: Maybe<Scalars['timestamptz']>;
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['String']>;
  orderedBagsCount?: Maybe<Scalars['Int']>;
  poNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProductBagOrders_Min_Fields = {
  __typename?: 'productBagOrders_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deliveredAt?: Maybe<Scalars['timestamptz']>;
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  item?: Maybe<Scalars['String']>;
  orderedBagsCount?: Maybe<Scalars['Int']>;
  poNumber?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "productBagOrders" */
export type ProductBagOrders_Mutation_Response = {
  __typename?: 'productBagOrders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductBagOrders>;
};

/** on_conflict condition type for table "productBagOrders" */
export type ProductBagOrders_On_Conflict = {
  constraint: ProductBagOrders_Constraint;
  update_columns?: Array<ProductBagOrders_Update_Column>;
  where?: InputMaybe<ProductBagOrders_Bool_Exp>;
};

/** Ordering options when selecting data from "productBagOrders". */
export type ProductBagOrders_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  deliveredAt?: InputMaybe<Order_By>;
  deliveredBagsCount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Order_By>;
  orderedBagsCount?: InputMaybe<Order_By>;
  poApproved?: InputMaybe<Order_By>;
  poNumber?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: productBagOrders */
export type ProductBagOrders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "productBagOrders" */
export enum ProductBagOrders_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveredAt = 'deliveredAt',
  /** column name */
  DeliveredBagsCount = 'deliveredBagsCount',
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  Id = 'id',
  /** column name */
  Item = 'item',
  /** column name */
  OrderedBagsCount = 'orderedBagsCount',
  /** column name */
  PoApproved = 'poApproved',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorId = 'vendorId'
}

/** input type for updating data in table "productBagOrders" */
export type ProductBagOrders_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Scalars['String']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProductBagOrders_Stddev_Fields = {
  __typename?: 'productBagOrders_stddev_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProductBagOrders_Stddev_Pop_Fields = {
  __typename?: 'productBagOrders_stddev_pop_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProductBagOrders_Stddev_Samp_Fields = {
  __typename?: 'productBagOrders_stddev_samp_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "productBagOrders" */
export type ProductBagOrders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductBagOrders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductBagOrders_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredAt?: InputMaybe<Scalars['timestamptz']>;
  deliveredBagsCount?: InputMaybe<Scalars['Int']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Scalars['String']>;
  orderedBagsCount?: InputMaybe<Scalars['Int']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProductBagOrders_Sum_Fields = {
  __typename?: 'productBagOrders_sum_fields';
  deliveredBagsCount?: Maybe<Scalars['Int']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  orderedBagsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['float8']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** update columns of table "productBagOrders" */
export enum ProductBagOrders_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveredAt = 'deliveredAt',
  /** column name */
  DeliveredBagsCount = 'deliveredBagsCount',
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  Id = 'id',
  /** column name */
  Item = 'item',
  /** column name */
  OrderedBagsCount = 'orderedBagsCount',
  /** column name */
  PoApproved = 'poApproved',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VendorId = 'vendorId'
}

export type ProductBagOrders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductBagOrders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductBagOrders_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductBagOrders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductBagOrders_Var_Pop_Fields = {
  __typename?: 'productBagOrders_var_pop_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProductBagOrders_Var_Samp_Fields = {
  __typename?: 'productBagOrders_var_samp_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProductBagOrders_Variance_Fields = {
  __typename?: 'productBagOrders_variance_fields';
  deliveredBagsCount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedBagsCount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "productBuyers" */
export type ProductBuyers = {
  __typename?: 'productBuyers';
  address?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  profileCompleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "productBuyers" */
export type ProductBuyersAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "productBuyers" */
export type ProductBuyers_Aggregate = {
  __typename?: 'productBuyers_aggregate';
  aggregate?: Maybe<ProductBuyers_Aggregate_Fields>;
  nodes: Array<ProductBuyers>;
};

/** aggregate fields of "productBuyers" */
export type ProductBuyers_Aggregate_Fields = {
  __typename?: 'productBuyers_aggregate_fields';
  avg?: Maybe<ProductBuyers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductBuyers_Max_Fields>;
  min?: Maybe<ProductBuyers_Min_Fields>;
  stddev?: Maybe<ProductBuyers_Stddev_Fields>;
  stddev_pop?: Maybe<ProductBuyers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductBuyers_Stddev_Samp_Fields>;
  sum?: Maybe<ProductBuyers_Sum_Fields>;
  var_pop?: Maybe<ProductBuyers_Var_Pop_Fields>;
  var_samp?: Maybe<ProductBuyers_Var_Samp_Fields>;
  variance?: Maybe<ProductBuyers_Variance_Fields>;
};


/** aggregate fields of "productBuyers" */
export type ProductBuyers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductBuyers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProductBuyers_Avg_Fields = {
  __typename?: 'productBuyers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "productBuyers". All fields are combined with a logical 'AND'. */
export type ProductBuyers_Bool_Exp = {
  _and?: InputMaybe<Array<ProductBuyers_Bool_Exp>>;
  _not?: InputMaybe<ProductBuyers_Bool_Exp>;
  _or?: InputMaybe<Array<ProductBuyers_Bool_Exp>>;
  address?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  gstin?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  primaryContactEmail?: InputMaybe<String_Comparison_Exp>;
  primaryContactMobile?: InputMaybe<String_Comparison_Exp>;
  primaryContactName?: InputMaybe<String_Comparison_Exp>;
  profileCompleted?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "productBuyers" */
export enum ProductBuyers_Constraint {
  /** unique or primary key constraint on columns "gstin" */
  ProductBuyersGstinKey = 'productBuyers_gstin_key',
  /** unique or primary key constraint on columns "id" */
  ProductBuyersPkey = 'productBuyers_pkey'
}

/** input type for incrementing numeric columns in table "productBuyers" */
export type ProductBuyers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "productBuyers" */
export type ProductBuyers_Insert_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductBuyers_Max_Fields = {
  __typename?: 'productBuyers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ProductBuyers_Min_Fields = {
  __typename?: 'productBuyers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "productBuyers" */
export type ProductBuyers_Mutation_Response = {
  __typename?: 'productBuyers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductBuyers>;
};

/** input type for inserting object relation for remote table "productBuyers" */
export type ProductBuyers_Obj_Rel_Insert_Input = {
  data: ProductBuyers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductBuyers_On_Conflict>;
};

/** on_conflict condition type for table "productBuyers" */
export type ProductBuyers_On_Conflict = {
  constraint: ProductBuyers_Constraint;
  update_columns?: Array<ProductBuyers_Update_Column>;
  where?: InputMaybe<ProductBuyers_Bool_Exp>;
};

/** Ordering options when selecting data from "productBuyers". */
export type ProductBuyers_Order_By = {
  address?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  gstin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  primaryContactEmail?: InputMaybe<Order_By>;
  primaryContactMobile?: InputMaybe<Order_By>;
  primaryContactName?: InputMaybe<Order_By>;
  profileCompleted?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: productBuyers */
export type ProductBuyers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "productBuyers" */
export enum ProductBuyers_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryContactEmail = 'primaryContactEmail',
  /** column name */
  PrimaryContactMobile = 'primaryContactMobile',
  /** column name */
  PrimaryContactName = 'primaryContactName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "productBuyers" */
export type ProductBuyers_Set_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProductBuyers_Stddev_Fields = {
  __typename?: 'productBuyers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProductBuyers_Stddev_Pop_Fields = {
  __typename?: 'productBuyers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProductBuyers_Stddev_Samp_Fields = {
  __typename?: 'productBuyers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "productBuyers" */
export type ProductBuyers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductBuyers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductBuyers_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ProductBuyers_Sum_Fields = {
  __typename?: 'productBuyers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "productBuyers" */
export enum ProductBuyers_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryContactEmail = 'primaryContactEmail',
  /** column name */
  PrimaryContactMobile = 'primaryContactMobile',
  /** column name */
  PrimaryContactName = 'primaryContactName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ProductBuyers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductBuyers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductBuyers_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductBuyers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductBuyers_Var_Pop_Fields = {
  __typename?: 'productBuyers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProductBuyers_Var_Samp_Fields = {
  __typename?: 'productBuyers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProductBuyers_Variance_Fields = {
  __typename?: 'productBuyers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "productInventorySummary" */
export type ProductInventorySummary = {
  __typename?: 'productInventorySummary';
  bagSizeKg?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  producedBagsCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product?: Maybe<Products>;
  productId?: Maybe<Scalars['Int']>;
  soldBagsCount?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "productInventorySummary" */
export type ProductInventorySummary_Aggregate = {
  __typename?: 'productInventorySummary_aggregate';
  aggregate?: Maybe<ProductInventorySummary_Aggregate_Fields>;
  nodes: Array<ProductInventorySummary>;
};

export type ProductInventorySummary_Aggregate_Bool_Exp = {
  count?: InputMaybe<ProductInventorySummary_Aggregate_Bool_Exp_Count>;
};

export type ProductInventorySummary_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ProductInventorySummary_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "productInventorySummary" */
export type ProductInventorySummary_Aggregate_Fields = {
  __typename?: 'productInventorySummary_aggregate_fields';
  avg?: Maybe<ProductInventorySummary_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductInventorySummary_Max_Fields>;
  min?: Maybe<ProductInventorySummary_Min_Fields>;
  stddev?: Maybe<ProductInventorySummary_Stddev_Fields>;
  stddev_pop?: Maybe<ProductInventorySummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductInventorySummary_Stddev_Samp_Fields>;
  sum?: Maybe<ProductInventorySummary_Sum_Fields>;
  var_pop?: Maybe<ProductInventorySummary_Var_Pop_Fields>;
  var_samp?: Maybe<ProductInventorySummary_Var_Samp_Fields>;
  variance?: Maybe<ProductInventorySummary_Variance_Fields>;
};


/** aggregate fields of "productInventorySummary" */
export type ProductInventorySummary_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "productInventorySummary" */
export type ProductInventorySummary_Aggregate_Order_By = {
  avg?: InputMaybe<ProductInventorySummary_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ProductInventorySummary_Max_Order_By>;
  min?: InputMaybe<ProductInventorySummary_Min_Order_By>;
  stddev?: InputMaybe<ProductInventorySummary_Stddev_Order_By>;
  stddev_pop?: InputMaybe<ProductInventorySummary_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<ProductInventorySummary_Stddev_Samp_Order_By>;
  sum?: InputMaybe<ProductInventorySummary_Sum_Order_By>;
  var_pop?: InputMaybe<ProductInventorySummary_Var_Pop_Order_By>;
  var_samp?: InputMaybe<ProductInventorySummary_Var_Samp_Order_By>;
  variance?: InputMaybe<ProductInventorySummary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "productInventorySummary" */
export type ProductInventorySummary_Arr_Rel_Insert_Input = {
  data: Array<ProductInventorySummary_Insert_Input>;
};

/** aggregate avg on columns */
export type ProductInventorySummary_Avg_Fields = {
  __typename?: 'productInventorySummary_avg_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Avg_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "productInventorySummary". All fields are combined with a logical 'AND'. */
export type ProductInventorySummary_Bool_Exp = {
  _and?: InputMaybe<Array<ProductInventorySummary_Bool_Exp>>;
  _not?: InputMaybe<ProductInventorySummary_Bool_Exp>;
  _or?: InputMaybe<Array<ProductInventorySummary_Bool_Exp>>;
  bagSizeKg?: InputMaybe<Int_Comparison_Exp>;
  currentBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  producedBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Int_Comparison_Exp>;
  soldBagsCount?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "productInventorySummary" */
export type ProductInventorySummary_Insert_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  producedBagsCount?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['Int']>;
  soldBagsCount?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type ProductInventorySummary_Max_Fields = {
  __typename?: 'productInventorySummary_max_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  producedBagsCount?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['Int']>;
  soldBagsCount?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Max_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ProductInventorySummary_Min_Fields = {
  __typename?: 'productInventorySummary_min_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  producedBagsCount?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['Int']>;
  soldBagsCount?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Min_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "productInventorySummary". */
export type ProductInventorySummary_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** select columns of table "productInventorySummary" */
export enum ProductInventorySummary_Select_Column {
  /** column name */
  BagSizeKg = 'bagSizeKg',
  /** column name */
  CurrentBagsCount = 'currentBagsCount',
  /** column name */
  Id = 'id',
  /** column name */
  ProducedBagsCount = 'producedBagsCount',
  /** column name */
  ProductId = 'productId',
  /** column name */
  SoldBagsCount = 'soldBagsCount'
}

/** aggregate stddev on columns */
export type ProductInventorySummary_Stddev_Fields = {
  __typename?: 'productInventorySummary_stddev_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Stddev_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ProductInventorySummary_Stddev_Pop_Fields = {
  __typename?: 'productInventorySummary_stddev_pop_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Stddev_Pop_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ProductInventorySummary_Stddev_Samp_Fields = {
  __typename?: 'productInventorySummary_stddev_samp_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Stddev_Samp_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "productInventorySummary" */
export type ProductInventorySummary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductInventorySummary_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductInventorySummary_Stream_Cursor_Value_Input = {
  bagSizeKg?: InputMaybe<Scalars['Int']>;
  currentBagsCount?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  producedBagsCount?: InputMaybe<Scalars['bigint']>;
  productId?: InputMaybe<Scalars['Int']>;
  soldBagsCount?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type ProductInventorySummary_Sum_Fields = {
  __typename?: 'productInventorySummary_sum_fields';
  bagSizeKg?: Maybe<Scalars['Int']>;
  currentBagsCount?: Maybe<Scalars['bigint']>;
  producedBagsCount?: Maybe<Scalars['bigint']>;
  productId?: Maybe<Scalars['Int']>;
  soldBagsCount?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Sum_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type ProductInventorySummary_Var_Pop_Fields = {
  __typename?: 'productInventorySummary_var_pop_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Var_Pop_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ProductInventorySummary_Var_Samp_Fields = {
  __typename?: 'productInventorySummary_var_samp_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Var_Samp_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type ProductInventorySummary_Variance_Fields = {
  __typename?: 'productInventorySummary_variance_fields';
  bagSizeKg?: Maybe<Scalars['Float']>;
  currentBagsCount?: Maybe<Scalars['Float']>;
  producedBagsCount?: Maybe<Scalars['Float']>;
  productId?: Maybe<Scalars['Float']>;
  soldBagsCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "productInventorySummary" */
export type ProductInventorySummary_Variance_Order_By = {
  bagSizeKg?: InputMaybe<Order_By>;
  currentBagsCount?: InputMaybe<Order_By>;
  producedBagsCount?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  soldBagsCount?: InputMaybe<Order_By>;
};

/** columns and relationships of "productTypes" */
export type ProductTypes = {
  __typename?: 'productTypes';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  productTypeRawMaterials: Array<RawMaterialsProductTypes>;
  /** An aggregate relationship */
  productTypeRawMaterials_aggregate: RawMaterialsProductTypes_Aggregate;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesProductTypeRawMaterialsArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


/** columns and relationships of "productTypes" */
export type ProductTypesProductTypeRawMaterials_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};

/** aggregated selection of "productTypes" */
export type ProductTypes_Aggregate = {
  __typename?: 'productTypes_aggregate';
  aggregate?: Maybe<ProductTypes_Aggregate_Fields>;
  nodes: Array<ProductTypes>;
};

/** aggregate fields of "productTypes" */
export type ProductTypes_Aggregate_Fields = {
  __typename?: 'productTypes_aggregate_fields';
  avg?: Maybe<ProductTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<ProductTypes_Max_Fields>;
  min?: Maybe<ProductTypes_Min_Fields>;
  stddev?: Maybe<ProductTypes_Stddev_Fields>;
  stddev_pop?: Maybe<ProductTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ProductTypes_Stddev_Samp_Fields>;
  sum?: Maybe<ProductTypes_Sum_Fields>;
  var_pop?: Maybe<ProductTypes_Var_Pop_Fields>;
  var_samp?: Maybe<ProductTypes_Var_Samp_Fields>;
  variance?: Maybe<ProductTypes_Variance_Fields>;
};


/** aggregate fields of "productTypes" */
export type ProductTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ProductTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProductTypes_Avg_Fields = {
  __typename?: 'productTypes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "productTypes". All fields are combined with a logical 'AND'. */
export type ProductTypes_Bool_Exp = {
  _and?: InputMaybe<Array<ProductTypes_Bool_Exp>>;
  _not?: InputMaybe<ProductTypes_Bool_Exp>;
  _or?: InputMaybe<Array<ProductTypes_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  productTypeRawMaterials?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
  productTypeRawMaterials_aggregate?: InputMaybe<RawMaterialsProductTypes_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "productTypes" */
export enum ProductTypes_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProductTypesNameKey = 'productTypes_name_key',
  /** unique or primary key constraint on columns "id" */
  ProductTypesPkey = 'productTypes_pkey'
}

/** input type for incrementing numeric columns in table "productTypes" */
export type ProductTypes_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "productTypes" */
export type ProductTypes_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  productTypeRawMaterials?: InputMaybe<RawMaterialsProductTypes_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductTypes_Max_Fields = {
  __typename?: 'productTypes_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ProductTypes_Min_Fields = {
  __typename?: 'productTypes_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "productTypes" */
export type ProductTypes_Mutation_Response = {
  __typename?: 'productTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProductTypes>;
};

/** input type for inserting object relation for remote table "productTypes" */
export type ProductTypes_Obj_Rel_Insert_Input = {
  data: ProductTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<ProductTypes_On_Conflict>;
};

/** on_conflict condition type for table "productTypes" */
export type ProductTypes_On_Conflict = {
  constraint: ProductTypes_Constraint;
  update_columns?: Array<ProductTypes_Update_Column>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "productTypes". */
export type ProductTypes_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  productTypeRawMaterials_aggregate?: InputMaybe<RawMaterialsProductTypes_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: productTypes */
export type ProductTypes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "productTypes" */
export enum ProductTypes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "productTypes" */
export type ProductTypes_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProductTypes_Stddev_Fields = {
  __typename?: 'productTypes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProductTypes_Stddev_Pop_Fields = {
  __typename?: 'productTypes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProductTypes_Stddev_Samp_Fields = {
  __typename?: 'productTypes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "productTypes" */
export type ProductTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: ProductTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductTypes_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ProductTypes_Sum_Fields = {
  __typename?: 'productTypes_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "productTypes" */
export enum ProductTypes_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type ProductTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ProductTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProductTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: ProductTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ProductTypes_Var_Pop_Fields = {
  __typename?: 'productTypes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProductTypes_Var_Samp_Fields = {
  __typename?: 'productTypes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProductTypes_Variance_Fields = {
  __typename?: 'productTypes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  inventorySummary: Array<ProductInventorySummary>;
  /** An aggregate relationship */
  inventorySummary_aggregate: ProductInventorySummary_Aggregate;
  /** An object relationship */
  productType: ProductTypes;
  productTypeId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "products" */
export type ProductsInventorySummaryArgs = {
  distinct_on?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductInventorySummary_Order_By>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsInventorySummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductInventorySummary_Order_By>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inventorySummary?: InputMaybe<ProductInventorySummary_Bool_Exp>;
  inventorySummary_aggregate?: InputMaybe<ProductInventorySummary_Aggregate_Bool_Exp>;
  productType?: InputMaybe<ProductTypes_Bool_Exp>;
  productTypeId?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint on columns "productTypeId", "brand" */
  CustomUniqueProductsProductTypeIdBrand = 'custom_unique_products_productTypeId_brand',
  /** unique or primary key constraint on columns "id" */
  ProductsPkey = 'products_pkey'
}

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  productTypeId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  brand?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  inventorySummary?: InputMaybe<ProductInventorySummary_Arr_Rel_Insert_Input>;
  productType?: InputMaybe<ProductTypes_Obj_Rel_Insert_Input>;
  productTypeId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  productTypeId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  brand?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  productTypeId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  brand?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inventorySummary_aggregate?: InputMaybe<ProductInventorySummary_Aggregate_Order_By>;
  productType?: InputMaybe<ProductTypes_Order_By>;
  productTypeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  brand?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  productTypeId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  productTypeId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  id?: Maybe<Scalars['Int']>;
  productTypeId?: Maybe<Scalars['Int']>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  id?: Maybe<Scalars['Float']>;
  productTypeId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "purchaseOrderItems" */
export type PurchaseOrderItems = {
  __typename?: 'purchaseOrderItems';
  /** An object relationship */
  broker?: Maybe<Brokers>;
  broker_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  discount?: Maybe<Scalars['numeric']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  /** An object relationship */
  item: Items;
  itemId: Scalars['uuid'];
  orderedItemsCount?: Maybe<Scalars['Int']>;
  poId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vendor: RmSellers;
  vendorId: Scalars['Int'];
};

/** aggregated selection of "purchaseOrderItems" */
export type PurchaseOrderItems_Aggregate = {
  __typename?: 'purchaseOrderItems_aggregate';
  aggregate?: Maybe<PurchaseOrderItems_Aggregate_Fields>;
  nodes: Array<PurchaseOrderItems>;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp = {
  avg?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp_Var_Samp>;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Avg = {
  arguments: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Corr = {
  arguments: PurchaseOrderItems_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Corr_Arguments = {
  X: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Max = {
  arguments: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Min = {
  arguments: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Sum = {
  arguments: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type PurchaseOrderItems_Aggregate_Bool_Exp_Var_Samp = {
  arguments: PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "purchaseOrderItems" */
export type PurchaseOrderItems_Aggregate_Fields = {
  __typename?: 'purchaseOrderItems_aggregate_fields';
  avg?: Maybe<PurchaseOrderItems_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PurchaseOrderItems_Max_Fields>;
  min?: Maybe<PurchaseOrderItems_Min_Fields>;
  stddev?: Maybe<PurchaseOrderItems_Stddev_Fields>;
  stddev_pop?: Maybe<PurchaseOrderItems_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PurchaseOrderItems_Stddev_Samp_Fields>;
  sum?: Maybe<PurchaseOrderItems_Sum_Fields>;
  var_pop?: Maybe<PurchaseOrderItems_Var_Pop_Fields>;
  var_samp?: Maybe<PurchaseOrderItems_Var_Samp_Fields>;
  variance?: Maybe<PurchaseOrderItems_Variance_Fields>;
};


/** aggregate fields of "purchaseOrderItems" */
export type PurchaseOrderItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "purchaseOrderItems" */
export type PurchaseOrderItems_Aggregate_Order_By = {
  avg?: InputMaybe<PurchaseOrderItems_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PurchaseOrderItems_Max_Order_By>;
  min?: InputMaybe<PurchaseOrderItems_Min_Order_By>;
  stddev?: InputMaybe<PurchaseOrderItems_Stddev_Order_By>;
  stddev_pop?: InputMaybe<PurchaseOrderItems_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<PurchaseOrderItems_Stddev_Samp_Order_By>;
  sum?: InputMaybe<PurchaseOrderItems_Sum_Order_By>;
  var_pop?: InputMaybe<PurchaseOrderItems_Var_Pop_Order_By>;
  var_samp?: InputMaybe<PurchaseOrderItems_Var_Samp_Order_By>;
  variance?: InputMaybe<PurchaseOrderItems_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "purchaseOrderItems" */
export type PurchaseOrderItems_Arr_Rel_Insert_Input = {
  data: Array<PurchaseOrderItems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PurchaseOrderItems_On_Conflict>;
};

/** aggregate avg on columns */
export type PurchaseOrderItems_Avg_Fields = {
  __typename?: 'purchaseOrderItems_avg_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Avg_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "purchaseOrderItems". All fields are combined with a logical 'AND'. */
export type PurchaseOrderItems_Bool_Exp = {
  _and?: InputMaybe<Array<PurchaseOrderItems_Bool_Exp>>;
  _not?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  _or?: InputMaybe<Array<PurchaseOrderItems_Bool_Exp>>;
  broker?: InputMaybe<Brokers_Bool_Exp>;
  broker_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount?: InputMaybe<Numeric_Comparison_Exp>;
  gstPercent?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  item?: InputMaybe<Items_Bool_Exp>;
  itemId?: InputMaybe<Uuid_Comparison_Exp>;
  orderedItemsCount?: InputMaybe<Int_Comparison_Exp>;
  poId?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  unitPrice?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<RmSellers_Bool_Exp>;
  vendorId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchaseOrderItems" */
export enum PurchaseOrderItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseOrderItemsPkey = 'purchaseOrderItems_pkey'
}

/** input type for incrementing numeric columns in table "purchaseOrderItems" */
export type PurchaseOrderItems_Inc_Input = {
  discount?: InputMaybe<Scalars['numeric']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  orderedItemsCount?: InputMaybe<Scalars['Int']>;
  poId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "purchaseOrderItems" */
export type PurchaseOrderItems_Insert_Input = {
  broker?: InputMaybe<Brokers_Obj_Rel_Insert_Input>;
  broker_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['numeric']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  item?: InputMaybe<Items_Obj_Rel_Insert_Input>;
  itemId?: InputMaybe<Scalars['uuid']>;
  orderedItemsCount?: InputMaybe<Scalars['Int']>;
  poId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor?: InputMaybe<RmSellers_Obj_Rel_Insert_Input>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type PurchaseOrderItems_Max_Fields = {
  __typename?: 'purchaseOrderItems_max_fields';
  broker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['numeric']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['uuid']>;
  orderedItemsCount?: Maybe<Scalars['Int']>;
  poId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Max_Order_By = {
  broker_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PurchaseOrderItems_Min_Fields = {
  __typename?: 'purchaseOrderItems_min_fields';
  broker_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount?: Maybe<Scalars['numeric']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['uuid']>;
  orderedItemsCount?: Maybe<Scalars['Int']>;
  poId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Min_Order_By = {
  broker_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  itemId?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "purchaseOrderItems" */
export type PurchaseOrderItems_Mutation_Response = {
  __typename?: 'purchaseOrderItems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrderItems>;
};

/** on_conflict condition type for table "purchaseOrderItems" */
export type PurchaseOrderItems_On_Conflict = {
  constraint: PurchaseOrderItems_Constraint;
  update_columns?: Array<PurchaseOrderItems_Update_Column>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};

/** Ordering options when selecting data from "purchaseOrderItems". */
export type PurchaseOrderItems_Order_By = {
  broker?: InputMaybe<Brokers_Order_By>;
  broker_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item?: InputMaybe<Items_Order_By>;
  itemId?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<RmSellers_Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: purchaseOrderItems */
export type PurchaseOrderItems_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column {
  /** column name */
  BrokerId = 'broker_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  OrderedItemsCount = 'orderedItemsCount',
  /** column name */
  PoId = 'poId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendorId'
}

/** select "purchaseOrderItems_aggregate_bool_exp_avg_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_corr_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_max_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_min_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_sum_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** select "purchaseOrderItems_aggregate_bool_exp_var_samp_arguments_columns" columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Select_Column_PurchaseOrderItems_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  UnitPrice = 'unitPrice'
}

/** input type for updating data in table "purchaseOrderItems" */
export type PurchaseOrderItems_Set_Input = {
  broker_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['numeric']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  orderedItemsCount?: InputMaybe<Scalars['Int']>;
  poId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type PurchaseOrderItems_Stddev_Fields = {
  __typename?: 'purchaseOrderItems_stddev_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Stddev_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PurchaseOrderItems_Stddev_Pop_Fields = {
  __typename?: 'purchaseOrderItems_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Stddev_Pop_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PurchaseOrderItems_Stddev_Samp_Fields = {
  __typename?: 'purchaseOrderItems_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Stddev_Samp_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "purchaseOrderItems" */
export type PurchaseOrderItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PurchaseOrderItems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PurchaseOrderItems_Stream_Cursor_Value_Input = {
  broker_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discount?: InputMaybe<Scalars['numeric']>;
  gstPercent?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  itemId?: InputMaybe<Scalars['uuid']>;
  orderedItemsCount?: InputMaybe<Scalars['Int']>;
  poId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  remarks?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type PurchaseOrderItems_Sum_Fields = {
  __typename?: 'purchaseOrderItems_sum_fields';
  discount?: Maybe<Scalars['numeric']>;
  gstPercent?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  orderedItemsCount?: Maybe<Scalars['Int']>;
  poId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['float8']>;
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Sum_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** update columns of table "purchaseOrderItems" */
export enum PurchaseOrderItems_Update_Column {
  /** column name */
  BrokerId = 'broker_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  GstPercent = 'gstPercent',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'itemId',
  /** column name */
  OrderedItemsCount = 'orderedItemsCount',
  /** column name */
  PoId = 'poId',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendorId'
}

export type PurchaseOrderItems_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PurchaseOrderItems_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PurchaseOrderItems_Set_Input>;
  /** filter the rows which have to be updated */
  where: PurchaseOrderItems_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PurchaseOrderItems_Var_Pop_Fields = {
  __typename?: 'purchaseOrderItems_var_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Var_Pop_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PurchaseOrderItems_Var_Samp_Fields = {
  __typename?: 'purchaseOrderItems_var_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Var_Samp_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type PurchaseOrderItems_Variance_Fields = {
  __typename?: 'purchaseOrderItems_variance_fields';
  discount?: Maybe<Scalars['Float']>;
  gstPercent?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  orderedItemsCount?: Maybe<Scalars['Float']>;
  poId?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "purchaseOrderItems" */
export type PurchaseOrderItems_Variance_Order_By = {
  discount?: InputMaybe<Order_By>;
  gstPercent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  orderedItemsCount?: InputMaybe<Order_By>;
  poId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unitPrice?: InputMaybe<Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** columns and relationships of "purchaseOrders" */
export type PurchaseOrders = {
  __typename?: 'purchaseOrders';
  /** Expiration date of the PO contract */
  contract_validity?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  created_by_contact_no?: Maybe<Scalars['String']>;
  created_by_name?: Maybe<Scalars['String']>;
  delivery_completed_at?: Maybe<Scalars['timestamptz']>;
  /** Discount applicable for po */
  discount?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  /** An array relationship */
  inwardShipments: Array<InwardShipments>;
  /** An aggregate relationship */
  inwardShipments_aggregate: InwardShipments_Aggregate;
  payment_terms?: Maybe<Scalars['String']>;
  poApproved?: Maybe<Scalars['Boolean']>;
  poNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  purchaseOrderItems: Array<PurchaseOrderItems>;
  /** An aggregate relationship */
  purchaseOrderItems_aggregate: PurchaseOrderItems_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "purchaseOrders" */
export type PurchaseOrdersInwardShipmentsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipments_Order_By>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


/** columns and relationships of "purchaseOrders" */
export type PurchaseOrdersInwardShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipments_Order_By>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


/** columns and relationships of "purchaseOrders" */
export type PurchaseOrdersPurchaseOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrderItems_Order_By>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};


/** columns and relationships of "purchaseOrders" */
export type PurchaseOrdersPurchaseOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrderItems_Order_By>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};

/** aggregated selection of "purchaseOrders" */
export type PurchaseOrders_Aggregate = {
  __typename?: 'purchaseOrders_aggregate';
  aggregate?: Maybe<PurchaseOrders_Aggregate_Fields>;
  nodes: Array<PurchaseOrders>;
};

/** aggregate fields of "purchaseOrders" */
export type PurchaseOrders_Aggregate_Fields = {
  __typename?: 'purchaseOrders_aggregate_fields';
  avg?: Maybe<PurchaseOrders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<PurchaseOrders_Max_Fields>;
  min?: Maybe<PurchaseOrders_Min_Fields>;
  stddev?: Maybe<PurchaseOrders_Stddev_Fields>;
  stddev_pop?: Maybe<PurchaseOrders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PurchaseOrders_Stddev_Samp_Fields>;
  sum?: Maybe<PurchaseOrders_Sum_Fields>;
  var_pop?: Maybe<PurchaseOrders_Var_Pop_Fields>;
  var_samp?: Maybe<PurchaseOrders_Var_Samp_Fields>;
  variance?: Maybe<PurchaseOrders_Variance_Fields>;
};


/** aggregate fields of "purchaseOrders" */
export type PurchaseOrders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PurchaseOrders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PurchaseOrders_Avg_Fields = {
  __typename?: 'purchaseOrders_avg_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "purchaseOrders". All fields are combined with a logical 'AND'. */
export type PurchaseOrders_Bool_Exp = {
  _and?: InputMaybe<Array<PurchaseOrders_Bool_Exp>>;
  _not?: InputMaybe<PurchaseOrders_Bool_Exp>;
  _or?: InputMaybe<Array<PurchaseOrders_Bool_Exp>>;
  contract_validity?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_contact_no?: InputMaybe<String_Comparison_Exp>;
  created_by_name?: InputMaybe<String_Comparison_Exp>;
  delivery_completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inwardShipments?: InputMaybe<InwardShipments_Bool_Exp>;
  inwardShipments_aggregate?: InputMaybe<InwardShipments_Aggregate_Bool_Exp>;
  payment_terms?: InputMaybe<String_Comparison_Exp>;
  poApproved?: InputMaybe<Boolean_Comparison_Exp>;
  poNumber?: InputMaybe<String_Comparison_Exp>;
  purchaseOrderItems?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
  purchaseOrderItems_aggregate?: InputMaybe<PurchaseOrderItems_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchaseOrders" */
export enum PurchaseOrders_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseOrdersPkey = 'purchaseOrders_pkey'
}

/** input type for incrementing numeric columns in table "purchaseOrders" */
export type PurchaseOrders_Inc_Input = {
  /** Discount applicable for po */
  discount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "purchaseOrders" */
export type PurchaseOrders_Insert_Input = {
  /** Expiration date of the PO contract */
  contract_validity?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_contact_no?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  delivery_completed_at?: InputMaybe<Scalars['timestamptz']>;
  /** Discount applicable for po */
  discount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  inwardShipments?: InputMaybe<InwardShipments_Arr_Rel_Insert_Input>;
  payment_terms?: InputMaybe<Scalars['String']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  purchaseOrderItems?: InputMaybe<PurchaseOrderItems_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type PurchaseOrders_Max_Fields = {
  __typename?: 'purchaseOrders_max_fields';
  /** Expiration date of the PO contract */
  contract_validity?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_contact_no?: Maybe<Scalars['String']>;
  created_by_name?: Maybe<Scalars['String']>;
  delivery_completed_at?: Maybe<Scalars['timestamptz']>;
  /** Discount applicable for po */
  discount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  payment_terms?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type PurchaseOrders_Min_Fields = {
  __typename?: 'purchaseOrders_min_fields';
  /** Expiration date of the PO contract */
  contract_validity?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_contact_no?: Maybe<Scalars['String']>;
  created_by_name?: Maybe<Scalars['String']>;
  delivery_completed_at?: Maybe<Scalars['timestamptz']>;
  /** Discount applicable for po */
  discount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  payment_terms?: Maybe<Scalars['String']>;
  poNumber?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "purchaseOrders" */
export type PurchaseOrders_Mutation_Response = {
  __typename?: 'purchaseOrders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PurchaseOrders>;
};

/** input type for inserting object relation for remote table "purchaseOrders" */
export type PurchaseOrders_Obj_Rel_Insert_Input = {
  data: PurchaseOrders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<PurchaseOrders_On_Conflict>;
};

/** on_conflict condition type for table "purchaseOrders" */
export type PurchaseOrders_On_Conflict = {
  constraint: PurchaseOrders_Constraint;
  update_columns?: Array<PurchaseOrders_Update_Column>;
  where?: InputMaybe<PurchaseOrders_Bool_Exp>;
};

/** Ordering options when selecting data from "purchaseOrders". */
export type PurchaseOrders_Order_By = {
  contract_validity?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_contact_no?: InputMaybe<Order_By>;
  created_by_name?: InputMaybe<Order_By>;
  delivery_completed_at?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipments_aggregate?: InputMaybe<InwardShipments_Aggregate_Order_By>;
  payment_terms?: InputMaybe<Order_By>;
  poApproved?: InputMaybe<Order_By>;
  poNumber?: InputMaybe<Order_By>;
  purchaseOrderItems_aggregate?: InputMaybe<PurchaseOrderItems_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: purchaseOrders */
export type PurchaseOrders_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "purchaseOrders" */
export enum PurchaseOrders_Select_Column {
  /** column name */
  ContractValidity = 'contract_validity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByContactNo = 'created_by_contact_no',
  /** column name */
  CreatedByName = 'created_by_name',
  /** column name */
  DeliveryCompletedAt = 'delivery_completed_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentTerms = 'payment_terms',
  /** column name */
  PoApproved = 'poApproved',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "purchaseOrders" */
export type PurchaseOrders_Set_Input = {
  /** Expiration date of the PO contract */
  contract_validity?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_contact_no?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  delivery_completed_at?: InputMaybe<Scalars['timestamptz']>;
  /** Discount applicable for po */
  discount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  payment_terms?: InputMaybe<Scalars['String']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type PurchaseOrders_Stddev_Fields = {
  __typename?: 'purchaseOrders_stddev_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PurchaseOrders_Stddev_Pop_Fields = {
  __typename?: 'purchaseOrders_stddev_pop_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PurchaseOrders_Stddev_Samp_Fields = {
  __typename?: 'purchaseOrders_stddev_samp_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "purchaseOrders" */
export type PurchaseOrders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PurchaseOrders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type PurchaseOrders_Stream_Cursor_Value_Input = {
  /** Expiration date of the PO contract */
  contract_validity?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_contact_no?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  delivery_completed_at?: InputMaybe<Scalars['timestamptz']>;
  /** Discount applicable for po */
  discount?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  payment_terms?: InputMaybe<Scalars['String']>;
  poApproved?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type PurchaseOrders_Sum_Fields = {
  __typename?: 'purchaseOrders_sum_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "purchaseOrders" */
export enum PurchaseOrders_Update_Column {
  /** column name */
  ContractValidity = 'contract_validity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByContactNo = 'created_by_contact_no',
  /** column name */
  CreatedByName = 'created_by_name',
  /** column name */
  DeliveryCompletedAt = 'delivery_completed_at',
  /** column name */
  Discount = 'discount',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentTerms = 'payment_terms',
  /** column name */
  PoApproved = 'poApproved',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type PurchaseOrders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PurchaseOrders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PurchaseOrders_Set_Input>;
  /** filter the rows which have to be updated */
  where: PurchaseOrders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type PurchaseOrders_Var_Pop_Fields = {
  __typename?: 'purchaseOrders_var_pop_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PurchaseOrders_Var_Samp_Fields = {
  __typename?: 'purchaseOrders_var_samp_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PurchaseOrders_Variance_Fields = {
  __typename?: 'purchaseOrders_variance_fields';
  /** Discount applicable for po */
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "GodownCurrentState" */
  GodownCurrentState: Array<GodownCurrentState>;
  /** fetch aggregated fields from the table: "GodownCurrentState" */
  GodownCurrentState_aggregate: GodownCurrentState_Aggregate;
  /** fetch data from the table: "MillCurrentState" */
  MillCurrentState: Array<MillCurrentState>;
  /** fetch aggregated fields from the table: "MillCurrentState" */
  MillCurrentState_aggregate: MillCurrentState_Aggregate;
  /** fetch data from the table: "MillSummary" */
  MillSummary: Array<MillSummary>;
  /** fetch aggregated fields from the table: "MillSummary" */
  MillSummary_aggregate: MillSummary_Aggregate;
  /** fetch data from the table: "RmPgBagsSummary" */
  RmPgBagsSummary: Array<RmPgBagsSummary>;
  /** fetch aggregated fields from the table: "RmPgBagsSummary" */
  RmPgBagsSummary_aggregate: RmPgBagsSummary_Aggregate;
  /** fetch data from the table: "brokers" */
  brokers: Array<Brokers>;
  /** fetch aggregated fields from the table: "brokers" */
  brokers_aggregate: Brokers_Aggregate;
  /** fetch data from the table: "brokers" using primary key columns */
  brokers_by_pk?: Maybe<Brokers>;
  /** fetch data from the table: "completed_process_yield" */
  completed_process_yield: Array<Completed_Process_Yield>;
  /** fetch aggregated fields from the table: "completed_process_yield" */
  completed_process_yield_aggregate: Completed_Process_Yield_Aggregate;
  /** fetch data from the table: "counters" */
  counters: Array<Counters>;
  /** fetch aggregated fields from the table: "counters" */
  counters_aggregate: Counters_Aggregate;
  /** fetch data from the table: "counters" using primary key columns */
  counters_by_pk?: Maybe<Counters>;
  ecp13?: Maybe<Ecp13query_Root>;
  /** fetch data from the table: "enum_empty_bag_wt_unit" */
  enum_empty_bag_wt_unit: Array<Enum_Empty_Bag_Wt_Unit>;
  /** fetch aggregated fields from the table: "enum_empty_bag_wt_unit" */
  enum_empty_bag_wt_unit_aggregate: Enum_Empty_Bag_Wt_Unit_Aggregate;
  /** fetch data from the table: "enum_empty_bag_wt_unit" using primary key columns */
  enum_empty_bag_wt_unit_by_pk?: Maybe<Enum_Empty_Bag_Wt_Unit>;
  /** fetch data from the table: "enum_internalTransfer_source_destination" */
  enum_internalTransfer_source_destination: Array<Enum_InternalTransfer_Source_Destination>;
  /** fetch aggregated fields from the table: "enum_internalTransfer_source_destination" */
  enum_internalTransfer_source_destination_aggregate: Enum_InternalTransfer_Source_Destination_Aggregate;
  /** fetch data from the table: "enum_internalTransfer_source_destination" using primary key columns */
  enum_internalTransfer_source_destination_by_pk?: Maybe<Enum_InternalTransfer_Source_Destination>;
  /** fetch data from the table: "enum_inwardShipmentItem_source" */
  enum_inwardShipmentItem_source: Array<Enum_InwardShipmentItem_Source>;
  /** fetch aggregated fields from the table: "enum_inwardShipmentItem_source" */
  enum_inwardShipmentItem_source_aggregate: Enum_InwardShipmentItem_Source_Aggregate;
  /** fetch data from the table: "enum_inwardShipmentItem_source" using primary key columns */
  enum_inwardShipmentItem_source_by_pk?: Maybe<Enum_InwardShipmentItem_Source>;
  /** fetch data from the table: "enum_inward_shipment_status" */
  enum_inward_shipment_status: Array<Enum_Inward_Shipment_Status>;
  /** fetch aggregated fields from the table: "enum_inward_shipment_status" */
  enum_inward_shipment_status_aggregate: Enum_Inward_Shipment_Status_Aggregate;
  /** fetch data from the table: "enum_inward_shipment_status" using primary key columns */
  enum_inward_shipment_status_by_pk?: Maybe<Enum_Inward_Shipment_Status>;
  /** fetch data from the table: "enum_item_category" */
  enum_item_category: Array<Enum_Item_Category>;
  /** fetch aggregated fields from the table: "enum_item_category" */
  enum_item_category_aggregate: Enum_Item_Category_Aggregate;
  /** fetch data from the table: "enum_item_category" using primary key columns */
  enum_item_category_by_pk?: Maybe<Enum_Item_Category>;
  /** fetch data from the table: "enum_labor_charge_type" */
  enum_labor_charge_type: Array<Enum_Labor_Charge_Type>;
  /** fetch aggregated fields from the table: "enum_labor_charge_type" */
  enum_labor_charge_type_aggregate: Enum_Labor_Charge_Type_Aggregate;
  /** fetch data from the table: "enum_labor_charge_type" using primary key columns */
  enum_labor_charge_type_by_pk?: Maybe<Enum_Labor_Charge_Type>;
  /** fetch data from the table: "enum_milling_process_status" */
  enum_milling_process_status: Array<Enum_Milling_Process_Status>;
  /** fetch aggregated fields from the table: "enum_milling_process_status" */
  enum_milling_process_status_aggregate: Enum_Milling_Process_Status_Aggregate;
  /** fetch data from the table: "enum_milling_process_status" using primary key columns */
  enum_milling_process_status_by_pk?: Maybe<Enum_Milling_Process_Status>;
  /** fetch data from the table: "enum_milling_process_type" */
  enum_milling_process_type: Array<Enum_Milling_Process_Type>;
  /** fetch aggregated fields from the table: "enum_milling_process_type" */
  enum_milling_process_type_aggregate: Enum_Milling_Process_Type_Aggregate;
  /** fetch data from the table: "enum_milling_process_type" using primary key columns */
  enum_milling_process_type_by_pk?: Maybe<Enum_Milling_Process_Type>;
  /** fetch data from the table: "enum_outwardShipmentOrder_source" */
  enum_outwardShipmentOrder_source: Array<Enum_OutwardShipmentOrder_Source>;
  /** fetch aggregated fields from the table: "enum_outwardShipmentOrder_source" */
  enum_outwardShipmentOrder_source_aggregate: Enum_OutwardShipmentOrder_Source_Aggregate;
  /** fetch data from the table: "enum_outwardShipmentOrder_source" using primary key columns */
  enum_outwardShipmentOrder_source_by_pk?: Maybe<Enum_OutwardShipmentOrder_Source>;
  /** fetch data from the table: "enum_paddy_grade" */
  enum_paddy_grade: Array<Enum_Paddy_Grade>;
  /** fetch aggregated fields from the table: "enum_paddy_grade" */
  enum_paddy_grade_aggregate: Enum_Paddy_Grade_Aggregate;
  /** fetch data from the table: "enum_paddy_grade" using primary key columns */
  enum_paddy_grade_by_pk?: Maybe<Enum_Paddy_Grade>;
  /** fetch data from the table: "enum_raw_material_type" */
  enum_raw_material_type: Array<Enum_Raw_Material_Type>;
  /** fetch aggregated fields from the table: "enum_raw_material_type" */
  enum_raw_material_type_aggregate: Enum_Raw_Material_Type_Aggregate;
  /** fetch data from the table: "enum_raw_material_type" using primary key columns */
  enum_raw_material_type_by_pk?: Maybe<Enum_Raw_Material_Type>;
  /** fetch data from the table: "enum_setting_value_type" */
  enum_setting_value_type: Array<Enum_Setting_Value_Type>;
  /** fetch aggregated fields from the table: "enum_setting_value_type" */
  enum_setting_value_type_aggregate: Enum_Setting_Value_Type_Aggregate;
  /** fetch data from the table: "enum_setting_value_type" using primary key columns */
  enum_setting_value_type_by_pk?: Maybe<Enum_Setting_Value_Type>;
  /** fetch data from the table: "enum_source_destination" */
  enum_source_destination: Array<Enum_Source_Destination>;
  /** fetch aggregated fields from the table: "enum_source_destination" */
  enum_source_destination_aggregate: Enum_Source_Destination_Aggregate;
  /** fetch data from the table: "enum_source_destination" using primary key columns */
  enum_source_destination_by_pk?: Maybe<Enum_Source_Destination>;
  /** fetch data from the table: "enum_user_role" */
  enum_user_role: Array<Enum_User_Role>;
  /** fetch aggregated fields from the table: "enum_user_role" */
  enum_user_role_aggregate: Enum_User_Role_Aggregate;
  /** fetch data from the table: "enum_user_role" using primary key columns */
  enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  fileUploadS3Url?: Maybe<FileUploadS3UrlOutputType>;
  getFilesUrl?: Maybe<Array<Maybe<GetFilesUrlOutputType>>>;
  getWeighbridgeWeight?: Maybe<GetWeighbridgeWeightOutput>;
  /** fetch data from the table: "godownRawMaterialSummary" */
  godownRawMaterialSummary: Array<GodownRawMaterialSummary>;
  /** fetch aggregated fields from the table: "godownRawMaterialSummary" */
  godownRawMaterialSummary_aggregate: GodownRawMaterialSummary_Aggregate;
  /** fetch data from the table: "godownSummary" */
  godownSummary: Array<GodownSummary>;
  /** fetch aggregated fields from the table: "godownSummary" */
  godownSummary_aggregate: GodownSummary_Aggregate;
  /** fetch data from the table: "godowns" */
  godowns: Array<Godowns>;
  /** fetch aggregated fields from the table: "godowns" */
  godowns_aggregate: Godowns_Aggregate;
  /** fetch data from the table: "godowns" using primary key columns */
  godowns_by_pk?: Maybe<Godowns>;
  /** fetch data from the table: "individual_in_out_for_combined_process" */
  individual_in_out_for_combined_process: Array<Individual_In_Out_For_Combined_Process>;
  /** fetch aggregated fields from the table: "individual_in_out_for_combined_process" */
  individual_in_out_for_combined_process_aggregate: Individual_In_Out_For_Combined_Process_Aggregate;
  /** An array relationship */
  internalTransferShipmentItems: Array<InternalTransferShipmentItems>;
  /** An aggregate relationship */
  internalTransferShipmentItems_aggregate: InternalTransferShipmentItems_Aggregate;
  /** fetch data from the table: "internalTransferShipmentItems" using primary key columns */
  internalTransferShipmentItems_by_pk?: Maybe<InternalTransferShipmentItems>;
  /** fetch data from the table: "internalTransferShipments" */
  internalTransferShipments: Array<InternalTransferShipments>;
  /** fetch aggregated fields from the table: "internalTransferShipments" */
  internalTransferShipments_aggregate: InternalTransferShipments_Aggregate;
  /** fetch data from the table: "internalTransferShipments" using primary key columns */
  internalTransferShipments_by_pk?: Maybe<InternalTransferShipments>;
  /** An array relationship */
  inwardShipmentItems: Array<InwardShipmentItems>;
  /** An aggregate relationship */
  inwardShipmentItems_aggregate: InwardShipmentItems_Aggregate;
  /** fetch data from the table: "inwardShipmentItems" using primary key columns */
  inwardShipmentItems_by_pk?: Maybe<InwardShipmentItems>;
  /** An array relationship */
  inwardShipments: Array<InwardShipments>;
  /** An aggregate relationship */
  inwardShipments_aggregate: InwardShipments_Aggregate;
  /** fetch data from the table: "inwardShipments" using primary key columns */
  inwardShipments_by_pk?: Maybe<InwardShipments>;
  /** fetch data from the table: "items" */
  items: Array<Items>;
  /** fetch aggregated fields from the table: "items" */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** An array relationship */
  millingInItems: Array<MillingInItems>;
  /** An aggregate relationship */
  millingInItems_aggregate: MillingInItems_Aggregate;
  /** fetch data from the table: "millingInItems" using primary key columns */
  millingInItems_by_pk?: Maybe<MillingInItems>;
  /** fetch data from the table: "millingOutItems" */
  millingOutItems: Array<MillingOutItems>;
  /** fetch aggregated fields from the table: "millingOutItems" */
  millingOutItems_aggregate: MillingOutItems_Aggregate;
  /** fetch data from the table: "millingOutItems" using primary key columns */
  millingOutItems_by_pk?: Maybe<MillingOutItems>;
  /** fetch data from the table: "millingProcesses" */
  millingProcesses: Array<MillingProcesses>;
  /** fetch aggregated fields from the table: "millingProcesses" */
  millingProcesses_aggregate: MillingProcesses_Aggregate;
  /** fetch data from the table: "millingProcesses" using primary key columns */
  millingProcesses_by_pk?: Maybe<MillingProcesses>;
  /** fetch data from the table: "mills" */
  mills: Array<Mills>;
  /** fetch aggregated fields from the table: "mills" */
  mills_aggregate: Mills_Aggregate;
  /** fetch data from the table: "mills" using primary key columns */
  mills_by_pk?: Maybe<Mills>;
  /** fetch data from the table: "outwardShipmentOrders" */
  outwardShipmentOrders: Array<OutwardShipmentOrders>;
  /** fetch aggregated fields from the table: "outwardShipmentOrders" */
  outwardShipmentOrders_aggregate: OutwardShipmentOrders_Aggregate;
  /** fetch data from the table: "outwardShipmentOrders" using primary key columns */
  outwardShipmentOrders_by_pk?: Maybe<OutwardShipmentOrders>;
  /** fetch data from the table: "outwardShipments" */
  outwardShipments: Array<OutwardShipments>;
  /** fetch data from the table: "outwardShipmentsDrivers" */
  outwardShipmentsDrivers: Array<OutwardShipmentsDrivers>;
  /** fetch aggregated fields from the table: "outwardShipmentsDrivers" */
  outwardShipmentsDrivers_aggregate: OutwardShipmentsDrivers_Aggregate;
  /** fetch aggregated fields from the table: "outwardShipments" */
  outwardShipments_aggregate: OutwardShipments_Aggregate;
  /** fetch data from the table: "outwardShipments" using primary key columns */
  outwardShipments_by_pk?: Maybe<OutwardShipments>;
  /** fetch data from the table: "paddyBagOrders" */
  paddyBagOrders: Array<PaddyBagOrders>;
  /** fetch aggregated fields from the table: "paddyBagOrders" */
  paddyBagOrders_aggregate: PaddyBagOrders_Aggregate;
  /** fetch data from the table: "paddyBagOrders" using primary key columns */
  paddyBagOrders_by_pk?: Maybe<PaddyBagOrders>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "process_wise_in_out" */
  process_wise_in_out: Array<Process_Wise_In_Out>;
  /** fetch aggregated fields from the table: "process_wise_in_out" */
  process_wise_in_out_aggregate: Process_Wise_In_Out_Aggregate;
  /** fetch data from the table: "productBagOrders" */
  productBagOrders: Array<ProductBagOrders>;
  /** fetch aggregated fields from the table: "productBagOrders" */
  productBagOrders_aggregate: ProductBagOrders_Aggregate;
  /** fetch data from the table: "productBagOrders" using primary key columns */
  productBagOrders_by_pk?: Maybe<ProductBagOrders>;
  /** fetch data from the table: "productBuyers" */
  productBuyers: Array<ProductBuyers>;
  /** fetch aggregated fields from the table: "productBuyers" */
  productBuyers_aggregate: ProductBuyers_Aggregate;
  /** fetch data from the table: "productBuyers" using primary key columns */
  productBuyers_by_pk?: Maybe<ProductBuyers>;
  /** fetch data from the table: "productInventorySummary" */
  productInventorySummary: Array<ProductInventorySummary>;
  /** fetch aggregated fields from the table: "productInventorySummary" */
  productInventorySummary_aggregate: ProductInventorySummary_Aggregate;
  /** fetch data from the table: "productTypes" */
  productTypes: Array<ProductTypes>;
  /** fetch aggregated fields from the table: "productTypes" */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "productTypes" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** An array relationship */
  purchaseOrderItems: Array<PurchaseOrderItems>;
  /** An aggregate relationship */
  purchaseOrderItems_aggregate: PurchaseOrderItems_Aggregate;
  /** fetch data from the table: "purchaseOrderItems" using primary key columns */
  purchaseOrderItems_by_pk?: Maybe<PurchaseOrderItems>;
  /** fetch data from the table: "purchaseOrders" */
  purchaseOrders: Array<PurchaseOrders>;
  /** fetch aggregated fields from the table: "purchaseOrders" */
  purchaseOrders_aggregate: PurchaseOrders_Aggregate;
  /** fetch data from the table: "purchaseOrders" using primary key columns */
  purchaseOrders_by_pk?: Maybe<PurchaseOrders>;
  /** fetch data from the table: "rawMaterials" */
  rawMaterials: Array<RawMaterials>;
  /** An array relationship */
  rawMaterialsProductTypes: Array<RawMaterialsProductTypes>;
  /** An aggregate relationship */
  rawMaterialsProductTypes_aggregate: RawMaterialsProductTypes_Aggregate;
  /** fetch data from the table: "rawMaterialsProductTypes" using primary key columns */
  rawMaterialsProductTypes_by_pk?: Maybe<RawMaterialsProductTypes>;
  /** fetch aggregated fields from the table: "rawMaterials" */
  rawMaterials_aggregate: RawMaterials_Aggregate;
  /** fetch data from the table: "rawMaterials" using primary key columns */
  rawMaterials_by_pk?: Maybe<RawMaterials>;
  /** fetch data from the table: "rmSellers" */
  rmSellers: Array<RmSellers>;
  /** fetch aggregated fields from the table: "rmSellers" */
  rmSellers_aggregate: RmSellers_Aggregate;
  /** fetch data from the table: "rmSellers" using primary key columns */
  rmSellers_by_pk?: Maybe<RmSellers>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** fetch data from the table: "transporterDrivers" */
  transporterDrivers: Array<TransporterDrivers>;
  /** fetch aggregated fields from the table: "transporterDrivers" */
  transporterDrivers_aggregate: TransporterDrivers_Aggregate;
  /** fetch data from the table: "transporterDrivers" using primary key columns */
  transporterDrivers_by_pk?: Maybe<TransporterDrivers>;
  /** fetch data from the table: "transporterVehicles" */
  transporterVehicles: Array<TransporterVehicles>;
  /** fetch aggregated fields from the table: "transporterVehicles" */
  transporterVehicles_aggregate: TransporterVehicles_Aggregate;
  /** fetch data from the table: "transporterVehicles" using primary key columns */
  transporterVehicles_by_pk?: Maybe<TransporterVehicles>;
  /** fetch data from the table: "transporters" */
  transporters: Array<Transporters>;
  /** fetch aggregated fields from the table: "transporters" */
  transporters_aggregate: Transporters_Aggregate;
  /** fetch data from the table: "transporters" using primary key columns */
  transporters_by_pk?: Maybe<Transporters>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  vendorsItemCategories: Array<VendorsItemCategories>;
  /** An aggregate relationship */
  vendorsItemCategories_aggregate: VendorsItemCategories_Aggregate;
  /** fetch data from the table: "vendorsItemCategories" using primary key columns */
  vendorsItemCategories_by_pk?: Maybe<VendorsItemCategories>;
  /** fetch data from the table: "weighbridgeReceipts" */
  weighbridgeReceipts: Array<WeighbridgeReceipts>;
  /** fetch aggregated fields from the table: "weighbridgeReceipts" */
  weighbridgeReceipts_aggregate: WeighbridgeReceipts_Aggregate;
  /** fetch data from the table: "weighbridgeReceipts" using primary key columns */
  weighbridgeReceipts_by_pk?: Maybe<WeighbridgeReceipts>;
};


export type Query_RootGodownCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownCurrentState_Order_By>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


export type Query_RootGodownCurrentState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownCurrentState_Order_By>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


export type Query_RootMillCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<MillCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillCurrentState_Order_By>>;
  where?: InputMaybe<MillCurrentState_Bool_Exp>;
};


export type Query_RootMillCurrentState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillCurrentState_Order_By>>;
  where?: InputMaybe<MillCurrentState_Bool_Exp>;
};


export type Query_RootMillSummaryArgs = {
  distinct_on?: InputMaybe<Array<MillSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillSummary_Order_By>>;
  where?: InputMaybe<MillSummary_Bool_Exp>;
};


export type Query_RootMillSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillSummary_Order_By>>;
  where?: InputMaybe<MillSummary_Bool_Exp>;
};


export type Query_RootRmPgBagsSummaryArgs = {
  distinct_on?: InputMaybe<Array<RmPgBagsSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmPgBagsSummary_Order_By>>;
  where?: InputMaybe<RmPgBagsSummary_Bool_Exp>;
};


export type Query_RootRmPgBagsSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RmPgBagsSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmPgBagsSummary_Order_By>>;
  where?: InputMaybe<RmPgBagsSummary_Bool_Exp>;
};


export type Query_RootBrokersArgs = {
  distinct_on?: InputMaybe<Array<Brokers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brokers_Order_By>>;
  where?: InputMaybe<Brokers_Bool_Exp>;
};


export type Query_RootBrokers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brokers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brokers_Order_By>>;
  where?: InputMaybe<Brokers_Bool_Exp>;
};


export type Query_RootBrokers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompleted_Process_YieldArgs = {
  distinct_on?: InputMaybe<Array<Completed_Process_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Completed_Process_Yield_Order_By>>;
  where?: InputMaybe<Completed_Process_Yield_Bool_Exp>;
};


export type Query_RootCompleted_Process_Yield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Completed_Process_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Completed_Process_Yield_Order_By>>;
  where?: InputMaybe<Completed_Process_Yield_Bool_Exp>;
};


export type Query_RootCountersArgs = {
  distinct_on?: InputMaybe<Array<Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Counters_Order_By>>;
  where?: InputMaybe<Counters_Bool_Exp>;
};


export type Query_RootCounters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Counters_Order_By>>;
  where?: InputMaybe<Counters_Bool_Exp>;
};


export type Query_RootCounters_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEnum_Empty_Bag_Wt_UnitArgs = {
  distinct_on?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Order_By>>;
  where?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
};


export type Query_RootEnum_Empty_Bag_Wt_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Order_By>>;
  where?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
};


export type Query_RootEnum_Empty_Bag_Wt_Unit_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_InternalTransfer_Source_DestinationArgs = {
  distinct_on?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
};


export type Query_RootEnum_InternalTransfer_Source_Destination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
};


export type Query_RootEnum_InternalTransfer_Source_Destination_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_InwardShipmentItem_SourceArgs = {
  distinct_on?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Order_By>>;
  where?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
};


export type Query_RootEnum_InwardShipmentItem_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Order_By>>;
  where?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
};


export type Query_RootEnum_InwardShipmentItem_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Inward_Shipment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Inward_Shipment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Inward_Shipment_Status_Order_By>>;
  where?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
};


export type Query_RootEnum_Inward_Shipment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Inward_Shipment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Inward_Shipment_Status_Order_By>>;
  where?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
};


export type Query_RootEnum_Inward_Shipment_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Item_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Enum_Item_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Item_Category_Order_By>>;
  where?: InputMaybe<Enum_Item_Category_Bool_Exp>;
};


export type Query_RootEnum_Item_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Item_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Item_Category_Order_By>>;
  where?: InputMaybe<Enum_Item_Category_Bool_Exp>;
};


export type Query_RootEnum_Item_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Labor_Charge_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Labor_Charge_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Labor_Charge_Type_Order_By>>;
  where?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
};


export type Query_RootEnum_Labor_Charge_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Labor_Charge_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Labor_Charge_Type_Order_By>>;
  where?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
};


export type Query_RootEnum_Labor_Charge_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Milling_Process_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Status_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
};


export type Query_RootEnum_Milling_Process_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Status_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
};


export type Query_RootEnum_Milling_Process_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Milling_Process_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Type_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
};


export type Query_RootEnum_Milling_Process_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Type_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
};


export type Query_RootEnum_Milling_Process_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_OutwardShipmentOrder_SourceArgs = {
  distinct_on?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Order_By>>;
  where?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
};


export type Query_RootEnum_OutwardShipmentOrder_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Order_By>>;
  where?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
};


export type Query_RootEnum_OutwardShipmentOrder_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Paddy_GradeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Paddy_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Paddy_Grade_Order_By>>;
  where?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
};


export type Query_RootEnum_Paddy_Grade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Paddy_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Paddy_Grade_Order_By>>;
  where?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
};


export type Query_RootEnum_Paddy_Grade_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Raw_Material_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Raw_Material_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Raw_Material_Type_Order_By>>;
  where?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
};


export type Query_RootEnum_Raw_Material_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Raw_Material_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Raw_Material_Type_Order_By>>;
  where?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
};


export type Query_RootEnum_Raw_Material_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Setting_Value_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Value_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
};


export type Query_RootEnum_Setting_Value_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Value_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
};


export type Query_RootEnum_Setting_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Source_DestinationArgs = {
  distinct_on?: InputMaybe<Array<Enum_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
};


export type Query_RootEnum_Source_Destination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
};


export type Query_RootEnum_Source_Destination_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_User_RoleArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Role_Order_By>>;
  where?: InputMaybe<Enum_User_Role_Bool_Exp>;
};


export type Query_RootEnum_User_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Role_Order_By>>;
  where?: InputMaybe<Enum_User_Role_Bool_Exp>;
};


export type Query_RootEnum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFileUploadS3UrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type Query_RootGetFilesUrlArgs = {
  keys: Array<Scalars['String']>;
};


export type Query_RootGodownRawMaterialSummaryArgs = {
  distinct_on?: InputMaybe<Array<GodownRawMaterialSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownRawMaterialSummary_Order_By>>;
  where?: InputMaybe<GodownRawMaterialSummary_Bool_Exp>;
};


export type Query_RootGodownRawMaterialSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownRawMaterialSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownRawMaterialSummary_Order_By>>;
  where?: InputMaybe<GodownRawMaterialSummary_Bool_Exp>;
};


export type Query_RootGodownSummaryArgs = {
  distinct_on?: InputMaybe<Array<GodownSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownSummary_Order_By>>;
  where?: InputMaybe<GodownSummary_Bool_Exp>;
};


export type Query_RootGodownSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownSummary_Order_By>>;
  where?: InputMaybe<GodownSummary_Bool_Exp>;
};


export type Query_RootGodownsArgs = {
  distinct_on?: InputMaybe<Array<Godowns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Godowns_Order_By>>;
  where?: InputMaybe<Godowns_Bool_Exp>;
};


export type Query_RootGodowns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Godowns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Godowns_Order_By>>;
  where?: InputMaybe<Godowns_Bool_Exp>;
};


export type Query_RootGodowns_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootIndividual_In_Out_For_Combined_ProcessArgs = {
  distinct_on?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Order_By>>;
  where?: InputMaybe<Individual_In_Out_For_Combined_Process_Bool_Exp>;
};


export type Query_RootIndividual_In_Out_For_Combined_Process_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Order_By>>;
  where?: InputMaybe<Individual_In_Out_For_Combined_Process_Bool_Exp>;
};


export type Query_RootInternalTransferShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipmentItems_Order_By>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};


export type Query_RootInternalTransferShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipmentItems_Order_By>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};


export type Query_RootInternalTransferShipmentItems_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInternalTransferShipmentsArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipments_Order_By>>;
  where?: InputMaybe<InternalTransferShipments_Bool_Exp>;
};


export type Query_RootInternalTransferShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipments_Order_By>>;
  where?: InputMaybe<InternalTransferShipments_Bool_Exp>;
};


export type Query_RootInternalTransferShipments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInwardShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


export type Query_RootInwardShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


export type Query_RootInwardShipmentItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInwardShipmentsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipments_Order_By>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


export type Query_RootInwardShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipments_Order_By>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


export type Query_RootInwardShipments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Query_RootItems_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMillingInItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


export type Query_RootMillingInItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


export type Query_RootMillingInItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMillingOutItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingOutItems_Order_By>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};


export type Query_RootMillingOutItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingOutItems_Order_By>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};


export type Query_RootMillingOutItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMillingProcessesArgs = {
  distinct_on?: InputMaybe<Array<MillingProcesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingProcesses_Order_By>>;
  where?: InputMaybe<MillingProcesses_Bool_Exp>;
};


export type Query_RootMillingProcesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingProcesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingProcesses_Order_By>>;
  where?: InputMaybe<MillingProcesses_Bool_Exp>;
};


export type Query_RootMillingProcesses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMillsArgs = {
  distinct_on?: InputMaybe<Array<Mills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mills_Order_By>>;
  where?: InputMaybe<Mills_Bool_Exp>;
};


export type Query_RootMills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mills_Order_By>>;
  where?: InputMaybe<Mills_Bool_Exp>;
};


export type Query_RootMills_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutwardShipmentOrdersArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentOrders_Order_By>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};


export type Query_RootOutwardShipmentOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentOrders_Order_By>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};


export type Query_RootOutwardShipmentOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutwardShipmentsArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipments_Order_By>>;
  where?: InputMaybe<OutwardShipments_Bool_Exp>;
};


export type Query_RootOutwardShipmentsDriversArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentsDrivers_Order_By>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


export type Query_RootOutwardShipmentsDrivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentsDrivers_Order_By>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


export type Query_RootOutwardShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipments_Order_By>>;
  where?: InputMaybe<OutwardShipments_Bool_Exp>;
};


export type Query_RootOutwardShipments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPaddyBagOrdersArgs = {
  distinct_on?: InputMaybe<Array<PaddyBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaddyBagOrders_Order_By>>;
  where?: InputMaybe<PaddyBagOrders_Bool_Exp>;
};


export type Query_RootPaddyBagOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaddyBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaddyBagOrders_Order_By>>;
  where?: InputMaybe<PaddyBagOrders_Bool_Exp>;
};


export type Query_RootPaddyBagOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProcess_Wise_In_OutArgs = {
  distinct_on?: InputMaybe<Array<Process_Wise_In_Out_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Process_Wise_In_Out_Order_By>>;
  where?: InputMaybe<Process_Wise_In_Out_Bool_Exp>;
};


export type Query_RootProcess_Wise_In_Out_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Process_Wise_In_Out_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Process_Wise_In_Out_Order_By>>;
  where?: InputMaybe<Process_Wise_In_Out_Bool_Exp>;
};


export type Query_RootProductBagOrdersArgs = {
  distinct_on?: InputMaybe<Array<ProductBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBagOrders_Order_By>>;
  where?: InputMaybe<ProductBagOrders_Bool_Exp>;
};


export type Query_RootProductBagOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBagOrders_Order_By>>;
  where?: InputMaybe<ProductBagOrders_Bool_Exp>;
};


export type Query_RootProductBagOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProductBuyersArgs = {
  distinct_on?: InputMaybe<Array<ProductBuyers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBuyers_Order_By>>;
  where?: InputMaybe<ProductBuyers_Bool_Exp>;
};


export type Query_RootProductBuyers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductBuyers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBuyers_Order_By>>;
  where?: InputMaybe<ProductBuyers_Bool_Exp>;
};


export type Query_RootProductBuyers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProductInventorySummaryArgs = {
  distinct_on?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductInventorySummary_Order_By>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};


export type Query_RootProductInventorySummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductInventorySummary_Order_By>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};


export type Query_RootProductTypesArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Query_RootProductTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPurchaseOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrderItems_Order_By>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};


export type Query_RootPurchaseOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrderItems_Order_By>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};


export type Query_RootPurchaseOrderItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPurchaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrders_Order_By>>;
  where?: InputMaybe<PurchaseOrders_Bool_Exp>;
};


export type Query_RootPurchaseOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrders_Order_By>>;
  where?: InputMaybe<PurchaseOrders_Bool_Exp>;
};


export type Query_RootPurchaseOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRawMaterialsArgs = {
  distinct_on?: InputMaybe<Array<RawMaterials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterials_Order_By>>;
  where?: InputMaybe<RawMaterials_Bool_Exp>;
};


export type Query_RootRawMaterialsProductTypesArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


export type Query_RootRawMaterialsProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


export type Query_RootRawMaterialsProductTypes_By_PkArgs = {
  productTypeId: Scalars['Int'];
  rawMaterialId: Scalars['Int'];
};


export type Query_RootRawMaterials_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RawMaterials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterials_Order_By>>;
  where?: InputMaybe<RawMaterials_Bool_Exp>;
};


export type Query_RootRawMaterials_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRmSellersArgs = {
  distinct_on?: InputMaybe<Array<RmSellers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmSellers_Order_By>>;
  where?: InputMaybe<RmSellers_Bool_Exp>;
};


export type Query_RootRmSellers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RmSellers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmSellers_Order_By>>;
  where?: InputMaybe<RmSellers_Bool_Exp>;
};


export type Query_RootRmSellers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSettingsArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Query_RootSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Query_RootSettings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransporterDriversArgs = {
  distinct_on?: InputMaybe<Array<TransporterDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterDrivers_Order_By>>;
  where?: InputMaybe<TransporterDrivers_Bool_Exp>;
};


export type Query_RootTransporterDrivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TransporterDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterDrivers_Order_By>>;
  where?: InputMaybe<TransporterDrivers_Bool_Exp>;
};


export type Query_RootTransporterDrivers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransporterVehiclesArgs = {
  distinct_on?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterVehicles_Order_By>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};


export type Query_RootTransporterVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterVehicles_Order_By>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};


export type Query_RootTransporterVehicles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTransportersArgs = {
  distinct_on?: InputMaybe<Array<Transporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transporters_Order_By>>;
  where?: InputMaybe<Transporters_Bool_Exp>;
};


export type Query_RootTransporters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transporters_Order_By>>;
  where?: InputMaybe<Transporters_Bool_Exp>;
};


export type Query_RootTransporters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVendorsItemCategoriesArgs = {
  distinct_on?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VendorsItemCategories_Order_By>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};


export type Query_RootVendorsItemCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VendorsItemCategories_Order_By>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};


export type Query_RootVendorsItemCategories_By_PkArgs = {
  itemCategory: Enum_Item_Category_Enum;
  vendorId: Scalars['Int'];
};


export type Query_RootWeighbridgeReceiptsArgs = {
  distinct_on?: InputMaybe<Array<WeighbridgeReceipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WeighbridgeReceipts_Order_By>>;
  where?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
};


export type Query_RootWeighbridgeReceipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WeighbridgeReceipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WeighbridgeReceipts_Order_By>>;
  where?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
};


export type Query_RootWeighbridgeReceipts_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "rawMaterials" */
export type RawMaterials = {
  __typename?: 'rawMaterials';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  inwardShipmentItems: Array<InwardShipmentItems>;
  /** An aggregate relationship */
  inwardShipmentItems_aggregate: InwardShipmentItems_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  rawMaterialsProductTypes: Array<RawMaterialsProductTypes>;
  /** An aggregate relationship */
  rawMaterialsProductTypes_aggregate: RawMaterialsProductTypes_Aggregate;
  type: Enum_Raw_Material_Type_Enum;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "rawMaterials" */
export type RawMaterialsInwardShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "rawMaterials" */
export type RawMaterialsInwardShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "rawMaterials" */
export type RawMaterialsRawMaterialsProductTypesArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


/** columns and relationships of "rawMaterials" */
export type RawMaterialsRawMaterialsProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};

/** columns and relationships of "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes = {
  __typename?: 'rawMaterialsProductTypes';
  /** An object relationship */
  productType: ProductTypes;
  productTypeId: Scalars['Int'];
  /** An object relationship */
  rawMaterial: RawMaterials;
  rawMaterialId: Scalars['Int'];
};

/** aggregated selection of "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Aggregate = {
  __typename?: 'rawMaterialsProductTypes_aggregate';
  aggregate?: Maybe<RawMaterialsProductTypes_Aggregate_Fields>;
  nodes: Array<RawMaterialsProductTypes>;
};

export type RawMaterialsProductTypes_Aggregate_Bool_Exp = {
  count?: InputMaybe<RawMaterialsProductTypes_Aggregate_Bool_Exp_Count>;
};

export type RawMaterialsProductTypes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Aggregate_Fields = {
  __typename?: 'rawMaterialsProductTypes_aggregate_fields';
  avg?: Maybe<RawMaterialsProductTypes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RawMaterialsProductTypes_Max_Fields>;
  min?: Maybe<RawMaterialsProductTypes_Min_Fields>;
  stddev?: Maybe<RawMaterialsProductTypes_Stddev_Fields>;
  stddev_pop?: Maybe<RawMaterialsProductTypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RawMaterialsProductTypes_Stddev_Samp_Fields>;
  sum?: Maybe<RawMaterialsProductTypes_Sum_Fields>;
  var_pop?: Maybe<RawMaterialsProductTypes_Var_Pop_Fields>;
  var_samp?: Maybe<RawMaterialsProductTypes_Var_Samp_Fields>;
  variance?: Maybe<RawMaterialsProductTypes_Variance_Fields>;
};


/** aggregate fields of "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Aggregate_Order_By = {
  avg?: InputMaybe<RawMaterialsProductTypes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<RawMaterialsProductTypes_Max_Order_By>;
  min?: InputMaybe<RawMaterialsProductTypes_Min_Order_By>;
  stddev?: InputMaybe<RawMaterialsProductTypes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<RawMaterialsProductTypes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<RawMaterialsProductTypes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<RawMaterialsProductTypes_Sum_Order_By>;
  var_pop?: InputMaybe<RawMaterialsProductTypes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<RawMaterialsProductTypes_Var_Samp_Order_By>;
  variance?: InputMaybe<RawMaterialsProductTypes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Arr_Rel_Insert_Input = {
  data: Array<RawMaterialsProductTypes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<RawMaterialsProductTypes_On_Conflict>;
};

/** aggregate avg on columns */
export type RawMaterialsProductTypes_Avg_Fields = {
  __typename?: 'rawMaterialsProductTypes_avg_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Avg_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rawMaterialsProductTypes". All fields are combined with a logical 'AND'. */
export type RawMaterialsProductTypes_Bool_Exp = {
  _and?: InputMaybe<Array<RawMaterialsProductTypes_Bool_Exp>>;
  _not?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
  _or?: InputMaybe<Array<RawMaterialsProductTypes_Bool_Exp>>;
  productType?: InputMaybe<ProductTypes_Bool_Exp>;
  productTypeId?: InputMaybe<Int_Comparison_Exp>;
  rawMaterial?: InputMaybe<RawMaterials_Bool_Exp>;
  rawMaterialId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "rawMaterialsProductTypes" */
export enum RawMaterialsProductTypes_Constraint {
  /** unique or primary key constraint on columns "productTypeId", "rawMaterialId" */
  RawMaterialsProductTypesPkey = 'rawMaterialsProductTypes_pkey'
}

/** input type for incrementing numeric columns in table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Inc_Input = {
  productTypeId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Insert_Input = {
  productType?: InputMaybe<ProductTypes_Obj_Rel_Insert_Input>;
  productTypeId?: InputMaybe<Scalars['Int']>;
  rawMaterial?: InputMaybe<RawMaterials_Obj_Rel_Insert_Input>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type RawMaterialsProductTypes_Max_Fields = {
  __typename?: 'rawMaterialsProductTypes_max_fields';
  productTypeId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Max_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type RawMaterialsProductTypes_Min_Fields = {
  __typename?: 'rawMaterialsProductTypes_min_fields';
  productTypeId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Min_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Mutation_Response = {
  __typename?: 'rawMaterialsProductTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RawMaterialsProductTypes>;
};

/** on_conflict condition type for table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_On_Conflict = {
  constraint: RawMaterialsProductTypes_Constraint;
  update_columns?: Array<RawMaterialsProductTypes_Update_Column>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "rawMaterialsProductTypes". */
export type RawMaterialsProductTypes_Order_By = {
  productType?: InputMaybe<ProductTypes_Order_By>;
  productTypeId?: InputMaybe<Order_By>;
  rawMaterial?: InputMaybe<RawMaterials_Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rawMaterialsProductTypes */
export type RawMaterialsProductTypes_Pk_Columns_Input = {
  productTypeId: Scalars['Int'];
  rawMaterialId: Scalars['Int'];
};

/** select columns of table "rawMaterialsProductTypes" */
export enum RawMaterialsProductTypes_Select_Column {
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  RawMaterialId = 'rawMaterialId'
}

/** input type for updating data in table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Set_Input = {
  productTypeId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type RawMaterialsProductTypes_Stddev_Fields = {
  __typename?: 'rawMaterialsProductTypes_stddev_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Stddev_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type RawMaterialsProductTypes_Stddev_Pop_Fields = {
  __typename?: 'rawMaterialsProductTypes_stddev_pop_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Stddev_Pop_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type RawMaterialsProductTypes_Stddev_Samp_Fields = {
  __typename?: 'rawMaterialsProductTypes_stddev_samp_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Stddev_Samp_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RawMaterialsProductTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RawMaterialsProductTypes_Stream_Cursor_Value_Input = {
  productTypeId?: InputMaybe<Scalars['Int']>;
  rawMaterialId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type RawMaterialsProductTypes_Sum_Fields = {
  __typename?: 'rawMaterialsProductTypes_sum_fields';
  productTypeId?: Maybe<Scalars['Int']>;
  rawMaterialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Sum_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** update columns of table "rawMaterialsProductTypes" */
export enum RawMaterialsProductTypes_Update_Column {
  /** column name */
  ProductTypeId = 'productTypeId',
  /** column name */
  RawMaterialId = 'rawMaterialId'
}

export type RawMaterialsProductTypes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RawMaterialsProductTypes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RawMaterialsProductTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: RawMaterialsProductTypes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RawMaterialsProductTypes_Var_Pop_Fields = {
  __typename?: 'rawMaterialsProductTypes_var_pop_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Var_Pop_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type RawMaterialsProductTypes_Var_Samp_Fields = {
  __typename?: 'rawMaterialsProductTypes_var_samp_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Var_Samp_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type RawMaterialsProductTypes_Variance_Fields = {
  __typename?: 'rawMaterialsProductTypes_variance_fields';
  productTypeId?: Maybe<Scalars['Float']>;
  rawMaterialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rawMaterialsProductTypes" */
export type RawMaterialsProductTypes_Variance_Order_By = {
  productTypeId?: InputMaybe<Order_By>;
  rawMaterialId?: InputMaybe<Order_By>;
};

/** aggregated selection of "rawMaterials" */
export type RawMaterials_Aggregate = {
  __typename?: 'rawMaterials_aggregate';
  aggregate?: Maybe<RawMaterials_Aggregate_Fields>;
  nodes: Array<RawMaterials>;
};

/** aggregate fields of "rawMaterials" */
export type RawMaterials_Aggregate_Fields = {
  __typename?: 'rawMaterials_aggregate_fields';
  avg?: Maybe<RawMaterials_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RawMaterials_Max_Fields>;
  min?: Maybe<RawMaterials_Min_Fields>;
  stddev?: Maybe<RawMaterials_Stddev_Fields>;
  stddev_pop?: Maybe<RawMaterials_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RawMaterials_Stddev_Samp_Fields>;
  sum?: Maybe<RawMaterials_Sum_Fields>;
  var_pop?: Maybe<RawMaterials_Var_Pop_Fields>;
  var_samp?: Maybe<RawMaterials_Var_Samp_Fields>;
  variance?: Maybe<RawMaterials_Variance_Fields>;
};


/** aggregate fields of "rawMaterials" */
export type RawMaterials_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RawMaterials_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RawMaterials_Avg_Fields = {
  __typename?: 'rawMaterials_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rawMaterials". All fields are combined with a logical 'AND'. */
export type RawMaterials_Bool_Exp = {
  _and?: InputMaybe<Array<RawMaterials_Bool_Exp>>;
  _not?: InputMaybe<RawMaterials_Bool_Exp>;
  _or?: InputMaybe<Array<RawMaterials_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inwardShipmentItems?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  inwardShipmentItems_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rawMaterialsProductTypes?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
  rawMaterialsProductTypes_aggregate?: InputMaybe<RawMaterialsProductTypes_Aggregate_Bool_Exp>;
  type?: InputMaybe<Enum_Raw_Material_Type_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rawMaterials" */
export enum RawMaterials_Constraint {
  /** unique or primary key constraint on columns "name" */
  RawMaterialsNameKey = 'rawMaterials_name_key',
  /** unique or primary key constraint on columns "id" */
  RawMaterialsPkey = 'rawMaterials_pkey'
}

/** input type for incrementing numeric columns in table "rawMaterials" */
export type RawMaterials_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rawMaterials" */
export type RawMaterials_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  inwardShipmentItems?: InputMaybe<InwardShipmentItems_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  rawMaterialsProductTypes?: InputMaybe<RawMaterialsProductTypes_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Enum_Raw_Material_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RawMaterials_Max_Fields = {
  __typename?: 'rawMaterials_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type RawMaterials_Min_Fields = {
  __typename?: 'rawMaterials_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "rawMaterials" */
export type RawMaterials_Mutation_Response = {
  __typename?: 'rawMaterials_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RawMaterials>;
};

/** input type for inserting object relation for remote table "rawMaterials" */
export type RawMaterials_Obj_Rel_Insert_Input = {
  data: RawMaterials_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RawMaterials_On_Conflict>;
};

/** on_conflict condition type for table "rawMaterials" */
export type RawMaterials_On_Conflict = {
  constraint: RawMaterials_Constraint;
  update_columns?: Array<RawMaterials_Update_Column>;
  where?: InputMaybe<RawMaterials_Bool_Exp>;
};

/** Ordering options when selecting data from "rawMaterials". */
export type RawMaterials_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentItems_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  rawMaterialsProductTypes_aggregate?: InputMaybe<RawMaterialsProductTypes_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rawMaterials */
export type RawMaterials_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "rawMaterials" */
export enum RawMaterials_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "rawMaterials" */
export type RawMaterials_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Raw_Material_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RawMaterials_Stddev_Fields = {
  __typename?: 'rawMaterials_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RawMaterials_Stddev_Pop_Fields = {
  __typename?: 'rawMaterials_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RawMaterials_Stddev_Samp_Fields = {
  __typename?: 'rawMaterials_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rawMaterials" */
export type RawMaterials_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RawMaterials_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RawMaterials_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Raw_Material_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type RawMaterials_Sum_Fields = {
  __typename?: 'rawMaterials_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "rawMaterials" */
export enum RawMaterials_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type RawMaterials_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RawMaterials_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RawMaterials_Set_Input>;
  /** filter the rows which have to be updated */
  where: RawMaterials_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RawMaterials_Var_Pop_Fields = {
  __typename?: 'rawMaterials_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RawMaterials_Var_Samp_Fields = {
  __typename?: 'rawMaterials_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RawMaterials_Variance_Fields = {
  __typename?: 'rawMaterials_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rmSellers" */
export type RmSellers = {
  __typename?: 'rmSellers';
  address?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  doesMaintenance?: Maybe<Scalars['Boolean']>;
  gstin?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  inwardShipmentItems: Array<InwardShipmentItems>;
  /** An aggregate relationship */
  inwardShipmentItems_aggregate: InwardShipmentItems_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  profileCompleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  vendorsItemCategories: Array<VendorsItemCategories>;
  /** An aggregate relationship */
  vendorsItemCategories_aggregate: VendorsItemCategories_Aggregate;
};


/** columns and relationships of "rmSellers" */
export type RmSellersAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "rmSellers" */
export type RmSellersInwardShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "rmSellers" */
export type RmSellersInwardShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


/** columns and relationships of "rmSellers" */
export type RmSellersPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


/** columns and relationships of "rmSellers" */
export type RmSellersPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


/** columns and relationships of "rmSellers" */
export type RmSellersVendorsItemCategoriesArgs = {
  distinct_on?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VendorsItemCategories_Order_By>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};


/** columns and relationships of "rmSellers" */
export type RmSellersVendorsItemCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VendorsItemCategories_Order_By>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};

/** aggregated selection of "rmSellers" */
export type RmSellers_Aggregate = {
  __typename?: 'rmSellers_aggregate';
  aggregate?: Maybe<RmSellers_Aggregate_Fields>;
  nodes: Array<RmSellers>;
};

/** aggregate fields of "rmSellers" */
export type RmSellers_Aggregate_Fields = {
  __typename?: 'rmSellers_aggregate_fields';
  avg?: Maybe<RmSellers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<RmSellers_Max_Fields>;
  min?: Maybe<RmSellers_Min_Fields>;
  stddev?: Maybe<RmSellers_Stddev_Fields>;
  stddev_pop?: Maybe<RmSellers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<RmSellers_Stddev_Samp_Fields>;
  sum?: Maybe<RmSellers_Sum_Fields>;
  var_pop?: Maybe<RmSellers_Var_Pop_Fields>;
  var_samp?: Maybe<RmSellers_Var_Samp_Fields>;
  variance?: Maybe<RmSellers_Variance_Fields>;
};


/** aggregate fields of "rmSellers" */
export type RmSellers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<RmSellers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RmSellers_Avg_Fields = {
  __typename?: 'rmSellers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "rmSellers". All fields are combined with a logical 'AND'. */
export type RmSellers_Bool_Exp = {
  _and?: InputMaybe<Array<RmSellers_Bool_Exp>>;
  _not?: InputMaybe<RmSellers_Bool_Exp>;
  _or?: InputMaybe<Array<RmSellers_Bool_Exp>>;
  address?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  doesMaintenance?: InputMaybe<Boolean_Comparison_Exp>;
  gstin?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inwardShipmentItems?: InputMaybe<InwardShipmentItems_Bool_Exp>;
  inwardShipmentItems_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payments?: InputMaybe<Payment_Bool_Exp>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>;
  primaryContactEmail?: InputMaybe<String_Comparison_Exp>;
  primaryContactMobile?: InputMaybe<String_Comparison_Exp>;
  primaryContactName?: InputMaybe<String_Comparison_Exp>;
  profileCompleted?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendorsItemCategories?: InputMaybe<VendorsItemCategories_Bool_Exp>;
  vendorsItemCategories_aggregate?: InputMaybe<VendorsItemCategories_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "rmSellers" */
export enum RmSellers_Constraint {
  /** unique or primary key constraint on columns "gstin" */
  RmSellersGstinKey = 'rmSellers_gstin_key',
  /** unique or primary key constraint on columns "id" */
  RmSellersPkey = 'rmSellers_pkey'
}

/** input type for incrementing numeric columns in table "rmSellers" */
export type RmSellers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "rmSellers" */
export type RmSellers_Insert_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  doesMaintenance?: InputMaybe<Scalars['Boolean']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inwardShipmentItems?: InputMaybe<InwardShipmentItems_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vendorsItemCategories?: InputMaybe<VendorsItemCategories_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type RmSellers_Max_Fields = {
  __typename?: 'rmSellers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type RmSellers_Min_Fields = {
  __typename?: 'rmSellers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "rmSellers" */
export type RmSellers_Mutation_Response = {
  __typename?: 'rmSellers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RmSellers>;
};

/** input type for inserting object relation for remote table "rmSellers" */
export type RmSellers_Obj_Rel_Insert_Input = {
  data: RmSellers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<RmSellers_On_Conflict>;
};

/** on_conflict condition type for table "rmSellers" */
export type RmSellers_On_Conflict = {
  constraint: RmSellers_Constraint;
  update_columns?: Array<RmSellers_Update_Column>;
  where?: InputMaybe<RmSellers_Bool_Exp>;
};

/** Ordering options when selecting data from "rmSellers". */
export type RmSellers_Order_By = {
  address?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  doesMaintenance?: InputMaybe<Order_By>;
  gstin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inwardShipmentItems_aggregate?: InputMaybe<InwardShipmentItems_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>;
  primaryContactEmail?: InputMaybe<Order_By>;
  primaryContactMobile?: InputMaybe<Order_By>;
  primaryContactName?: InputMaybe<Order_By>;
  profileCompleted?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vendorsItemCategories_aggregate?: InputMaybe<VendorsItemCategories_Aggregate_Order_By>;
};

/** primary key columns input for table: rmSellers */
export type RmSellers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "rmSellers" */
export enum RmSellers_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DoesMaintenance = 'doesMaintenance',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryContactEmail = 'primaryContactEmail',
  /** column name */
  PrimaryContactMobile = 'primaryContactMobile',
  /** column name */
  PrimaryContactName = 'primaryContactName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "rmSellers" */
export type RmSellers_Set_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  doesMaintenance?: InputMaybe<Scalars['Boolean']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type RmSellers_Stddev_Fields = {
  __typename?: 'rmSellers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RmSellers_Stddev_Pop_Fields = {
  __typename?: 'rmSellers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RmSellers_Stddev_Samp_Fields = {
  __typename?: 'rmSellers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "rmSellers" */
export type RmSellers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: RmSellers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type RmSellers_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  doesMaintenance?: InputMaybe<Scalars['Boolean']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type RmSellers_Sum_Fields = {
  __typename?: 'rmSellers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "rmSellers" */
export enum RmSellers_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DoesMaintenance = 'doesMaintenance',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryContactEmail = 'primaryContactEmail',
  /** column name */
  PrimaryContactMobile = 'primaryContactMobile',
  /** column name */
  PrimaryContactName = 'primaryContactName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type RmSellers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RmSellers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RmSellers_Set_Input>;
  /** filter the rows which have to be updated */
  where: RmSellers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type RmSellers_Var_Pop_Fields = {
  __typename?: 'rmSellers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RmSellers_Var_Samp_Fields = {
  __typename?: 'rmSellers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RmSellers_Variance_Fields = {
  __typename?: 'rmSellers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "settings" */
export type Settings = {
  __typename?: 'settings';
  arrayStringValue?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateValue?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  floatValue?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  intValue?: Maybe<Scalars['Int']>;
  jsonValue?: Maybe<Scalars['json']>;
  name: Scalars['String'];
  stringValue?: Maybe<Scalars['String']>;
  timestampValue?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  valueType: Enum_Setting_Value_Type_Enum;
};


/** columns and relationships of "settings" */
export type SettingsJsonValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "settings" */
export type Settings_Aggregate = {
  __typename?: 'settings_aggregate';
  aggregate?: Maybe<Settings_Aggregate_Fields>;
  nodes: Array<Settings>;
};

/** aggregate fields of "settings" */
export type Settings_Aggregate_Fields = {
  __typename?: 'settings_aggregate_fields';
  avg?: Maybe<Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Settings_Max_Fields>;
  min?: Maybe<Settings_Min_Fields>;
  stddev?: Maybe<Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Settings_Sum_Fields>;
  var_pop?: Maybe<Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Settings_Var_Samp_Fields>;
  variance?: Maybe<Settings_Variance_Fields>;
};


/** aggregate fields of "settings" */
export type Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Settings_Avg_Fields = {
  __typename?: 'settings_avg_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "settings". All fields are combined with a logical 'AND'. */
export type Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Settings_Bool_Exp>>;
  _not?: InputMaybe<Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Settings_Bool_Exp>>;
  arrayStringValue?: InputMaybe<String_Array_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dateValue?: InputMaybe<Date_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  floatValue?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  intValue?: InputMaybe<Int_Comparison_Exp>;
  jsonValue?: InputMaybe<Json_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  stringValue?: InputMaybe<String_Comparison_Exp>;
  timestampValue?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  valueType?: InputMaybe<Enum_Setting_Value_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "settings" */
export enum Settings_Constraint {
  /** unique or primary key constraint on columns "name" */
  SettingsNameKey = 'settings_name_key',
  /** unique or primary key constraint on columns "id" */
  SettingsPkey = 'settings_pkey'
}

/** input type for incrementing numeric columns in table "settings" */
export type Settings_Inc_Input = {
  floatValue?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  intValue?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "settings" */
export type Settings_Insert_Input = {
  arrayStringValue?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateValue?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  floatValue?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  intValue?: InputMaybe<Scalars['Int']>;
  jsonValue?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  stringValue?: InputMaybe<Scalars['String']>;
  timestampValue?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valueType?: InputMaybe<Enum_Setting_Value_Type_Enum>;
};

/** aggregate max on columns */
export type Settings_Max_Fields = {
  __typename?: 'settings_max_fields';
  arrayStringValue?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateValue?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  floatValue?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  intValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stringValue?: Maybe<Scalars['String']>;
  timestampValue?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Settings_Min_Fields = {
  __typename?: 'settings_min_fields';
  arrayStringValue?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateValue?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  floatValue?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  intValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stringValue?: Maybe<Scalars['String']>;
  timestampValue?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "settings" */
export type Settings_Mutation_Response = {
  __typename?: 'settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Settings>;
};

/** on_conflict condition type for table "settings" */
export type Settings_On_Conflict = {
  constraint: Settings_Constraint;
  update_columns?: Array<Settings_Update_Column>;
  where?: InputMaybe<Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "settings". */
export type Settings_Order_By = {
  arrayStringValue?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateValue?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  floatValue?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intValue?: InputMaybe<Order_By>;
  jsonValue?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  stringValue?: InputMaybe<Order_By>;
  timestampValue?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  valueType?: InputMaybe<Order_By>;
};

/** primary key columns input for table: settings */
export type Settings_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "settings" */
export enum Settings_Select_Column {
  /** column name */
  ArrayStringValue = 'arrayStringValue',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateValue = 'dateValue',
  /** column name */
  Description = 'description',
  /** column name */
  FloatValue = 'floatValue',
  /** column name */
  Id = 'id',
  /** column name */
  IntValue = 'intValue',
  /** column name */
  JsonValue = 'jsonValue',
  /** column name */
  Name = 'name',
  /** column name */
  StringValue = 'stringValue',
  /** column name */
  TimestampValue = 'timestampValue',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValueType = 'valueType'
}

/** input type for updating data in table "settings" */
export type Settings_Set_Input = {
  arrayStringValue?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateValue?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  floatValue?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  intValue?: InputMaybe<Scalars['Int']>;
  jsonValue?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  stringValue?: InputMaybe<Scalars['String']>;
  timestampValue?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valueType?: InputMaybe<Enum_Setting_Value_Type_Enum>;
};

/** aggregate stddev on columns */
export type Settings_Stddev_Fields = {
  __typename?: 'settings_stddev_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Settings_Stddev_Pop_Fields = {
  __typename?: 'settings_stddev_pop_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Settings_Stddev_Samp_Fields = {
  __typename?: 'settings_stddev_samp_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "settings" */
export type Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Settings_Stream_Cursor_Value_Input = {
  arrayStringValue?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateValue?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  floatValue?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  intValue?: InputMaybe<Scalars['Int']>;
  jsonValue?: InputMaybe<Scalars['json']>;
  name?: InputMaybe<Scalars['String']>;
  stringValue?: InputMaybe<Scalars['String']>;
  timestampValue?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  valueType?: InputMaybe<Enum_Setting_Value_Type_Enum>;
};

/** aggregate sum on columns */
export type Settings_Sum_Fields = {
  __typename?: 'settings_sum_fields';
  floatValue?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  intValue?: Maybe<Scalars['Int']>;
};

/** update columns of table "settings" */
export enum Settings_Update_Column {
  /** column name */
  ArrayStringValue = 'arrayStringValue',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateValue = 'dateValue',
  /** column name */
  Description = 'description',
  /** column name */
  FloatValue = 'floatValue',
  /** column name */
  Id = 'id',
  /** column name */
  IntValue = 'intValue',
  /** column name */
  JsonValue = 'jsonValue',
  /** column name */
  Name = 'name',
  /** column name */
  StringValue = 'stringValue',
  /** column name */
  TimestampValue = 'timestampValue',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ValueType = 'valueType'
}

export type Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Settings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Settings_Var_Pop_Fields = {
  __typename?: 'settings_var_pop_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Settings_Var_Samp_Fields = {
  __typename?: 'settings_var_samp_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Settings_Variance_Fields = {
  __typename?: 'settings_variance_fields';
  floatValue?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "GodownCurrentState" */
  GodownCurrentState: Array<GodownCurrentState>;
  /** fetch aggregated fields from the table: "GodownCurrentState" */
  GodownCurrentState_aggregate: GodownCurrentState_Aggregate;
  /** fetch data from the table in a streaming manner: "GodownCurrentState" */
  GodownCurrentState_stream: Array<GodownCurrentState>;
  /** fetch data from the table: "MillCurrentState" */
  MillCurrentState: Array<MillCurrentState>;
  /** fetch aggregated fields from the table: "MillCurrentState" */
  MillCurrentState_aggregate: MillCurrentState_Aggregate;
  /** fetch data from the table in a streaming manner: "MillCurrentState" */
  MillCurrentState_stream: Array<MillCurrentState>;
  /** fetch data from the table: "MillSummary" */
  MillSummary: Array<MillSummary>;
  /** fetch aggregated fields from the table: "MillSummary" */
  MillSummary_aggregate: MillSummary_Aggregate;
  /** fetch data from the table in a streaming manner: "MillSummary" */
  MillSummary_stream: Array<MillSummary>;
  /** fetch data from the table: "RmPgBagsSummary" */
  RmPgBagsSummary: Array<RmPgBagsSummary>;
  /** fetch aggregated fields from the table: "RmPgBagsSummary" */
  RmPgBagsSummary_aggregate: RmPgBagsSummary_Aggregate;
  /** fetch data from the table in a streaming manner: "RmPgBagsSummary" */
  RmPgBagsSummary_stream: Array<RmPgBagsSummary>;
  /** fetch data from the table: "brokers" */
  brokers: Array<Brokers>;
  /** fetch aggregated fields from the table: "brokers" */
  brokers_aggregate: Brokers_Aggregate;
  /** fetch data from the table: "brokers" using primary key columns */
  brokers_by_pk?: Maybe<Brokers>;
  /** fetch data from the table in a streaming manner: "brokers" */
  brokers_stream: Array<Brokers>;
  /** fetch data from the table: "completed_process_yield" */
  completed_process_yield: Array<Completed_Process_Yield>;
  /** fetch aggregated fields from the table: "completed_process_yield" */
  completed_process_yield_aggregate: Completed_Process_Yield_Aggregate;
  /** fetch data from the table in a streaming manner: "completed_process_yield" */
  completed_process_yield_stream: Array<Completed_Process_Yield>;
  /** fetch data from the table: "counters" */
  counters: Array<Counters>;
  /** fetch aggregated fields from the table: "counters" */
  counters_aggregate: Counters_Aggregate;
  /** fetch data from the table: "counters" using primary key columns */
  counters_by_pk?: Maybe<Counters>;
  /** fetch data from the table in a streaming manner: "counters" */
  counters_stream: Array<Counters>;
  ecp13?: Maybe<Ecp13subscription_Root>;
  /** fetch data from the table: "enum_empty_bag_wt_unit" */
  enum_empty_bag_wt_unit: Array<Enum_Empty_Bag_Wt_Unit>;
  /** fetch aggregated fields from the table: "enum_empty_bag_wt_unit" */
  enum_empty_bag_wt_unit_aggregate: Enum_Empty_Bag_Wt_Unit_Aggregate;
  /** fetch data from the table: "enum_empty_bag_wt_unit" using primary key columns */
  enum_empty_bag_wt_unit_by_pk?: Maybe<Enum_Empty_Bag_Wt_Unit>;
  /** fetch data from the table in a streaming manner: "enum_empty_bag_wt_unit" */
  enum_empty_bag_wt_unit_stream: Array<Enum_Empty_Bag_Wt_Unit>;
  /** fetch data from the table: "enum_internalTransfer_source_destination" */
  enum_internalTransfer_source_destination: Array<Enum_InternalTransfer_Source_Destination>;
  /** fetch aggregated fields from the table: "enum_internalTransfer_source_destination" */
  enum_internalTransfer_source_destination_aggregate: Enum_InternalTransfer_Source_Destination_Aggregate;
  /** fetch data from the table: "enum_internalTransfer_source_destination" using primary key columns */
  enum_internalTransfer_source_destination_by_pk?: Maybe<Enum_InternalTransfer_Source_Destination>;
  /** fetch data from the table in a streaming manner: "enum_internalTransfer_source_destination" */
  enum_internalTransfer_source_destination_stream: Array<Enum_InternalTransfer_Source_Destination>;
  /** fetch data from the table: "enum_inwardShipmentItem_source" */
  enum_inwardShipmentItem_source: Array<Enum_InwardShipmentItem_Source>;
  /** fetch aggregated fields from the table: "enum_inwardShipmentItem_source" */
  enum_inwardShipmentItem_source_aggregate: Enum_InwardShipmentItem_Source_Aggregate;
  /** fetch data from the table: "enum_inwardShipmentItem_source" using primary key columns */
  enum_inwardShipmentItem_source_by_pk?: Maybe<Enum_InwardShipmentItem_Source>;
  /** fetch data from the table in a streaming manner: "enum_inwardShipmentItem_source" */
  enum_inwardShipmentItem_source_stream: Array<Enum_InwardShipmentItem_Source>;
  /** fetch data from the table: "enum_inward_shipment_status" */
  enum_inward_shipment_status: Array<Enum_Inward_Shipment_Status>;
  /** fetch aggregated fields from the table: "enum_inward_shipment_status" */
  enum_inward_shipment_status_aggregate: Enum_Inward_Shipment_Status_Aggregate;
  /** fetch data from the table: "enum_inward_shipment_status" using primary key columns */
  enum_inward_shipment_status_by_pk?: Maybe<Enum_Inward_Shipment_Status>;
  /** fetch data from the table in a streaming manner: "enum_inward_shipment_status" */
  enum_inward_shipment_status_stream: Array<Enum_Inward_Shipment_Status>;
  /** fetch data from the table: "enum_item_category" */
  enum_item_category: Array<Enum_Item_Category>;
  /** fetch aggregated fields from the table: "enum_item_category" */
  enum_item_category_aggregate: Enum_Item_Category_Aggregate;
  /** fetch data from the table: "enum_item_category" using primary key columns */
  enum_item_category_by_pk?: Maybe<Enum_Item_Category>;
  /** fetch data from the table in a streaming manner: "enum_item_category" */
  enum_item_category_stream: Array<Enum_Item_Category>;
  /** fetch data from the table: "enum_labor_charge_type" */
  enum_labor_charge_type: Array<Enum_Labor_Charge_Type>;
  /** fetch aggregated fields from the table: "enum_labor_charge_type" */
  enum_labor_charge_type_aggregate: Enum_Labor_Charge_Type_Aggregate;
  /** fetch data from the table: "enum_labor_charge_type" using primary key columns */
  enum_labor_charge_type_by_pk?: Maybe<Enum_Labor_Charge_Type>;
  /** fetch data from the table in a streaming manner: "enum_labor_charge_type" */
  enum_labor_charge_type_stream: Array<Enum_Labor_Charge_Type>;
  /** fetch data from the table: "enum_milling_process_status" */
  enum_milling_process_status: Array<Enum_Milling_Process_Status>;
  /** fetch aggregated fields from the table: "enum_milling_process_status" */
  enum_milling_process_status_aggregate: Enum_Milling_Process_Status_Aggregate;
  /** fetch data from the table: "enum_milling_process_status" using primary key columns */
  enum_milling_process_status_by_pk?: Maybe<Enum_Milling_Process_Status>;
  /** fetch data from the table in a streaming manner: "enum_milling_process_status" */
  enum_milling_process_status_stream: Array<Enum_Milling_Process_Status>;
  /** fetch data from the table: "enum_milling_process_type" */
  enum_milling_process_type: Array<Enum_Milling_Process_Type>;
  /** fetch aggregated fields from the table: "enum_milling_process_type" */
  enum_milling_process_type_aggregate: Enum_Milling_Process_Type_Aggregate;
  /** fetch data from the table: "enum_milling_process_type" using primary key columns */
  enum_milling_process_type_by_pk?: Maybe<Enum_Milling_Process_Type>;
  /** fetch data from the table in a streaming manner: "enum_milling_process_type" */
  enum_milling_process_type_stream: Array<Enum_Milling_Process_Type>;
  /** fetch data from the table: "enum_outwardShipmentOrder_source" */
  enum_outwardShipmentOrder_source: Array<Enum_OutwardShipmentOrder_Source>;
  /** fetch aggregated fields from the table: "enum_outwardShipmentOrder_source" */
  enum_outwardShipmentOrder_source_aggregate: Enum_OutwardShipmentOrder_Source_Aggregate;
  /** fetch data from the table: "enum_outwardShipmentOrder_source" using primary key columns */
  enum_outwardShipmentOrder_source_by_pk?: Maybe<Enum_OutwardShipmentOrder_Source>;
  /** fetch data from the table in a streaming manner: "enum_outwardShipmentOrder_source" */
  enum_outwardShipmentOrder_source_stream: Array<Enum_OutwardShipmentOrder_Source>;
  /** fetch data from the table: "enum_paddy_grade" */
  enum_paddy_grade: Array<Enum_Paddy_Grade>;
  /** fetch aggregated fields from the table: "enum_paddy_grade" */
  enum_paddy_grade_aggregate: Enum_Paddy_Grade_Aggregate;
  /** fetch data from the table: "enum_paddy_grade" using primary key columns */
  enum_paddy_grade_by_pk?: Maybe<Enum_Paddy_Grade>;
  /** fetch data from the table in a streaming manner: "enum_paddy_grade" */
  enum_paddy_grade_stream: Array<Enum_Paddy_Grade>;
  /** fetch data from the table: "enum_raw_material_type" */
  enum_raw_material_type: Array<Enum_Raw_Material_Type>;
  /** fetch aggregated fields from the table: "enum_raw_material_type" */
  enum_raw_material_type_aggregate: Enum_Raw_Material_Type_Aggregate;
  /** fetch data from the table: "enum_raw_material_type" using primary key columns */
  enum_raw_material_type_by_pk?: Maybe<Enum_Raw_Material_Type>;
  /** fetch data from the table in a streaming manner: "enum_raw_material_type" */
  enum_raw_material_type_stream: Array<Enum_Raw_Material_Type>;
  /** fetch data from the table: "enum_setting_value_type" */
  enum_setting_value_type: Array<Enum_Setting_Value_Type>;
  /** fetch aggregated fields from the table: "enum_setting_value_type" */
  enum_setting_value_type_aggregate: Enum_Setting_Value_Type_Aggregate;
  /** fetch data from the table: "enum_setting_value_type" using primary key columns */
  enum_setting_value_type_by_pk?: Maybe<Enum_Setting_Value_Type>;
  /** fetch data from the table in a streaming manner: "enum_setting_value_type" */
  enum_setting_value_type_stream: Array<Enum_Setting_Value_Type>;
  /** fetch data from the table: "enum_source_destination" */
  enum_source_destination: Array<Enum_Source_Destination>;
  /** fetch aggregated fields from the table: "enum_source_destination" */
  enum_source_destination_aggregate: Enum_Source_Destination_Aggregate;
  /** fetch data from the table: "enum_source_destination" using primary key columns */
  enum_source_destination_by_pk?: Maybe<Enum_Source_Destination>;
  /** fetch data from the table in a streaming manner: "enum_source_destination" */
  enum_source_destination_stream: Array<Enum_Source_Destination>;
  /** fetch data from the table: "enum_user_role" */
  enum_user_role: Array<Enum_User_Role>;
  /** fetch aggregated fields from the table: "enum_user_role" */
  enum_user_role_aggregate: Enum_User_Role_Aggregate;
  /** fetch data from the table: "enum_user_role" using primary key columns */
  enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** fetch data from the table in a streaming manner: "enum_user_role" */
  enum_user_role_stream: Array<Enum_User_Role>;
  /** fetch data from the table: "godownRawMaterialSummary" */
  godownRawMaterialSummary: Array<GodownRawMaterialSummary>;
  /** fetch aggregated fields from the table: "godownRawMaterialSummary" */
  godownRawMaterialSummary_aggregate: GodownRawMaterialSummary_Aggregate;
  /** fetch data from the table in a streaming manner: "godownRawMaterialSummary" */
  godownRawMaterialSummary_stream: Array<GodownRawMaterialSummary>;
  /** fetch data from the table: "godownSummary" */
  godownSummary: Array<GodownSummary>;
  /** fetch aggregated fields from the table: "godownSummary" */
  godownSummary_aggregate: GodownSummary_Aggregate;
  /** fetch data from the table in a streaming manner: "godownSummary" */
  godownSummary_stream: Array<GodownSummary>;
  /** fetch data from the table: "godowns" */
  godowns: Array<Godowns>;
  /** fetch aggregated fields from the table: "godowns" */
  godowns_aggregate: Godowns_Aggregate;
  /** fetch data from the table: "godowns" using primary key columns */
  godowns_by_pk?: Maybe<Godowns>;
  /** fetch data from the table in a streaming manner: "godowns" */
  godowns_stream: Array<Godowns>;
  /** fetch data from the table: "individual_in_out_for_combined_process" */
  individual_in_out_for_combined_process: Array<Individual_In_Out_For_Combined_Process>;
  /** fetch aggregated fields from the table: "individual_in_out_for_combined_process" */
  individual_in_out_for_combined_process_aggregate: Individual_In_Out_For_Combined_Process_Aggregate;
  /** fetch data from the table in a streaming manner: "individual_in_out_for_combined_process" */
  individual_in_out_for_combined_process_stream: Array<Individual_In_Out_For_Combined_Process>;
  /** An array relationship */
  internalTransferShipmentItems: Array<InternalTransferShipmentItems>;
  /** An aggregate relationship */
  internalTransferShipmentItems_aggregate: InternalTransferShipmentItems_Aggregate;
  /** fetch data from the table: "internalTransferShipmentItems" using primary key columns */
  internalTransferShipmentItems_by_pk?: Maybe<InternalTransferShipmentItems>;
  /** fetch data from the table in a streaming manner: "internalTransferShipmentItems" */
  internalTransferShipmentItems_stream: Array<InternalTransferShipmentItems>;
  /** fetch data from the table: "internalTransferShipments" */
  internalTransferShipments: Array<InternalTransferShipments>;
  /** fetch aggregated fields from the table: "internalTransferShipments" */
  internalTransferShipments_aggregate: InternalTransferShipments_Aggregate;
  /** fetch data from the table: "internalTransferShipments" using primary key columns */
  internalTransferShipments_by_pk?: Maybe<InternalTransferShipments>;
  /** fetch data from the table in a streaming manner: "internalTransferShipments" */
  internalTransferShipments_stream: Array<InternalTransferShipments>;
  /** An array relationship */
  inwardShipmentItems: Array<InwardShipmentItems>;
  /** An aggregate relationship */
  inwardShipmentItems_aggregate: InwardShipmentItems_Aggregate;
  /** fetch data from the table: "inwardShipmentItems" using primary key columns */
  inwardShipmentItems_by_pk?: Maybe<InwardShipmentItems>;
  /** fetch data from the table in a streaming manner: "inwardShipmentItems" */
  inwardShipmentItems_stream: Array<InwardShipmentItems>;
  /** An array relationship */
  inwardShipments: Array<InwardShipments>;
  /** An aggregate relationship */
  inwardShipments_aggregate: InwardShipments_Aggregate;
  /** fetch data from the table: "inwardShipments" using primary key columns */
  inwardShipments_by_pk?: Maybe<InwardShipments>;
  /** fetch data from the table in a streaming manner: "inwardShipments" */
  inwardShipments_stream: Array<InwardShipments>;
  /** fetch data from the table: "items" */
  items: Array<Items>;
  /** fetch aggregated fields from the table: "items" */
  items_aggregate: Items_Aggregate;
  /** fetch data from the table: "items" using primary key columns */
  items_by_pk?: Maybe<Items>;
  /** fetch data from the table in a streaming manner: "items" */
  items_stream: Array<Items>;
  /** An array relationship */
  millingInItems: Array<MillingInItems>;
  /** An aggregate relationship */
  millingInItems_aggregate: MillingInItems_Aggregate;
  /** fetch data from the table: "millingInItems" using primary key columns */
  millingInItems_by_pk?: Maybe<MillingInItems>;
  /** fetch data from the table in a streaming manner: "millingInItems" */
  millingInItems_stream: Array<MillingInItems>;
  /** fetch data from the table: "millingOutItems" */
  millingOutItems: Array<MillingOutItems>;
  /** fetch aggregated fields from the table: "millingOutItems" */
  millingOutItems_aggregate: MillingOutItems_Aggregate;
  /** fetch data from the table: "millingOutItems" using primary key columns */
  millingOutItems_by_pk?: Maybe<MillingOutItems>;
  /** fetch data from the table in a streaming manner: "millingOutItems" */
  millingOutItems_stream: Array<MillingOutItems>;
  /** fetch data from the table: "millingProcesses" */
  millingProcesses: Array<MillingProcesses>;
  /** fetch aggregated fields from the table: "millingProcesses" */
  millingProcesses_aggregate: MillingProcesses_Aggregate;
  /** fetch data from the table: "millingProcesses" using primary key columns */
  millingProcesses_by_pk?: Maybe<MillingProcesses>;
  /** fetch data from the table in a streaming manner: "millingProcesses" */
  millingProcesses_stream: Array<MillingProcesses>;
  /** fetch data from the table: "mills" */
  mills: Array<Mills>;
  /** fetch aggregated fields from the table: "mills" */
  mills_aggregate: Mills_Aggregate;
  /** fetch data from the table: "mills" using primary key columns */
  mills_by_pk?: Maybe<Mills>;
  /** fetch data from the table in a streaming manner: "mills" */
  mills_stream: Array<Mills>;
  /** fetch data from the table: "outwardShipmentOrders" */
  outwardShipmentOrders: Array<OutwardShipmentOrders>;
  /** fetch aggregated fields from the table: "outwardShipmentOrders" */
  outwardShipmentOrders_aggregate: OutwardShipmentOrders_Aggregate;
  /** fetch data from the table: "outwardShipmentOrders" using primary key columns */
  outwardShipmentOrders_by_pk?: Maybe<OutwardShipmentOrders>;
  /** fetch data from the table in a streaming manner: "outwardShipmentOrders" */
  outwardShipmentOrders_stream: Array<OutwardShipmentOrders>;
  /** fetch data from the table: "outwardShipments" */
  outwardShipments: Array<OutwardShipments>;
  /** fetch data from the table: "outwardShipmentsDrivers" */
  outwardShipmentsDrivers: Array<OutwardShipmentsDrivers>;
  /** fetch aggregated fields from the table: "outwardShipmentsDrivers" */
  outwardShipmentsDrivers_aggregate: OutwardShipmentsDrivers_Aggregate;
  /** fetch data from the table in a streaming manner: "outwardShipmentsDrivers" */
  outwardShipmentsDrivers_stream: Array<OutwardShipmentsDrivers>;
  /** fetch aggregated fields from the table: "outwardShipments" */
  outwardShipments_aggregate: OutwardShipments_Aggregate;
  /** fetch data from the table: "outwardShipments" using primary key columns */
  outwardShipments_by_pk?: Maybe<OutwardShipments>;
  /** fetch data from the table in a streaming manner: "outwardShipments" */
  outwardShipments_stream: Array<OutwardShipments>;
  /** fetch data from the table: "paddyBagOrders" */
  paddyBagOrders: Array<PaddyBagOrders>;
  /** fetch aggregated fields from the table: "paddyBagOrders" */
  paddyBagOrders_aggregate: PaddyBagOrders_Aggregate;
  /** fetch data from the table: "paddyBagOrders" using primary key columns */
  paddyBagOrders_by_pk?: Maybe<PaddyBagOrders>;
  /** fetch data from the table in a streaming manner: "paddyBagOrders" */
  paddyBagOrders_stream: Array<PaddyBagOrders>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table in a streaming manner: "payment" */
  payment_stream: Array<Payment>;
  /** fetch data from the table: "process_wise_in_out" */
  process_wise_in_out: Array<Process_Wise_In_Out>;
  /** fetch aggregated fields from the table: "process_wise_in_out" */
  process_wise_in_out_aggregate: Process_Wise_In_Out_Aggregate;
  /** fetch data from the table in a streaming manner: "process_wise_in_out" */
  process_wise_in_out_stream: Array<Process_Wise_In_Out>;
  /** fetch data from the table: "productBagOrders" */
  productBagOrders: Array<ProductBagOrders>;
  /** fetch aggregated fields from the table: "productBagOrders" */
  productBagOrders_aggregate: ProductBagOrders_Aggregate;
  /** fetch data from the table: "productBagOrders" using primary key columns */
  productBagOrders_by_pk?: Maybe<ProductBagOrders>;
  /** fetch data from the table in a streaming manner: "productBagOrders" */
  productBagOrders_stream: Array<ProductBagOrders>;
  /** fetch data from the table: "productBuyers" */
  productBuyers: Array<ProductBuyers>;
  /** fetch aggregated fields from the table: "productBuyers" */
  productBuyers_aggregate: ProductBuyers_Aggregate;
  /** fetch data from the table: "productBuyers" using primary key columns */
  productBuyers_by_pk?: Maybe<ProductBuyers>;
  /** fetch data from the table in a streaming manner: "productBuyers" */
  productBuyers_stream: Array<ProductBuyers>;
  /** fetch data from the table: "productInventorySummary" */
  productInventorySummary: Array<ProductInventorySummary>;
  /** fetch aggregated fields from the table: "productInventorySummary" */
  productInventorySummary_aggregate: ProductInventorySummary_Aggregate;
  /** fetch data from the table in a streaming manner: "productInventorySummary" */
  productInventorySummary_stream: Array<ProductInventorySummary>;
  /** fetch data from the table: "productTypes" */
  productTypes: Array<ProductTypes>;
  /** fetch aggregated fields from the table: "productTypes" */
  productTypes_aggregate: ProductTypes_Aggregate;
  /** fetch data from the table: "productTypes" using primary key columns */
  productTypes_by_pk?: Maybe<ProductTypes>;
  /** fetch data from the table in a streaming manner: "productTypes" */
  productTypes_stream: Array<ProductTypes>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** An array relationship */
  purchaseOrderItems: Array<PurchaseOrderItems>;
  /** An aggregate relationship */
  purchaseOrderItems_aggregate: PurchaseOrderItems_Aggregate;
  /** fetch data from the table: "purchaseOrderItems" using primary key columns */
  purchaseOrderItems_by_pk?: Maybe<PurchaseOrderItems>;
  /** fetch data from the table in a streaming manner: "purchaseOrderItems" */
  purchaseOrderItems_stream: Array<PurchaseOrderItems>;
  /** fetch data from the table: "purchaseOrders" */
  purchaseOrders: Array<PurchaseOrders>;
  /** fetch aggregated fields from the table: "purchaseOrders" */
  purchaseOrders_aggregate: PurchaseOrders_Aggregate;
  /** fetch data from the table: "purchaseOrders" using primary key columns */
  purchaseOrders_by_pk?: Maybe<PurchaseOrders>;
  /** fetch data from the table in a streaming manner: "purchaseOrders" */
  purchaseOrders_stream: Array<PurchaseOrders>;
  /** fetch data from the table: "rawMaterials" */
  rawMaterials: Array<RawMaterials>;
  /** An array relationship */
  rawMaterialsProductTypes: Array<RawMaterialsProductTypes>;
  /** An aggregate relationship */
  rawMaterialsProductTypes_aggregate: RawMaterialsProductTypes_Aggregate;
  /** fetch data from the table: "rawMaterialsProductTypes" using primary key columns */
  rawMaterialsProductTypes_by_pk?: Maybe<RawMaterialsProductTypes>;
  /** fetch data from the table in a streaming manner: "rawMaterialsProductTypes" */
  rawMaterialsProductTypes_stream: Array<RawMaterialsProductTypes>;
  /** fetch aggregated fields from the table: "rawMaterials" */
  rawMaterials_aggregate: RawMaterials_Aggregate;
  /** fetch data from the table: "rawMaterials" using primary key columns */
  rawMaterials_by_pk?: Maybe<RawMaterials>;
  /** fetch data from the table in a streaming manner: "rawMaterials" */
  rawMaterials_stream: Array<RawMaterials>;
  /** fetch data from the table: "rmSellers" */
  rmSellers: Array<RmSellers>;
  /** fetch aggregated fields from the table: "rmSellers" */
  rmSellers_aggregate: RmSellers_Aggregate;
  /** fetch data from the table: "rmSellers" using primary key columns */
  rmSellers_by_pk?: Maybe<RmSellers>;
  /** fetch data from the table in a streaming manner: "rmSellers" */
  rmSellers_stream: Array<RmSellers>;
  /** fetch data from the table: "settings" */
  settings: Array<Settings>;
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate;
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>;
  /** fetch data from the table in a streaming manner: "settings" */
  settings_stream: Array<Settings>;
  /** fetch data from the table: "transporterDrivers" */
  transporterDrivers: Array<TransporterDrivers>;
  /** fetch aggregated fields from the table: "transporterDrivers" */
  transporterDrivers_aggregate: TransporterDrivers_Aggregate;
  /** fetch data from the table: "transporterDrivers" using primary key columns */
  transporterDrivers_by_pk?: Maybe<TransporterDrivers>;
  /** fetch data from the table in a streaming manner: "transporterDrivers" */
  transporterDrivers_stream: Array<TransporterDrivers>;
  /** fetch data from the table: "transporterVehicles" */
  transporterVehicles: Array<TransporterVehicles>;
  /** fetch aggregated fields from the table: "transporterVehicles" */
  transporterVehicles_aggregate: TransporterVehicles_Aggregate;
  /** fetch data from the table: "transporterVehicles" using primary key columns */
  transporterVehicles_by_pk?: Maybe<TransporterVehicles>;
  /** fetch data from the table in a streaming manner: "transporterVehicles" */
  transporterVehicles_stream: Array<TransporterVehicles>;
  /** fetch data from the table: "transporters" */
  transporters: Array<Transporters>;
  /** fetch aggregated fields from the table: "transporters" */
  transporters_aggregate: Transporters_Aggregate;
  /** fetch data from the table: "transporters" using primary key columns */
  transporters_by_pk?: Maybe<Transporters>;
  /** fetch data from the table in a streaming manner: "transporters" */
  transporters_stream: Array<Transporters>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** An array relationship */
  vendorsItemCategories: Array<VendorsItemCategories>;
  /** An aggregate relationship */
  vendorsItemCategories_aggregate: VendorsItemCategories_Aggregate;
  /** fetch data from the table: "vendorsItemCategories" using primary key columns */
  vendorsItemCategories_by_pk?: Maybe<VendorsItemCategories>;
  /** fetch data from the table in a streaming manner: "vendorsItemCategories" */
  vendorsItemCategories_stream: Array<VendorsItemCategories>;
  /** fetch data from the table: "weighbridgeReceipts" */
  weighbridgeReceipts: Array<WeighbridgeReceipts>;
  /** fetch aggregated fields from the table: "weighbridgeReceipts" */
  weighbridgeReceipts_aggregate: WeighbridgeReceipts_Aggregate;
  /** fetch data from the table: "weighbridgeReceipts" using primary key columns */
  weighbridgeReceipts_by_pk?: Maybe<WeighbridgeReceipts>;
  /** fetch data from the table in a streaming manner: "weighbridgeReceipts" */
  weighbridgeReceipts_stream: Array<WeighbridgeReceipts>;
};


export type Subscription_RootGodownCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownCurrentState_Order_By>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


export type Subscription_RootGodownCurrentState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownCurrentState_Order_By>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


export type Subscription_RootGodownCurrentState_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GodownCurrentState_Stream_Cursor_Input>>;
  where?: InputMaybe<GodownCurrentState_Bool_Exp>;
};


export type Subscription_RootMillCurrentStateArgs = {
  distinct_on?: InputMaybe<Array<MillCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillCurrentState_Order_By>>;
  where?: InputMaybe<MillCurrentState_Bool_Exp>;
};


export type Subscription_RootMillCurrentState_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillCurrentState_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillCurrentState_Order_By>>;
  where?: InputMaybe<MillCurrentState_Bool_Exp>;
};


export type Subscription_RootMillCurrentState_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MillCurrentState_Stream_Cursor_Input>>;
  where?: InputMaybe<MillCurrentState_Bool_Exp>;
};


export type Subscription_RootMillSummaryArgs = {
  distinct_on?: InputMaybe<Array<MillSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillSummary_Order_By>>;
  where?: InputMaybe<MillSummary_Bool_Exp>;
};


export type Subscription_RootMillSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillSummary_Order_By>>;
  where?: InputMaybe<MillSummary_Bool_Exp>;
};


export type Subscription_RootMillSummary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MillSummary_Stream_Cursor_Input>>;
  where?: InputMaybe<MillSummary_Bool_Exp>;
};


export type Subscription_RootRmPgBagsSummaryArgs = {
  distinct_on?: InputMaybe<Array<RmPgBagsSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmPgBagsSummary_Order_By>>;
  where?: InputMaybe<RmPgBagsSummary_Bool_Exp>;
};


export type Subscription_RootRmPgBagsSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RmPgBagsSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmPgBagsSummary_Order_By>>;
  where?: InputMaybe<RmPgBagsSummary_Bool_Exp>;
};


export type Subscription_RootRmPgBagsSummary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RmPgBagsSummary_Stream_Cursor_Input>>;
  where?: InputMaybe<RmPgBagsSummary_Bool_Exp>;
};


export type Subscription_RootBrokersArgs = {
  distinct_on?: InputMaybe<Array<Brokers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brokers_Order_By>>;
  where?: InputMaybe<Brokers_Bool_Exp>;
};


export type Subscription_RootBrokers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Brokers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Brokers_Order_By>>;
  where?: InputMaybe<Brokers_Bool_Exp>;
};


export type Subscription_RootBrokers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBrokers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Brokers_Stream_Cursor_Input>>;
  where?: InputMaybe<Brokers_Bool_Exp>;
};


export type Subscription_RootCompleted_Process_YieldArgs = {
  distinct_on?: InputMaybe<Array<Completed_Process_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Completed_Process_Yield_Order_By>>;
  where?: InputMaybe<Completed_Process_Yield_Bool_Exp>;
};


export type Subscription_RootCompleted_Process_Yield_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Completed_Process_Yield_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Completed_Process_Yield_Order_By>>;
  where?: InputMaybe<Completed_Process_Yield_Bool_Exp>;
};


export type Subscription_RootCompleted_Process_Yield_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Completed_Process_Yield_Stream_Cursor_Input>>;
  where?: InputMaybe<Completed_Process_Yield_Bool_Exp>;
};


export type Subscription_RootCountersArgs = {
  distinct_on?: InputMaybe<Array<Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Counters_Order_By>>;
  where?: InputMaybe<Counters_Bool_Exp>;
};


export type Subscription_RootCounters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Counters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Counters_Order_By>>;
  where?: InputMaybe<Counters_Bool_Exp>;
};


export type Subscription_RootCounters_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCounters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Counters_Stream_Cursor_Input>>;
  where?: InputMaybe<Counters_Bool_Exp>;
};


export type Subscription_RootEnum_Empty_Bag_Wt_UnitArgs = {
  distinct_on?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Order_By>>;
  where?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
};


export type Subscription_RootEnum_Empty_Bag_Wt_Unit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Empty_Bag_Wt_Unit_Order_By>>;
  where?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
};


export type Subscription_RootEnum_Empty_Bag_Wt_Unit_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Empty_Bag_Wt_Unit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Empty_Bag_Wt_Unit_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Empty_Bag_Wt_Unit_Bool_Exp>;
};


export type Subscription_RootEnum_InternalTransfer_Source_DestinationArgs = {
  distinct_on?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
};


export type Subscription_RootEnum_InternalTransfer_Source_Destination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InternalTransfer_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
};


export type Subscription_RootEnum_InternalTransfer_Source_Destination_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_InternalTransfer_Source_Destination_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_InternalTransfer_Source_Destination_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_InternalTransfer_Source_Destination_Bool_Exp>;
};


export type Subscription_RootEnum_InwardShipmentItem_SourceArgs = {
  distinct_on?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Order_By>>;
  where?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
};


export type Subscription_RootEnum_InwardShipmentItem_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_InwardShipmentItem_Source_Order_By>>;
  where?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
};


export type Subscription_RootEnum_InwardShipmentItem_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_InwardShipmentItem_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_InwardShipmentItem_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_InwardShipmentItem_Source_Bool_Exp>;
};


export type Subscription_RootEnum_Inward_Shipment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Inward_Shipment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Inward_Shipment_Status_Order_By>>;
  where?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Inward_Shipment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Inward_Shipment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Inward_Shipment_Status_Order_By>>;
  where?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Inward_Shipment_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Inward_Shipment_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Inward_Shipment_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Inward_Shipment_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Item_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Enum_Item_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Item_Category_Order_By>>;
  where?: InputMaybe<Enum_Item_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Item_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Item_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Item_Category_Order_By>>;
  where?: InputMaybe<Enum_Item_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Item_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Item_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Item_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Item_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Labor_Charge_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Labor_Charge_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Labor_Charge_Type_Order_By>>;
  where?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Labor_Charge_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Labor_Charge_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Labor_Charge_Type_Order_By>>;
  where?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Labor_Charge_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Labor_Charge_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Labor_Charge_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Labor_Charge_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Milling_Process_StatusArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Status_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Milling_Process_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Status_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Milling_Process_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Milling_Process_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Milling_Process_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Milling_Process_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Milling_Process_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Type_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Milling_Process_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Milling_Process_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Milling_Process_Type_Order_By>>;
  where?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Milling_Process_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Milling_Process_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Milling_Process_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Milling_Process_Type_Bool_Exp>;
};


export type Subscription_RootEnum_OutwardShipmentOrder_SourceArgs = {
  distinct_on?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Order_By>>;
  where?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
};


export type Subscription_RootEnum_OutwardShipmentOrder_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_OutwardShipmentOrder_Source_Order_By>>;
  where?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
};


export type Subscription_RootEnum_OutwardShipmentOrder_Source_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_OutwardShipmentOrder_Source_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_OutwardShipmentOrder_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_OutwardShipmentOrder_Source_Bool_Exp>;
};


export type Subscription_RootEnum_Paddy_GradeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Paddy_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Paddy_Grade_Order_By>>;
  where?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
};


export type Subscription_RootEnum_Paddy_Grade_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Paddy_Grade_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Paddy_Grade_Order_By>>;
  where?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
};


export type Subscription_RootEnum_Paddy_Grade_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Paddy_Grade_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Paddy_Grade_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Paddy_Grade_Bool_Exp>;
};


export type Subscription_RootEnum_Raw_Material_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Raw_Material_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Raw_Material_Type_Order_By>>;
  where?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Raw_Material_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Raw_Material_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Raw_Material_Type_Order_By>>;
  where?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Raw_Material_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Raw_Material_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Raw_Material_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Raw_Material_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Setting_Value_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Value_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Setting_Value_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Setting_Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Setting_Value_Type_Order_By>>;
  where?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Setting_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Setting_Value_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Setting_Value_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Setting_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Source_DestinationArgs = {
  distinct_on?: InputMaybe<Array<Enum_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
};


export type Subscription_RootEnum_Source_Destination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Source_Destination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Source_Destination_Order_By>>;
  where?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
};


export type Subscription_RootEnum_Source_Destination_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Source_Destination_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_Source_Destination_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_Source_Destination_Bool_Exp>;
};


export type Subscription_RootEnum_User_RoleArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Role_Order_By>>;
  where?: InputMaybe<Enum_User_Role_Bool_Exp>;
};


export type Subscription_RootEnum_User_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_User_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_User_Role_Order_By>>;
  where?: InputMaybe<Enum_User_Role_Bool_Exp>;
};


export type Subscription_RootEnum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_User_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enum_User_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Enum_User_Role_Bool_Exp>;
};


export type Subscription_RootGodownRawMaterialSummaryArgs = {
  distinct_on?: InputMaybe<Array<GodownRawMaterialSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownRawMaterialSummary_Order_By>>;
  where?: InputMaybe<GodownRawMaterialSummary_Bool_Exp>;
};


export type Subscription_RootGodownRawMaterialSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownRawMaterialSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownRawMaterialSummary_Order_By>>;
  where?: InputMaybe<GodownRawMaterialSummary_Bool_Exp>;
};


export type Subscription_RootGodownRawMaterialSummary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GodownRawMaterialSummary_Stream_Cursor_Input>>;
  where?: InputMaybe<GodownRawMaterialSummary_Bool_Exp>;
};


export type Subscription_RootGodownSummaryArgs = {
  distinct_on?: InputMaybe<Array<GodownSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownSummary_Order_By>>;
  where?: InputMaybe<GodownSummary_Bool_Exp>;
};


export type Subscription_RootGodownSummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GodownSummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<GodownSummary_Order_By>>;
  where?: InputMaybe<GodownSummary_Bool_Exp>;
};


export type Subscription_RootGodownSummary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<GodownSummary_Stream_Cursor_Input>>;
  where?: InputMaybe<GodownSummary_Bool_Exp>;
};


export type Subscription_RootGodownsArgs = {
  distinct_on?: InputMaybe<Array<Godowns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Godowns_Order_By>>;
  where?: InputMaybe<Godowns_Bool_Exp>;
};


export type Subscription_RootGodowns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Godowns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Godowns_Order_By>>;
  where?: InputMaybe<Godowns_Bool_Exp>;
};


export type Subscription_RootGodowns_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGodowns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Godowns_Stream_Cursor_Input>>;
  where?: InputMaybe<Godowns_Bool_Exp>;
};


export type Subscription_RootIndividual_In_Out_For_Combined_ProcessArgs = {
  distinct_on?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Order_By>>;
  where?: InputMaybe<Individual_In_Out_For_Combined_Process_Bool_Exp>;
};


export type Subscription_RootIndividual_In_Out_For_Combined_Process_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Individual_In_Out_For_Combined_Process_Order_By>>;
  where?: InputMaybe<Individual_In_Out_For_Combined_Process_Bool_Exp>;
};


export type Subscription_RootIndividual_In_Out_For_Combined_Process_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Individual_In_Out_For_Combined_Process_Stream_Cursor_Input>>;
  where?: InputMaybe<Individual_In_Out_For_Combined_Process_Bool_Exp>;
};


export type Subscription_RootInternalTransferShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipmentItems_Order_By>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};


export type Subscription_RootInternalTransferShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipmentItems_Order_By>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};


export type Subscription_RootInternalTransferShipmentItems_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInternalTransferShipmentItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InternalTransferShipmentItems_Stream_Cursor_Input>>;
  where?: InputMaybe<InternalTransferShipmentItems_Bool_Exp>;
};


export type Subscription_RootInternalTransferShipmentsArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipments_Order_By>>;
  where?: InputMaybe<InternalTransferShipments_Bool_Exp>;
};


export type Subscription_RootInternalTransferShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalTransferShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InternalTransferShipments_Order_By>>;
  where?: InputMaybe<InternalTransferShipments_Bool_Exp>;
};


export type Subscription_RootInternalTransferShipments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInternalTransferShipments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InternalTransferShipments_Stream_Cursor_Input>>;
  where?: InputMaybe<InternalTransferShipments_Bool_Exp>;
};


export type Subscription_RootInwardShipmentItemsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


export type Subscription_RootInwardShipmentItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipmentItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipmentItems_Order_By>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


export type Subscription_RootInwardShipmentItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInwardShipmentItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InwardShipmentItems_Stream_Cursor_Input>>;
  where?: InputMaybe<InwardShipmentItems_Bool_Exp>;
};


export type Subscription_RootInwardShipmentsArgs = {
  distinct_on?: InputMaybe<Array<InwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipments_Order_By>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


export type Subscription_RootInwardShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<InwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<InwardShipments_Order_By>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


export type Subscription_RootInwardShipments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInwardShipments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<InwardShipments_Stream_Cursor_Input>>;
  where?: InputMaybe<InwardShipments_Bool_Exp>;
};


export type Subscription_RootItemsArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Items_Order_By>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootItems_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Items_Stream_Cursor_Input>>;
  where?: InputMaybe<Items_Bool_Exp>;
};


export type Subscription_RootMillingInItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


export type Subscription_RootMillingInItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingInItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingInItems_Order_By>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


export type Subscription_RootMillingInItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMillingInItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MillingInItems_Stream_Cursor_Input>>;
  where?: InputMaybe<MillingInItems_Bool_Exp>;
};


export type Subscription_RootMillingOutItemsArgs = {
  distinct_on?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingOutItems_Order_By>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};


export type Subscription_RootMillingOutItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingOutItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingOutItems_Order_By>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};


export type Subscription_RootMillingOutItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMillingOutItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MillingOutItems_Stream_Cursor_Input>>;
  where?: InputMaybe<MillingOutItems_Bool_Exp>;
};


export type Subscription_RootMillingProcessesArgs = {
  distinct_on?: InputMaybe<Array<MillingProcesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingProcesses_Order_By>>;
  where?: InputMaybe<MillingProcesses_Bool_Exp>;
};


export type Subscription_RootMillingProcesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MillingProcesses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MillingProcesses_Order_By>>;
  where?: InputMaybe<MillingProcesses_Bool_Exp>;
};


export type Subscription_RootMillingProcesses_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMillingProcesses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MillingProcesses_Stream_Cursor_Input>>;
  where?: InputMaybe<MillingProcesses_Bool_Exp>;
};


export type Subscription_RootMillsArgs = {
  distinct_on?: InputMaybe<Array<Mills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mills_Order_By>>;
  where?: InputMaybe<Mills_Bool_Exp>;
};


export type Subscription_RootMills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mills_Order_By>>;
  where?: InputMaybe<Mills_Bool_Exp>;
};


export type Subscription_RootMills_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mills_Stream_Cursor_Input>>;
  where?: InputMaybe<Mills_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentOrdersArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentOrders_Order_By>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentOrders_Order_By>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutwardShipmentOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OutwardShipmentOrders_Stream_Cursor_Input>>;
  where?: InputMaybe<OutwardShipmentOrders_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentsArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipments_Order_By>>;
  where?: InputMaybe<OutwardShipments_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentsDriversArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentsDrivers_Order_By>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentsDrivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipmentsDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipmentsDrivers_Order_By>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


export type Subscription_RootOutwardShipmentsDrivers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OutwardShipmentsDrivers_Stream_Cursor_Input>>;
  where?: InputMaybe<OutwardShipmentsDrivers_Bool_Exp>;
};


export type Subscription_RootOutwardShipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<OutwardShipments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OutwardShipments_Order_By>>;
  where?: InputMaybe<OutwardShipments_Bool_Exp>;
};


export type Subscription_RootOutwardShipments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutwardShipments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OutwardShipments_Stream_Cursor_Input>>;
  where?: InputMaybe<OutwardShipments_Bool_Exp>;
};


export type Subscription_RootPaddyBagOrdersArgs = {
  distinct_on?: InputMaybe<Array<PaddyBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaddyBagOrders_Order_By>>;
  where?: InputMaybe<PaddyBagOrders_Bool_Exp>;
};


export type Subscription_RootPaddyBagOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PaddyBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PaddyBagOrders_Order_By>>;
  where?: InputMaybe<PaddyBagOrders_Bool_Exp>;
};


export type Subscription_RootPaddyBagOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPaddyBagOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PaddyBagOrders_Stream_Cursor_Input>>;
  where?: InputMaybe<PaddyBagOrders_Bool_Exp>;
};


export type Subscription_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootProcess_Wise_In_OutArgs = {
  distinct_on?: InputMaybe<Array<Process_Wise_In_Out_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Process_Wise_In_Out_Order_By>>;
  where?: InputMaybe<Process_Wise_In_Out_Bool_Exp>;
};


export type Subscription_RootProcess_Wise_In_Out_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Process_Wise_In_Out_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Process_Wise_In_Out_Order_By>>;
  where?: InputMaybe<Process_Wise_In_Out_Bool_Exp>;
};


export type Subscription_RootProcess_Wise_In_Out_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Process_Wise_In_Out_Stream_Cursor_Input>>;
  where?: InputMaybe<Process_Wise_In_Out_Bool_Exp>;
};


export type Subscription_RootProductBagOrdersArgs = {
  distinct_on?: InputMaybe<Array<ProductBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBagOrders_Order_By>>;
  where?: InputMaybe<ProductBagOrders_Bool_Exp>;
};


export type Subscription_RootProductBagOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductBagOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBagOrders_Order_By>>;
  where?: InputMaybe<ProductBagOrders_Bool_Exp>;
};


export type Subscription_RootProductBagOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProductBagOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductBagOrders_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductBagOrders_Bool_Exp>;
};


export type Subscription_RootProductBuyersArgs = {
  distinct_on?: InputMaybe<Array<ProductBuyers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBuyers_Order_By>>;
  where?: InputMaybe<ProductBuyers_Bool_Exp>;
};


export type Subscription_RootProductBuyers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductBuyers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductBuyers_Order_By>>;
  where?: InputMaybe<ProductBuyers_Bool_Exp>;
};


export type Subscription_RootProductBuyers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProductBuyers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductBuyers_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductBuyers_Bool_Exp>;
};


export type Subscription_RootProductInventorySummaryArgs = {
  distinct_on?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductInventorySummary_Order_By>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};


export type Subscription_RootProductInventorySummary_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductInventorySummary_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductInventorySummary_Order_By>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};


export type Subscription_RootProductInventorySummary_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductInventorySummary_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductInventorySummary_Bool_Exp>;
};


export type Subscription_RootProductTypesArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ProductTypes_Order_By>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductTypes_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProductTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ProductTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<ProductTypes_Bool_Exp>;
};


export type Subscription_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Products_Bool_Exp>;
};


export type Subscription_RootPurchaseOrderItemsArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrderItems_Order_By>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};


export type Subscription_RootPurchaseOrderItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrderItems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrderItems_Order_By>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};


export type Subscription_RootPurchaseOrderItems_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPurchaseOrderItems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PurchaseOrderItems_Stream_Cursor_Input>>;
  where?: InputMaybe<PurchaseOrderItems_Bool_Exp>;
};


export type Subscription_RootPurchaseOrdersArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrders_Order_By>>;
  where?: InputMaybe<PurchaseOrders_Bool_Exp>;
};


export type Subscription_RootPurchaseOrders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PurchaseOrders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PurchaseOrders_Order_By>>;
  where?: InputMaybe<PurchaseOrders_Bool_Exp>;
};


export type Subscription_RootPurchaseOrders_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPurchaseOrders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<PurchaseOrders_Stream_Cursor_Input>>;
  where?: InputMaybe<PurchaseOrders_Bool_Exp>;
};


export type Subscription_RootRawMaterialsArgs = {
  distinct_on?: InputMaybe<Array<RawMaterials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterials_Order_By>>;
  where?: InputMaybe<RawMaterials_Bool_Exp>;
};


export type Subscription_RootRawMaterialsProductTypesArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


export type Subscription_RootRawMaterialsProductTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RawMaterialsProductTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterialsProductTypes_Order_By>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


export type Subscription_RootRawMaterialsProductTypes_By_PkArgs = {
  productTypeId: Scalars['Int'];
  rawMaterialId: Scalars['Int'];
};


export type Subscription_RootRawMaterialsProductTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RawMaterialsProductTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<RawMaterialsProductTypes_Bool_Exp>;
};


export type Subscription_RootRawMaterials_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RawMaterials_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RawMaterials_Order_By>>;
  where?: InputMaybe<RawMaterials_Bool_Exp>;
};


export type Subscription_RootRawMaterials_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRawMaterials_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RawMaterials_Stream_Cursor_Input>>;
  where?: InputMaybe<RawMaterials_Bool_Exp>;
};


export type Subscription_RootRmSellersArgs = {
  distinct_on?: InputMaybe<Array<RmSellers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmSellers_Order_By>>;
  where?: InputMaybe<RmSellers_Bool_Exp>;
};


export type Subscription_RootRmSellers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<RmSellers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<RmSellers_Order_By>>;
  where?: InputMaybe<RmSellers_Bool_Exp>;
};


export type Subscription_RootRmSellers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRmSellers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<RmSellers_Stream_Cursor_Input>>;
  where?: InputMaybe<RmSellers_Bool_Exp>;
};


export type Subscription_RootSettingsArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Subscription_RootSettings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Settings_Order_By>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Subscription_RootSettings_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSettings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Settings_Bool_Exp>;
};


export type Subscription_RootTransporterDriversArgs = {
  distinct_on?: InputMaybe<Array<TransporterDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterDrivers_Order_By>>;
  where?: InputMaybe<TransporterDrivers_Bool_Exp>;
};


export type Subscription_RootTransporterDrivers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TransporterDrivers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterDrivers_Order_By>>;
  where?: InputMaybe<TransporterDrivers_Bool_Exp>;
};


export type Subscription_RootTransporterDrivers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransporterDrivers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TransporterDrivers_Stream_Cursor_Input>>;
  where?: InputMaybe<TransporterDrivers_Bool_Exp>;
};


export type Subscription_RootTransporterVehiclesArgs = {
  distinct_on?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterVehicles_Order_By>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};


export type Subscription_RootTransporterVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterVehicles_Order_By>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};


export type Subscription_RootTransporterVehicles_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransporterVehicles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TransporterVehicles_Stream_Cursor_Input>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};


export type Subscription_RootTransportersArgs = {
  distinct_on?: InputMaybe<Array<Transporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transporters_Order_By>>;
  where?: InputMaybe<Transporters_Bool_Exp>;
};


export type Subscription_RootTransporters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transporters_Order_By>>;
  where?: InputMaybe<Transporters_Bool_Exp>;
};


export type Subscription_RootTransporters_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTransporters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transporters_Stream_Cursor_Input>>;
  where?: InputMaybe<Transporters_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVendorsItemCategoriesArgs = {
  distinct_on?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VendorsItemCategories_Order_By>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};


export type Subscription_RootVendorsItemCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<VendorsItemCategories_Order_By>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};


export type Subscription_RootVendorsItemCategories_By_PkArgs = {
  itemCategory: Enum_Item_Category_Enum;
  vendorId: Scalars['Int'];
};


export type Subscription_RootVendorsItemCategories_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<VendorsItemCategories_Stream_Cursor_Input>>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};


export type Subscription_RootWeighbridgeReceiptsArgs = {
  distinct_on?: InputMaybe<Array<WeighbridgeReceipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WeighbridgeReceipts_Order_By>>;
  where?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
};


export type Subscription_RootWeighbridgeReceipts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WeighbridgeReceipts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WeighbridgeReceipts_Order_By>>;
  where?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
};


export type Subscription_RootWeighbridgeReceipts_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWeighbridgeReceipts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WeighbridgeReceipts_Stream_Cursor_Input>>;
  where?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "transporterDrivers" */
export type TransporterDrivers = {
  __typename?: 'transporterDrivers';
  address?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  drivingLicence?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mobile: Scalars['String'];
  name: Scalars['String'];
  panFile?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  profileCompleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "transporterDrivers" */
export type TransporterDriversAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "transporterDrivers" */
export type TransporterDrivers_Aggregate = {
  __typename?: 'transporterDrivers_aggregate';
  aggregate?: Maybe<TransporterDrivers_Aggregate_Fields>;
  nodes: Array<TransporterDrivers>;
};

/** aggregate fields of "transporterDrivers" */
export type TransporterDrivers_Aggregate_Fields = {
  __typename?: 'transporterDrivers_aggregate_fields';
  avg?: Maybe<TransporterDrivers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TransporterDrivers_Max_Fields>;
  min?: Maybe<TransporterDrivers_Min_Fields>;
  stddev?: Maybe<TransporterDrivers_Stddev_Fields>;
  stddev_pop?: Maybe<TransporterDrivers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TransporterDrivers_Stddev_Samp_Fields>;
  sum?: Maybe<TransporterDrivers_Sum_Fields>;
  var_pop?: Maybe<TransporterDrivers_Var_Pop_Fields>;
  var_samp?: Maybe<TransporterDrivers_Var_Samp_Fields>;
  variance?: Maybe<TransporterDrivers_Variance_Fields>;
};


/** aggregate fields of "transporterDrivers" */
export type TransporterDrivers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TransporterDrivers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TransporterDrivers_Avg_Fields = {
  __typename?: 'transporterDrivers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transporterDrivers". All fields are combined with a logical 'AND'. */
export type TransporterDrivers_Bool_Exp = {
  _and?: InputMaybe<Array<TransporterDrivers_Bool_Exp>>;
  _not?: InputMaybe<TransporterDrivers_Bool_Exp>;
  _or?: InputMaybe<Array<TransporterDrivers_Bool_Exp>>;
  address?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  drivingLicence?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  panFile?: InputMaybe<String_Comparison_Exp>;
  photo?: InputMaybe<String_Comparison_Exp>;
  profileCompleted?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transporterDrivers" */
export enum TransporterDrivers_Constraint {
  /** unique or primary key constraint on columns "drivingLicence" */
  TransporterDriversDrivingLicenceKey = 'transporterDrivers_drivingLicence_key',
  /** unique or primary key constraint on columns "mobile" */
  TransporterDriversMobileKey = 'transporterDrivers_mobile_key',
  /** unique or primary key constraint on columns "id" */
  TransporterDriversPkey = 'transporterDrivers_pkey'
}

/** input type for incrementing numeric columns in table "transporterDrivers" */
export type TransporterDrivers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transporterDrivers" */
export type TransporterDrivers_Insert_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  drivingLicence?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  panFile?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TransporterDrivers_Max_Fields = {
  __typename?: 'transporterDrivers_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  drivingLicence?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  panFile?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TransporterDrivers_Min_Fields = {
  __typename?: 'transporterDrivers_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  drivingLicence?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  panFile?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transporterDrivers" */
export type TransporterDrivers_Mutation_Response = {
  __typename?: 'transporterDrivers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TransporterDrivers>;
};

/** input type for inserting object relation for remote table "transporterDrivers" */
export type TransporterDrivers_Obj_Rel_Insert_Input = {
  data: TransporterDrivers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TransporterDrivers_On_Conflict>;
};

/** on_conflict condition type for table "transporterDrivers" */
export type TransporterDrivers_On_Conflict = {
  constraint: TransporterDrivers_Constraint;
  update_columns?: Array<TransporterDrivers_Update_Column>;
  where?: InputMaybe<TransporterDrivers_Bool_Exp>;
};

/** Ordering options when selecting data from "transporterDrivers". */
export type TransporterDrivers_Order_By = {
  address?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  drivingLicence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  panFile?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  profileCompleted?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transporterDrivers */
export type TransporterDrivers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transporterDrivers" */
export enum TransporterDrivers_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DrivingLicence = 'drivingLicence',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  PanFile = 'panFile',
  /** column name */
  Photo = 'photo',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "transporterDrivers" */
export type TransporterDrivers_Set_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  drivingLicence?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  panFile?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TransporterDrivers_Stddev_Fields = {
  __typename?: 'transporterDrivers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TransporterDrivers_Stddev_Pop_Fields = {
  __typename?: 'transporterDrivers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TransporterDrivers_Stddev_Samp_Fields = {
  __typename?: 'transporterDrivers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "transporterDrivers" */
export type TransporterDrivers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TransporterDrivers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TransporterDrivers_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  drivingLicence?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  panFile?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TransporterDrivers_Sum_Fields = {
  __typename?: 'transporterDrivers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "transporterDrivers" */
export enum TransporterDrivers_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DrivingLicence = 'drivingLicence',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  PanFile = 'panFile',
  /** column name */
  Photo = 'photo',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type TransporterDrivers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TransporterDrivers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransporterDrivers_Set_Input>;
  /** filter the rows which have to be updated */
  where: TransporterDrivers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TransporterDrivers_Var_Pop_Fields = {
  __typename?: 'transporterDrivers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TransporterDrivers_Var_Samp_Fields = {
  __typename?: 'transporterDrivers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TransporterDrivers_Variance_Fields = {
  __typename?: 'transporterDrivers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "transporterVehicles" */
export type TransporterVehicles = {
  __typename?: 'transporterVehicles';
  capacityKgs?: Maybe<Scalars['float8']>;
  chassisNo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  engineNo?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  number?: Maybe<Scalars['String']>;
  profileComplete?: Maybe<Scalars['Boolean']>;
  rcS3Key?: Maybe<Scalars['String']>;
  /** An object relationship */
  transporter: Transporters;
  transporterId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "transporterVehicles" */
export type TransporterVehicles_Aggregate = {
  __typename?: 'transporterVehicles_aggregate';
  aggregate?: Maybe<TransporterVehicles_Aggregate_Fields>;
  nodes: Array<TransporterVehicles>;
};

export type TransporterVehicles_Aggregate_Bool_Exp = {
  avg?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp_Var_Samp>;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Avg = {
  arguments: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Corr = {
  arguments: TransporterVehicles_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Corr_Arguments = {
  X: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Max = {
  arguments: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Min = {
  arguments: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Sum = {
  arguments: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type TransporterVehicles_Aggregate_Bool_Exp_Var_Samp = {
  arguments: TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TransporterVehicles_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "transporterVehicles" */
export type TransporterVehicles_Aggregate_Fields = {
  __typename?: 'transporterVehicles_aggregate_fields';
  avg?: Maybe<TransporterVehicles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<TransporterVehicles_Max_Fields>;
  min?: Maybe<TransporterVehicles_Min_Fields>;
  stddev?: Maybe<TransporterVehicles_Stddev_Fields>;
  stddev_pop?: Maybe<TransporterVehicles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<TransporterVehicles_Stddev_Samp_Fields>;
  sum?: Maybe<TransporterVehicles_Sum_Fields>;
  var_pop?: Maybe<TransporterVehicles_Var_Pop_Fields>;
  var_samp?: Maybe<TransporterVehicles_Var_Samp_Fields>;
  variance?: Maybe<TransporterVehicles_Variance_Fields>;
};


/** aggregate fields of "transporterVehicles" */
export type TransporterVehicles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transporterVehicles" */
export type TransporterVehicles_Aggregate_Order_By = {
  avg?: InputMaybe<TransporterVehicles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<TransporterVehicles_Max_Order_By>;
  min?: InputMaybe<TransporterVehicles_Min_Order_By>;
  stddev?: InputMaybe<TransporterVehicles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<TransporterVehicles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<TransporterVehicles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<TransporterVehicles_Sum_Order_By>;
  var_pop?: InputMaybe<TransporterVehicles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<TransporterVehicles_Var_Samp_Order_By>;
  variance?: InputMaybe<TransporterVehicles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transporterVehicles" */
export type TransporterVehicles_Arr_Rel_Insert_Input = {
  data: Array<TransporterVehicles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<TransporterVehicles_On_Conflict>;
};

/** aggregate avg on columns */
export type TransporterVehicles_Avg_Fields = {
  __typename?: 'transporterVehicles_avg_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transporterVehicles" */
export type TransporterVehicles_Avg_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transporterVehicles". All fields are combined with a logical 'AND'. */
export type TransporterVehicles_Bool_Exp = {
  _and?: InputMaybe<Array<TransporterVehicles_Bool_Exp>>;
  _not?: InputMaybe<TransporterVehicles_Bool_Exp>;
  _or?: InputMaybe<Array<TransporterVehicles_Bool_Exp>>;
  capacityKgs?: InputMaybe<Float8_Comparison_Exp>;
  chassisNo?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  engineNo?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  profileComplete?: InputMaybe<Boolean_Comparison_Exp>;
  rcS3Key?: InputMaybe<String_Comparison_Exp>;
  transporter?: InputMaybe<Transporters_Bool_Exp>;
  transporterId?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "transporterVehicles" */
export enum TransporterVehicles_Constraint {
  /** unique or primary key constraint on columns "chassisNo" */
  CustomUniqueTransporterVehiclesChassisNo = 'custom_unique_transporterVehicles_chassisNo',
  /** unique or primary key constraint on columns "engineNo" */
  CustomUniqueTransporterVehiclesEngineNo = 'custom_unique_transporterVehicles_engineNo',
  /** unique or primary key constraint on columns "number" */
  TransporterVehiclesNumberKey = 'transporterVehicles_number_key',
  /** unique or primary key constraint on columns "id" */
  TransporterVehiclesPkey = 'transporterVehicles_pkey'
}

/** input type for incrementing numeric columns in table "transporterVehicles" */
export type TransporterVehicles_Inc_Input = {
  capacityKgs?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  transporterId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transporterVehicles" */
export type TransporterVehicles_Insert_Input = {
  capacityKgs?: InputMaybe<Scalars['float8']>;
  chassisNo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  engineNo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['String']>;
  rcS3Key?: InputMaybe<Scalars['String']>;
  transporter?: InputMaybe<Transporters_Obj_Rel_Insert_Input>;
  transporterId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TransporterVehicles_Max_Fields = {
  __typename?: 'transporterVehicles_max_fields';
  capacityKgs?: Maybe<Scalars['float8']>;
  chassisNo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  engineNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  rcS3Key?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "transporterVehicles" */
export type TransporterVehicles_Max_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  chassisNo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  engineNo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rcS3Key?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type TransporterVehicles_Min_Fields = {
  __typename?: 'transporterVehicles_min_fields';
  capacityKgs?: Maybe<Scalars['float8']>;
  chassisNo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  engineNo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['String']>;
  rcS3Key?: Maybe<Scalars['String']>;
  transporterId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "transporterVehicles" */
export type TransporterVehicles_Min_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  chassisNo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  engineNo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rcS3Key?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "transporterVehicles" */
export type TransporterVehicles_Mutation_Response = {
  __typename?: 'transporterVehicles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TransporterVehicles>;
};

/** input type for inserting object relation for remote table "transporterVehicles" */
export type TransporterVehicles_Obj_Rel_Insert_Input = {
  data: TransporterVehicles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<TransporterVehicles_On_Conflict>;
};

/** on_conflict condition type for table "transporterVehicles" */
export type TransporterVehicles_On_Conflict = {
  constraint: TransporterVehicles_Constraint;
  update_columns?: Array<TransporterVehicles_Update_Column>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};

/** Ordering options when selecting data from "transporterVehicles". */
export type TransporterVehicles_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  chassisNo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  engineNo?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  profileComplete?: InputMaybe<Order_By>;
  rcS3Key?: InputMaybe<Order_By>;
  transporter?: InputMaybe<Transporters_Order_By>;
  transporterId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transporterVehicles */
export type TransporterVehicles_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column {
  /** column name */
  CapacityKgs = 'capacityKgs',
  /** column name */
  ChassisNo = 'chassisNo',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EngineNo = 'engineNo',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  RcS3Key = 'rcS3Key',
  /** column name */
  TransporterId = 'transporterId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "transporterVehicles_aggregate_bool_exp_avg_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_corr_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_max_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_min_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_sum_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** select "transporterVehicles_aggregate_bool_exp_var_samp_arguments_columns" columns of table "transporterVehicles" */
export enum TransporterVehicles_Select_Column_TransporterVehicles_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  CapacityKgs = 'capacityKgs'
}

/** input type for updating data in table "transporterVehicles" */
export type TransporterVehicles_Set_Input = {
  capacityKgs?: InputMaybe<Scalars['float8']>;
  chassisNo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  engineNo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['String']>;
  rcS3Key?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TransporterVehicles_Stddev_Fields = {
  __typename?: 'transporterVehicles_stddev_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transporterVehicles" */
export type TransporterVehicles_Stddev_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type TransporterVehicles_Stddev_Pop_Fields = {
  __typename?: 'transporterVehicles_stddev_pop_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transporterVehicles" */
export type TransporterVehicles_Stddev_Pop_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type TransporterVehicles_Stddev_Samp_Fields = {
  __typename?: 'transporterVehicles_stddev_samp_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transporterVehicles" */
export type TransporterVehicles_Stddev_Samp_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transporterVehicles" */
export type TransporterVehicles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: TransporterVehicles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type TransporterVehicles_Stream_Cursor_Value_Input = {
  capacityKgs?: InputMaybe<Scalars['float8']>;
  chassisNo?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  engineNo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<Scalars['String']>;
  rcS3Key?: InputMaybe<Scalars['String']>;
  transporterId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type TransporterVehicles_Sum_Fields = {
  __typename?: 'transporterVehicles_sum_fields';
  capacityKgs?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  transporterId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "transporterVehicles" */
export type TransporterVehicles_Sum_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** update columns of table "transporterVehicles" */
export enum TransporterVehicles_Update_Column {
  /** column name */
  CapacityKgs = 'capacityKgs',
  /** column name */
  ChassisNo = 'chassisNo',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EngineNo = 'engineNo',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  RcS3Key = 'rcS3Key',
  /** column name */
  TransporterId = 'transporterId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type TransporterVehicles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TransporterVehicles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransporterVehicles_Set_Input>;
  /** filter the rows which have to be updated */
  where: TransporterVehicles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type TransporterVehicles_Var_Pop_Fields = {
  __typename?: 'transporterVehicles_var_pop_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transporterVehicles" */
export type TransporterVehicles_Var_Pop_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type TransporterVehicles_Var_Samp_Fields = {
  __typename?: 'transporterVehicles_var_samp_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transporterVehicles" */
export type TransporterVehicles_Var_Samp_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type TransporterVehicles_Variance_Fields = {
  __typename?: 'transporterVehicles_variance_fields';
  capacityKgs?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  transporterId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transporterVehicles" */
export type TransporterVehicles_Variance_Order_By = {
  capacityKgs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transporterId?: InputMaybe<Order_By>;
};

/** columns and relationships of "transporters" */
export type Transporters = {
  __typename?: 'transporters';
  address?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  profileCompleted?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  vehicles: Array<TransporterVehicles>;
  /** An aggregate relationship */
  vehicles_aggregate: TransporterVehicles_Aggregate;
};


/** columns and relationships of "transporters" */
export type TransportersAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "transporters" */
export type TransportersVehiclesArgs = {
  distinct_on?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterVehicles_Order_By>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};


/** columns and relationships of "transporters" */
export type TransportersVehicles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<TransporterVehicles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TransporterVehicles_Order_By>>;
  where?: InputMaybe<TransporterVehicles_Bool_Exp>;
};

/** aggregated selection of "transporters" */
export type Transporters_Aggregate = {
  __typename?: 'transporters_aggregate';
  aggregate?: Maybe<Transporters_Aggregate_Fields>;
  nodes: Array<Transporters>;
};

/** aggregate fields of "transporters" */
export type Transporters_Aggregate_Fields = {
  __typename?: 'transporters_aggregate_fields';
  avg?: Maybe<Transporters_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transporters_Max_Fields>;
  min?: Maybe<Transporters_Min_Fields>;
  stddev?: Maybe<Transporters_Stddev_Fields>;
  stddev_pop?: Maybe<Transporters_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transporters_Stddev_Samp_Fields>;
  sum?: Maybe<Transporters_Sum_Fields>;
  var_pop?: Maybe<Transporters_Var_Pop_Fields>;
  var_samp?: Maybe<Transporters_Var_Samp_Fields>;
  variance?: Maybe<Transporters_Variance_Fields>;
};


/** aggregate fields of "transporters" */
export type Transporters_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transporters_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Transporters_Avg_Fields = {
  __typename?: 'transporters_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transporters". All fields are combined with a logical 'AND'. */
export type Transporters_Bool_Exp = {
  _and?: InputMaybe<Array<Transporters_Bool_Exp>>;
  _not?: InputMaybe<Transporters_Bool_Exp>;
  _or?: InputMaybe<Array<Transporters_Bool_Exp>>;
  address?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  gstin?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  primaryContactEmail?: InputMaybe<String_Comparison_Exp>;
  primaryContactMobile?: InputMaybe<String_Comparison_Exp>;
  primaryContactName?: InputMaybe<String_Comparison_Exp>;
  profileCompleted?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicles?: InputMaybe<TransporterVehicles_Bool_Exp>;
  vehicles_aggregate?: InputMaybe<TransporterVehicles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "transporters" */
export enum Transporters_Constraint {
  /** unique or primary key constraint on columns "gstin" */
  TransportersGstinKey = 'transporters_gstin_key',
  /** unique or primary key constraint on columns "id" */
  TransportersPkey = 'transporters_pkey'
}

/** input type for incrementing numeric columns in table "transporters" */
export type Transporters_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transporters" */
export type Transporters_Insert_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicles?: InputMaybe<TransporterVehicles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Transporters_Max_Fields = {
  __typename?: 'transporters_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Transporters_Min_Fields = {
  __typename?: 'transporters_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  gstin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  primaryContactEmail?: Maybe<Scalars['String']>;
  primaryContactMobile?: Maybe<Scalars['String']>;
  primaryContactName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "transporters" */
export type Transporters_Mutation_Response = {
  __typename?: 'transporters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transporters>;
};

/** input type for inserting object relation for remote table "transporters" */
export type Transporters_Obj_Rel_Insert_Input = {
  data: Transporters_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Transporters_On_Conflict>;
};

/** on_conflict condition type for table "transporters" */
export type Transporters_On_Conflict = {
  constraint: Transporters_Constraint;
  update_columns?: Array<Transporters_Update_Column>;
  where?: InputMaybe<Transporters_Bool_Exp>;
};

/** Ordering options when selecting data from "transporters". */
export type Transporters_Order_By = {
  address?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  gstin?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  primaryContactEmail?: InputMaybe<Order_By>;
  primaryContactMobile?: InputMaybe<Order_By>;
  primaryContactName?: InputMaybe<Order_By>;
  profileCompleted?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vehicles_aggregate?: InputMaybe<TransporterVehicles_Aggregate_Order_By>;
};

/** primary key columns input for table: transporters */
export type Transporters_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transporters" */
export enum Transporters_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryContactEmail = 'primaryContactEmail',
  /** column name */
  PrimaryContactMobile = 'primaryContactMobile',
  /** column name */
  PrimaryContactName = 'primaryContactName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "transporters" */
export type Transporters_Set_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Transporters_Stddev_Fields = {
  __typename?: 'transporters_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Transporters_Stddev_Pop_Fields = {
  __typename?: 'transporters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Transporters_Stddev_Samp_Fields = {
  __typename?: 'transporters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "transporters" */
export type Transporters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transporters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transporters_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  gstin?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Transporters_Sum_Fields = {
  __typename?: 'transporters_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "transporters" */
export enum Transporters_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Gstin = 'gstin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryContactEmail = 'primaryContactEmail',
  /** column name */
  PrimaryContactMobile = 'primaryContactMobile',
  /** column name */
  PrimaryContactName = 'primaryContactName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Transporters_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transporters_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transporters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transporters_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transporters_Var_Pop_Fields = {
  __typename?: 'transporters_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Transporters_Var_Samp_Fields = {
  __typename?: 'transporters_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Transporters_Variance_Fields = {
  __typename?: 'transporters_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  address?: Maybe<Scalars['json']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: Enum_User_Role_Enum;
  session_token?: Maybe<Scalars['String']>;
  updatePassword: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "users" */
export type UsersAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  address?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dob?: InputMaybe<Date_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mobile?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Enum_User_Role_Enum_Comparison_Exp>;
  session_token?: InputMaybe<String_Comparison_Exp>;
  updatePassword?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "mobile" */
  UsersMobileKey = 'users_mobile_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "session_token" */
  UsersSessionTokenKey = 'users_session_token_key'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Enum_User_Role_Enum>;
  session_token?: InputMaybe<Scalars['String']>;
  updatePassword?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  session_token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dob?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  session_token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  address?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deletedAt?: InputMaybe<Order_By>;
  dob?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mobile?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  session_token?: InputMaybe<Order_By>;
  updatePassword?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  SessionToken = 'session_token',
  /** column name */
  UpdatePassword = 'updatePassword',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Enum_User_Role_Enum>;
  session_token?: InputMaybe<Scalars['String']>;
  updatePassword?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Enum_User_Role_Enum>;
  session_token?: InputMaybe<Scalars['String']>;
  updatePassword?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Dob = 'dob',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  SessionToken = 'session_token',
  /** column name */
  UpdatePassword = 'updatePassword',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** Currently rmSellers is considered as vendor */
export type VendorsItemCategories = {
  __typename?: 'vendorsItemCategories';
  itemCategory: Enum_Item_Category_Enum;
  /** An object relationship */
  item_category: Enum_Item_Category;
  /** An object relationship */
  vendor: RmSellers;
  vendorId: Scalars['Int'];
};

/** aggregated selection of "vendorsItemCategories" */
export type VendorsItemCategories_Aggregate = {
  __typename?: 'vendorsItemCategories_aggregate';
  aggregate?: Maybe<VendorsItemCategories_Aggregate_Fields>;
  nodes: Array<VendorsItemCategories>;
};

export type VendorsItemCategories_Aggregate_Bool_Exp = {
  count?: InputMaybe<VendorsItemCategories_Aggregate_Bool_Exp_Count>;
};

export type VendorsItemCategories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<VendorsItemCategories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendorsItemCategories" */
export type VendorsItemCategories_Aggregate_Fields = {
  __typename?: 'vendorsItemCategories_aggregate_fields';
  avg?: Maybe<VendorsItemCategories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<VendorsItemCategories_Max_Fields>;
  min?: Maybe<VendorsItemCategories_Min_Fields>;
  stddev?: Maybe<VendorsItemCategories_Stddev_Fields>;
  stddev_pop?: Maybe<VendorsItemCategories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<VendorsItemCategories_Stddev_Samp_Fields>;
  sum?: Maybe<VendorsItemCategories_Sum_Fields>;
  var_pop?: Maybe<VendorsItemCategories_Var_Pop_Fields>;
  var_samp?: Maybe<VendorsItemCategories_Var_Samp_Fields>;
  variance?: Maybe<VendorsItemCategories_Variance_Fields>;
};


/** aggregate fields of "vendorsItemCategories" */
export type VendorsItemCategories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<VendorsItemCategories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vendorsItemCategories" */
export type VendorsItemCategories_Aggregate_Order_By = {
  avg?: InputMaybe<VendorsItemCategories_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<VendorsItemCategories_Max_Order_By>;
  min?: InputMaybe<VendorsItemCategories_Min_Order_By>;
  stddev?: InputMaybe<VendorsItemCategories_Stddev_Order_By>;
  stddev_pop?: InputMaybe<VendorsItemCategories_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<VendorsItemCategories_Stddev_Samp_Order_By>;
  sum?: InputMaybe<VendorsItemCategories_Sum_Order_By>;
  var_pop?: InputMaybe<VendorsItemCategories_Var_Pop_Order_By>;
  var_samp?: InputMaybe<VendorsItemCategories_Var_Samp_Order_By>;
  variance?: InputMaybe<VendorsItemCategories_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vendorsItemCategories" */
export type VendorsItemCategories_Arr_Rel_Insert_Input = {
  data: Array<VendorsItemCategories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<VendorsItemCategories_On_Conflict>;
};

/** aggregate avg on columns */
export type VendorsItemCategories_Avg_Fields = {
  __typename?: 'vendorsItemCategories_avg_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Avg_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vendorsItemCategories". All fields are combined with a logical 'AND'. */
export type VendorsItemCategories_Bool_Exp = {
  _and?: InputMaybe<Array<VendorsItemCategories_Bool_Exp>>;
  _not?: InputMaybe<VendorsItemCategories_Bool_Exp>;
  _or?: InputMaybe<Array<VendorsItemCategories_Bool_Exp>>;
  itemCategory?: InputMaybe<Enum_Item_Category_Enum_Comparison_Exp>;
  item_category?: InputMaybe<Enum_Item_Category_Bool_Exp>;
  vendor?: InputMaybe<RmSellers_Bool_Exp>;
  vendorId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendorsItemCategories" */
export enum VendorsItemCategories_Constraint {
  /** unique or primary key constraint on columns "vendorId", "itemCategory" */
  VendorsItemCategoriesPkey = 'vendorsItemCategories_pkey'
}

/** input type for incrementing numeric columns in table "vendorsItemCategories" */
export type VendorsItemCategories_Inc_Input = {
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "vendorsItemCategories" */
export type VendorsItemCategories_Insert_Input = {
  itemCategory?: InputMaybe<Enum_Item_Category_Enum>;
  item_category?: InputMaybe<Enum_Item_Category_Obj_Rel_Insert_Input>;
  vendor?: InputMaybe<RmSellers_Obj_Rel_Insert_Input>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type VendorsItemCategories_Max_Fields = {
  __typename?: 'vendorsItemCategories_max_fields';
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Max_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type VendorsItemCategories_Min_Fields = {
  __typename?: 'vendorsItemCategories_min_fields';
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Min_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendorsItemCategories" */
export type VendorsItemCategories_Mutation_Response = {
  __typename?: 'vendorsItemCategories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<VendorsItemCategories>;
};

/** on_conflict condition type for table "vendorsItemCategories" */
export type VendorsItemCategories_On_Conflict = {
  constraint: VendorsItemCategories_Constraint;
  update_columns?: Array<VendorsItemCategories_Update_Column>;
  where?: InputMaybe<VendorsItemCategories_Bool_Exp>;
};

/** Ordering options when selecting data from "vendorsItemCategories". */
export type VendorsItemCategories_Order_By = {
  itemCategory?: InputMaybe<Order_By>;
  item_category?: InputMaybe<Enum_Item_Category_Order_By>;
  vendor?: InputMaybe<RmSellers_Order_By>;
  vendorId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendorsItemCategories */
export type VendorsItemCategories_Pk_Columns_Input = {
  itemCategory: Enum_Item_Category_Enum;
  vendorId: Scalars['Int'];
};

/** select columns of table "vendorsItemCategories" */
export enum VendorsItemCategories_Select_Column {
  /** column name */
  ItemCategory = 'itemCategory',
  /** column name */
  VendorId = 'vendorId'
}

/** input type for updating data in table "vendorsItemCategories" */
export type VendorsItemCategories_Set_Input = {
  itemCategory?: InputMaybe<Enum_Item_Category_Enum>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type VendorsItemCategories_Stddev_Fields = {
  __typename?: 'vendorsItemCategories_stddev_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Stddev_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type VendorsItemCategories_Stddev_Pop_Fields = {
  __typename?: 'vendorsItemCategories_stddev_pop_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Stddev_Pop_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type VendorsItemCategories_Stddev_Samp_Fields = {
  __typename?: 'vendorsItemCategories_stddev_samp_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Stddev_Samp_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vendorsItemCategories" */
export type VendorsItemCategories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: VendorsItemCategories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type VendorsItemCategories_Stream_Cursor_Value_Input = {
  itemCategory?: InputMaybe<Enum_Item_Category_Enum>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type VendorsItemCategories_Sum_Fields = {
  __typename?: 'vendorsItemCategories_sum_fields';
  vendorId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Sum_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** update columns of table "vendorsItemCategories" */
export enum VendorsItemCategories_Update_Column {
  /** column name */
  ItemCategory = 'itemCategory',
  /** column name */
  VendorId = 'vendorId'
}

export type VendorsItemCategories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VendorsItemCategories_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VendorsItemCategories_Set_Input>;
  /** filter the rows which have to be updated */
  where: VendorsItemCategories_Bool_Exp;
};

/** aggregate var_pop on columns */
export type VendorsItemCategories_Var_Pop_Fields = {
  __typename?: 'vendorsItemCategories_var_pop_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Var_Pop_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type VendorsItemCategories_Var_Samp_Fields = {
  __typename?: 'vendorsItemCategories_var_samp_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Var_Samp_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type VendorsItemCategories_Variance_Fields = {
  __typename?: 'vendorsItemCategories_variance_fields';
  vendorId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "vendorsItemCategories" */
export type VendorsItemCategories_Variance_Order_By = {
  vendorId?: InputMaybe<Order_By>;
};

/** columns and relationships of "weighbridgeReceipts" */
export type WeighbridgeReceipts = {
  __typename?: 'weighbridgeReceipts';
  amountTaken?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerName: Scalars['String'];
  driverName: Scalars['String'];
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  material?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleNumber: Scalars['String'];
};

/** aggregated selection of "weighbridgeReceipts" */
export type WeighbridgeReceipts_Aggregate = {
  __typename?: 'weighbridgeReceipts_aggregate';
  aggregate?: Maybe<WeighbridgeReceipts_Aggregate_Fields>;
  nodes: Array<WeighbridgeReceipts>;
};

/** aggregate fields of "weighbridgeReceipts" */
export type WeighbridgeReceipts_Aggregate_Fields = {
  __typename?: 'weighbridgeReceipts_aggregate_fields';
  avg?: Maybe<WeighbridgeReceipts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<WeighbridgeReceipts_Max_Fields>;
  min?: Maybe<WeighbridgeReceipts_Min_Fields>;
  stddev?: Maybe<WeighbridgeReceipts_Stddev_Fields>;
  stddev_pop?: Maybe<WeighbridgeReceipts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WeighbridgeReceipts_Stddev_Samp_Fields>;
  sum?: Maybe<WeighbridgeReceipts_Sum_Fields>;
  var_pop?: Maybe<WeighbridgeReceipts_Var_Pop_Fields>;
  var_samp?: Maybe<WeighbridgeReceipts_Var_Samp_Fields>;
  variance?: Maybe<WeighbridgeReceipts_Variance_Fields>;
};


/** aggregate fields of "weighbridgeReceipts" */
export type WeighbridgeReceipts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WeighbridgeReceipts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WeighbridgeReceipts_Avg_Fields = {
  __typename?: 'weighbridgeReceipts_avg_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "weighbridgeReceipts". All fields are combined with a logical 'AND'. */
export type WeighbridgeReceipts_Bool_Exp = {
  _and?: InputMaybe<Array<WeighbridgeReceipts_Bool_Exp>>;
  _not?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
  _or?: InputMaybe<Array<WeighbridgeReceipts_Bool_Exp>>;
  amountTaken?: InputMaybe<Float8_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerName?: InputMaybe<String_Comparison_Exp>;
  driverName?: InputMaybe<String_Comparison_Exp>;
  emptyVehicleWtKg?: InputMaybe<Float8_Comparison_Exp>;
  fullVehicleWtKg?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  material?: InputMaybe<String_Comparison_Exp>;
  receiptNumber?: InputMaybe<String_Comparison_Exp>;
  remark?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vehicleNumber?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "weighbridgeReceipts" */
export enum WeighbridgeReceipts_Constraint {
  /** unique or primary key constraint on columns "id" */
  WeighbridgeReceiptsPkey = 'weighbridgeReceipts_pkey'
}

/** input type for incrementing numeric columns in table "weighbridgeReceipts" */
export type WeighbridgeReceipts_Inc_Input = {
  amountTaken?: InputMaybe<Scalars['float8']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "weighbridgeReceipts" */
export type WeighbridgeReceipts_Insert_Input = {
  amountTaken?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerName?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  material?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WeighbridgeReceipts_Max_Fields = {
  __typename?: 'weighbridgeReceipts_max_fields';
  amountTaken?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerName?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WeighbridgeReceipts_Min_Fields = {
  __typename?: 'weighbridgeReceipts_min_fields';
  amountTaken?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  customerName?: Maybe<Scalars['String']>;
  driverName?: Maybe<Scalars['String']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleNumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "weighbridgeReceipts" */
export type WeighbridgeReceipts_Mutation_Response = {
  __typename?: 'weighbridgeReceipts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WeighbridgeReceipts>;
};

/** on_conflict condition type for table "weighbridgeReceipts" */
export type WeighbridgeReceipts_On_Conflict = {
  constraint: WeighbridgeReceipts_Constraint;
  update_columns?: Array<WeighbridgeReceipts_Update_Column>;
  where?: InputMaybe<WeighbridgeReceipts_Bool_Exp>;
};

/** Ordering options when selecting data from "weighbridgeReceipts". */
export type WeighbridgeReceipts_Order_By = {
  amountTaken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerName?: InputMaybe<Order_By>;
  driverName?: InputMaybe<Order_By>;
  emptyVehicleWtKg?: InputMaybe<Order_By>;
  fullVehicleWtKg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  material?: InputMaybe<Order_By>;
  receiptNumber?: InputMaybe<Order_By>;
  remark?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vehicleNumber?: InputMaybe<Order_By>;
};

/** primary key columns input for table: weighbridgeReceipts */
export type WeighbridgeReceipts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "weighbridgeReceipts" */
export enum WeighbridgeReceipts_Select_Column {
  /** column name */
  AmountTaken = 'amountTaken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerName = 'customerName',
  /** column name */
  DriverName = 'driverName',
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  Id = 'id',
  /** column name */
  Material = 'material',
  /** column name */
  Remark = 'remark',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VehicleNumber = 'vehicleNumber'
}

/** input type for updating data in table "weighbridgeReceipts" */
export type WeighbridgeReceipts_Set_Input = {
  amountTaken?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerName?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  material?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type WeighbridgeReceipts_Stddev_Fields = {
  __typename?: 'weighbridgeReceipts_stddev_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WeighbridgeReceipts_Stddev_Pop_Fields = {
  __typename?: 'weighbridgeReceipts_stddev_pop_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WeighbridgeReceipts_Stddev_Samp_Fields = {
  __typename?: 'weighbridgeReceipts_stddev_samp_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "weighbridgeReceipts" */
export type WeighbridgeReceipts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WeighbridgeReceipts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type WeighbridgeReceipts_Stream_Cursor_Value_Input = {
  amountTaken?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  customerName?: InputMaybe<Scalars['String']>;
  driverName?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg?: InputMaybe<Scalars['float8']>;
  fullVehicleWtKg?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  material?: InputMaybe<Scalars['String']>;
  remark?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type WeighbridgeReceipts_Sum_Fields = {
  __typename?: 'weighbridgeReceipts_sum_fields';
  amountTaken?: Maybe<Scalars['float8']>;
  emptyVehicleWtKg?: Maybe<Scalars['float8']>;
  fullVehicleWtKg?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "weighbridgeReceipts" */
export enum WeighbridgeReceipts_Update_Column {
  /** column name */
  AmountTaken = 'amountTaken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerName = 'customerName',
  /** column name */
  DriverName = 'driverName',
  /** column name */
  EmptyVehicleWtKg = 'emptyVehicleWtKg',
  /** column name */
  FullVehicleWtKg = 'fullVehicleWtKg',
  /** column name */
  Id = 'id',
  /** column name */
  Material = 'material',
  /** column name */
  Remark = 'remark',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VehicleNumber = 'vehicleNumber'
}

export type WeighbridgeReceipts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WeighbridgeReceipts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WeighbridgeReceipts_Set_Input>;
  /** filter the rows which have to be updated */
  where: WeighbridgeReceipts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type WeighbridgeReceipts_Var_Pop_Fields = {
  __typename?: 'weighbridgeReceipts_var_pop_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WeighbridgeReceipts_Var_Samp_Fields = {
  __typename?: 'weighbridgeReceipts_var_samp_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WeighbridgeReceipts_Variance_Fields = {
  __typename?: 'weighbridgeReceipts_variance_fields';
  amountTaken?: Maybe<Scalars['Float']>;
  emptyVehicleWtKg?: Maybe<Scalars['Float']>;
  fullVehicleWtKg?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type UserLoginMutationVariables = Exact<{
  mobile: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserLoginMutation = { __typename?: 'mutation_root', login?: { __typename?: 'LoginOutput', name: string, mobile: string, email?: string | null, role: string, token: string, updatePassword: boolean } | null };

export type AddItemMutationVariables = Exact<{
  object: Items_Insert_Input;
}>;


export type AddItemMutation = { __typename?: 'mutation_root', insert_items_one?: { __typename?: 'items', category: Enum_Item_Category_Enum, id: string, name: string } | null };

export type AddKesharKaliCodesMutationVariables = Exact<{
  objects: Array<Ecp13labels_Insert_Input> | Ecp13labels_Insert_Input;
}>;


export type AddKesharKaliCodesMutation = { __typename?: 'mutation_root', ecp13?: { __typename?: 'ecp13mutation_root', insert_labels?: { __typename?: 'ecp13labels_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'ecp13labels', code: string, created_at: any, id: number }> } | null } | null };

export type AddOrRemoveBagsToOutwardShipmentOrderMutationVariables = Exact<{
  outwardShipmentOrderId: Scalars['Int'];
  loadedBagsCount: Scalars['Int'];
}>;


export type AddOrRemoveBagsToOutwardShipmentOrderMutation = { __typename?: 'mutation_root', update_outwardShipmentOrders_by_pk?: { __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null } | null };

export type AddPaymentDetailsMutationVariables = Exact<{
  inwardShipmentId: Scalars['Int'];
  isPaymentDone: Scalars['Boolean'];
  object: Payment_Insert_Input;
}>;


export type AddPaymentDetailsMutation = { __typename?: 'mutation_root', insert_payment_one?: { __typename?: 'payment', amount?: any | null, created_at: Date | string, deductions?: any | null, id: string, inward_shipment_id: number, mode?: string | null, payment_done_at?: Date | string | null, payment_number?: string | null, rmSeller_id: number } | null, update_inwardShipments_by_pk?: { __typename?: 'inwardShipments', isPaymentDone: boolean, id: number } | null };

export type AdminSetUserPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type AdminSetUserPasswordMutation = { __typename?: 'mutation_root', adminSetPassword?: { __typename?: 'StatusOutput', status: boolean } | null };

export type ApprovePurchaseOrderMutationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApprovePurchaseOrderMutationMutation = { __typename?: 'mutation_root', update_purchaseOrders_by_pk?: { __typename?: 'purchaseOrders', id: number, poApproved?: boolean | null } | null };

export type AttachDriversToOutwardShipmentMutationMutationVariables = Exact<{
  objects: Array<OutwardShipmentsDrivers_Insert_Input> | OutwardShipmentsDrivers_Insert_Input;
}>;


export type AttachDriversToOutwardShipmentMutationMutation = { __typename?: 'mutation_root', driver?: { __typename?: 'outwardShipmentsDrivers_mutation_response', returning: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }> } | null };

export type CompleteInwardShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
  emptyVehicleImage?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg: Scalars['float8'];
  emptyWtTakenAt: Scalars['timestamptz'];
}>;


export type CompleteInwardShipmentMutation = { __typename?: 'mutation_root', completeInwardShipment?: { __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null } | null };

export type CreateBagsOthersInwardShipmentMutationVariables = Exact<{
  object: InwardShipments_Insert_Input;
}>;


export type CreateBagsOthersInwardShipmentMutation = { __typename?: 'mutation_root', insert_inwardShipments_one?: { __typename?: 'inwardShipments', deliveryPersonMobile?: string | null, driverName?: string | null, vehicleNumber?: string | null, updatedAt?: Date | string | null, createdAt?: Date | string | null, completedAt?: Date | string | null, status?: Enum_Inward_Shipment_Status_Enum | null, grn?: string | null, po_id?: number | null, id: number, items: Array<{ __typename?: 'inwardShipmentItems', rmSellerId?: number | null, destination?: Enum_Source_Destination_Enum | null, godownId?: number | null, millId?: number | null, isBackFromMaintenance?: boolean | null, source?: Enum_InwardShipmentItem_Source_Enum | null, updatedAt?: Date | string | null, itemId?: string | null, id: number, createdAt?: Date | string | null, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, invoice?: string | null, remark?: string | null }> } | null };

export type CreateBagsOthersOutwardShipmentMutationVariables = Exact<{
  object: OutwardShipments_Insert_Input;
}>;


export type CreateBagsOthersOutwardShipmentMutation = { __typename?: 'mutation_root', insert_outwardShipments_one?: { __typename?: 'outwardShipments', createdAt?: Date | string | null, grn?: string | null, id: number, invoiceAddedAt?: Date | string | null, nonProductPersonMobile?: string | null, nonProductPersonName?: string | null, nonProductVehicleNumber?: string | null, updatedAt?: Date | string | null, orders: Array<{ __typename?: 'outwardShipmentOrders', createdAt?: Date | string | null, godownId?: number | null, id: number, invoiceNo?: string | null, isOutForMaintenance?: boolean | null, itemId?: string | null, loadedBagsCount?: number | null, millId?: number | null, source?: Enum_OutwardShipmentOrder_Source_Enum | null, remark?: string | null }> } | null };

export type CreateBrokerMutationVariables = Exact<{
  address?: InputMaybe<Scalars['jsonb']>;
  email?: InputMaybe<Scalars['String']>;
  gst?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type CreateBrokerMutation = { __typename?: 'mutation_root', insert_brokers_one?: { __typename?: 'brokers', address?: any | null, email?: string | null, gst?: string | null, id: string, mobile?: string | null, name: string } | null };

export type CreateGodownMutationVariables = Exact<{
  name: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  capacityBags?: InputMaybe<Scalars['Int']>;
}>;


export type CreateGodownMutation = { __typename?: 'mutation_root', createGodown?: { __typename?: 'godowns', id: number, name: string, location?: string | null, capacityBags?: number | null, description?: string | null, inwardShipmentItems: Array<{ __typename?: 'inwardShipmentItems', id: number }>, millingInItems: Array<{ __typename?: 'millingInItems', id: number }>, summary?: { __typename?: 'godownSummary', id?: number | null } | null } | null };

export type CreateInternalTransferManualShipmentMutationVariables = Exact<{
  object: InternalTransferShipments_Insert_Input;
}>;


export type CreateInternalTransferManualShipmentMutation = { __typename?: 'mutation_root', insert_internalTransferShipments_one?: { __typename?: 'internalTransferShipments', id: string } | null };

export type CreateInternalTransferShipmentItemMutationVariables = Exact<{
  object: InternalTransferShipmentItems_Insert_Input;
}>;


export type CreateInternalTransferShipmentItemMutation = { __typename?: 'mutation_root', insert_internalTransferShipmentItems_one?: { __typename?: 'internalTransferShipmentItems', bagsCount?: number | null, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, id: string, bagWtKg?: any | null, created_at: Date | string, internalTransferShipmentId: string, netMaterialWtPerBagKg?: any | null, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, rmEmptyBagsWtKg?: any | null, rmPaddyGrade?: string | null, source?: Enum_InternalTransfer_Source_Destination_Enum | null, updated_at: Date | string, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, product?: { __typename?: 'products', id: number, brand: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', id: number, name: string } | null } | null };

export type CreateInternalTransferShipmentMutationVariables = Exact<{
  personName: Scalars['String'];
  firstVehicleWtKg: Scalars['numeric'];
  vehicleNumber: Scalars['String'];
  firstVehicleImage?: InputMaybe<Scalars['String']>;
  materialType: Enum_Item_Category_Enum;
  firstVehicleWtTakenAt: Scalars['timestamptz'];
}>;


export type CreateInternalTransferShipmentMutation = { __typename?: 'mutation_root', insert_internalTransferShipments_one?: { __typename?: 'internalTransferShipments', id: string, personName?: string | null, vehicleNumber?: string | null, grn?: string | null, firstVehicleWtKg?: any | null, firstVehicleImage?: string | null, firstVehicleWtTakenAt?: Date | string | null, materialType?: Enum_Item_Category_Enum | null } | null };

export type CreateInwardShipmentItemMutationVariables = Exact<{
  inwardShipmentId: Scalars['Int'];
  rmSellerId: Scalars['Int'];
  rawMaterialId: Scalars['Int'];
  paddyGrade?: InputMaybe<Scalars['String']>;
  bagsCount: Scalars['Int'];
  materialReturned: Scalars['Boolean'];
  materialReturnedReason?: InputMaybe<Scalars['String']>;
  emptyBagsReturned: Scalars['Boolean'];
  godownId?: InputMaybe<Scalars['Int']>;
  destination?: InputMaybe<Enum_Source_Destination_Enum>;
  millId?: InputMaybe<Scalars['Int']>;
}>;


export type CreateInwardShipmentItemMutation = { __typename?: 'mutation_root', createInwardShipmentItem?: { __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, emptyBagsReturned?: boolean | null, pricePerKg?: any | null, destination?: Enum_Source_Destination_Enum | null, materialReturned?: boolean | null, materialReturnedReason?: string | null, shipment: { __typename?: 'inwardShipments', id: number, vehicleNumber?: string | null }, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, seller?: { __typename?: 'rmSellers', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null } | null };

export type CreateInwardShipmentMutationVariables = Exact<{
  vehicleNumber: Scalars['String'];
  driverName: Scalars['String'];
  fullVehicleWtKg: Scalars['float8'];
  fullVehicleImage?: InputMaybe<Scalars['String']>;
}>;


export type CreateInwardShipmentMutation = { __typename?: 'mutation_root', createInwardShipment?: { __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> } | null };

export type CreateOutwardShipmentMutationVariables = Exact<{
  transporterId: Scalars['Int'];
  transporterVehicleId: Scalars['Int'];
  emptyVehicleWtKg: Scalars['float8'];
}>;


export type CreateOutwardShipmentMutation = { __typename?: 'mutation_root', createOutwardShipment?: { __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, mobile: string, name: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null }> } | null };

export type CreateOutwardShipmentOrderMutationVariables = Exact<{
  outwardShipmentId: Scalars['Int'];
  productId: Scalars['Int'];
  bagSizeKg: Scalars['Int'];
  plannedBagsCount: Scalars['Int'];
  brokerId?: InputMaybe<Scalars['uuid']>;
}>;


export type CreateOutwardShipmentOrderMutation = { __typename?: 'mutation_root', createOutwardShipmentOrder?: { __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, brokerId?: string | null } | null };

export type CreateProductBuyerMutationVariables = Exact<{
  name: Scalars['String'];
  gstin?: InputMaybe<Scalars['String']>;
  primaryContactName?: InputMaybe<Scalars['String']>;
  primaryContactMobile?: InputMaybe<Scalars['String']>;
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['json']>;
}>;


export type CreateProductBuyerMutation = { __typename?: 'mutation_root', createProductBuyer?: { __typename?: 'productBuyers', id: number, name: string, address?: any | null, gstin?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, primaryContactName?: string | null, profileCompleted?: boolean | null } | null };

export type CreateProductInternalShipmentItemMutationVariables = Exact<{
  productId: Scalars['Int'];
  bagWtKg: Scalars['numeric'];
  bagsCount: Scalars['Int'];
  source: Enum_InternalTransfer_Source_Destination_Enum;
  destination: Enum_InternalTransfer_Source_Destination_Enum;
  destinationGodownId?: InputMaybe<Scalars['Int']>;
  destinationMillId?: InputMaybe<Scalars['Int']>;
  sourceGodownId?: InputMaybe<Scalars['Int']>;
  sourceMillId?: InputMaybe<Scalars['Int']>;
  internalTransferShipmentId: Scalars['uuid'];
}>;


export type CreateProductInternalShipmentItemMutation = { __typename?: 'mutation_root', insert_internalTransferShipmentItems_one?: { __typename?: 'internalTransferShipmentItems', bagWtKg?: any | null, bagsCount?: number | null, created_at: Date | string, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, id: string, internalTransferShipmentId: string, source?: Enum_InternalTransfer_Source_Destination_Enum | null, updated_at: Date | string, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, product?: { __typename?: 'products', id: number, brand: string } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', name: string, id: number } | null } | null };

export type CreateProductMutationVariables = Exact<{
  productTypeId: Scalars['Int'];
  brand: Scalars['String'];
}>;


export type CreateProductMutation = { __typename?: 'mutation_root', createProduct?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null };

export type CreateProductTypeMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateProductTypeMutation = { __typename?: 'mutation_root', createProductType?: { __typename?: 'productTypes', id: number, name: string } | null };

export type CreatePurchaseOrderMutationMutationVariables = Exact<{
  purchaseOrderItems: PurchaseOrderItems_Arr_Rel_Insert_Input;
  discount?: InputMaybe<Scalars['numeric']>;
  payment_terms?: InputMaybe<Scalars['String']>;
  created_by_contact_no?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  contract_validity?: InputMaybe<Scalars['timestamptz']>;
}>;


export type CreatePurchaseOrderMutationMutation = { __typename?: 'mutation_root', insert_purchaseOrders_one?: { __typename?: 'purchaseOrders', id: number, discount?: any | null, payment_terms?: string | null, poNumber?: string | null, created_by_contact_no?: string | null, created_by_name?: string | null } | null };

export type CreateRawMaterialMutationVariables = Exact<{
  data: Array<RawMaterialsProductTypes_Insert_Input> | RawMaterialsProductTypes_Insert_Input;
  name: Scalars['String'];
  type?: InputMaybe<Enum_Raw_Material_Type_Enum>;
}>;


export type CreateRawMaterialMutation = { __typename?: 'mutation_root', insert_rawMaterials_one?: { __typename?: 'rawMaterials', id: number, createdAt?: Date | string | null, name: string, type: Enum_Raw_Material_Type_Enum, rawMaterialsProductTypes: Array<{ __typename?: 'rawMaterialsProductTypes', productType: { __typename?: 'productTypes', id: number, name: string } }> } | null };

export type CreateTransporterDriverMutationVariables = Exact<{
  object: TransporterDrivers_Insert_Input;
}>;


export type CreateTransporterDriverMutation = { __typename?: 'mutation_root', createTransporterDriver?: { __typename?: 'transporterDrivers', id: number, name: string, address?: any | null, mobile: string, drivingLicence?: string | null, profileCompleted?: boolean | null, photo?: string | null, panFile?: string | null } | null };

export type CreateTransporterMutationVariables = Exact<{
  name: Scalars['String'];
  address: Scalars['json'];
  gstin?: InputMaybe<Scalars['String']>;
  primaryContactName: Scalars['String'];
  primaryContactMobile: Scalars['String'];
  primaryContactEmail?: InputMaybe<Scalars['String']>;
}>;


export type CreateTransporterMutation = { __typename?: 'mutation_root', createTransporter?: { __typename?: 'transporters', id: number, name: string, address?: any | null, gstin?: string | null, profileCompleted?: boolean | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null } | null };

export type CreateTransporterVehicleMutationVariables = Exact<{
  object: TransporterVehicles_Insert_Input;
}>;


export type CreateTransporterVehicleMutation = { __typename?: 'mutation_root', createTransporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, engineNo?: string | null, chassisNo?: string | null, capacityKgs?: any | null, rcS3Key?: string | null, transporter: { __typename?: 'transporters', id: number, name: string } } | null };

export type CreateUserMutationVariables = Exact<{
  mobile: Scalars['String'];
  name: Scalars['String'];
  address?: InputMaybe<Scalars['json']>;
  dob?: InputMaybe<Scalars['date']>;
  email?: InputMaybe<Scalars['String']>;
  role: UserRole;
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', createUser?: { __typename?: 'CreateUserOutputType', id: number, name: string, address?: any | null, mobile: string, email?: string | null, role: UserRole, dob?: Date | string | null, updatePassword: boolean } | null };

export type CreateVendorMutationVariables = Exact<{
  object: RmSellers_Insert_Input;
}>;


export type CreateVendorMutation = { __typename?: 'mutation_root', createRmSeller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null, gstin?: string | null, profileCompleted?: boolean | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, doesMaintenance?: boolean | null, createdAt?: Date | string | null, updatedAt?: Date | string | null, vendorsItemCategories: Array<{ __typename?: 'vendorsItemCategories', itemCategory: Enum_Item_Category_Enum }> } | null };

export type CreateWeighbridgeReceiptMutationVariables = Exact<{
  vehicleNumber: Scalars['String'];
  driverName: Scalars['String'];
  customerName: Scalars['String'];
  material?: InputMaybe<Scalars['String']>;
  emptyVehicleWtKg: Scalars['float8'];
}>;


export type CreateWeighbridgeReceiptMutation = { __typename?: 'mutation_root', createWeighbridgeReceipt?: { __typename?: 'weighbridgeReceipts', id: number, receiptNumber?: string | null, vehicleNumber: string, driverName: string, customerName: string, material?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, amountTaken?: any | null, remark?: string | null } | null };

export type DeleteBrokerByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteBrokerByIdMutation = { __typename?: 'mutation_root', delete_brokers_by_pk?: { __typename?: 'brokers', id: string } | null };

export type DeleteGodownMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteGodownMutation = { __typename?: 'mutation_root', deleteGodown?: { __typename?: 'godowns', id: number } | null };

export type DeleteInternalTransferShipmentItemMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteInternalTransferShipmentItemMutation = { __typename?: 'mutation_root', delete_internalTransferShipmentItems_by_pk?: { __typename?: 'internalTransferShipmentItems', id: string } | null };

export type DeleteInternalTransferShipmentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteInternalTransferShipmentMutation = { __typename?: 'mutation_root', delete_internalTransferShipments_by_pk?: { __typename?: 'internalTransferShipments', id: string } | null };

export type DeleteInwardShipmentItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInwardShipmentItemMutation = { __typename?: 'mutation_root', deleteInwardShipmentItem?: { __typename?: 'inwardShipmentItems', id: number } | null };

export type DeleteInwardShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInwardShipmentMutation = { __typename?: 'mutation_root', deleteInwardShipment?: { __typename?: 'inwardShipments', id: number } | null };

export type DeleteItemMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteItemMutation = { __typename?: 'mutation_root', delete_items_by_pk?: { __typename?: 'items', id: string } | null };

export type DeleteOutwardShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOutwardShipmentMutation = { __typename?: 'mutation_root', deleteOutwardShipment?: { __typename?: 'outwardShipments', id: number } | null };

export type DeleteOutwardShipmentOrderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOutwardShipmentOrderMutation = { __typename?: 'mutation_root', deleteOutwardShipmentOrder?: { __typename?: 'outwardShipmentOrders', id: number } | null };

export type DeleteProductBuyerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductBuyerMutation = { __typename?: 'mutation_root', deleteProductBuyer?: { __typename?: 'productBuyers', id: number } | null };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMutation = { __typename?: 'mutation_root', deleteProduct?: { __typename?: 'products', id: number } | null };

export type DeleteProductTypeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductTypeMutation = { __typename?: 'mutation_root', deleteProductType?: { __typename?: 'productTypes', id: number } | null };

export type DeletePurchaseOrderMutationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePurchaseOrderMutationMutation = { __typename?: 'mutation_root', delete_purchaseOrders_by_pk?: { __typename?: 'purchaseOrders', id: number } | null };

export type DeleteRawMaterialMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRawMaterialMutation = { __typename?: 'mutation_root', deleteRawMaterial?: { __typename?: 'rawMaterials', id: number } | null };

export type DeleteRmSellerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRmSellerMutation = { __typename?: 'mutation_root', delete_vendorsItemCategories?: { __typename?: 'vendorsItemCategories_mutation_response', affected_rows: number } | null, deleteRmSeller?: { __typename?: 'rmSellers', id: number } | null };

export type DeleteS3FileMutationVariables = Exact<{
  s3Key: Scalars['String'];
}>;


export type DeleteS3FileMutation = { __typename?: 'mutation_root', deleteS3File?: { __typename?: 'DeleteS3FileOutputType', status: boolean } | null };

export type DeleteTransporterDriverMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTransporterDriverMutation = { __typename?: 'mutation_root', deleteTransporterDriver?: { __typename?: 'transporterDrivers', id: number } | null };

export type DeleteTransporterMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTransporterMutation = { __typename?: 'mutation_root', deleteTransporter?: { __typename?: 'transporters', id: number } | null };

export type DeleteTransporterVehicleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTransporterVehicleMutation = { __typename?: 'mutation_root', deleteTransporterVehicle?: { __typename?: 'transporterVehicles', id: number } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', deleteUser?: { __typename?: 'users', id: number } | null };

export type DeleteWeighbridgeReceiptByPkMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteWeighbridgeReceiptByPkMutation = { __typename?: 'mutation_root', delete_weighbridgeReceipts_by_pk?: { __typename?: 'weighbridgeReceipts', id: number } | null };

export type FinishInternalTransferShipmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  secondVehicleImage?: InputMaybe<Scalars['String']>;
  secondVehicleWtKg: Scalars['numeric'];
  secondVehicleWtTakenAt: Scalars['timestamptz'];
}>;


export type FinishInternalTransferShipmentMutation = { __typename?: 'mutation_root', update_internalTransferShipments_by_pk?: { __typename?: 'internalTransferShipments', id: string } | null };

export type FinishInwardShipmentUnloadingMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FinishInwardShipmentUnloadingMutation = { __typename?: 'mutation_root', update_inwardShipments_by_pk?: { __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> } | null };

export type FinishOutwardShipmentLoadingMutationVariables = Exact<{
  id: Scalars['Int'];
  loadedAt: Scalars['timestamptz'];
}>;


export type FinishOutwardShipmentLoadingMutation = { __typename?: 'mutation_root', update_outwardShipments_by_pk?: { __typename?: 'outwardShipments', id: number } | null };

export type FinishOutwardShipmentOrdersMutationVariables = Exact<{
  id: Scalars['Int'];
  ordersTakenAt: Scalars['timestamptz'];
}>;


export type FinishOutwardShipmentOrdersMutation = { __typename?: 'mutation_root', update_outwardShipments_by_pk?: { __typename?: 'outwardShipments', id: number } | null };

export type FinishOutwardShipmentTruckOutMutationVariables = Exact<{
  id: Scalars['Int'];
  fullVehicleWtKg: Scalars['float8'];
  fullWtTakenAt: Scalars['timestamptz'];
  truckOutAt: Scalars['timestamptz'];
}>;


export type FinishOutwardShipmentTruckOutMutation = { __typename?: 'mutation_root', update_outwardShipments_by_pk?: { __typename?: 'outwardShipments', id: number } | null };

export type MarkDeliveryCompletedForPoMutationVariables = Exact<{
  delivery_completed_at: Scalars['timestamptz'];
  id: Scalars['Int'];
}>;


export type MarkDeliveryCompletedForPoMutation = { __typename?: 'mutation_root', update_purchaseOrders_by_pk?: { __typename?: 'purchaseOrders', id: number, poNumber?: string | null, delivery_completed_at?: Date | string | null } | null };

export type UpdateBrokerByIdMutationVariables = Exact<{
  id: Scalars['uuid'];
  address?: InputMaybe<Scalars['jsonb']>;
  email?: InputMaybe<Scalars['String']>;
  gst?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type UpdateBrokerByIdMutation = { __typename?: 'mutation_root', update_brokers_by_pk?: { __typename?: 'brokers', id: string, name: string, mobile?: string | null, email?: string | null, gst?: string | null, address?: any | null } | null };

export type UpdateGodownMutationVariables = Exact<{
  id: Scalars['Int'];
  capacityBags: Scalars['Int'];
  location?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type UpdateGodownMutation = { __typename?: 'mutation_root', updateGodown?: { __typename?: 'godowns', id: number, name: string, location?: string | null, capacityBags?: number | null } | null };

export type UpdateInternalTransferShipmentItemMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: InternalTransferShipmentItems_Set_Input;
}>;


export type UpdateInternalTransferShipmentItemMutation = { __typename?: 'mutation_root', update_internalTransferShipmentItems_by_pk?: { __typename?: 'internalTransferShipmentItems', bagsCount?: number | null, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, id: string, bagWtKg?: any | null, created_at: Date | string, internalTransferShipmentId: string, netMaterialWtPerBagKg?: any | null, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, rmEmptyBagsWtKg?: any | null, rmPaddyGrade?: string | null, source?: Enum_InternalTransfer_Source_Destination_Enum | null, updated_at: Date | string, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, product?: { __typename?: 'products', id: number, brand: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', id: number, name: string } | null } | null };

export type UpdateInternalTransferShipmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  firstVehicleWtKg: Scalars['numeric'];
  personName: Scalars['String'];
  vehicleNumber: Scalars['String'];
  detailsEnteredAt?: InputMaybe<Scalars['timestamptz']>;
  firstVehicleImage?: InputMaybe<Scalars['String']>;
  materialType: Enum_Item_Category_Enum;
  firstVehicleWtTakenAt: Scalars['timestamptz'];
}>;


export type UpdateInternalTransferShipmentMutation = { __typename?: 'mutation_root', update_internalTransferShipments_by_pk?: { __typename?: 'internalTransferShipments', id: string, detailsEnteredAt?: Date | string | null, grn?: string | null, firstVehicleWtKg?: any | null, firstVehicleImage?: string | null, firstVehicleWtTakenAt?: Date | string | null, personName?: string | null, vehicleNumber?: string | null, materialType?: Enum_Item_Category_Enum | null } | null };

export type UpdateInwardShipmentAdminMutationVariables = Exact<{
  id: Scalars['Int'];
  vehicleNumber: Scalars['String'];
  fullVehicleWtKg: Scalars['float8'];
  emptyVehicleWtKg: Scalars['float8'];
  driverName: Scalars['String'];
  laborChargePerBag?: InputMaybe<Scalars['float8']>;
  laborChargeType?: InputMaybe<Enum_Labor_Charge_Type_Enum>;
  weighbridgeCharges?: InputMaybe<Scalars['float8']>;
}>;


export type UpdateInwardShipmentAdminMutation = { __typename?: 'mutation_root', updateInwardShipmentAdmin?: { __typename?: 'inwardShipments', id: number } | null };

export type UpdateInwardShipmentItemMutationVariables = Exact<{
  id: Scalars['Int'];
  inwardShipmentId: Scalars['Int'];
  rawMaterialId: Scalars['Int'];
  rmSellerId: Scalars['Int'];
  paddyGrade?: InputMaybe<Scalars['String']>;
  bagsCount: Scalars['Int'];
  materialReturned: Scalars['Boolean'];
  materialReturnedReason?: InputMaybe<Scalars['String']>;
  emptyBagsReturned: Scalars['Boolean'];
  godownId?: InputMaybe<Scalars['Int']>;
  destination?: InputMaybe<Enum_Source_Destination_Enum>;
  millId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateInwardShipmentItemMutation = { __typename?: 'mutation_root', updateInwardShipmentItem?: { __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, emptyBagsReturned?: boolean | null, pricePerKg?: any | null, destination?: Enum_Source_Destination_Enum | null, materialReturned?: boolean | null, materialReturnedReason?: string | null, shipment: { __typename?: 'inwardShipments', id: number, vehicleNumber?: string | null }, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, seller?: { __typename?: 'rmSellers', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null } | null };

export type UpdateInwardShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
  driverName: Scalars['String'];
  fullVehicleWtKg: Scalars['float8'];
  vehicleNumber: Scalars['String'];
}>;


export type UpdateInwardShipmentMutation = { __typename?: 'mutation_root', updateInwardShipment?: { __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> } | null };

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Items_Set_Input;
}>;


export type UpdateItemMutation = { __typename?: 'mutation_root', update_items_by_pk?: { __typename?: 'items', category: Enum_Item_Category_Enum, id: string, name: string } | null };

export type UpdateMillDetailsMutationVariables = Exact<{
  id: Scalars['Int'];
  address: Scalars['String'];
  company_name: Scalars['String'];
}>;


export type UpdateMillDetailsMutation = { __typename?: 'mutation_root', update_mills_by_pk?: { __typename?: 'mills', address?: string | null, id: number, name: string, company_name?: string | null } | null };

export type UpdateOutwardShipmentMutationVariables = Exact<{
  id: Scalars['Int'];
  productId: Scalars['Int'];
  plannedBagsCount: Scalars['Int'];
  bagSizeKg: Scalars['Int'];
  brokerId?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateOutwardShipmentMutation = { __typename?: 'mutation_root', updateOutwardShipmentOrder?: { __typename?: 'outwardShipmentOrders', id: number, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, brokerId?: string | null, invoiceNo?: string | null } | null };

export type UpdatePaymentDetailsMutationVariables = Exact<{
  id: Scalars['uuid'];
  amount?: InputMaybe<Scalars['numeric']>;
  deductions?: InputMaybe<Scalars['jsonb']>;
  payment_number?: InputMaybe<Scalars['String']>;
  payment_done_at?: InputMaybe<Scalars['timestamptz']>;
  mode?: InputMaybe<Scalars['String']>;
  inwardShipmentId: Scalars['Int'];
  isPaymentDone: Scalars['Boolean'];
}>;


export type UpdatePaymentDetailsMutation = { __typename?: 'mutation_root', update_payment_by_pk?: { __typename?: 'payment', amount?: any | null, deductions?: any | null, id: string, mode?: string | null, payment_done_at?: Date | string | null, payment_number?: string | null } | null, update_inwardShipments_by_pk?: { __typename?: 'inwardShipments', isPaymentDone: boolean, id: number } | null };

export type UpdateProductBuyerMutationVariables = Exact<{
  id: Scalars['Int'];
  address?: InputMaybe<Scalars['json']>;
  gstin?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile: Scalars['String'];
  primaryContactName: Scalars['String'];
}>;


export type UpdateProductBuyerMutation = { __typename?: 'mutation_root', updateProductBuyer?: { __typename?: 'productBuyers', id: number, name: string, address?: any | null, gstin?: string | null, primaryContactEmail?: string | null, primaryContactName?: string | null, primaryContactMobile?: string | null, profileCompleted?: boolean | null } | null };

export type UpdateProductInternalShipmentItemMutationVariables = Exact<{
  id: Scalars['uuid'];
  bagWtKg: Scalars['numeric'];
  bagsCount: Scalars['Int'];
  destination: Enum_InternalTransfer_Source_Destination_Enum;
  destinationGodownId?: InputMaybe<Scalars['Int']>;
  destinationMillId?: InputMaybe<Scalars['Int']>;
  internalTransferShipmentId: Scalars['uuid'];
  productId: Scalars['Int'];
  source: Enum_InternalTransfer_Source_Destination_Enum;
  sourceGodownId?: InputMaybe<Scalars['Int']>;
  sourceMillId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateProductInternalShipmentItemMutation = { __typename?: 'mutation_root', update_internalTransferShipmentItems_by_pk?: { __typename?: 'internalTransferShipmentItems', bagWtKg?: any | null, bagsCount?: number | null, created_at: Date | string, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, internalTransferShipmentId: string, id: string, source?: Enum_InternalTransfer_Source_Destination_Enum | null, updated_at: Date | string, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, product?: { __typename?: 'products', id: number, brand: string } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', id: number, name: string } | null } | null };

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['Int'];
  productTypeId: Scalars['Int'];
  brand: Scalars['String'];
}>;


export type UpdateProductMutation = { __typename?: 'mutation_root', update_products_by_pk?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null };

export type UpdateProductTypeMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type UpdateProductTypeMutation = { __typename?: 'mutation_root', updateProductType?: { __typename?: 'productTypes', id: number, name: string } | null };

export type UpdateRawMaterialMutationMutationVariables = Exact<{
  name: Scalars['String'];
  type?: InputMaybe<Enum_Raw_Material_Type_Enum>;
  objects: Array<RawMaterialsProductTypes_Insert_Input> | RawMaterialsProductTypes_Insert_Input;
  dltProductTypeIds: Array<Scalars['Int']> | Scalars['Int'];
  rawMaterialId: Scalars['Int'];
}>;


export type UpdateRawMaterialMutationMutation = { __typename?: 'mutation_root', insert_rawMaterialsProductTypes?: { __typename?: 'rawMaterialsProductTypes_mutation_response', returning: Array<{ __typename?: 'rawMaterialsProductTypes', productTypeId: number, rawMaterialId: number }> } | null, update_rawMaterials_by_pk?: { __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum, rawMaterialsProductTypes: Array<{ __typename?: 'rawMaterialsProductTypes', productType: { __typename?: 'productTypes', id: number, name: string } }> } | null, delete_rawMaterialsProductTypes?: { __typename?: 'rawMaterialsProductTypes_mutation_response', returning: Array<{ __typename?: 'rawMaterialsProductTypes', productTypeId: number, rawMaterialId: number }> } | null };

export type UpdateSettingMutationVariables = Exact<{
  name: Scalars['String'];
  intValue?: InputMaybe<Scalars['Int']>;
  floatValue?: InputMaybe<Scalars['float8']>;
  arrayStringValue?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type UpdateSettingMutation = { __typename?: 'mutation_root', updateSetting?: { __typename?: 'settings_mutation_response', returning: Array<{ __typename?: 'settings', id: number, name: string, arrayStringValue?: Array<string> | null, valueType: Enum_Setting_Value_Type_Enum, floatValue?: any | null, intValue?: number | null }> } | null };

export type UpdateTransporterDriverMutationVariables = Exact<{
  id: Scalars['Int'];
  _set: TransporterDrivers_Set_Input;
}>;


export type UpdateTransporterDriverMutation = { __typename?: 'mutation_root', updateTransporterDriver?: { __typename?: 'transporterDrivers', id: number, name: string, address?: any | null, mobile: string, drivingLicence?: string | null, profileCompleted?: boolean | null, photo?: string | null, panFile?: string | null } | null };

export type UpdateTransporterMutationVariables = Exact<{
  id: Scalars['Int'];
  address: Scalars['json'];
  gstin?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  primaryContactEmail?: InputMaybe<Scalars['String']>;
  primaryContactMobile: Scalars['String'];
  primaryContactName: Scalars['String'];
}>;


export type UpdateTransporterMutation = { __typename?: 'mutation_root', updateTransporter?: { __typename?: 'transporters', id: number, name: string, address?: any | null, gstin?: string | null, profileCompleted?: boolean | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null } | null };

export type UpdateTransporterVehicleMutationVariables = Exact<{
  id: Scalars['Int'];
  _set: TransporterVehicles_Set_Input;
}>;


export type UpdateTransporterVehicleMutation = { __typename?: 'mutation_root', updateTransporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, engineNo?: string | null, chassisNo?: string | null, capacityKgs?: any | null, rcS3Key?: string | null, transporter: { __typename?: 'transporters', id: number, name: string } } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int'];
  _set: Users_Set_Input;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: number, name?: string | null, address?: any | null, mobile?: string | null, email?: string | null, role: Enum_User_Role_Enum, dob?: Date | string | null, updatePassword: boolean } | null };

export type UpdateVendorByPkMutationVariables = Exact<{
  id: Scalars['Int'];
  objects: Array<VendorsItemCategories_Insert_Input> | VendorsItemCategories_Insert_Input;
  _set: RmSellers_Set_Input;
}>;


export type UpdateVendorByPkMutation = { __typename?: 'mutation_root', delete_vendorsItemCategories?: { __typename?: 'vendorsItemCategories_mutation_response', affected_rows: number } | null, insert_vendorsItemCategories?: { __typename?: 'vendorsItemCategories_mutation_response', affected_rows: number } | null, updateRmSeller?: { __typename?: 'rmSellers', address?: any | null, createdAt?: Date | string | null, doesMaintenance?: boolean | null, gstin?: string | null, id: number, name: string, primaryContactMobile?: string | null, primaryContactEmail?: string | null, primaryContactName?: string | null, profileCompleted?: boolean | null, updatedAt?: Date | string | null } | null };

export type UpdateWeighbridgeReceiptMutationVariables = Exact<{
  id: Scalars['Int'];
  fullVehicleWtKg: Scalars['float8'];
  amountTaken: Scalars['float8'];
  remark?: InputMaybe<Scalars['String']>;
}>;


export type UpdateWeighbridgeReceiptMutation = { __typename?: 'mutation_root', updateWeighbridgeReceipt?: { __typename?: 'weighbridgeReceipts', id: number, receiptNumber?: string | null, vehicleNumber: string, driverName: string, customerName: string, material?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, amountTaken?: any | null, remark?: string | null } | null };

export type AllItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllItemsQuery = { __typename?: 'query_root', items: Array<{ __typename?: 'items', category: Enum_Item_Category_Enum, id: string, name: string }> };

export type CheckRiceRawMaterialQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckRiceRawMaterialQuery = { __typename?: 'query_root', rawMaterials_aggregate: { __typename?: 'rawMaterials_aggregate', aggregate?: { __typename?: 'rawMaterials_aggregate_fields', count: number } | null } };

export type CustomItemListQueryVariables = Exact<{
  _category: Enum_Item_Category_Enum;
}>;


export type CustomItemListQuery = { __typename?: 'query_root', items: Array<{ __typename?: 'items', id: string, name: string }> };

export type CustomVendorListQueryVariables = Exact<{
  _item_category: Enum_Item_Category_Enum;
}>;


export type CustomVendorListQuery = { __typename?: 'query_root', rmSellers: Array<{ __typename?: 'rmSellers', name: string, id: number }> };

export type DailyInternalTransferShipmentsQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type DailyInternalTransferShipmentsQuery = { __typename?: 'query_root', internalTransferShipments: Array<{ __typename?: 'internalTransferShipments', created_at: Date | string, grn?: string | null, id: string, personName?: string | null, personMobile?: string | null, vehicleNumber?: string | null, materialTypeValue?: { __typename?: 'enum_item_category', display_name?: string | null } | null, internalTransferShipmentItems: Array<{ __typename?: 'internalTransferShipmentItems', remark?: string | null }> }> };

export type FileUploadS3UrlQueryVariables = Exact<{
  fileName: Scalars['String'];
  contentType: Scalars['String'];
}>;


export type FileUploadS3UrlQuery = { __typename?: 'query_root', fileUploadS3Url?: { __typename?: 'FileUploadS3UrlOutputType', url: string, key: string, policy: string, algorithm: string, credential: string, date: string, signature: string, contentType: string, contentDisposition: string } | null };

export type GetAdminOpenInwardShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminOpenInwardShipmentsQuery = { __typename?: 'query_root', getAdminOpenInwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> }> };

export type GetAdminReviewInwardShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminReviewInwardShipmentsQuery = { __typename?: 'query_root', getAdminReviewInwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> }> };

export type GetAllBrokersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllBrokersQuery = { __typename?: 'query_root', brokers: Array<{ __typename?: 'brokers', id: string, mobile?: string | null, name: string, email?: string | null, address?: any | null, gst?: string | null }> };

export type GetAllGodownsMillsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllGodownsMillsQuery = { __typename?: 'query_root', getAllMills: Array<{ __typename?: 'mills', id: number, name: string }>, getAllGodowns: Array<{ __typename?: 'godowns', id: number, name: string, location?: string | null, capacityBags?: number | null, description?: string | null, summary?: { __typename?: 'godownSummary', id?: number | null, currentBagsCount?: any | null } | null }> };

export type GetAllGodownsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllGodownsQuery = { __typename?: 'query_root', getAllGodowns: Array<{ __typename?: 'godowns', id: number, name: string, location?: string | null, capacityBags?: number | null, description?: string | null, summary?: { __typename?: 'godownSummary', id?: number | null, currentBagsCount?: any | null } | null }> };

export type GetAllInternalTransferShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInternalTransferShipmentsQuery = { __typename?: 'query_root', internalTransferShipments: Array<{ __typename?: 'internalTransferShipments', created_at: Date | string, grn?: string | null, id: string, personName?: string | null, personMobile?: string | null, vehicleNumber?: string | null, materialTypeValue?: { __typename?: 'enum_item_category', display_name?: string | null } | null, internalTransferShipmentItems: Array<{ __typename?: 'internalTransferShipmentItems', remark?: string | null }> }> };

export type GetAllInwardShipmentBySellerIdQueryVariables = Exact<{
  rmSellerId: Scalars['Int'];
}>;


export type GetAllInwardShipmentBySellerIdQuery = { __typename?: 'query_root', inwardShipments: Array<{ __typename?: 'inwardShipments', grn_v2?: string | null, id: number, isPaymentDone: boolean, weighbridgeCharges?: any | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, createdAt?: Date | string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, emptyBagCost?: any | null, pricePerKg?: any | null, destination?: Enum_Source_Destination_Enum | null, netMaterialWtPerBagKg?: any | null, brokeragePerQuintal?: any | null, materialReturned?: boolean | null, emptyBagsWtKg?: any | null, emptyBagsReturned?: boolean | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null }>, payments: Array<{ __typename?: 'payment', amount?: any | null, created_at: Date | string, deductions?: any | null, id: string, inward_shipment_id: number }> }> };

export type GetAllInwardShipmentForBagsQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type GetAllInwardShipmentForBagsQuery = { __typename?: 'query_root', inwardShipments: Array<{ __typename?: 'inwardShipments', createdAt?: Date | string | null, grn?: string | null, id: number, deliveryPersonMobile?: string | null, driverName?: string | null, vehicleNumber?: string | null, purchaseOrder?: { __typename?: 'purchaseOrders', poNumber?: string | null, id: number } | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, bagsCount?: number | null, isBackFromMaintenance?: boolean | null, destination?: Enum_Source_Destination_Enum | null, invoice?: string | null, remark?: string | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, seller?: { __typename?: 'rmSellers', id: number, name: string } | null, mill?: { __typename?: 'mills', id: number, name: string } | null }> }> };

export type GetAllInwardShipmentForOtherItemsQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type GetAllInwardShipmentForOtherItemsQuery = { __typename?: 'query_root', inwardShipments: Array<{ __typename?: 'inwardShipments', createdAt?: Date | string | null, id: number, grn?: string | null, deliveryPersonMobile?: string | null, driverName?: string | null, vehicleNumber?: string | null, purchaseOrder?: { __typename?: 'purchaseOrders', id: number, poNumber?: string | null } | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, destination?: Enum_Source_Destination_Enum | null, remark?: string | null, seller?: { __typename?: 'rmSellers', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, mill?: { __typename?: 'mills', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null }> }> };

export type GetAllInwardShipmentForRawMaterialByStartAndEndDateQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type GetAllInwardShipmentForRawMaterialByStartAndEndDateQuery = { __typename?: 'query_root', inwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, isPaymentDone: boolean, paymentComments?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, destination?: Enum_Source_Destination_Enum | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, mill?: { __typename?: 'mills', id: number, name: string } | null }> }> };

export type GetAllInwardShipmentForRawMaterialQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllInwardShipmentForRawMaterialQuery = { __typename?: 'query_root', inwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, isPaymentDone: boolean, paymentComments?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, emptyBagCost?: any | null, pricePerKg?: any | null, destination?: Enum_Source_Destination_Enum | null, netMaterialWtPerBagKg?: any | null, brokeragePerQuintal?: any | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, mill?: { __typename?: 'mills', id: number, name: string } | null }> }> };

export type GetAllItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllItemCategoriesQuery = { __typename?: 'query_root', enum_item_category: Array<{ __typename?: 'enum_item_category', display_name?: string | null, value: string }> };

export type GetAllMaterialUnloadingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMaterialUnloadingQuery = { __typename?: 'query_root', getOpenInwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> }>, getAllRmSellers: Array<{ __typename?: 'rmSellers', id: number, name: string, address?: any | null, gstin?: string | null, profileCompleted?: boolean | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null }>, getAllRawMaterials: Array<{ __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum, rawMaterialsProductTypes: Array<{ __typename?: 'rawMaterialsProductTypes', productType: { __typename?: 'productTypes', id: number, name: string } }> }>, getAllGodowns: Array<{ __typename?: 'godowns', id: number, name: string, location?: string | null, capacityBags?: number | null, description?: string | null, summary?: { __typename?: 'godownSummary', id?: number | null, currentBagsCount?: any | null } | null }> };

export type GetAllOutwardShipmentsForBagsQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type GetAllOutwardShipmentsForBagsQuery = { __typename?: 'query_root', outwardShipments: Array<{ __typename?: 'outwardShipments', createdAt?: Date | string | null, grn?: string | null, id: number, nonProductPersonMobile?: string | null, nonProductPersonName?: string | null, nonProductVehicleNumber?: string | null, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, source?: Enum_OutwardShipmentOrder_Source_Enum | null, loadedBagsCount?: number | null, isOutForMaintenance?: boolean | null, remark?: string | null, mill?: { __typename?: 'mills', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, paddyBagBuyer?: { __typename?: 'rmSellers', id: number, name: string } | null }> }> };

export type GetAllOutwardShipmentsForOtherItemsQueryQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type GetAllOutwardShipmentsForOtherItemsQueryQuery = { __typename?: 'query_root', outwardShipments: Array<{ __typename?: 'outwardShipments', createdAt?: Date | string | null, grn?: string | null, id: number, nonProductPersonMobile?: string | null, nonProductPersonName?: string | null, nonProductVehicleNumber?: string | null, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, source?: Enum_OutwardShipmentOrder_Source_Enum | null, othersItemName?: string | null, othersDestination?: string | null, othersWtKg?: any | null, othersQuantity?: any | null, remark?: string | null, mill?: { __typename?: 'mills', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null }> }> };

export type GetAllOutwardShipmentsForProductsQueryVariables = Exact<{
  createdAt: Timestamptz_Comparison_Exp;
}>;


export type GetAllOutwardShipmentsForProductsQuery = { __typename?: 'query_root', outwardShipments: Array<{ __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string, address?: any | null } | null, broker?: { __typename?: 'brokers', id: string, name: string } | null }> }> };

export type GetProductBuyerByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProductBuyerByIdQuery = { __typename?: 'query_root', getProductBuyerById?: { __typename?: 'productBuyers', id: number, name: string, gstin?: string | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, address?: any | null } | null };

export type GetAllProductBuyersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductBuyersQuery = { __typename?: 'query_root', getAllProductBuyers: Array<{ __typename?: 'productBuyers', id: number, name: string, address?: any | null, gstin?: string | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, profileCompleted?: boolean | null }> };

export type GetAllProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductTypesQuery = { __typename?: 'query_root', getAllProductTypes: Array<{ __typename?: 'productTypes', id: number, name: string }> };

export type GetAllProductsAndProductTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductsAndProductTypesQuery = { __typename?: 'query_root', getAllProducts: Array<{ __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } }>, getAllProductTypes: Array<{ __typename?: 'productTypes', id: number, name: string }> };

export type GetAllProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductsQuery = { __typename?: 'query_root', getAllProducts: Array<{ __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } }>, getAllProductTypes: Array<{ __typename?: 'productTypes', id: number, name: string }> };

export type GetAllPurchaseOrderBasedOnItemCategoryQueryVariables = Exact<{
  item_category: Enum_Item_Category_Enum;
}>;


export type GetAllPurchaseOrderBasedOnItemCategoryQuery = { __typename?: 'query_root', purchaseOrders: Array<{ __typename?: 'purchaseOrders', poApproved?: boolean | null, id: number, poNumber?: string | null, created_at: Date | string, delivery_completed_at?: Date | string | null, purchaseOrderItems: Array<{ __typename?: 'purchaseOrderItems', id: number, orderedItemsCount?: number | null, unitPrice?: any | null, remarks?: string | null, gstPercent?: any | null, item: { __typename?: 'items', id: string, name: string }, vendor: { __typename?: 'rmSellers', id: number, name: string, gstin?: string | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null } }> }> };

export type GetAllPurchaseOrderQueryVariables = Exact<{
  item_category: Enum_Item_Category_Enum;
}>;


export type GetAllPurchaseOrderQuery = { __typename?: 'query_root', purchaseOrders: Array<{ __typename?: 'purchaseOrders', id: number, poNumber?: string | null, poApproved?: boolean | null, delivery_completed_at?: Date | string | null, purchaseOrderItems: Array<{ __typename?: 'purchaseOrderItems', item: { __typename?: 'items', id: string, name: string, category: Enum_Item_Category_Enum }, vendor: { __typename?: 'rmSellers', id: number, name: string } }> }> };

export type GetAllRawMaterialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRawMaterialsQuery = { __typename?: 'query_root', getAllRawMaterials: Array<{ __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum, rawMaterialsProductTypes: Array<{ __typename?: 'rawMaterialsProductTypes', productType: { __typename?: 'productTypes', id: number, name: string } }> }>, getAllProductTypes: Array<{ __typename?: 'productTypes', id: number, name: string }> };

export type GetAllRmSellersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRmSellersQuery = { __typename?: 'query_root', getAllRmSellers: Array<{ __typename?: 'rmSellers', gstin?: string | null, id: number, name: string, primaryContactMobile?: string | null, primaryContactName?: string | null, vendorsItemCategories: Array<{ __typename?: 'vendorsItemCategories', vendorId: number, item_category: { __typename?: 'enum_item_category', value: string, display_name?: string | null } }> }> };

export type GetAllTransporterDriversQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTransporterDriversQuery = { __typename?: 'query_root', getAllTransporterDrivers: Array<{ __typename?: 'transporterDrivers', id: number, name: string, address?: any | null, mobile: string, drivingLicence?: string | null, profileCompleted?: boolean | null, photo?: string | null, panFile?: string | null }> };

export type GetAllTransportersAndTransporterVehiclesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTransportersAndTransporterVehiclesQuery = { __typename?: 'query_root', getAllTransporterVehicles: Array<{ __typename?: 'transporterVehicles', id: number, number?: string | null, engineNo?: string | null, chassisNo?: string | null, capacityKgs?: any | null, rcS3Key?: string | null, transporter: { __typename?: 'transporters', id: number, name: string } }>, getAllTransporters: Array<{ __typename?: 'transporters', id: number, name: string, address?: any | null, gstin?: string | null, profileCompleted?: boolean | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null }> };

export type GetAllTransportersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTransportersQuery = { __typename?: 'query_root', getAllTransporters: Array<{ __typename?: 'transporters', id: number, name: string, address?: any | null, gstin?: string | null, profileCompleted?: boolean | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null }> };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'query_root', getAllUsers: Array<{ __typename?: 'users', id: number, name?: string | null, mobile?: string | null, email?: string | null, updatePassword: boolean, dob?: Date | string | null, address?: any | null }> };

export type GetAllVehiclesOfTransporterQueryVariables = Exact<{
  transporterId: Scalars['Int'];
}>;


export type GetAllVehiclesOfTransporterQuery = { __typename?: 'query_root', getAllVehiclesOfTransporter: Array<{ __typename?: 'transporterVehicles', id: number, number?: string | null, engineNo?: string | null, chassisNo?: string | null, capacityKgs?: any | null, rcS3Key?: string | null, transporter: { __typename?: 'transporters', id: number, name: string } }> };

export type GetAllVendorsAndItemsBasedOnItemCategoryQueryVariables = Exact<{
  item_category: Enum_Item_Category_Enum;
}>;


export type GetAllVendorsAndItemsBasedOnItemCategoryQuery = { __typename?: 'query_root', rmSellers: Array<{ __typename?: 'rmSellers', id: number, name: string, gstin?: string | null, primaryContactName?: string | null, primaryContactMobile?: string | null, primaryContactEmail?: string | null, address?: any | null, profileCompleted?: boolean | null }>, items: Array<{ __typename?: 'items', category: Enum_Item_Category_Enum, id: string, name: string }> };

export type GetAllWeighbridgeEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllWeighbridgeEntriesQuery = { __typename?: 'query_root', weighbridgeReceipts: Array<{ __typename?: 'weighbridgeReceipts', id: number, amountTaken?: any | null, createdAt?: Date | string | null, customerName: string, driverName: string, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, material?: string | null, receiptNumber?: string | null, remark?: string | null, vehicleNumber: string }>, weighbridgeReceipts_aggregate: { __typename?: 'weighbridgeReceipts_aggregate', aggregate?: { __typename?: 'weighbridgeReceipts_aggregate_fields', sum?: { __typename?: 'weighbridgeReceipts_sum_fields', amountTaken?: any | null } | null } | null } };

export type GetBhagwatiMillIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBhagwatiMillIdQuery = { __typename?: 'query_root', mills: Array<{ __typename?: 'mills', id: number, name: string, address?: string | null, company_name?: string | null }> };

export type GetBrokerByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetBrokerByIdQuery = { __typename?: 'query_root', brokers_by_pk?: { __typename?: 'brokers', address?: any | null, email?: string | null, gst?: string | null, mobile?: string | null, name: string, id: string } | null };

export type GetFilesUrlQueryVariables = Exact<{
  keys: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetFilesUrlQuery = { __typename?: 'query_root', getFilesUrl?: Array<{ __typename?: 'GetFilesUrlOutputType', key: string, url: string } | null> | null };

export type GetGodownByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetGodownByIdQuery = { __typename?: 'query_root', getGodownById?: { __typename?: 'godowns', id: number, name: string, capacityBags?: number | null, location?: string | null, summary?: { __typename?: 'godownSummary', id?: number | null, currentBagsCount?: any | null } | null } | null };

export type GetGodownCurrentStateByGodownIdQueryVariables = Exact<{
  godownId: Scalars['Int'];
}>;


export type GetGodownCurrentStateByGodownIdQuery = { __typename?: 'query_root', getGodownCurrentStateByGodownId: Array<{ __typename?: 'GodownCurrentState', id?: string | null, godownId?: number | null, paddyGrade?: string | null, inBagsCount?: any | null, outBagsCount?: any | null, currentBagsCount?: any | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum } | null }> };

export type GetGodownCurrentStateByRawMaterialIdQueryVariables = Exact<{
  rawMaterialId: Scalars['Int'];
}>;


export type GetGodownCurrentStateByRawMaterialIdQuery = { __typename?: 'query_root', getGodownCurrentStateByRawMaterialId: Array<{ __typename?: 'GodownCurrentState', id?: string | null, godownId?: number | null, rawMaterialId?: number | null, paddyGrade?: string | null, inBagsCount?: any | null, outBagsCount?: any | null, currentBagsCount?: any | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> };

export type GetInternalTransferShipmentByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetInternalTransferShipmentByPkQuery = { __typename?: 'query_root', internalTransferShipments_by_pk?: { __typename?: 'internalTransferShipments', created_at: Date | string, firstVehicleImage?: string | null, firstVehicleWtKg?: any | null, grn?: string | null, personMobile?: string | null, personName?: string | null, secondVehicleImage?: string | null, secondVehicleWtKg?: any | null, vehicleNumber?: string | null, materialTypeValue?: { __typename?: 'enum_item_category', display_name?: string | null, value: string } | null, internalTransferShipmentItems: Array<{ __typename?: 'internalTransferShipmentItems', id: string, bagsCount?: number | null, rmPaddyGrade?: string | null, rmEmptyBagsWtKg?: any | null, netMaterialWtPerBagKg?: any | null, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, source?: Enum_InternalTransfer_Source_Destination_Enum | null, bagWtKg?: any | null, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, remark?: string | null, item?: { __typename?: 'items', id: string, name: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', id: number, name: string } | null, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, product?: { __typename?: 'products', brand: string, id: number, productType: { __typename?: 'productTypes', id: number, name: string } } | null }> } | null };

export type GetInwardShipmentByIdModifyGrnQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInwardShipmentByIdModifyGrnQuery = { __typename?: 'query_root', getInwardShipmentByIdModifyGrn?: { __typename?: 'inwardShipments', completedAt?: Date | string | null, id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, billing_details?: any | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, destination?: Enum_Source_Destination_Enum | null, bagsCount?: number | null, materialReturned?: boolean | null, materialReturnedReason?: string | null, emptyBagsReturned?: boolean | null, pricePerKg?: any | null, emptyBagCost?: any | null, emptyBagsWtKg?: any | null, userEmptyBagWtUnit?: Enum_Empty_Bag_Wt_Unit_Enum | null, brokeragePerQuintal?: any | null, netMaterialWtPerBagKg?: any | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, mill?: { __typename?: 'mills', id: number, name: string } | null }> } | null, getAllRmSellers: Array<{ __typename?: 'rmSellers', id: number, name: string }>, getAllRawMaterials: Array<{ __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum }>, getAllMills: Array<{ __typename?: 'mills', id: number, name: string }>, getAllGodowns: Array<{ __typename?: 'godowns', id: number, name: string, location?: string | null, capacityBags?: number | null, description?: string | null, summary?: { __typename?: 'godownSummary', id?: number | null, currentBagsCount?: any | null } | null }> };

export type GetInwardShipmentByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInwardShipmentByIdQuery = { __typename?: 'query_root', getInwardShipmentById?: { __typename?: 'inwardShipments', completedAt?: Date | string | null, id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, isPaymentDone: boolean, paymentComments?: string | null, deliveryPersonMobile?: string | null, billing_details?: any | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, emptyBagCost?: any | null, emptyBagsWtKg?: any | null, pricePerKg?: any | null, emptyBagsReturned?: boolean | null, materialReturned?: boolean | null, destination?: Enum_Source_Destination_Enum | null, netMaterialWtPerBagKg?: any | null, brokeragePerQuintal?: any | null, userEmptyBagWtUnit?: Enum_Empty_Bag_Wt_Unit_Enum | null, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, isBackFromMaintenance?: boolean | null, godown?: { __typename?: 'godowns', id: number, name: string } | null, seller?: { __typename?: 'rmSellers', id: number, name: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null }> } | null, mills: Array<{ __typename?: 'mills', id: number, name: string, address?: string | null, company_name?: string | null }> };

export type GetInwardShipmentItemsQueryVariables = Exact<{
  inwardShipmentId: Scalars['Int'];
}>;


export type GetInwardShipmentItemsQuery = { __typename?: 'query_root', getInwardShipmentItems: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, emptyBagsReturned?: boolean | null, pricePerKg?: any | null, destination?: Enum_Source_Destination_Enum | null, materialReturned?: boolean | null, materialReturnedReason?: string | null, shipment: { __typename?: 'inwardShipments', id: number, vehicleNumber?: string | null, grn?: string | null }, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, seller?: { __typename?: 'rmSellers', id: number, name: string } | null, godown?: { __typename?: 'godowns', id: number, name: string } | null }> };

export type GetInwardShipmentsForMonitoringQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInwardShipmentsForMonitoringQueryQuery = { __typename?: 'query_root', getInwardShipmentsForMonitoring: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> }> };

export type GetOpenInwardShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenInwardShipmentsQuery = { __typename?: 'query_root', getOpenInwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> }> };

export type GetOpenWeighbridgeReceiptsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenWeighbridgeReceiptsQuery = { __typename?: 'query_root', getOpenWeighbridgeReceipts: Array<{ __typename?: 'weighbridgeReceipts', id: number, receiptNumber?: string | null, vehicleNumber: string, driverName: string, customerName: string, material?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, amountTaken?: any | null, remark?: string | null }> };

export type GetOutwardShipmentByIdQueryVariables = Exact<{
  outwardShipmentId: Scalars['Int'];
}>;


export type GetOutwardShipmentByIdQuery = { __typename?: 'query_root', getOutwardShipmentById?: { __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, truckOutAt?: Date | string | null, ordersTakenAt?: Date | string | null, loadedAt?: Date | string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null, broker?: { __typename?: 'brokers', id: string, name: string } | null }> } | null };

export type GetOutwardShipmentOrdersByBuyerQueryVariables = Exact<{
  productBuyerId: Scalars['Int'];
}>;


export type GetOutwardShipmentOrdersByBuyerQuery = { __typename?: 'query_root', getOutwardShipmentOrdersByBuyer: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, outwardShipment: { __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, truckOutAt?: Date | string | null }, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string, primaryContactName?: string | null, primaryContactMobile?: string | null, gstin?: string | null, address?: any | null } | null }> };

export type GetOutwardShipmentProductsForMonitoringQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutwardShipmentProductsForMonitoringQueryQuery = { __typename?: 'query_root', getOutwardShipmentProductsForMonitoring: Array<{ __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null }> }> };

export type GetOutwardShipmentsForInvoicingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutwardShipmentsForInvoicingQuery = { __typename?: 'query_root', getOutwardShipmentsForInvoicing: Array<{ __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null }> }> };

export type GetOutwardShipmentsForOrdersAndLoadingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutwardShipmentsForOrdersAndLoadingQuery = { __typename?: 'query_root', getOutwardShipmentsForOrders: Array<{ __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null }> }> };

export type GetOutwardShipmentsForOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutwardShipmentsForOrdersQuery = { __typename?: 'query_root', getOutwardShipmentsForOrders: Array<{ __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null }> }> };

export type GetOutwardShipmentsForTruckOutQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOutwardShipmentsForTruckOutQuery = { __typename?: 'query_root', getOutwardShipmentsForTruckOut: Array<{ __typename?: 'outwardShipments', id: number, grn?: string | null, createdAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null, status?: string | null, transporter?: { __typename?: 'transporters', id: number, name: string } | null, transporterVehicle?: { __typename?: 'transporterVehicles', id: number, number?: string | null, capacityKgs?: any | null, rcS3Key?: string | null } | null, drivers: Array<{ __typename?: 'outwardShipmentsDrivers', driver: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, panFile?: string | null, photo?: string | null } }>, orders: Array<{ __typename?: 'outwardShipmentOrders', id: number, number?: string | null, bagSizeKg?: number | null, plannedBagsCount?: number | null, loadedBagsCount?: number | null, invoiceNo?: string | null, product?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null, buyer?: { __typename?: 'productBuyers', id: number, name: string } | null }> }> };

export type GetPoDeliveryDataQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPoDeliveryDataQuery = { __typename?: 'query_root', purchaseOrders_by_pk?: { __typename?: 'purchaseOrders', poApproved?: boolean | null, id: number, created_at: Date | string, poNumber?: string | null, created_by_name?: string | null, created_by_contact_no?: string | null, delivery_completed_at?: Date | string | null, contract_validity?: Date | string | null, purchaseOrderItems: Array<{ __typename?: 'purchaseOrderItems', id: number, orderedItemsCount?: number | null, quantity?: number | null, item: { __typename?: 'items', id: string, name: string }, vendor: { __typename?: 'rmSellers', name: string, gstin?: string | null } }>, inwardShipments: Array<{ __typename?: 'inwardShipments', id: number, po_id?: number | null, grn_v2?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', itemId?: string | null, othersItemName?: string | null, othersQuantity?: any | null, bagsCount?: number | null }> }> } | null };

export type GetPaymentDetailsByGrnQueryVariables = Exact<{
  grn: Scalars['String'];
}>;


export type GetPaymentDetailsByGrnQuery = { __typename?: 'query_root', payment: Array<{ __typename?: 'payment', amount?: any | null, created_at: Date | string, deductions?: any | null, id: string, mode?: string | null, payment_done_at?: Date | string | null, rmSeller_id: number, payment_number?: string | null }> };

export type GetProductByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProductByIdQuery = { __typename?: 'query_root', getProductById?: { __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } } | null };

export type GetProductInwardShipmentItemsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;


export type GetProductInwardShipmentItemsQuery = { __typename?: 'query_root', internalTransferShipmentItems: Array<{ __typename?: 'internalTransferShipmentItems', id: string, bagWtKg?: any | null, bagsCount?: number | null, created_at: Date | string, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, internalTransferShipmentId: string, source?: Enum_InternalTransfer_Source_Destination_Enum | null, updated_at: Date | string, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, product?: { __typename?: 'products', brand: string, id: number, productType: { __typename?: 'productTypes', id: number, name: string } } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', id: number, name: string } | null }> };

export type GetProductsOfProductTypeQueryQueryVariables = Exact<{
  productTypeId: Scalars['Int'];
}>;


export type GetProductsOfProductTypeQueryQuery = { __typename?: 'query_root', getProductsOfProductType: Array<{ __typename?: 'products', id: number, brand: string }> };

export type GetPurchaseOrderByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPurchaseOrderByIdQuery = { __typename?: 'query_root', purchaseOrders_by_pk?: { __typename?: 'purchaseOrders', poApproved?: boolean | null, id: number, created_at: Date | string, poNumber?: string | null, created_by_name?: string | null, created_by_contact_no?: string | null, payment_terms?: string | null, discount?: any | null, contract_validity?: Date | string | null, purchaseOrderItems: Array<{ __typename?: 'purchaseOrderItems', id: number, orderedItemsCount?: number | null, unitPrice?: any | null, remarks?: string | null, gstPercent?: any | null, quantity?: number | null, discount?: any | null, item: { __typename?: 'items', id: string, name: string }, vendor: { __typename?: 'rmSellers', id: number, name: string, gstin?: string | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, address?: any | null }, broker?: { __typename?: 'brokers', name: string } | null }> } | null, mills: Array<{ __typename?: 'mills', id: number, name: string, address?: string | null, company_name?: string | null }> };

export type GetRawMaterialByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRawMaterialByIdQuery = { __typename?: 'query_root', getRawMaterialById?: { __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum, rawMaterialsProductTypes: Array<{ __typename?: 'rawMaterialsProductTypes', productType: { __typename?: 'productTypes', id: number, name: string } }> } | null };

export type GetRawMaterialsByRiceTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRawMaterialsByRiceTypeQuery = { __typename?: 'query_root', rawMaterials: Array<{ __typename?: 'rawMaterials', id: number, name: string }> };

export type GetRmSellerByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRmSellerByIdQuery = { __typename?: 'query_root', getRmSellerById?: { __typename?: 'rmSellers', id: number, name: string, gstin?: string | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, address?: any | null, doesMaintenance?: boolean | null, inwardShipmentItems: Array<{ __typename?: 'inwardShipmentItems', id: number, destination?: Enum_Source_Destination_Enum | null, materialReturned?: boolean | null, paddyGrade?: string | null, netMaterialWtPerBagKg?: any | null, pricePerKg?: any | null, emptyBagCost?: any | null, emptyBagsWtKg?: any | null, bagsCount?: number | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string, type: Enum_Raw_Material_Type_Enum } | null, shipment: { __typename?: 'inwardShipments', id: number, grn?: string | null, completedAt?: Date | string | null, fullVehicleWtKg?: any | null, emptyVehicleWtKg?: any | null }, godown?: { __typename?: 'godowns', id: number, name: string } | null }>, vendorsItemCategories: Array<{ __typename?: 'vendorsItemCategories', itemCategory: Enum_Item_Category_Enum, vendorId: number }>, payments: Array<{ __typename?: 'payment', amount?: any | null, deductions?: any | null, id: string, created_at: Date | string }> } | null };

export type GetSettingByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetSettingByNameQuery = { __typename?: 'query_root', getSettingByName: Array<{ __typename?: 'settings', id: number, name: string, valueType: Enum_Setting_Value_Type_Enum, arrayStringValue?: Array<string> | null, floatValue?: any | null, intValue?: number | null }> };

export type GetShipmentOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShipmentOrdersQuery = { __typename?: 'query_root', getAllProductBuyers: Array<{ __typename?: 'productBuyers', id: number, name: string }>, getAllProductTypes: Array<{ __typename?: 'productTypes', id: number, name: string }>, getAllProducts: Array<{ __typename?: 'products', id: number, brand: string, productType: { __typename?: 'productTypes', id: number, name: string } }>, getAllBrokers: Array<{ __typename?: 'brokers', id: string, name: string }> };

export type GetTransporterByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTransporterByIdQuery = { __typename?: 'query_root', getTransporterById?: { __typename?: 'transporters', id: number, name: string, gstin?: string | null, primaryContactName?: string | null, primaryContactEmail?: string | null, primaryContactMobile?: string | null, address?: any | null, vehicles: Array<{ __typename?: 'transporterVehicles', id: number, number?: string | null, rcS3Key?: string | null }> } | null };

export type GetTransporterDriverByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTransporterDriverByIdQuery = { __typename?: 'query_root', getTransporterDriverById?: { __typename?: 'transporterDrivers', id: number, name: string, mobile: string, drivingLicence?: string | null, address?: any | null, photo?: string | null, panFile?: string | null } | null };

export type GetTransporterVehicleByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTransporterVehicleByIdQuery = { __typename?: 'query_root', getTransporterVehicleById?: { __typename?: 'transporterVehicles', id: number, number?: string | null, engineNo?: string | null, chassisNo?: string | null, capacityKgs?: any | null, rcS3Key?: string | null, transporter: { __typename?: 'transporters', id: number, name: string } } | null };

export type GetUnloadedInwardShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnloadedInwardShipmentsQuery = { __typename?: 'query_root', getUnloadedInwardShipments: Array<{ __typename?: 'inwardShipments', id: number, grn?: string | null, vehicleNumber?: string | null, driverName?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, status?: Enum_Inward_Shipment_Status_Enum | null, completedAt?: Date | string | null, createdAt?: Date | string | null, laborChargeType?: Enum_Labor_Charge_Type_Enum | null, laborChargePerBag?: any | null, weighbridgeCharges?: any | null, fullVehicleImage?: string | null, emptyVehicleImage?: string | null, items: Array<{ __typename?: 'inwardShipmentItems', id: number, paddyGrade?: string | null, bagsCount?: number | null, seller?: { __typename?: 'rmSellers', id: number, name: string, address?: any | null } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null }> }> };

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserByIdQuery = { __typename?: 'query_root', getUserById?: { __typename?: 'users', id: number, name?: string | null, email?: string | null, mobile?: string | null, dob?: Date | string | null, role: Enum_User_Role_Enum, updatePassword: boolean, address?: any | null } | null };

export type GetWeighbridgeReceiptByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetWeighbridgeReceiptByIdQuery = { __typename?: 'query_root', getWeighbridgeReceiptById?: { __typename?: 'weighbridgeReceipts', id: number, receiptNumber?: string | null, vehicleNumber: string, createdAt?: Date | string | null, driverName: string, customerName: string, material?: string | null, emptyVehicleWtKg?: any | null, fullVehicleWtKg?: any | null, amountTaken?: any | null, remark?: string | null } | null };

export type GetWeighbridgeWeightQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWeighbridgeWeightQuery = { __typename?: 'query_root', getWeighbridgeWeight?: { __typename?: 'getWeighbridgeWeightOutput', weight: number } | null };

export type InternalTransferOpenShipmentsQueryVariables = Exact<{
  materialType: Enum_Item_Category_Enum;
}>;


export type InternalTransferOpenShipmentsQuery = { __typename?: 'query_root', internalTransferShipments: Array<{ __typename?: 'internalTransferShipments', firstVehicleWtKg?: any | null, id: string, personName?: string | null, vehicleNumber?: string | null, grn?: string | null, firstVehicleImage?: string | null, materialType?: Enum_Item_Category_Enum | null, firstVehicleWtTakenAt?: Date | string | null }> };

export type InternalTransferShipmentsForFinishingQueryVariables = Exact<{
  materialType: Enum_Item_Category_Enum;
}>;


export type InternalTransferShipmentsForFinishingQuery = { __typename?: 'query_root', internalTransferShipments: Array<{ __typename?: 'internalTransferShipments', id: string, personName?: string | null, vehicleNumber?: string | null, grn?: string | null, materialType?: Enum_Item_Category_Enum | null, firstVehicleWtKg?: any | null, internalTransferShipmentItems: Array<{ __typename?: 'internalTransferShipmentItems', bagsCount?: number | null, id: string, rmEmptyBagsWtKg?: any | null }> }> };

export type InternalTransferShipmentsItemsQueryVariables = Exact<{
  _internalTransferShipmentId: Scalars['uuid'];
}>;


export type InternalTransferShipmentsItemsQuery = { __typename?: 'query_root', internalTransferShipmentItems: Array<{ __typename?: 'internalTransferShipmentItems', bagsCount?: number | null, destination?: Enum_InternalTransfer_Source_Destination_Enum | null, id: string, bagWtKg?: any | null, created_at: Date | string, internalTransferShipmentId: string, netMaterialWtPerBagKg?: any | null, othersItemName?: string | null, othersQuantity?: any | null, othersWtKg?: any | null, rmEmptyBagsWtKg?: any | null, rmPaddyGrade?: string | null, source?: Enum_InternalTransfer_Source_Destination_Enum | null, updated_at: Date | string, destinationGodown?: { __typename?: 'godowns', id: number, name: string } | null, destinationMill?: { __typename?: 'mills', id: number, name: string } | null, item?: { __typename?: 'items', id: string, name: string } | null, product?: { __typename?: 'products', id: number, brand: string } | null, rawMaterial?: { __typename?: 'rawMaterials', id: number, name: string } | null, sourceGodown?: { __typename?: 'godowns', id: number, name: string } | null, sourceMill?: { __typename?: 'mills', id: number, name: string } | null }> };

export type ItemsEnumInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ItemsEnumInfoQuery = { __typename?: 'query_root', enum_item_category: Array<{ __typename?: 'enum_item_category', display_name?: string | null, value: string }> };
